import {createRouter, createWebHistory} from 'vue-router'
import {analytic, get_uuid, is_historical_route} from "@/library";
import login from '../components/basics/login'
import home from '../components/basics/home'
import tmh_admin_main from "@/components/orgs/tmh_admin/tmh_admin_main";
import tmh_freetime_controls from "@/components/orgs/tmh_admin/tmh_freetime_controls";
import tmh_internal_controls from "@/components/orgs/tmh_admin/tmh_internal_controls";
import tmh_mother_pda_controls from "@/components/orgs/tmh_admin/tmh_mother_pda_controls";
import personal_profile_new from "@/components/profiles/personal_profile";
import FreeTime_interface from "@/components/FreeTime/FreeTime_interface";
import spyglass_agency_interface from "@/components/SpyGlass/spyglass_agency_interface";
import pda_profile_main from "@/components/orgs/pda/pda_profile_main";
import hello_landing from "@/components/orgs/pda/hello_landing";
import mfa_login from "@/components/basics/mfa_login";
import mfa_register from "@/components/basics/mfa_register";
import mfa_confirm_registration from "@/components/basics/mfa_confirm_registration";
import mfa_verify_totp from "@/components/basics/mfa_verify_totp";
import mfa_forgotten_pw from "@/components/basics/mfa_forgotten_pw";
import mfa_confirm_password_reset from "@/components/basics/mfa_confirm_password_reset";
import store from '../store'
import {Database} from "@/client_db";
import new_register from "@/components/basics/new_register";
import new_forgotten_pw from "@/components/basics/new_forgotten_pw";
import new_confirm_registration from "@/components/basics/new_confirm_registration";
import new_confirm_password_reset from "@/components/basics/new_confirm_password_reset";
import agencies_in_area from "@/components/funnel/agencies_in_area";
import job_request from "@/components/funnel/job_request";
import resend_registration_confirmation_code from "@/components/basics/resend_registration_confirmation_code";
import tmh_geography_controls from "@/components/orgs/tmh_admin/tmh_geography_controls";
import tmh_dashboard from "@/components/orgs/tmh_admin/tmh_dashboard.vue";
import tmh_spyglass_controls from "@/components/orgs/tmh_admin/tmh_spyglass_controls";
import tmh_speedydelivery_controls from "@/components/orgs/tmh_admin/tmh_speedydelivery_controls";
import spyglass_agency_landing_page from "@/components/SpyGlass/spyglass_agency_landing_page";
import sg_outside from "@/components/basics/sg_outside";
import spyglass_agency_landing_page_public from "@/components/SpyGlass/spyglass_agency_landing_page_public";
import contact_form from "@/components/basics/contact_form";
import for_caregivers from "@/components/funnel/for_caregivers";
import for_agencies from "@/components/funnel/for_agencies";
import forums from "@/components/basics/forums";
import forums_control from "@/components/orgs/tmh_admin/forums_control";
import personal_info from "@/components/profiles/personal_info";
import pda_info from "@/components/orgs/pda/pda_info";
import freetime_cg_help from "@/components/FreeTime/freetime_cg_help";
import freetime_admin_help from "@/components/FreeTime/freetime_admin_help";
import about from "@/components/basics/about";
import check_out_freetime from "@/components/funnel/landing_pages/facebook/check_out_freetime";
import check_out_spyglass from "@/components/funnel/landing_pages/facebook/check_out_spyglass";
import infection_control from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/infection_control_test";
import sms_opt_in from "@/components/basics/sms_opt_in";
import terms_of_service from "@/components/basics/terms_of_service";
import privacy from "@/components/basics/privacy";
import chatbot from "@/components/funnel/chatbot";
import needing_caregiver from "@/components/funnel/needing_caregiver";
import what_you_need_to_know from "@/components/blog/what_you_need_to_know";
import notfications_campaign from "@/components/funnel/landing_pages/facebook/notfications_campaign";
import speedydelivery_main_user_interface from "@/components/SpeedyDelivery/speedydelivery_main_user_interface";

import congrats_on_discount from "@/components/Vortex/congrats_on_discount.vue";

import copd from "@/components/SpeedyDelivery/module_pages/copd";
import copd_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/copd_test";

import diabetes from "@/components/SpeedyDelivery/module_pages/diabetes";
import diabetes_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/diabetes_test";

import fall_risk from "@/components/SpeedyDelivery/module_pages/fall_risk";
import fall_risk_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/fall_risk_test";

import hepatitis from "@/components/SpeedyDelivery/module_pages/hepatitis";
import hepatitis_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/hepatitis_test";

import hoarding from "@/components/SpeedyDelivery/module_pages/hoarding";
import hoarding_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/hoarding_test";

import uti from "@/components/SpeedyDelivery/module_pages/uti";
import uti_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/uti_test";

import vascular_problems from "@/components/SpeedyDelivery/module_pages/vascular_problems";
import vascular_problems_test
    from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/vascular_problems_test";

import shingles from "@/components/SpeedyDelivery/module_pages/shingles";
import shingles_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/shingles_test";

import abuse from "@/components/SpeedyDelivery/module_pages/abuse";
import abuse_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/abuse_test";
import client_bill_of_rights_test
    from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/client_bill_of_rights_test";
import documentation_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/documentation_test";
import first_aid_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/first_aid_test";
import hippa_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/hippa_test";
import infection_control_test
    from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/infection_control_test";
import neglect_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/neglect_test";
import professionalism_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/professionalism_test";
import transfers_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/transfers_test";
import in_service_registration from "@/components/SpeedyDelivery/in_service_registration";
import neglect from "@/components/SpeedyDelivery/module_pages/neglect";
import alzheimers from "@/components/SpeedyDelivery/module_pages/alzheimers";
import client_bill_of_rights from "@/components/SpeedyDelivery/module_pages/client_bill_of_rights";
import documentation from "@/components/SpeedyDelivery/module_pages/documentation";
import first_aid from "@/components/SpeedyDelivery/module_pages/first_aid";
import hippa from "@/components/SpeedyDelivery/module_pages/hippa";
import infection_control_module from "@/components/SpeedyDelivery/module_pages/infection_control_module";
import professionalism from "@/components/SpeedyDelivery/module_pages/professionalism";
import transfers_2 from "@/components/SpeedyDelivery/module_pages/transfers_2";
import alzheimers_test from "@/components/SpeedyDelivery/speedydelivery_tests/in_the_know/alzheimers_test";
import speedy_delivery_agency_interface from "@/components/SpeedyDelivery/speedy_delivery_agency_interface";
import upload_caregiver_document from "@/components/SpeedyDelivery/upload_caregiver_document";
import custom_in_service from "@/components/SpeedyDelivery/custom_in_service";
import agency_local_docs_main from "@/components/SpeedyDelivery/agency_local_docs_main.vue";
import SpyGlassPlusAd from "@/components/SpyGlass/SpyGlassPlus/SpyGlassPlusAd.vue";
import interested_view from "@/components/SpyGlass/SpyGlassPlus/interested_view.vue";
import interested_view_trad from "@/components/SpyGlass/SpyGlassSMS/interested_view_trad.vue";
import main_ad_page from "@/components/Vortex/JointAdPage/main_ad_page.vue";
import market_ad_page from "@/components/Vortex/JointAdPage/market_ad_page.vue";
import vortex_admin_main from "@/components/Vortex/vortex_admin_main.vue";
import register_discount from "@/components/Vortex/JointAdPage/register_discount.vue";
import discount_terms from "@/components/Vortex/discount_terms.vue";
import SpyGlassTradAd from "@/components/SpyGlass/SpyGlassSMS/SpyGlassTradAd.vue";
import tmh_vortex_controls from "@/components/orgs/tmh_admin/tmh_vortex_controls.vue";
import assistant_registration from "@/components/FreeTime/assistant_registration.vue";

let db = new Database();
let std_route_auth_context = {
    auth_context: [['div', 'perms']],
    auth_key: {
        'key': 'route',
        'value': 'profile'
    }
}

let private_route_auth_context = {
    auth_context: [['div', 'perms']],
    auth_key: {
        'key': 'route',
        'value': 'general'
    }
}

let agency_speedy_route_auth_context = {
    auth_context: [['div', 'o&a'], ['div', 'perms']],
    auth_key: {
        'key': 'route',
        'value': 'speedydelivery:agency'
    }
}

let agency_members_route_auth_context = {
    auth_context: [['div', 'members']],
    auth_key: {
        'key': 'route',
        'value': 'interface_agency'
    }
}


let id_only_public_auth_context = {
    auth_context: ["id_only"],

}

// const render_switch = () => {
//     console.log("INSIDE THE RENDER SWITCH.")
//     // render_switch based on
//     // Dan's solution at https://stackoverflow.com/questions/66342500/vuejs-3-how-to-render-router-view-router-view-from-vue-router
//     // and my previous shallowref code used in the store to return components.
//     console.log("route component string is..")
//     console.log(store.state.route_component)
//     switch (store.state.route_component) {
//         case "person":
//             console.log("in PERSON render")
//             // console.log(h)
//             return h(store.getters.get_next_route_component);
//         case "private_duty_agency":
//             console.log("in PDA render")
//             // console.log(h)
//             return h(store.getters.get_next_route_component);
//         case "tmh_admin":
//             console.log("in THM render")
//             console.log(h)
//             return h(store.getters.get_next_route_component);
//         default:
//             console.log("in DEFAULT  render")
//             // console.log(h)
//             return h(store.getters.get_next_route_component);
//     }
// }


const routes = [
    {
        path: '/',
        name: 'Home',
        component: home
    },
    {
        path: '/login',
        name: 'Login',
        component: login
    },
    {
        path: '/contact',
        name: 'Contact Form',
        component: contact_form
    },
    {
        path: '/about',
        name: 'About Tiny Magic Hat',
        component: about
    },
    {
        path: '/tos',
        name: 'Tiny Magic Hat Terms of Service',
        component: terms_of_service
    },
    {
        path: '/privacy',
        name: 'Tiny Magic Hat Privacy Policy',
        component: privacy
    },
    {
        path: '/hello_landing',
        name: 'Hello Landing',
        component: hello_landing
    },
    {
        path: '/sms_opt_in',
        name: 'Hello PDA',
        component: sms_opt_in
    },
    {
        path: '/facebook_welcome/50021',
        name: 'landing-Facebook-freetime1',
        component: check_out_freetime
    },
    {
        path: '/facebook_welcome/07908',
        name: 'landing-Facebook-spyglass1',
        component: check_out_spyglass
    },
    {
        path: '/facebook_welcome/myrtle-beach-caregiver-jobs',
        name: 'landing-Facebook Myrtle Beach Notifications campaign',
        component: notfications_campaign
    },
    {
        path: '/spyglass_notifications',
        name: 'General SpyGlass Notifications Subscriptions page ',
        component: notfications_campaign
    },

     {
        path: '/starfinder_notifications',
        name: 'General StarFinder Notifications Subscriptions page ',
        component: notfications_campaign
    },


    {
        path: '/speedydelivery/tests/infection_control',
        name: 'sd tests infection_control',
        component: infection_control
    },

    {
        path: '/for_caregivers',
        name: 'Caregiver feature page',
        component: for_caregivers
    },

    {
        path: '/for-caregivers',
        name: 'Caregiver feature page new url',
        component: for_caregivers
    },

    {
        path: '/for_agencies',
        name: 'Agency feature page',
        component: for_agencies
    },
    {
        path: '/for-agencies',
        name: 'Agency feature page new url',
        component: for_agencies
    },
    {
        path: '/local-caregiving-agencies',
        name: 'Public listing of agencies and adverts',
        component: agencies_in_area
    },

    {
        path: '/mfa_login',
        name: 'MFA Login',
        component: mfa_login
    },
    {
        path: '/mfa_register',
        name: 'MFA Register',
        component: mfa_register
    },
    {
        path: '/mfa_register_confirm',
        name: 'MFA Confirm Registration',
        component: mfa_confirm_registration
    },

    {
        path: '/mfa_verify_totp',
        name: 'MFA TOTP Verification',
        component: mfa_verify_totp
    },
    {
        path: '/mfa_forgot_pw',
        name: 'MFA Forgotten Password',
        component: mfa_forgotten_pw
    },
    {
        path: '/mfa_confirm_pw_reset',
        name: 'MFA Confirm Password Reset',
        component: mfa_confirm_password_reset
    },
    {
        path: '/resend_registration_confirmation',
        name: 'Resend the code needed to confirm a new account',
        component: resend_registration_confirmation_code
    },
    //  {
    //     path: '/cg_freetime',
    //     name: 'Caregive FreeTime Interface',
    //     component: freetime_caregiver_interface
    // },

    // {
    //     path: '/:name/profile',
    //     name: 'Profile',
    //     component: {
    //         render: render_switch
    //     },
    //     meta: {
    //         auth_context: [['div', 'public'], ['div', 'perms']],
    //         auth_key: {
    //             'key': 'route',
    //             'value': 'profile'
    //         }
    //     }
    // },

    {
        path: '/:name/profile',
        name: 'profile',
        component: personal_profile_new,
        meta: std_route_auth_context
    },

    {
        path: '/:name/profile/info',
        name: 'personal info page',
        component: personal_info,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery',
        name: 'speedydelivery main user interface',
        component: speedydelivery_main_user_interface,
        meta: std_route_auth_context
    },

    // ========================================================================
    {
        path: '/:name/speedydelivery/copd',
        name: 'COPD Module ',
        component: copd,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/copd/test',
        name: 'COPD TestModule ',
        component: copd_test,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/diabetes',
        name: 'diabetes Module ',
        component: diabetes,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/diabetes/test',
        name: 'diabetes TestModule ',
        component: diabetes_test,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/fall_risk',
        name: 'fall_risk Module ',
        component: fall_risk,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/fall_risk/test',
        name: 'fall_risk TestModule ',
        component: fall_risk_test,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/hepatitis',
        name: 'hepatitis Module ',
        component: hepatitis,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/hepatitis/test',
        name: 'hepatitis TestModule ',
        component: hepatitis_test,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/hoarding',
        name: 'hoarding Module ',
        component: hoarding,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/hoarding/test',
        name: 'hoarding TestModule ',
        component: hoarding_test,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/uti',
        name: 'uti Module ',
        component: uti,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/uti/test',
        name: 'uti TestModule ',
        component: uti_test,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/vascular_problems',
        name: 'vascular_problems Module ',
        component: vascular_problems,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/vascular_problems/test',
        name: 'vascular_problems TestModule ',
        component: vascular_problems_test,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/shingles',
        name: 'shingles Module ',
        component: shingles,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/shingles/test',
        name: 'shingles TestModule ',
        component: shingles_test,
        meta: std_route_auth_context
    },
    // ========================================================================

    {
        path: '/:name/speedydelivery/abuse',
        name: 'Abuse Module ',
        component: abuse,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/abuse/test',
        name: 'Abuse TestModule ',
        component: abuse_test,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/alzheimers-disease',
        name: 'Alzheimer\'s Disease Module',
        component: alzheimers,
        meta: std_route_auth_context
    },
    {
        path: '/:name/speedydelivery/alzheimers-disease/test',
        name: 'Alzheimer\'s Disease TestModule',
        component: alzheimers_test,
        meta: std_route_auth_context
    },


    {
        path: '/:name/speedydelivery/patient-bill-of-rights',
        name: 'Patient Bill of Rights Module',
        component: client_bill_of_rights,
        meta: std_route_auth_context
    },
    {
        path: '/:name/speedydelivery/patient-bill-of-rights/test',
        name: 'Patient Bill of Rights TestModule',
        component: client_bill_of_rights_test,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/documentation',
        name: 'Documentation Module',
        component: documentation,
        meta: std_route_auth_context
    },
    {
        path: '/:name/speedydelivery/documentation/test',
        name: 'Documentation TestModule',
        component: documentation_test,
        meta: std_route_auth_context
    },
    {
        path: '/:name/speedydelivery/first-aid',
        name: 'First Aid Module',
        component: first_aid,
        meta: std_route_auth_context
    },
    {
        path: '/:name/speedydelivery/first-aid/test',
        name: 'First Aid TestModule',
        component: first_aid_test,
        meta: std_route_auth_context
    },
    {
        path: '/:name/speedydelivery/hippa-and-confidentiality',
        name: 'Confidentiality Module',
        component: hippa,
        meta: std_route_auth_context
    },
    {
        path: '/:name/speedydelivery/hippa-and-confidentiality/test',
        name: 'Confidentiality TestModule',
        component: hippa_test,
        meta: std_route_auth_context
    },
    {
        path: '/:name/speedydelivery/infection-control',
        name: 'Infection Control Module',
        component: infection_control_module,
        meta: std_route_auth_context
    },
    {
        path: '/:name/speedydelivery/infection-control/test',
        name: 'Infection Control TestModule ',
        component: infection_control_test,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/neglect',
        name: 'Neglect Module ',
        component: neglect,
        meta: std_route_auth_context
    },
    {
        path: '/:name/speedydelivery/neglect/test',
        name: 'Neglect TestModule ',
        component: neglect_test,
        meta: std_route_auth_context
    },
    {
        path: '/:name/speedydelivery/professionalism-in-caregiving',
        name: 'Professionalism in Caregiving Module',
        component: professionalism,
        meta: std_route_auth_context
    },
    {
        path: '/:name/speedydelivery/professionalism-in-caregiving/test',
        name: 'Professionalism in Caregiving TestModule',
        component: professionalism_test,
        meta: std_route_auth_context
    },

    {
        path: '/:name/speedydelivery/transfers',
        name: 'Transfers Module',
        component: transfers_2,
        meta: std_route_auth_context
    },
    {
        path: '/:name/speedydelivery/transfers/test',
        name: 'Transfers TestModule',
        component: transfers_test,
        meta: std_route_auth_context
    },
    {
        path: '/pda/:pda/speedydelivery',
        name: 'SpeedyDelivery Agency Interface',
        component: speedy_delivery_agency_interface,
        meta: agency_speedy_route_auth_context
    },
    {
        path: '/pda/:pda/speedydelivery/:caregiver',
        name: 'SpeedyDelivery Agency Interface selected caregiver',
        component: speedy_delivery_agency_interface,
        meta: agency_speedy_route_auth_context
    },
    {
        path: '/pda/:pda/speedydelivery/expirations',
        name: 'SpeedyDelivery Agency Interface Expirations',
        component: hello_landing,
        meta: agency_speedy_route_auth_context
    },
    {
        path: '/pda/:pda/speedydelivery/uploads/:caregiver',
        name: 'SpeedyDelivery Document Upload Interface',
        component: upload_caregiver_document,
        meta: agency_speedy_route_auth_context
    },
     {
        path: '/pda/:pda/speedydelivery/agency_local_docs',
        name: 'Create an Agency Local Document Type',
        component: agency_local_docs_main,
        meta: agency_speedy_route_auth_context
    },

    {
        path: '/pda/:pda/speedydelivery/custom_in_service',
        name: 'Create a Custom SpeedyDelivery In Service',
        component: custom_in_service,
        meta: agency_speedy_route_auth_context
    },
    {
        path: '/pda/:pda/speedydelivery/custom_in_service/:module_id',
        name: 'Assign caregivers to your custom in service.',
        component: in_service_registration,
        meta: agency_speedy_route_auth_context
    },
    {
        path: '/tinymagichat/main',
        name: 'tmh',
        component: tmh_admin_main,
        children: [
            {
                path: 'freetime',
                component: tmh_freetime_controls,
                meta: private_route_auth_context
            },
            {
                path: 'pda_mother',
                component: tmh_mother_pda_controls,
                meta: private_route_auth_context
            },
            {
                path: 'tmh',
                component: tmh_internal_controls,
                meta: private_route_auth_context
            },
            {
                path: 'geography',
                component: tmh_geography_controls,
                meta: private_route_auth_context
            },
            {
                path: 'dashboard',
                component: tmh_dashboard,
                meta: private_route_auth_context
            },
            {
                path: 'spyglass',
                component: tmh_spyglass_controls,
                meta: private_route_auth_context
            },
            {
                path: 'speedydelivery',
                component: tmh_speedydelivery_controls,
                meta: private_route_auth_context
            },
            {
                path: 'vortex',
                component: tmh_vortex_controls,
                meta: private_route_auth_context
            },
            {
                path: 'forums',
                component: forums_control,
                meta: private_route_auth_context
            },
        ],
        meta: std_route_auth_context
    },


    {
        path: '/pda/:name/home/',
        name: 'PDA Home',
        component: pda_profile_main,
        meta: {
            auth_context: [['div', 'perms']],
            auth_key: {
                'key': 'route',
                'value': 'profile'
            }
        }
    },

    {
        path: '/pda/:name/home/info',
        name: 'PDA Info',
        component: pda_info,
        meta: {
            auth_context: [['div', 'perms']],
            auth_key: {
                'key': 'route',
                'value': 'profile'
            }
        }
    },


    {
        path: '/register',
        name: 'Register',
        component: new_register
    },

    {
        path: '/register_confirm',
        name: 'Confirm Registration',
        component: new_confirm_registration
    },
    {
        path: '/forgot_pw',
        name: 'Forgotten Password',
        component: new_forgotten_pw
    },
    {
        path: '/confirm_pw_reset',
        name: 'Confirm Password Reset',
        component: new_confirm_password_reset
    },
    {
        path: '/pda/:pda/freetime',
        name: 'FreeTime Interface',
        component: FreeTime_interface,
        meta: agency_members_route_auth_context
    },

    {
        path: '/pda/:pda/freetime/assistant_registration',
        name: 'Assistant Registration',
        component: assistant_registration,
        meta: agency_members_route_auth_context
    },

    {
        path: '/pda/:pda/freetime/help',
        name: 'FreeTime Help Page',
        component: freetime_cg_help,
        meta: agency_members_route_auth_context
    },
    {
        path: '/pda/:pda/freetime/admin_help',
        name: 'FreeTime Admin Help Page',
        component: freetime_admin_help,
        meta: agency_members_route_auth_context
    },

     {
        path: '/pda/:pda/vortex',
        name: 'Vortex Interface',
        component: vortex_admin_main,
        meta: private_route_auth_context
    },

    {
        path: '/pda/:pda/spyglass',
        name: 'SpyGlass Interface',
        component: spyglass_agency_interface,
        meta: private_route_auth_context
    },
     {
        path: '/pda/:pda/starfinder',
        name: 'StarFinder Interface',
        component: spyglass_agency_interface,
        meta: private_route_auth_context
    },

    {
        path: '/pda/:pda/spyglass/agency_preview',
        name: 'SpyGlass Agency Landing Page Editing Interface',
        component: spyglass_agency_landing_page,
        meta: private_route_auth_context
    },
     {
        path: '/pda/:pda/starfinder/agency_preview',
        name: 'StarFinder Agency Landing Page Editing Interface',
        component: spyglass_agency_landing_page,
        meta: private_route_auth_context
    },
    {
        path: '/spyglass',
        name: 'SpyGlass Schedule Ads',
        component: sg_outside
    },
     {
        path: '/starfinder',
        name: 'StarFinder Schedule Ads',
        component: sg_outside
    },
    {
        path: '/spyglass/:pda/landing',
        name: 'SpyGlass Public Agency Landing Page',
        component: spyglass_agency_landing_page_public,
        meta: id_only_public_auth_context
    },
     {
        path: '/starfinder/:pda/landing',
        name: 'StarFinder Public Agency Landing Page',
        component: spyglass_agency_landing_page_public,
        meta: id_only_public_auth_context
    },
    {
        path: '/main_forum',
        name: 'Commenting Forum',
        component: forums
    },
    {
        path: '/main-forum',
        name: 'Commenting Forum new url',
        component: forums
    },

    {
        path: '/make-caregiver-job-request',
        name: 'Job request',
        component: job_request
    },

    {
        path: '/help',
        name: 'Help chat',
        component: chatbot
    },
    {
        path: '/find-a-caregiver',
        name: 'Client searching for caregiver',
        component: needing_caregiver
    },
    {
        path: '/blog/home-care-and-caregiving-things-you-should-know',
        name: 'Home care and caregiving, what you need to know',
        component: what_you_need_to_know
    },
      {
        path: '/spyglassplus/:uuid',
        name: 'Individual caregiver SpyGlass Plus Ad',
        component: SpyGlassPlusAd
    },
     {
        path: '/starfinderplus/:uuid',
        name: 'Individual caregiver StarFinder Plus Ad',
        component: SpyGlassPlusAd
    },

     {
        path: '/spyglasstrad/:uuid',
        name: 'Individual caregiver SpyGlass Trad Ad',
        component: SpyGlassTradAd
    },
     {
        path: '/starfindertrad/:uuid',
        name: 'Individual caregiver StarFinder Trad Ad',
        component: SpyGlassTradAd
    },

    {
        path: '/pda/:pda/spyglass/plus/interested/:tmhname/:uuid',
        name: 'Individual caregiver who is interested in a plus ad',
        component: interested_view
    },
     {
        path: '/pda/:pda/starfinder/plus/interested/:tmhname/:uuid',
        name: 'Individual caregiver who is interested in a plus ad',
        component: interested_view
    },

    {
        path: '/pda/:pda/spyglass/standard/interested/:tmhname/:uuid',
        name: 'Individual caregiver who is interested in a trad ad',
        component: interested_view_trad
    },
     {
        path: '/pda/:pda/starfinder/standard/interested/:tmhname/:uuid',
        name: 'Individual caregiver who is interested in a trad ad',
        component: interested_view_trad
    },
    {
        path: '/markets',
        name: 'Master Markets Page',
        component: main_ad_page
    },
    {
        path: '/markets/:state/:market',
        name: 'Individual Market Page',
        component: market_ad_page
    },
     {
        path: '/register_discount',
        name: 'Discount Registration Page',
        component: register_discount
    },
    {
        path: '/discount_terms',
        name: 'New Client Sign up Discount Terms',
        component: discount_terms
    },
    {
        path: '/discount_created',
        name: 'New Client Discount Created',
        component: congrats_on_discount
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    previous: {},
    routes
})


router.beforeEach(async function (to, from, next) {

    // console.log("**********************************************************************************************")
    // console.log("**********************************************************************************************")
    // console.log("**********************************************************************************************")
    //   console.log(JSON.stringify(store.getters.find_current_preamble))


    // console.log(`inside global level guard going to ${to.fullPath} and coming from ${from.fullPath}`)
    let ignores = ['/', '/login', '/contact', '/register', '/sms_opt_in', '/confirm_pw_reset', '/forgot_pw', '/register_confirm',
        '/mfa_login', '/mfa_register', '/mfa_register_confirm', '/mfa_verify_totp', '/mfa_forgot_pw', '/mfa_confirm_pw_reset',
        '/hello_landing', '/local-caregiving-agencies', '/what-is-freetime', '/what-is-spyglass', '/what-is-speedydelivery',
        '/helping_agencies', '/for_caregivers', '/for_agencies', '/for-caregivers', '/for-agencies', '/main-forum', '/about',
        '/facebook_welcome/50021', '/facebook_welcome/07908', '/facebook_welcome/myrtle-beach-caregiver-jobs',
        '/speedydelivery/tests/infection_control', '/tos', '/privacy',
        '/make-caregiver-job-request', '/spyglass', '/find-a-caregiver', '/blog/home-care-and-caregiving-things-you-should-know',
        '/help', '/spyglass_notifications', '/register_discount', '/discount_terms'
    ]

    let spyglass_plus_ad_route = false
    let spyglass_trad_ad_route = false

    if (to.fullPath.includes("spyglassplus")){
        let target = "spyglass_plus_ad"
        // console.log("Inside the SpyGlass Plus Premium SChedule offer page")
        // console.log(`First the full path route...`)
        // console.log(to.fullPath)
        // console.log("Then the parts")
        // console.log(to.fullPath.split("/"))
        let parts = to.fullPath.split("/")
        let uuid = parts[2]
        // console.log(`The uuid was ${uuid}`)
        localStorage.setItem(target, uuid)
        spyglass_plus_ad_route = true
        // to.fullPath = '/spyglass?fbclid=IwAR0lyXatdysi16s97ZRKcA5uXUBWwJ3IFL08rH2Tz5uG_1t5BxVG2T8Xalo_aem_ARX3aEANjTHY9evE4kFK4oVWkewYz2YjBwUhjIyuAnekQwEYMjkoy0hrYzhQXPWLTs8kveY9w8tiCUUWdNhQJ28J1DAVxuoZEz3oHaLkAPesxiEgqs9l8e0llwNqWAaJXGU'
    } else if(to.fullPath.includes("spyglasstrad")){
        let target = "spyglass_trad_ad"
        let parts = to.fullPath.split("/")
        let uuid = parts[2]
         localStorage.setItem(target, uuid)
        spyglass_trad_ad_route = true
    }



    analytic({
        source: to.fullPath,
        event: "mounted",
        message: `in Router going to ${to.fullPath}`
    })

    //Facebook appends some ugly query string that is messing up my internal routing.
    if (to.fullPath.includes("?")) {
        let parts = to.fullPath.split("?")
        to.fullPath = parts[0]
    }
    //Strip trailing slash from url
    if (to.fullPath.endsWith("/") && to.fullPath.length > 1) {
        to.fullPath = to.fullPath.slice(0, -1);
    } else {
        // console.log(`Inside the slash hunter with ${to.fullPath}, so there was no slash.`)
    }

    analytic({
        source: to.fullPath,
        event: "mounted",
        message: `Getting ready to set latest dev route to ${to.fullPath}`
    })

    // console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
    // console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
    // console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
    // console.log(`The Full path we are saving as the latest route is ${to.fullPath}`)
    // console.log(JSON.stringify(store.getters.find_current_preamble))


    // console.log(`R1-- The current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)
    // console.log(`After R1 the latest route is ${to.fullPath}`)
    // console.log(`After R1 the proposed preamble is ${store.getters.proposed_preamble}`)
    store.commit('save_latest_route', to.fullPath)
    // console.log(`R2-- The current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)

    // console.log(`Latest route from local storage: ${localStorage.getItem('dev_latest_route')}`)
    // console.log(`Latest route from vuex: ${store.getters.latest_route}`)
    // console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBb')
    // console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBb')
    // console.log('BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBb')
    // console.log(JSON.stringify(store.getters.find_current_preamble))
    // console.log(`Latest route from local storage: ${localStorage.getItem('dev_latest_route')}`)
    // console.log(`Latest route from vuex: ${store.getters.latest_route}`)

    if (!store.getters.universal_user_session_id) {
        store.commit('universal_user_session_id', get_uuid())
    }


    //Log route changes for analtics
    //to.fullPath represents the new page getting mounted
    await store.dispatch('log_mount_time', to.fullPath)
    // analytic(`${to.fullPath}::mounted`)
    analytic({
        source: to.fullPath,
        event: "mounted"
    })
    if (to.fullPath !== from.fullPath) {
        let duration = await store.dispatch('log_mount_time', from.fullPath)
        if (duration) {
            // analytic(`${from.fullPath}::unmounted:duration:${duration}`)
            analytic({
                source: from.fullPath,
                event: "unmounted",
                duration: duration
            })
            // console.log(`In router....duration is ${duration}`)
        }
    }

    let history_flag = true
    let authorized = true

    if (!ignores.includes(to.fullPath) && !spyglass_plus_ad_route && !spyglass_trad_ad_route) {
        //These are the non-open paths
        // console.log("Going down non-open routes")
        // console.log(`The current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)
        // console.log("(((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((")
        // console.log("(((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((")
        // console.log("(((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((((")

      // console.log(JSON.stringify(store.getters.find_current_preamble))

        let historical_route = is_historical_route(to.fullPath)
        // console.log("back from is_historical_route")
        // console.log(`Historical route? ${historical_route} -- ${to.fullPath}`)
        if (historical_route && authorized) {
            // console.log("We have seen this route before and it is authorized by default. Pulling from history.")
            store.commit('populate_storage_from_history', to.fullPath)
            // store.commit('increment_universal_page_update')
        } else if (!store.getters.context_changing_route) {
            // console.log(`R3-- The current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)
            //    todo route authorization
            // console.log("We are in a non-context changing route.")
            // console.log(`R4-- The current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)
            let history = {
                'route_component': store.getters.get_next_route_component_str,
                'route_preamble': JSON.stringify(store.getters.find_current_preamble),
                'route': to.fullPath
            }
            // console.log("The manually constructed history object is...")
            // console.log(JSON.stringify(history))
            let store_item = {
                'path': to.fullPath,
                'history': history
            }

            // console.log("the history object looks like this.")
            // console.log(JSON.stringify(history))

            if (history_flag && authorized) {
                console.log("in router. saving the route history.")
                console.log(JSON.stringify(store_item))
                store.commit('save_route_history', store_item)
            }

        } else {
            console.log("We are in a private context-changing route")
            try {
                //Regular routes
                const foo = async () => {

                    console.log("TESTINTG CODE STARTS")
                    console.log(to)
                    console.log(router.options.routes)
                    console.log("TESTINTG CODE ends")
                    console.log("run get_current_entity_type")
                    // let next_org_type_obj_string = await db.get_current_entity_type()


                    // console.log("in router. get_current_entity_type is:")
                    // console.log(next_org_type_obj_string)
                    // store.commit('set_next_route_component_object', next_org_type_obj_string)


                    if (store.getters.proposed_preamble.length === 0) {
                        history_flag = false
                        store.commit('error_enqueue',
                            `Sorry, but ${to.fullPath} is not a route.`)
                        // console.log("Error. Proposed preamble length is 0.")
                    } else {
                        // console.log(store.getters.proposed_preamble)
                    }

                    // analytic({
                    //     source: "Router Foo",
                    //     event: "Waypoint2"
                    // })


                    // if (to.matched.length > 1) {
                    //     console.log(`${to.fullPath} matches more that one route. This is likely unintended.`)
                    //     console.log(to.matched)
                    // }

                    let r = to.matched[to.matched.length - 1]
                    console.log("MATCHED ROUTE")
                    console.log(r)
                    if (r === undefined) {
                        console.log("this is some random unknown route")
                        next(from)
                        // next('/for_agencies')
                    }
                    if (r.meta['auth_context'] !== undefined) {
                        console.log("There is an auth_context for this route.")


                        authorized = false
                        let search = {
                            key: r.meta['auth_key']['key'],
                            additional_context: r.meta['auth_context'],
                            base_context: JSON.parse(store.getters.proposed_preamble)
                        }
                        console.log("set to search database with this...")
                        console.log(JSON.stringify(search))



                        let route_perms_array = await db.db_search(search)
                        // let route_perms_array = await db.db_search(search,r.meta['auth_key']['key'])
                        console.log("Found the following route_perms_array")
                        console.log(route_perms_array)
                        console.log(r.meta['auth_key']['value'])

                        // if(route_perms_array.length === 0){
                        //     let m = `${r} requires an authorization context, but you don't have one.`
                        //     store.commit('error_enqueue', m)
                        // }

                        // analytic({
                        //     source: "Router Foo",
                        //     event: "Waypoint5",
                        //     message: "Just before iterating the database search result."
                        // })

                        for (let k of route_perms_array) {
                            if (k === r.meta['auth_key']['value']) {
                                console.log("Route permission has been discovered.")
                                authorized = true
                            }
                        }
                        console.log("Authorized is now: " + authorized)
                    } else {
                        //nothing here
                        console.log("Landed in no man's land.......")
                        // analytic({
                        //     source: "Router Foo",
                        //     event: "Waypoint6",
                        //     message: "Landed in no man's land......."
                        // })
                        // next('/')

                    }

                    // console.log("in router. saving a new route preamble as:")
                    // console.log(store.getters.proposed_preamble)

                    let history = {
                        // 'route_component': next_org_type_obj_string,
                        'route_preamble': store.getters.proposed_preamble,
                        'route': to.fullPath
                    }
                    let store_item = {
                        'path': to.fullPath,
                        'history': history
                    }

                    if (history_flag && authorized) {
                        // console.log("in router. saving the route history.")
                        // console.log(JSON.stringify(store_item))
                        store.commit('save_route_history', store_item)
                    }

                }
                await foo()
            } catch (error) {

                // analytic({
                //             source: "Router Foo",
                //             event: "Waypoint8",
                //             message: "in the catch"
                //         })

                // analytic({
                //             source: "Router Foo",
                //             event: "Waypoint8.1 - Error",
                //             message: error
                //         })
                let r = to.matched[to.matched.length - 1]
                // console.log(r)
                // console.log(r.meta)
                // console.log(r.meta['auth_context'])
                if (r.meta['auth_context'][0] === 'id_only') {
                    authorized = true


                } else {
                    console.log("catching routing error, redirecting to login.")
                    console.log(error)
                    to.fullPath = '/login'
                    // store.commit('save_latest_route', to.fullPath)
                }
                // console.log(to.fullPath)

                store.commit('save_latest_route', to.fullPath)

            }
        }

    } else {
        // console.log("This route was an open route")
        // console.log("TESTINTG CODE STARTS")
        //              console.log(to)
        //              console.log(router.options.routes)
        //              console.log("TESTINTG CODE ends")
    }
    store.commit('update_context_changing_route', true)
    // store.commit('save_proposed_preamble', '[]')
    // console.log(`History_flag & authorized: ${history_flag} : ${authorized}`)
    if (history_flag && authorized) {
        // console.log(`History: true, authorized: true. Launching next() for ${to.fullPath}.`)
        store.commit('save_latest_route', to.fullPath)
        next();
    } else {
        //pass
        // next(new Error('Authentication failure'))
        // console.log("Not launching next(), but next(from) instead.")
        // console.log(`History was ${history_flag} and authorized was ${authorized}.`)
        // console.log()
        store.commit('save_latest_route', from)
        next(from);
    }
    store.commit('increment_universal_page_update')
});

router.onError(error => {
    console.log(error);
});

// router.beforeRouteEnter(to, from, next){
//     console.log("INSIDE BEFORE ROUTER HOOK")
//      next();
// }


export default router
