<template>
  <footer class=" bg_primary_purple_deep text_white shadow_inner" id="footer_wrapper">
    <div class="" id="outside_footer">

      <div class="footer_top_wrapper">
        <div class=" flex_wrap" id="footer_top">
          <img class="cursor_ptr" src="../../assets/re_white_stacked.svg" id="footer_logo" alt="Tiny Magic Hat Main Logo"
               @click="scroll_to_top">


          <p id="mission" class="font_raleway">The primary purposes of Tiny Magic Hat are to make caregiving a better job
            for caregivers, make life easier for those seeking care and to help home care agencies to find
            and retain happy caregivers.</p>


          <div class="flex_start_column " id="footer_links">
            <p><strong>LINKS</strong></p>
            <p class="cursor_ptr" @click="dual_route_wrapper('', 'page_top')">Home</p>
            <p class="cursor_ptr" @click="dual_route_wrapper('for-caregivers','for_cgs_main')">Caregivers</p>
            <p class="cursor_ptr" @click="dual_route_wrapper('for-agencies', '')">Agencies</p>
            <p class="cursor_ptr" @click="dual_route_wrapper('login')">Log In</p>
            <p class="cursor_ptr" @click="dual_route_wrapper('main-forum')">Forum</p>
            <p class="cursor_ptr" @click="dual_route_wrapper('contact')">Contact</p>
            <p class="cursor_ptr" @click="dual_route_wrapper('about')">About</p>
            <p class="cursor_ptr" @click="dual_route_wrapper('tos')">Terms</p>
            <p class="cursor_ptr" @click="dual_route_wrapper('privacy')">Privacy</p>
          </div>
          <div class="flex_centered_left_column " id="footer_contact">
            <p><strong>CONTACT US</strong></p>
            <p class="cursor_ptr" @click="send_email">info@tinymagichat.com</p>
            <p class="cursor_ptr" @click="phone_call('9195788119')">919.578.8119</p>
            <p>4801 Glenwood Avenue</p>
            <p>Suite 200 PMB 487</p>
            <p>Raleigh, NC 27612</p>
          </div>

        </div>
      </div>

      <div class="flex_centered_row">
        <div id="footer_line"></div>
      </div>

      <div class="flex_centered_row copywrite" id="footer_copyright">
        <p>Tiny Magic Hat &copy; 2022-2024</p>
      </div>
      <div class="flex_centered_row version_line " id="footer_version_tag">
        <version_tag class=""></version_tag>
      </div>
    </div>
  </footer>

</template>

<script>
/* eslint-disable */
import version_tag from "@/components/basics/version_tag";
import store from "@/store";
import {
  colors, dual_route, scroll_to_element, scroll_to_top, make_phone_call, email_info_at_tmh,

} from "@/library";

export default {
  name: "outside_footer",
  emits: ["link_clicked"],
  data() {
    return {
      colors: colors,
      mobile_mode_x: true,
      desktop_cutoff: 700,
    }
  },
  components: {
    version_tag
  },
  props: [
  ],
  async mounted() {
    // console.log("footer has been mounted.")
    // window.addEventListener("resize", this.run_window_height_adjustments);
    // this.run_window_height_adjustments()

  },
  updated() {
    // this.run_window_height_adjustments()
  },
  unmounted() {
    console.log("footer has been UNmounted.")
    // window.removeEventListener("resize", this.run_window_height_adjustments);
  },


  methods: {
    phone_call(number){
      make_phone_call((number))
    },
    send_email(){
      email_info_at_tmh()
    },

    dual_route_wrapper(route, inner = null) {
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      this.emit_state()
      dual_route(this.$router, route, inner)

    },
    scroll_to_top() {
      scroll_to_top()
    },
    emit_state() {
      this.$emit("link_clicked", true)
    },

    run_window_width_adjustments(new_w) {

      // console.log("Running window adjustments")

      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }
      let contact = document.getElementById('footer_contact')
      let links = document.getElementById('footer_links')
      if (this.num_is_between_inclusive(100, 541, new_w)) {
        contact.style.textAlign = 'center'
        links.style.textAlign = 'center'
      }
      if (this.num_is_between_inclusive(542, 1000, new_w)) {
        contact.style.textAlign = 'left'
        links.style.textAlign = 'left'
      }
      if (this.num_is_between_inclusive(1001, 5000, new_w)) {
        contact.style.textAlign = 'left'
        links.style.textAlign = 'left'
      }


    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },

  },
  computed: {
    windowWidth() {
      return store.state.windowWidth;
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>


<style scoped>


#outside_footer {
  width: 100vw;
  /*max-height: 50vh;*/
}

@media (min-width: 100px) {

  .footer_top_wrapper {
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }

  #mission {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 23px;
    width: 83%;
    font-style: italic;
  }

  #outside_footer {
    height: 910px;
    /*position: absolute;*/
    /*bottom: 0px;*/
    width: 100vw;
  }

  #footer_logo {
    width: 125px;
    padding: 20px;
  }

  #footer_links {
    color: white;
    padding: 30px;
    line-height: 20px;
    box-sizing: border-box
  }

  #footer_links p {
    font-size: 15px;
  }

  #footer_copyright p {
    font-size: 15px;
  }

  /*#footer_version_tag{*/
  /*  margin-bottom: 20px;*/
  /*}*/


  #footer_contact {
    color: white;
    padding: 30px;
    /*text-align: center;*/
    line-height: 20px;
    box-sizing: border-box
  }

  #footer_contact p {
    font-size: 15px;
  }

  #footer_line {
    border-style: solid;
    /*text-align: center;*/
    /*border-color: var(--new-primary-purple-very-deep_10);*/
    /*border-color: var(--dark_gray);*/
    border-color: var(--new-primary-purple_10);
    border-width: 1px;
    border-radius: 10px;
    width: 85%;
    margin-bottom: 20px;
    margin-top: 10px;
    background-color: var(--new-primary-purple);
  }

  #footer_top {
    /*width: 295px;*/
    display: flex;
    justify-content: center;
  }

  #footer_links {
    text-align: center;
  }

  #footer_contact {
    text-align: center;
  }
}


@media (min-width: 356px) {
  #outside_footer {
    height: 700px;
    /*position: absolute;*/
    /*bottom: 0px;*/
    width: 100vw;
  }
}

@media (min-width: 500px) {
  #mission {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 23px;
    width: 83%;
    margin-left: 100px;
    margin-right: 100px;
  }


  #footer_top {
    width: 600px;
    display: flex;
    justify-content: center;
  }

}


@media (min-width: 541px) {

  /*#footer_top {*/
  /*  width: 400px;*/
  /*}*/

}


@media (min-width: 1200px) {

  #mission {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 23px;
    width: 59%;
    margin-left: 100px;
    margin-right: 100px;
  }


  #footer_top {
    width: 800px;
    display: flex;
    justify-content: center;
  }

  #footer_links {
    text-align: left;
    margin-right: 100px;
    margin-left: 47px;
  }

  #footer_contact {
    text-align: left;
  }

  #outside_footer {
    height: 700px;
    /*position: absolute;*/
    /*bottom: 0px;*/
    width: 100vw;
  }

  #footer_copyright {
    padding-top: 30px;
  }

}

@media (min-width: 2000px) {


  .footer_top_wrapper {
    padding-top: 80px;
  }

  #outside_footer {
    height: 480px;
    /*position: absolute;*/
    /*bottom: 0px;*/
    width: 100vw;
  }


  #mission {
    font-size: 16px;
    margin-top: 85px;
    /*margin-bottom: 105px;*/
    width: 35%;
    margin-left: 0;
    margin-right: 0;
  }

  #footer_logo {
    width: 125px;
    padding: 20px;
    margin-right: 39px;
  }

  #footer_top {
    width: 1001px;
    display: flex;
    justify-content: space-between;
  }

  #footer_links {
    text-align: left;
    margin-right: 30px;
    margin-left: 47px;
  }

  #footer_contact {
    padding-left: 28px;
  }

  #footer_contact p:first-child {
    margin-bottom: 10px;
  }

  #footer_links p {
    font-size: 16px;
  }

  #footer_contact p {
    font-size: 16px;
  }

  #footer_links p:first-child {
    margin-bottom: 10px;
  }

  /* #footer_copyright {*/
  /*   padding-top: 50px;*/
  /*}*/

}

@media (min-width: 3000px) {
  #outside_footer {
    /*height: 400px;*/
  }


}
</style>