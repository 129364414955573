<template>

  <section class=" flex_centered_start_column core_c">
    <basic_page_infrastructure
        @local_page_update="local_page_update"
    ></basic_page_infrastructure>
    <inside_banner
        mode="profileyou_info"
    >
    </inside_banner>

    <div class="flex_centered_row">
      <div class="flex_centered_column info_form">
        <h1 class="header">All About You</h1>
        <p>The information on this page is only visible to you and administers of agencies you know.</p>
        <!--        <div>{{ this.sms_opt_in }}</div>-->
        <div class="indiv_inputs">
          <input_with_label
              ref="input_firstname"
              class="info_input margin_t_25"
              label="First Name"
              :start_value="profile_first_name"
              @emit_state="do_nothing"
          ></input_with_label>
          <input_with_label
              ref="input_nick_name"
              class="info_input"
              label="Nick Name"
              :start_value="profile_nick_name"
              @emit_state="do_nothing"
          ></input_with_label>
          <input_with_label
              ref="input_middlename"
              class="info_input"
              label="Middle Name"
              :start_value="profile_middle_name"
              @emit_state="do_nothing"
          ></input_with_label>
          <input_with_label
              ref="input_lastname"
              class="info_input"
              label="Last Name"
              :start_value="profile_last_name"
              @emit_state="do_nothing"
          ></input_with_label>
          <input_with_label
              ref="input_streetaddress"
              class="info_input"
              label="Street Address"
              :start_value="profile_address_name"
              @emit_state="do_nothing"
          ></input_with_label>
          <input_with_label
              ref="input_streetaddress2"
              class="info_input"
              label="Apartment Number"
              :start_value="profile_address2_name"
              @emit_state="do_nothing"
          ></input_with_label>
          <input_with_label
              ref="input_city"
              class="info_input"
              label="City"
              :start_value="profile_city_name"
              @emit_state="do_nothing"
          ></input_with_label>
          <dropdown_datalist
              ref="input_state"
              label="State"
              class="info_input"
              :start_value="profile_state_name"
              :data_list=states
              @value-selected="state_selected"
          ></dropdown_datalist>

          <input_with_label
              ref="input_zip"
              class="info_input"
              label="Zip"
              :start_value="profile_zip_name"
              @emit_state="do_nothing"
          ></input_with_label>
          <input_with_label
              ref="input_email"
              class="info_input"
              label="Email"
              :start_value="profile_email"
              @emit_state="do_nothing"
          ></input_with_label>

          <div class="flex_centered_row opt_in_row">
            <div class="flex_centered_column" @click="toggle_freetime_monthly_opt_in">
              <div v-if="!freetime_monthly_summary_opt_in" class="check_box shadow_inner cursor_ptr"></div>
              <div v-if="freetime_monthly_summary_opt_in">
                <img class="check_box_icon cursor_ptr"
                     src="@/assets/check-mark.svg"
                     alt="check box image">
              </div>
            </div>
            <div class="spacer"></div>
            <p v-if="freetime_monthly_summary_opt_in" class="opt_in_text_yes">Yes, I would like to see my monthly
              WonderPerks Bonus amount via
              text message. (12 text messages per year)</p>
            <p v-if="!freetime_monthly_summary_opt_in" class="opt_in_text_no">Yes, I would like to see my monthly
              WonderPerks Bonus amount via
              text message. (12 text messages per year)</p>
          </div>

          <div class="flex_centered_row opt_in_row">
            <div class="flex_centered_column" @click="toggle_freetime_yearly_opt_in">
              <div v-if="!freetime_yearly_summary_opt_in" class="check_box shadow_inner cursor_ptr"></div>
              <div v-if="freetime_yearly_summary_opt_in">
                <img class="check_box_icon cursor_ptr"
                     src="@/assets/check-mark.svg"
                     alt="check box image">
              </div>
            </div>
            <div class="spacer"></div>
            <p v-if="freetime_yearly_summary_opt_in" class="opt_in_text_yes">Yes, I would like to see my yearly WonderPerks
              Bonus amount via
              text message. (1 text message per year)</p>
            <p v-if="!freetime_yearly_summary_opt_in" class="opt_in_text_no">Yes, I would like to see my yearly WonderPerks
              Bonus amount via
              text message. (1 text message per year)</p>
          </div>

          <div class="flex_centered_row opt_in_row">
            <div class="flex_centered_column" @click="toggle_spyglass_plus_opt_in">
              <div v-if="!spyglass_plus_opt_in" class="check_box shadow_inner cursor_ptr"></div>
              <div v-if="spyglass_plus_opt_in">
                <img class="check_box_icon cursor_ptr"
                     src="@/assets/check-mark.svg"
                     alt="check box image">
              </div>
            </div>
            <div class="spacer"></div>
            <p v-if="spyglass_plus_opt_in" class="opt_in_text_yes">Yes, I want to get premium schedule offers from
              StarFinder Plus via text message.</p>
            <p v-if="!spyglass_plus_opt_in" class="opt_in_text_no">Yes, I want to get premium schedule offers from
              StarFinder Plus via text message.</p>
          </div>


          <div class="flex_centered_row opt_in_row">
            <div class="flex_centered_column" @click="toggle_spyglass_standard_opt_in">
              <div v-if="!spyglass_standard_opt_in" class="check_box shadow_inner cursor_ptr"></div>
              <div v-if="spyglass_standard_opt_in">
                <img class="check_box_icon cursor_ptr"
                     src="@/assets/check-mark.svg"
                     alt="check box image">
              </div>
            </div>
            <div class="spacer"></div>
            <p v-if="spyglass_standard_opt_in" class="opt_in_text_yes">Yes, I want to get standard client offers from
              StarFinder via text message.</p>
            <p v-if="!spyglass_standard_opt_in" class="opt_in_text_no">Yes, I want to get standard client offers from
              StarFinder via text message.</p>
          </div>


          <div v-if="i_am_not_admin">
            <h4 class="margin_t_40 margin_b_20 text_align_center">Select Your Neighborhoods</h4>
            <p>Select neighborhoods to get text messages about clients needing service in those neighborhoods.</p>
            <div v-for="(market, index) of trad_sg_sms_opt_ins" :key="`market-${index}`">
              <market_neighborhood_set
                  :neighborhoods="market"
                  @toggle_neighborhood_selection="change_neighborhood"
              ></market_neighborhood_set>
            </div>
          </div>


          <div class="flex_centered_row">
            <basic_text_button
                class="padding_lr_6 padding_tb_6 s_button "
                :hover_color=colors.cyanic_blue
                :hover_text_color=colors.white
                :button_state="true"
                :button_color="colors.new_orange_main"
                :text_color=colors.white
                :button_color_not_ready="colors.eli_gray"
                :button_not_ready_text_color="colors.pennie_gray"
                button_height="52px"
                button_width="150px"
                border_rad_pct="0"
                text="Submit"
                text_size="17px"
                :bold="true"
                event_name="submit_personal_info"
                @submit_personal_info="catch_profile_data"
            ></basic_text_button>
          </div>

        </div>
      </div>
    </div>
  </section>

  <outside_footer id="footer" class="footer_c">
  </outside_footer>

</template>

<script>
import input_with_label from "@/components/parts/input_with_label";
import inside_banner from "@/components/parts/inside_banner";
import outside_footer from "@/components/parts/outside_footer";
import dropdown_datalist from "@/components/parts/dropdown_datalist";
import basic_text_button from "@/components/parts/basic_text_button";
import market_neighborhood_set from "@/components/profiles/market_neighborhood_set.vue";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure.vue";
import {
  scroll_to_top,
  states,
  colors,
  be_main_address,
  parse_internal_json,
  get_profile_route,
  titleCase,
  get_my_rank,
  get_associated
} from "@/library";
import {_get_user_profile_data} from "@/library2";
import store from "@/store";
import axios from "axios";
import {Database} from "@/client_db";

export default {
  name: "personal_info",

  beforeMount() {
    this.profile_text = store.getters.get_profile_text
  },

  async mounted() {
    await this.i_am_admin()
    await this.get_current_neighborhood_opt_ins()
    scroll_to_top()
    console.log("in mounted")
    this.profile_text = store.getters.get_profile_text
    // console.log("Here is the original profile text...")
    // console.log(this.profile_text)
    this.db = new Database();
    if (this.profile_text.length === 0 || this.profile_text.length === undefined) {
      // console.log("Could not find profile data. So checking.")
      let profile_data = await _get_user_profile_data(this.db, store.getters.my_name)
      // console.log("Found this for profile data....")
      // console.log(profile_data)

      store.commit('set_profile_text', profile_data)
      this.profile_text = store.getters.get_profile_text
    } else {
      // console.log('Length was...')
      // console.log(this.profile_text.length)
    }
    await this.get_sms_opt_in_status()
  },

  async updated() {
    console.log("in updated")
    // await this.get_sms_opt_in_status()
    // console.log(`In next tick to find opt in: ${this.sms_opt_in}`)
    await this.$nextTick(function () {
      try {
        this.get_sms_opt_in_status()
        this.profile_text = store.getters.get_profile_text
        // console.log(`In next tick to find opt in: ${this.sms_opt_in}`)

      } catch (error) {
        // console.error(error)
      }

    })
  },

  data() {
    return {
      states: states,
      colors: colors,
      a_state: "",
      db: new Database(),
      profile_text: {},
      sms_opt_in_: true,
      opt_in_dict_: {},
      trad_sg_sms_opt_ins_: [],
      i_am_not_admin_: true,
      neighborhood_picks: []
    }
  },
  components: {
    basic_page_infrastructure,
    input_with_label,
    inside_banner,
    outside_footer,
    dropdown_datalist,
    basic_text_button,
    market_neighborhood_set
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    async local_page_update() {
      console.log("Running local page update.............*************************************************************************************")
      await this.get_current_neighborhood_opt_ins()
    },

    async toggle_neighborhood_backend(neighborhood, market, state, country) {
      let target_url = be_main_address('sg') + 'spyglass_trad_ad_neighborhood_toggle'

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        neighborhood: neighborhood,
        state: state,
        country: country,
        market: market
      }

      await axios.post(target_url, data1)
          .then((response) => {
                // console.log(response)
                // console.log(response.data.error)
                // console.log(response['data']['error'])

                if (response.data.error === false) {
                  console.log("successful return")
                  return null

                } else {
                  store.commit('error_enqueue', "Something went wrong trying to toggle the neighborhood selection.")
                }
              }
          )
    },

    async change_neighborhood(neighborhood) {
      let current = neighborhood.setting_status
      let n = neighborhood.neighborhood
      let next_status = !current
      let market = neighborhood.market
      let state = neighborhood.state
      let country = "US"
      let term
      if (next_status) {
        term = 'selected'
      } else {
        term = 'de-selected'
      }
      // console.log(`Setting ${n} to ${next_status}.`)
      // console.log(`${n} has been ${term}.`)
      store.commit('notification_enqueue', `${n} has been ${term}.`)
      await this.toggle_neighborhood_backend(n, market, state, country)


    },

    toggle_sms_opt_in() {
      this.sms_opt_in_ = !this.sms_opt_in_
      this.update_sms_opt_in_on_backend()
    },

    toggle_freetime_monthly_opt_in() {
      // this.sms_opt_in_ = !this.sms_opt_in_
      this.opt_in_dict_['ft_monthly_opt_in_status'] = !this.opt_in_dict_['ft_monthly_opt_in_status']
      this.update_freetime_monthly_opt_in_on_backend()
    },

    toggle_freetime_yearly_opt_in() {
      // this.sms_opt_in_ = !this.sms_opt_in_
      this.opt_in_dict_['ft_yearly_opt_in_status'] = !this.opt_in_dict_['ft_yearly_opt_in_status']
      this.update_freetime_yearly_opt_in_on_backend()
    },

    toggle_spyglass_plus_opt_in() {
      // this.sms_opt_in_ = !this.sms_opt_in_
      this.opt_in_dict_['spyglass_plus_opt_in_status'] = !this.opt_in_dict_['spyglass_plus_opt_in_status']
      this.opt_in_dict_['spyglass_plus'] = !this.opt_in_dict_['spyglass_plus']
      this.update_spyglass_plus_opt_in_on_backend()
    },

    toggle_spyglass_standard_opt_in() {
      // this.sms_opt_in_ = !this.sms_opt_in_
      this.opt_in_dict_['spyglass_standard_opt_in_status'] = !this.opt_in_dict_['spyglass_standard_opt_in_status']
      // this.opt_in_dict_['spyglass_plus'] = !this.opt_in_dict_['spyglass_plus']
      this.update_spyglass_standard_opt_in_on_backend()
    },

    update_sms_opt_in_on_backend() {
      console.log("Updating sms opt in status")
      store.commit('set_spinner_state', true)

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        sms_opt_in: this.sms_opt_in
      }

      let target_url = be_main_address('main_api') + 'sms_opt_in'
      axios.post(target_url, data1).then((response) => {

        // console.log("here is the sms opt in  response.......................")
        // console.log(response)
        // let res = JSON.parse(response.data.data)
        let res = response.data.data
        // console.log(res)
        let uuid = res['uuid']
        let status = res['value']['value']['sms_opt_in_status']
        // console.log(uuid)
        // console.log(status)
        let status_value = {
          'value': {
            'sms_opt_in_status': status
          }
        }
        return [uuid, status_value, response.data.message]
        // let data_key = `/people/${store.getters.my_name}/div/opt_ins/div/sms/${uuid}`
        // this.db.addKeyValue(data_key, status_value);
        // store.commit('notification_enqueue', response.data.message)

      }).then((status_value_array) => {
        let uuid = status_value_array[0]
        let status_value = status_value_array[1]
        let message = status_value_array[2]
        let data_key = `/people/${store.getters.my_name}/div/opt_ins/div/sms/${uuid}`
        this.db.addKeyValue(data_key, status_value);
        store.commit('notification_enqueue', message)
      }).then(() => {
        this.get_sms_opt_in_status()
      })
      store.commit('set_spinner_state', false)

    },

    update_freetime_monthly_opt_in_on_backend() {
      console.log("Updating freetime_monthly_opt_in status")
      store.commit('set_spinner_state', true)

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        node_name: 'freetime_monthly_summary_opt_in',
        setting_name: 'ft_monthly_opt_in_status',
        setting_status: this.freetime_monthly_summary_opt_in
      }

      let target_url = be_main_address('main_api') + 'freetime_monthly_opt_in'
      axios.post(target_url, data1).then((response) => {

        // console.log("here is the sms opt in  response.......................")
        // console.log(response)
        // let res = JSON.parse(response.data.data)
        let res = response.data.data
        // console.log(res)
        let uuid = res['uuid']
        let status = res['value']['value']['ft_monthly_opt_in_status']
        // console.log(uuid)
        // console.log(status)
        let status_value = {
          'value': {
            'ft_monthly_opt_in_status': status
          }
        }
        return [uuid, status_value, response.data.message]
        // let data_key = `/people/${store.getters.my_name}/div/opt_ins/div/sms/${uuid}`
        // this.db.addKeyValue(data_key, status_value);
        // store.commit('notification_enqueue', response.data.message)

      }).then((status_value_array) => {
        let uuid = status_value_array[0]
        let status_value = status_value_array[1]
        let message = status_value_array[2]
        let data_key = `/people/${store.getters.my_name}/div/opt_ins/div/sms/${uuid}`
        this.db.addKeyValue(data_key, status_value);
        store.commit('notification_enqueue', message)
      }).then(() => {
        this.get_sms_opt_in_status()
      })
      store.commit('set_spinner_state', false)

    },

    update_spyglass_standard_opt_in_on_backend() {
      console.log("Updating spyglas standard status")
      store.commit('set_spinner_state', true)

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        ft_yearly_opt_in_status: this.opt_in_dict_['spyglass_plus'],
        node_name: 'spyglass_standard_opt_in',
        setting_name: 'spyglass_standard_opt_in_status',
        setting_status: this.spyglass_standard_opt_in
      }

      let target_url = be_main_address('main_api') + 'spyglass_standard_opt_in'
      axios.post(target_url, data1).then((response) => {

        // console.log("here is the sms opt in  response.......................")
        // console.log(response)
        // let res = JSON.parse(response.data.data)
        let res = response.data.data
        // console.log(res)
        let uuid = res['uuid']
        let status = res['value']['value']['spyglass_standard_opt_in_status']
        // console.log(uuid)
        // console.log(status)
        let status_value = {
          'value': {
            'spyglass_standard_opt_in_status': status
          }
        }
        return [uuid, status_value, response.data.message]
        // let data_key = `/people/${store.getters.my_name}/div/opt_ins/div/sms/${uuid}`
        // this.db.addKeyValue(data_key, status_value);
        // store.commit('notification_enqueue', response.data.message)

      }).then((status_value_array) => {
        let uuid = status_value_array[0]
        let status_value = status_value_array[1]
        let message = status_value_array[2]
        let data_key = `/people/${store.getters.my_name}/div/opt_ins/div/sms/${uuid}`
        this.db.addKeyValue(data_key, status_value);
        store.commit('notification_enqueue', message)
      }).then(() => {
        this.get_sms_opt_in_status()
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        store.commit("error_enqueue", "Looks like Tiny Magic Hat is having some technical problems.")
        // if (error.response) {
        //   return null
        // } else if (error.request) {
        //   return null
        // } else {
        //   return null
        // }
      })
      store.commit('set_spinner_state', false)

    },

    update_spyglass_plus_opt_in_on_backend() {
      console.log("Updating spyglass plus status")
      store.commit('set_spinner_state', true)

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        ft_yearly_opt_in_status: this.opt_in_dict_['spyglass_plus'],
        node_name: 'spyglass_plus_opt_in',
        setting_name: 'spyglass_plus_opt_in_status',
        setting_status: this.spyglass_plus_opt_in
      }

      let target_url = be_main_address('main_api') + 'spyglass_plus_opt_in'
      axios.post(target_url, data1).then((response) => {

        // console.log("here is the sms opt in  response.......................")
        // console.log(response)
        // let res = JSON.parse(response.data.data)
        let res = response.data.data
        // console.log(res)
        let uuid = res['uuid']
        let status = res['value']['value']['spyglass_plus_opt_in_status']
        // console.log(uuid)
        // console.log(status)
        let status_value = {
          'value': {
            'spyglass_plus_opt_in_status': status
          }
        }
        return [uuid, status_value, response.data.message]
        // let data_key = `/people/${store.getters.my_name}/div/opt_ins/div/sms/${uuid}`
        // this.db.addKeyValue(data_key, status_value);
        // store.commit('notification_enqueue', response.data.message)

      }).then((status_value_array) => {
        let uuid = status_value_array[0]
        let status_value = status_value_array[1]
        let message = status_value_array[2]
        let data_key = `/people/${store.getters.my_name}/div/opt_ins/div/sms/${uuid}`
        this.db.addKeyValue(data_key, status_value);
        store.commit('notification_enqueue', message)
      }).then(() => {
        this.get_sms_opt_in_status()
      })
      store.commit('set_spinner_state', false)

    },

    update_freetime_yearly_opt_in_on_backend() {
      console.log("Updating freetime_yearly_opt_in status")
      store.commit('set_spinner_state', true)

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        ft_yearly_opt_in_status: this.opt_in_dict_['ft_yearly_opt_in_status'],
        node_name: 'freetime_yearly_summary_opt_in',
        setting_name: 'ft_yearly_opt_in_status',
        setting_status: this.freetime_yearly_summary_opt_in
      }

      let target_url = be_main_address('main_api') + 'freetime_yearly_opt_in'
      axios.post(target_url, data1).then((response) => {

        // console.log("here is the sms opt in  response.......................")
        // console.log(response)
        // let res = JSON.parse(response.data.data)
        let res = response.data.data
        // console.log(res)
        let uuid = res['uuid']
        let status = res['value']['value']['ft_yearly_opt_in_status']
        // console.log(uuid)
        // console.log(status)
        let status_value = {
          'value': {
            'ft_yearly_opt_in_status': status
          }
        }
        return [uuid, status_value, response.data.message]
        // let data_key = `/people/${store.getters.my_name}/div/opt_ins/div/sms/${uuid}`
        // this.db.addKeyValue(data_key, status_value);
        // store.commit('notification_enqueue', response.data.message)

      }).then((status_value_array) => {
        let uuid = status_value_array[0]
        let status_value = status_value_array[1]
        let message = status_value_array[2]
        let data_key = `/people/${store.getters.my_name}/div/opt_ins/div/sms/${uuid}`
        this.db.addKeyValue(data_key, status_value);
        store.commit('notification_enqueue', message)
      }).then(() => {
        this.get_sms_opt_in_status()
      })
      store.commit('set_spinner_state', false)

    },

    back_to_your_profile() {
      this.$router.push({path: get_profile_route()})
    },
    state_selected(the_state) {
      console.log(`${the_state} was selected as the state.`)
      this.a_state = the_state
    },
    async catch_profile_data() {

      // cl("Catching the profile data.")
      this.profile_text = {
        "first_name": this.$refs.input_firstname.get_value(),
        "nick_name": this.$refs.input_nick_name.get_value(),
        "middle_name": this.$refs.input_middlename.get_value(),
        "last_name": this.$refs.input_lastname.get_value(),
        "street_address": this.$refs.input_streetaddress.get_value(),
        "street_address2": this.$refs.input_streetaddress2.get_value(),
        "city": this.$refs.input_city.get_value(),
        "state": this.$refs.input_state.get_value(),
        "zip": this.$refs.input_zip.get_value(),
        "email": this.$refs.input_email.get_value(),
      }

      let title_case_options = ['first_name', 'nick_name', 'middle_name', 'last_name', 'street_address', 'street_address2', 'city',]

      //Limit the max characters to 75
      for (const property in this.profile_text) {
        // console.log(this.profile_text[property])
        let shortened = ""
        try {
          shortened = this.profile_text[property].slice(0, 75)
        } catch (error) {
          //pass
        }

        if (title_case_options.includes(property) && shortened) {
          shortened = titleCase(shortened)
        }
        this.profile_text[property] = shortened
      }

      let no_go = false
      let requireds = ['first_name', 'last_name']
      for (const element of requireds) {
        if (this.profile_text[element] === "") {
          no_go = true
        }
      }

      console.log(JSON.stringify(this.profile_text))
      // this.toggle_profile_form()
      if (no_go) {
        let m = "First at Last names are required to submit this form."
        store.commit('error_enqueue', m)
        return null
      }
      await this.save_profile_info()

    },

    async save_profile_info() {
      let data1 = {
        caller: store.getters.my_name,
        profile_text: this.profile_text,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }
      // console.log(data1)

      let target_url = be_main_address('user_profile') + 'save_personal_info'
      // console.log(target_url)
      axios.post(target_url, data1)
          .then((response) => {
            // console.log("response from server for save personal info is..")
            // console.log(typeof response)
            // console.log(response)
            let the_data_only = parse_internal_json(response.data)
            // console.log("parsed the_data_only from server is...")
            // console.log(the_data_only)

            try {
              store.commit('error_enqueue', the_data_only.payload.content.message)
            } catch (error) {
              store.commit('notification_enqueue', the_data_only.message)
            }
            // this.local_page_update()

          });
      this.back_to_your_profile()
    },

    async get_sms_opt_in_status() {
      let search = {
        key: "",
        additional_context: [['div', 'opt_ins'], ['div', 'sms']],
        base_context: store.getters.find_current_preamble
      }

      console.log("SMS search terms...")
      console.log(search)

      let status_array = await this.db.db_search(search)
      console.log("Original status array....")
      // console.log("Original status array....")
      // console.log("Original status array....")
      // console.log("Original status array....")
      // console.log("Original status array....")
      console.log(status_array)

      for (const status_object of status_array) {
        for (const property in status_object) {
          console.log("Iteracting the opt-ins....................")
          console.log(`${property}: ${status_object[property]}`);
          if (status_object[property] === 1) {
            this.opt_in_dict_[property] = true
          } else {
            this.opt_in_dict_[property] = false
          }
        }
      }
      // console.log("opt in results.e...")
      // console.log(this.opt_in_dict_)

      let status_dict = status_array[0]
      console.log("the status dict is ")
      console.log(status_dict)
      // console.log(`The status array is ${JSON.stringify(status_array)}`)
      // console.log(`The status is ${status_dict['sms_opt_in_status']}`)
      // console.log(`The status type isis ${typeof status_dict['sms_opt_in_status']}`)

      // console.log(`Looking for opt ins and found status of ${status_dict['sms_opt_in_status']}`)
      // console.log(status_dict)
      // console.log(status_dict['sms_opt_in_status'])
      if (status_dict['sms_opt_in_status'] === 1) {
        // console.log("Setting status to true")
        this.sms_opt_in_ = true
      } else if (status_dict['sms_opt_in_status'] === undefined) {
        // console.log("Status is undefind, so no change.")
      } else {
        // console.log("Setting status to false")
        this.sms_opt_in_ = false
      }
    },

    convertSettingStatus(arr) {
      return arr.map(obj => {
        if (obj.setting_status === "False" || obj.setting_status === "false") {
          obj.setting_status = false;
        } else if (obj.setting_status === "True" || obj.setting_status === "true") {
          obj.setting_status = true;
        }
        return obj;
      });
    },

    splitAndSortByMarket(original_1) {
      //convert booleans to javascript
      let original = this.convertSettingStatus(original_1)

      // Create a dictionary to hold arrays for each market
      const marketDict = {};

      // Iterate through the original array
      original.forEach(item => {
        const market = item.market;

        // If the market does not exist in the dictionary, create a new array for it
        if (!marketDict[market]) {
          marketDict[market] = [];
        }

        // Push the current item into the appropriate market array
        marketDict[market].push(item);
      });

      // Convert the dictionary into an array of arrays
      const result = Object.values(marketDict);

      // Sort each market array by the 'neighborhood' key
      result.forEach(marketArray => {
        marketArray.sort((a, b) => {
          if (a.neighborhood < b.neighborhood) {
            return -1;
          }
          if (a.neighborhood > b.neighborhood) {
            return 1;
          }
          return 0;
        });


      });

      // Sort the result array by the 'market' key
      result.sort((a, b) => {
        if (a[0].market < b[0].market) {
          return -1;
        }
        if (a[0].market > b[0].market) {
          return 1;
        }
        return 0;
      });

      return result;
    },

    async i_am_admin() {
      let my_orgs = await get_associated(this.db, 'org')
      // let org = store.getters.org_context
      for (const org of my_orgs) {
        console.log(`Looking at org: ${org}`)

        let my_rank = await get_my_rank(org)
        console.log(my_rank)
        let admin_ranks = ['owner', 'admin0', 'admin1']
        if (admin_ranks.includes(my_rank)) {
          console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++=")
          this.i_am_not_admin_ = false
        }
      }
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!1")
      this.i_am_not_admin_ = true

    },

    async get_current_neighborhood_opt_ins() {
      let my_name = store.getters.my_name

      // console.log(`Find current preamble`)
      // console.log(store.getters.find_current_preamble)

      // let current_org_portion_of_preamble = store.getters.preamble_org_portion

      let search = {
        key: '',
        additional_context: [['div', 'opt_ins'], ['neighborhoods', 'sg_trad_sms']],
        base_context: [['people', my_name]]
      }
      // console.log('search query is')

      // console.log( typeof search)
      // console.log(search)

      this.neighborhood_picks = await this.db.db_search(search)
      console.log("neighborhood_picks")
      console.log(this.neighborhood_picks)

      // if (db_res_array.length === 0) {
      //     return 'person'
      // } else {
      //     return db_res_array[0]
      // }
    }


  },


  computed: {

    i_am_not_admin() {
      return this.i_am_not_admin_
    },
    trad_sg_sms_opt_ins() {
      // let original = [
      //   [
      //     {
      //       "market": "Myrtle Beach",
      //       "neighborhood": "Surfside",
      //       "setting_status": true,
      //       "state": "SC"
      //     },
      //     {
      //       "market": "Myrtle Beach",
      //       "neighborhood": "Little River",
      //       "setting_status": true,
      //       "state": "SC"
      //     },
      //     {
      //       "market": "Myrtle Beach",
      //       "neighborhood": "Longs",
      //       "setting_status": false,
      //       "state": "SC"
      //     },
      //     {
      //       "market": "Myrtle Beach",
      //       "neighborhood": "Garden City",
      //       "setting_status": true,
      //       "state": "SC"
      //     },
      //     {
      //       "market": "Myrtle Beach",
      //       "neighborhood": "Pawleys Island",
      //       "setting_status": true,
      //       "state": "SC"
      //     },
      //     {
      //       "market": "Myrtle Beach",
      //       "neighborhood": "Georgetown",
      //       "setting_status": true,
      //       "state": "SC"
      //     },
      //     {
      //       "market": "Myrtle Beach",
      //       "neighborhood": "Murrells Inlet",
      //       "setting_status": true,
      //       "state": "SC"
      //     },
      //     {
      //       "market": "Myrtle Beach",
      //       "neighborhood": "Myrtle Beach",
      //       "setting_status": true,
      //       "state": "SC"
      //     }],
      //
      //   // [{
      //   //   "market": "Charleston",
      //   //   "neighborhood": "Mt. Pleasant",
      //   //   "setting_status": false,
      //   //     "state": "SC"
      //   // },
      //   //   {
      //   //     "market": "Charleston",
      //   //     "neighborhood": "John's Island",
      //   //     "setting_status": false,
      //   //     "state": "SC"
      //   //   },
      //   //   {
      //   //     "market": "Charleston",
      //   //     "neighborhood": "Downtown",
      //   //     "setting_status": false,
      //   //     "state": "SC"
      //   //   },
      //   //   {
      //   //     "market": "Charleston",
      //   //     "neighborhood": "Sullivan's Island Market",
      //   //     "setting_status": false,
      //   //     "state": "SC"
      //   //   },
      //   // ]
      // ]

      let res = this.splitAndSortByMarket(this.neighborhood_picks)
      console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
      console.log(res)
      return res
    },

    spyglass_plus_opt_in() {
      return this.opt_in_dict_['spyglass_plus_opt_in_status']
    },

    spyglass_standard_opt_in() {
      return this.opt_in_dict_['spyglass_standard_opt_in_status']
    },

    freetime_monthly_summary_opt_in() {
      return this.opt_in_dict_['ft_monthly_opt_in_status']
    },
    freetime_yearly_summary_opt_in() {
      return this.opt_in_dict_['ft_yearly_opt_in_status']
    },
    sms_opt_in() {
      return this.opt_in_dict_['sms_opt_in_status']
    },


    profile_first_name() {
      try {
        // console.log("in first name.......")
        // console.log(this.profile_text.first_name)
        return this.profile_text.first_name
      } catch (error) {
        return ""
      }

    },
    profile_nick_name() {
      try {
        return this.profile_text.nick_name
      } catch (error) {
        return ""
      }
    },
    profile_middle_name() {
      try {
        return this.profile_text.middle_name
      } catch (error) {
        return ""
      }
    },
    profile_last_name() {
      try {
        return this.profile_text.last_name
      } catch (error) {
        return ""
      }
    },
    profile_address_name() {
      try {
        return this.profile_text.street_address
      } catch (error) {
        return ""
      }
    },
    profile_address2_name() {
      try {
        return this.profile_text.street_address2
      } catch (error) {
        return ""
      }
    },
    profile_city_name() {
      try {
        return this.profile_text.city
      } catch (error) {
        return ""
      }
    },
    profile_state_name() {
      try {
        return this.profile_text.state
      } catch (error) {
        return ""
      }
    },
    profile_zip_name() {
      try {
        return this.profile_text.zip
      } catch (error) {
        return ""
      }
    },
    profile_email() {
      try {
        return this.profile_text.email
      } catch (error) {
        return ""
      }
    },
  },
}
</script>

<style scoped>

@media (min-width: 100px) {
  .info_form {
    width: 260px
  }

  h1 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    margin-left: 20px;
    /*text-align: center;*/

  }

  .s_button {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .spacer {
    width: 10px;
  }

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);

  }

  .check_box_icon {
    height: 30px;
  }

  .opt_in_row {
    margin-top: 10px;
    width: 250px;
  }

  .opt_in_text_yes {
    width: 200px;
    color: var(--primary-purple);
    font-weight: bold;
  }

  .opt_in_text_no {
    width: 200px;
    color: var(--wyatt_gray);
    font-weight: normal;
  }

}

@media (min-width: 500px) {
  .info_form {
    width: 380px
  }

  .info_input {
    width: 380px;
  }

  h1 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    margin-left: 20px;
    /*text-align: center;*/

  }

  .s_button {
    margin-top: 40px;
  }
}

@media (min-width: 1200px) {
  .info_form {
    width: 1000px;
  }

  .indiv_inputs {
    padding: 30px;
  }

  .info_input {
    width: 500px;
  }

  .s_button {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  h1 {
    font-size: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    font-size: 20px;
    margin-left: 20px;
    width: 500px;
    /*text-align: center;*/

  }

  .spacer {
    width: 30px;
  }

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);
  }

  .check_box_icon {
    height: 30px;

  }

  .opt_in_row {
    margin-top: 30px;
    margin-bottom: 50px;
    width: 500px;
  }

  .opt_in_text_yes {
    width: 350px;
    color: var(--primary-purple);
    font-weight: bold;
  }

  .opt_in_text_no {
    width: 350px;
    color: var(--wyatt_gray);
    font-weight: normal;
  }


}

</style>
