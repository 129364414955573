<template>
  <section class="flex_centered_start_column core_c">

    <section v-if="agency_interface === true">
<!--      <pda_admin_freetime_widgit></pda_admin_freetime_widgit>-->
      <pda_admin_freetime_widget2></pda_admin_freetime_widget2>
    </section>

    <section v-else-if="caregiver_interface === true">
      <caregiver_freetime_widgit2></caregiver_freetime_widgit2>
    </section>

    <section v-else>

      <div class="flex_centered_column">
        <div class="screen_block b_rad_10px bg_whitesmoke margin_t_banner_110  b_size">
          <div class="flex_centered_row">
            <img @click="profile_button_clicked"
                 class="cursor_ptr center_banner_icon margin_tb_10 hat_size padding_lr_30"
                 :src="require('@/assets/' + 'wonder_perks.svg')" alt="Center icon">
          </div>
          <div class="flex_centered_row">
            <div class="label_bolder font_raleway font_weight_800 margin_t_25 the_text">Your rank does not have WonderPerks
              access.
            </div>
          </div>
          <div class="flex_centered_row margin_t_25 margin_b_20">
            <basic_text_button
                :button_state="true"
                :button_color="colors.new_primary_purple"
                button_color_not_ready="gray"
                :button_height="go_back_button_size"
                :button_width="go_back_button_size"
                border_rad_pct="50%"
                text="Go back"
                :text_color="colors.white"
                :text_size="go_back_button_text_size"
                :bold="true"
                event_name="pda_home_button_clicked"
                @pda_home_button_clicked="pda_home_button_clicked"
            ></basic_text_button>
          </div>

        </div>
      </div>

    </section>
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
  </section>
  <outside_footer class="footer_c"></outside_footer>

</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable no-unused-vars */
import error_popup_queue from "@/components/basics/error_popup_queue";
import basic_text_button from "@/components/parts/basic_text_button";
import notification_popup from "@/components/basics/notification_popup";
import basic_icon_button from "@/components/parts/basic_icon_button";
// import caregiver_freetime_widgit from "@/components/FreeTime/cargiver_freetime_widgit";
import caregiver_freetime_widgit2 from "@/components/FreeTime/caregiver_freetime_widgit2";
import outside_footer from "@/components/parts/outside_footer";
import pda_admin_freetime_widget2 from "@/components/FreeTime/pda_admin_freetime_widget2";


import {Database} from "@/client_db";
import {
  add_class_to_body,
  colors,
  desktop_size,
  remove_class_to_body,
  remove_class_from_id,
  freetime_caregiver_interface_eligible,
  freetime_agency_interface_eligible,
  analytic,
  get_pda_home_route,
  get_profile_route,

} from "@/library";
import store from "@/store";

export default {
  name: "FreeTime_interface",

  async mounted() {
    // let body = document.querySelector('body')
    // add_class_to_body('bg_primary_purple')
    // body.style.background = colors.new_primary_purple
  },

  async beforeUnmount() {
    // let body = document.querySelector('body')
    // remove_class_to_body('bg_primary_purple')
    // body.style.background = colors.white
  },

  async beforeMount() {
    // console.log("Running beforeMount")
    // this.whole_ft_interface = uuidv4();
    this.db = new Database();
    await store.dispatch('notification_restart')
    this.agency_interface = await freetime_agency_interface_eligible()
    this.caregiver_interface = await freetime_caregiver_interface_eligible()
  },

  components: {

    error_popup_queue,
    notification_popup,
    basic_text_button,
    basic_icon_button,
    caregiver_freetime_widgit2,
    outside_footer,
    pda_admin_freetime_widget2
  },
  data() {
    return {
      colors: colors,
      db: "",
      show_hamburger_menu_x: true,
      show_hamburger_backer_x: true,
      show_gear_menu_x: false,
      show_gear_backer_x: false,
      show_baseline_popup_x: false,
      show_baseline_backer_x: false,
      agency_interface_perm_true: true,
      agency_interface: false,
      caregiver_interface: false,
      whole_ft_interface: "",
      go_back_button_size_: "75px",
      go_back_button_text_size_: "13px",
    }
  },
  methods: {
    async pda_home_button_clicked() {
      // console.log("inside profile_button_clicked")
        await this.$router.push({path: `${get_pda_home_route()}`})
      },

    async profile_button_clicked() {
      analytic("01:01:16:211")
      // console.log("inside profile_button_clicked")
      try {
        // this.hamburger_backer_clicked()
        // this.$emit('hamburger_backer_clicked', true)
        // await this.$router.push({path: `${this.get_profile_route_()}`})
        await this.$router.push({path: `${get_pda_home_route()}`})

      } catch (error) {
        // console.log(error)
      }

    },

    run_window_width_adjustments(new_w) {
      // console.log(`In window adjustments with ${new_w}`)
      // if (new_w >= 280) {
      //   this.mobile_mode_x = true
      // }
      //
      if (new_w >= 0 && new_w <= 450) {
        // remove_class_to_body('content_no_radial')
        // remove_class_to_body('content_large_screen')
        // remove_class_to_body('bg_white')

        // remove_class_from_id('cg_whole_page', 'bg_primary_purple-light')
        // this.go_back_button_size_="50px"

      }
      //
      //  if (new_w > 800 && new_w <= 2500) {
      //   this.go_back_button_size_="75px"
      // }
      //
      //  if (new_w > 2500 && new_w <= 10000) {
      //   this.go_back_button_size_="150px"
      // }

      // if (new_w >= 450) {
      //
      //   remove_class_to_body('content_large_screen')
      //   add_class_to_body('content_no_radial')
      //   // this.mobile_mode_x = true
      // }
      // if (new_w >= 375) {
      //   // message = 'The window width >= 375'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 540) {
      //   // message = 'The window width >= 540'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 640) {
      //   // message = 'The window width >= 640'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 1024) {
      //   // message = 'The window width >= 1024'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 1200) {
      //   // message = 'The window width >= 1200'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      if (new_w <= desktop_size) {
        // console.log("INSIDE THE WINDOW MONITOR LESS THAN DESKTOP..")
        // remove_class_to_body('content_large_screen')

        this.mobile_mode_x = true
        const matches = document.querySelectorAll(".screen_block_no_shadows");
        matches.forEach(function (match) {
          match.classList.remove('screen_block_no_shadows');
          match.classList.add('screen_block');
        });

      }
      if (new_w >= desktop_size) {
        // console.log("INSIDE THE WINDOW MONITOR MORE THAN DESKTOP..")
        // remove_class_to_body('content_no_radial')
        add_class_to_body('content_large_screen')
        this.mobile_mode_x = false

      }
    },
    toggle_agency_interface_perm() {
      console.log("toggle_agency_interface_perm clicked")
      this.agency_interface_perm_true = !this.agency_interface_perm_true
    },

    toggle_hamburger_menu() {
      this.show_hamburger_menu_x = !this.show_hamburger_menu_x
      this.show_hamburger_backer_x = !this.show_hamburger_backer_x
    },
    toggle_gear_menu() {
      this.show_gear_menu_x = !this.show_gear_menu_x
      this.show_gear_backer_x = !this.show_gear_backer_x
    },
    gear_menu_false() {
      this.show_gear_menu_x = false
      this.show_gear_backer_x = false
    },
    hamburger_menu_false() {
      this.show_hamburger_menu_x = false
      this.show_hamburger_backer_x = false
    },
    baseline_popup_false() {
      this.show_baseline_popup_x = false
      this.show_baseline_backer_x = false
    },
    baseline_button_clicked() {
      console.log("baseline button was clicked")
      this.hamburger_menu_false()
      // this.show_baseline_popup_x = true
      //Don't show backer, because backer for this menu is handled in the child
      // this.show_baseline_backer_x = true
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
  computed: {
    go_back_button_text_size(){
      return this.go_back_button_text_size_
    },
    go_back_button_size(){
      return this.go_back_button_size_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    agency_interface_ok() {
      return freetime_agency_interface_eligible()
    },
    caregiver_interface_ok() {
      return freetime_caregiver_interface_eligible()
    },
    agency_interface_perm() {
      return this.agency_interface_perm_true
      // let search = {
      //   key: "comp",
      //   additional_context: [['div', 'admin0']],
      //   base_context: JSON.parse(store.getters.proposed_preamble)
      // }
      // let route_perms_array = await db.db_search(search)
      // console.log("Found the following route_perms_array")
      // console.log(route_perms_array)

      // for (let k of route_perms_array) {
      //   if (k === r.meta['auth_key']['value']) {
      //     // console.log("Route permission has been discovered.")
      //     authorized = true
      //   }
      // }
    },
    show_hamburger_menu() {
      return this.show_hamburger_menu_x
    },
    show_gear_menu() {
      return this.show_gear_menu_x
    },
    show_gear_backer() {
      // console.log("in show_baseline_popup computed")
      return this.show_gear_backer_x
    },
    show_baseline_popup() {
      // console.log("in show_baseline_popup computed")
      return this.show_baseline_popup_x
    },
    show_hamburger_backer() {
      // console.log("in show_baseline_popup computed")
      return this.show_hamburger_backer_x
    },
    show_baseline_backer() {
      // console.log("in show_baseline_popup computed")
      return this.show_baseline_popup_x
    },
  }
}
</script>

<style scoped>




@media (min-width: 100px) {

.hat_size {
  width: 150px;
}

.b_size{
  max-width: 125px;
}
.the_text{
  font-size: 10px;
  /*padding-left: 25px;*/
  text-align: center;
}
}

@media (min-width: 900px) {

.hat_size {
  width: 200px;
}

.b_size{
  max-width: 500px;
}
.the_text{
  font-size: 15px;
  /*padding-left: 25px;*/
  text-align: center;
}
}


</style>