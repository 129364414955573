<template>
  <div class="flex_centered_column">
    <error_popup_queue></error_popup_queue>
    <div @click="advance_state(image_or_video_clicked_action)"
         class="profile_img_frame flex_fully_centered_column cursor_ptr">
      <img v-if="initial_state && personal_gear_available"
           class="cursor_ptr text_align_center"
           :src="require('@/assets/' + 'no-profile-picture.svg')" alt="No profile picture icon">
      <div v-if="initial_state && !personal_gear_available"
           class="text_align_center font_weight_600 padding_lr_15">Searching...
      </div>
      <video style="display:none" id="vid_camera" autoplay></video>
      <img v-show="image_is_displayed_composite_state" id="still_image" src="" alt="User Profile Image">
    </div>

    <canvas style="display:none;"></canvas>

    <div v-if="initial_state"></div>
    <div v-if="video_on_state">
      <div class="flex_centered_row button_bank">
        <basic_icon_button
            ref="btn3"
            class="margin_t_10 cursor_ptr"
            :ready="true"
            :button_color="btn3_c"
            :button_color_not_ready="colors.pennie_gray"
            :button_height="button_h"
            :button_width="button_w"
            :border_rad_pct='button_border_rad'
            :image_path='btn3_icon'
            :image_width="image3_w"
            alt_text="Take Picture"
            event_name="snap_pic"
            @snap_pic="snap_pic"
        ></basic_icon_button>
      </div>
    </div>
    <div v-if="considering_pic_state" class="flex_space_between_row">
      <basic_icon_button
          ref="btn4"
          class="margin_tb_20 margin_lr_10 cursor_ptr"
          :ready="true"
          :button_color="btn4_c"
          :button_color_not_ready="colors.pennie_gray"
          :button_height="button_h"
          :button_width="button_w"
          :border_rad_pct='button_border_rad'
          :image_path='btn4_icon'
          :image_width="image4_w"
          alt_text="Save Image"
          event_name="save_image"
          @save_image="save_profile_image"
      ></basic_icon_button>
      <basic_icon_button
          ref="btn4"
          class="margin_tb_20 margin_lr_10 cursor_ptr"
          :ready="true"
          :button_color="btn4_c"
          :button_color_not_ready="colors.pennie_gray"
          :button_height="button_h"
          :button_width="button_w"
          :border_rad_pct='button_border_rad'
          :image_path='btn2_icon'
          :image_width="image2_w"
          alt_text="Trash Image"
          event_name="trash_image"
          @trash_image="trash_image"
      ></basic_icon_button>
    </div>
    <div v-if="display_server_image_state"></div>
    <div v-if="request_to_start_video_state">
      <basic_icon_button
          ref="btn4"
          class="margin_t_10 cursor_ptr"
          :ready="true"
          :button_color="btn1_c"
          :button_color_not_ready="colors.pennie_gray"
          :button_height="button_h"
          :button_width="button_w"
          :border_rad_pct='button_border_rad'
          :image_path='btn1_icon'
          :image_width="image1_w"
          alt_text="Turn on video"
          event_name="video_on"
          @video_on="advance_state('start_video_button_clicked')"
      ></basic_icon_button>
    </div>
    <div v-if="video_restarted_state">
      <div class="flex_centered_row button_bank">
        <basic_icon_button
            ref="btn3"
            class="margin_t_10 cursor_ptr"
            :ready="true"
            :button_color="btn3_c"
            :button_color_not_ready="colors.pennie_gray"
            :button_height="button_h"
            :button_width="button_w"
            :border_rad_pct='button_border_rad'
            :image_path='btn3_icon'
            :image_width="image3_w"
            alt_text="Take Picture"
            event_name="snap_pic"
            @snap_pic="snap_pic"
        ></basic_icon_button>
      </div>
    </div>
    <div v-if="considering_alt_pic_state" class="flex_space_between_row">
      <basic_icon_button
          ref="btn4"
          class="margin_tb_20 margin_lr_10  cursor_ptr"
          :ready="true"
          :button_color="btn4_c"
          :button_color_not_ready="colors.pennie_gray"
          :button_height="button_h"
          :button_width="button_w"
          :border_rad_pct='button_border_rad'
          :image_path='btn4_icon'
          :image_width="image4_w"
          alt_text="Save Image"
          event_name="save_image"
          @save_image="save_profile_image"
      ></basic_icon_button>
      <basic_icon_button
          ref="btn4"
          class="margin_tb_20 margin_lr_10 cursor_ptr"
          :ready="true"
          :button_color="btn1_c"
          :button_color_not_ready="colors.pennie_gray"
          :button_height="button_h"
          :button_width="button_w"
          :border_rad_pct='button_border_rad'
          :image_path='btn2_icon'
          :image_width="image2_w"
          alt_text="Trash Image"
          event_name="trash_image"
          @trash_image="trash_image"
      ></basic_icon_button>
    </div>
  </div>


</template>

<script>
/* eslint-disable */
import {colors, on_your_own_profile_page} from "@/library";
import store from "@/store";
import error_popup_queue from "@/components/basics/error_popup_queue";
import basic_icon_button from "@/components/parts/basic_icon_button";
import {Database} from "@/client_db";

export default {
  name: "camera_widget",
  components: {
    basic_icon_button,
    error_popup_queue
  },
  props: ['event_name', 'frame_color', 'frame_text_color', 'button_h', 'button_w', 'button_border_rad',
    'personal_gear_available',
    'btn1_c', 'btn1_border_rad', 'btn1_icon', 'image1_w',
    'btn2_c', 'btn2_border_rad', 'btn2_icon', 'image2_w',
    'btn3_c', 'btn3_border_rad', 'btn3_icon', 'image3_w',
    'btn4_c', 'btn4_border_rad', 'btn4_icon', 'image4_w',
    'old_pic', 'disable_controls', 'saved_state', 'my_profile_page'

  ],
  async mounted() {
    // console.log("Mounting the camera widget")
    this.db = new Database();
    let bg = this.$el
    let frame = bg.querySelector(".profile_img_frame");
    frame.style.background = this.frame_color
    frame.style.color = this.frame_text_color

    // console.log("CAMERA WIDGET TEST")
    //   console.log(await on_your_own_profile_page(this.db))
    //
    //   if (!await on_your_own_profile_page(this.db)) {
    //     console.log("This is not my profile page")
    //     this.my_page_ = false
    //     return null
    //   } else {
    //     console.log("Looks like this is my profile page")
    //     this.my_page_ = false
    //   }

    try {
      await this.$nextTick(function () {
        if (this.original_image_available) {
          this.advance_state("original_image_available")
        } else {
          // console.log("Inside mounted, no original pic available.")
        }

        // console.log("CAMERA WIDGET TEST")
        // console.log(on_your_own_profile_page(this.db))
        //
        // if (!on_your_own_profile_page(this.db)) {
        //   console.log("This is not my profile page")
        //   this.my_page_ = false
        //   return null
        // } else {
        //   console.log("Looks like this is my profile page")
        //   this.my_page_ = false
        // }


      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.original_image_available) {
          // console.log("Running camera update... original pic available.")
          this.advance_state("original_image_available")
        } else {
          // console.log("Inside updated, no original pic available.")
        }
      })
    } catch (error) {
    }
  },
  data() {
    return {
      db: "",
      widget_state: {
        initial: true,
        video_on: false,
        considering_pic: false,
        display_server_image: false,
        request_to_start_video: false,
        video_restarted: false,
        considering_alt_pic: false
      },
      state_functions: {
        initial: this._set_initial_state,
        video_on: this._set_video_on_state,
        considering_pic: this._set_considering_pic_state,
        display_server_image: this._set_display_server_image_state,
        request_to_start_video: this._set_request_to_start_video_state,
        video_restarted: this._set_video_restarted_state,
        considering_alt_pic: this._set_considering_alternative_picture_state
      },
      state_parameter: {
        video_on_: false,
        image_on_server_: false,
        considering_pic_: false,
        controls: {
          snap: false,
          del_and_upload: false,
          start_video: false,
          active: false
        },
        image_displayed_: false,
        image_available_: false
      },
      colors: colors,
      video_width: 150 * 1.6,
      video_height: 150 * 1.6,
      // video_width: 200,
      // video_height: 200,
      video_stream: "",
      alternative_picture: "",
    }
  },
  computed: {
    image_is_displayed_composite_state() {
      if (this.considering_pic_state || this.display_server_image_state
          || this.request_to_start_video_state || this.considering_alt_pic_state) {
        return true
      } else {
        return false
      }
    },
    video_is_displayed_composite_state() {
      if (this.video_on_state || this.video_restarted_state) {
        return true
      } else {
        return false
      }
    },
    image_or_video_clicked_action() {
      if (this.video_is_displayed_composite_state) {
        return "live_video_clicked"
      } else if (this.image_is_displayed_composite_state) {
        return "still_image_clicked"
      } else {
        return "no_profile_icon_clicked"
      }
    },
    original_image_available() {
      if (this.old_pic === undefined || this.old_pic === "") {
        return false
      } else {
        return true
      }
    },
    video_constraints() {
      let height = this.video_height
      let width = this.video_width
      return {
        video: {
          width:
              {min: width, ideal: width},
          height:
              {min: height, ideal: height},
          facingMode: "user"
        },

      }
    },
    initial_state() {
      return this.widget_state.initial
    },

    video_on_state() {
      return this.widget_state.video_on
    },
    considering_pic_state() {
      return this.widget_state.considering_pic
    },
    display_server_image_state() {
      return this.widget_state.display_server_image
    },
    request_to_start_video_state() {
      return this.widget_state.request_to_start_video
    },
    video_restarted_state() {
      return this.widget_state.video_restarted
    },
    considering_alt_pic_state() {
      return this.widget_state.considering_alt_pic
    },
    video_on() {
      return this.state_parameter.video_on_
    },
    considering_pic() {
      return this.state_parameter.considering_pic_
    },
    controls_active() {
      return this.state_parameter.controls.active
    },
  },
  methods: {
    emit_state() {
      this.$emit(this.event_name, true)
    },

    source_original_image() {
      const img = document.getElementById("still_image");
      img.src = this.old_pic
    },
    source_alternative_image() {
      const img = document.getElementById("still_image");
      img.src = this.alternative_picture
    },
    video_display_on(v) {
      let video
      if (v !== undefined) {
        video = v
      } else {
        video = document.getElementById("vid_camera");
      }
      video.style.display = 'block'

      //Apple experiment
      video.setAttribute('autoplay', '')
      video.setAttribute('muted', '')
      video.setAttribute('playsinline', 'true')
      //Apple experiment over

      let constraints = this.video_constraints
      try {
        console.log("Let's look at the navigator...")
        console.log(navigator)
        console.log(navigator.mediaDevices.getUserMedia)
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          video.srcObject = stream;
          this.video_stream = stream
        });
      } catch (e) {
        console.error("Camera error:", e);
        if (e.name === "NotAllowedError") {
          store.commit("error_enqueue", "Camera access denied. Please allow camera permissions.");
        } else if (e.name === "NotFoundError") {
          store.commit("error_enqueue", "No camera found. Try reconnecting or restarting your device.");
        } else {
          store.commit("error_enqueue", "Your camera won't turn on. Is another program already using it?");
        }
      }

    },
    video_display_off() {
      const video = document.querySelector("video");
      video.style.display = 'none'
      let stream = this.video_stream
      try {
        stream.getTracks().forEach(function (track) {
          track.stop();
        });
      } catch (error) {
      }
    },
    show_image() {
      let img = document.getElementById("still_image");
      img.style.display = "block"
    },
    hide_image() {
      let img = document.getElementById("still_image");
      img.style.display = "none"
    },

    trash_image() {
      this.advance_state("trash_clicked")
    },
    snap_pic() {
      const canvas = document.querySelector("canvas");
      const video = document.querySelector("video");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0);

      this.alternative_picture = canvas.toDataURL("image/webp");

      this.advance_state("snap_pic_clicked")
    },
    save_profile_image() {
      store.commit('set_profile_image', this.alternative_picture)
      this.emit_state()
      this.advance_state("upload_clicked")
    },
    _set_state_to_true(name) {
      for (const [key, value] of Object.entries(this.widget_state)) {
        this.widget_state[key] = name === key;
      }
    },
    _get_state() {
      for (const [key, value] of Object.entries(this.widget_state)) {
        if (value === true) {
          return key
        }
      }
    },
    _switch_state(state_name) {
      this._set_state_to_true(state_name)
      this.state_functions[state_name]()
    },
    _set_initial_state() {
      this.state_parameter.video_on_ = false
      this.state_parameter.image_on_server_ = false
      this.state_parameter.considering_pic_ = false
      this.state_parameter.controls.active = false
      this.state_parameter.controls.snap = false
      this.state_parameter.controls.del_and_upload = false
      this.state_parameter.controls.start_video = false
      this.state_parameter.image_displayed_ = false
      this.state_parameter.image_available_ = false
    },
    _set_video_on_state() {
      console.log("Set video on state to True")
      this.state_parameter.video_on_ = true
      this.state_parameter.image_on_server_ = false
      this.state_parameter.considering_pic_ = false
      this.state_parameter.controls.active = true
      this.state_parameter.controls.snap = true
      this.state_parameter.controls.del_and_upload = false
      this.state_parameter.controls.start_video = false
      this.state_parameter.image_displayed_ = false
      this.state_parameter.image_available_ = false
    },
    _set_considering_pic_state() {
      this.state_parameter.video_on_ = false
      this.state_parameter.image_on_server_ = false
      this.state_parameter.considering_pic_ = true
      this.state_parameter.controls.active = true
      this.state_parameter.controls.snap = false
      this.state_parameter.controls.del_and_upload = true
      this.state_parameter.controls.start_video = false
      this.state_parameter.image_displayed_ = true
      this.state_parameter.image_available_ = true
    },
    _set_display_server_image_state() {
      this.state_parameter.video_on_ = false
      this.state_parameter.image_on_server_ = true
      this.state_parameter.considering_pic_ = false
      this.state_parameter.controls.active = false
      this.state_parameter.controls.snap = false
      this.state_parameter.controls.del_and_upload = false
      this.state_parameter.controls.start_video = false
      this.state_parameter.image_displayed_ = true
      this.state_parameter.image_available_ = true
    },
    _set_request_to_start_video_state() {
      console.log("request to start video is true")
      this.state_parameter.video_on_ = false
      this.state_parameter.image_on_server_ = true
      this.state_parameter.considering_pic_ = false
      this.state_parameter.controls.active = true
      this.state_parameter.controls.snap = false
      this.state_parameter.controls.del_and_upload = false
      this.state_parameter.controls.start_video = true
      this.state_parameter.image_displayed_ = true
      this.state_parameter.image_available_ = true
    },
    _set_video_restarted_state() {
      this.state_parameter.video_on_ = true
      this.state_parameter.image_on_server_ = true
      this.state_parameter.considering_pic_ = false
      this.state_parameter.controls.active = true
      this.state_parameter.controls.snap = true
      this.state_parameter.controls.del_and_upload = false
      this.state_parameter.controls.start_video = false
      this.state_parameter.image_displayed_ = false
      this.state_parameter.image_available_ = true
    },
    _set_considering_alternative_picture_state() {
      this.state_parameter.video_on_ = false
      this.state_parameter.image_on_server_ = true
      this.state_parameter.considering_pic_ = true
      this.state_parameter.controls.active = true
      this.state_parameter.controls.snap = false
      this.state_parameter.controls.del_and_upload = true
      this.state_parameter.controls.start_video = false
      this.state_parameter.image_displayed_ = true
      this.state_parameter.image_available_ = true
    },
    display_or_initial_state() {
      if (this.original_image_available) {
        return "display_server_image"
      } else {
        return "initial"
      }
    },

    async advance_state(action) {

      // if (this.my_profile_page) {
      //    console.log("HEY THIS IS MY PROFILE PAGE.")
      // } else {
      //   console.log("THIS IS NOT MY PROFILE PAGE. NO EDITING.")
      //   return null
      // }
      let start_state = this._get_state()

      //IF this is not your profile page, then you are only allowed to continue
      //if start_state is 'initial' or 'display_server_image'
      if (!this.my_profile_page) {
        if (start_state === 'initial' || start_state === 'display_server_image') {
          //pass
        } else {
          console.log(`Cannot advance state from ${start_state}.`)
          return null
        }
      }


      // console.log(`Current State is ${start_state} and action is ${action}`)
      if (start_state === "initial" && action === "no_profile_icon_clicked") {
        console.log("Test0")
        if (this.my_profile_page) {
          this.video_display_on()
          this._switch_state("video_on")
        } else {
          console.log("No editing someone else's picture.")
        }

      } else if (start_state === "initial" && action === "original_image_available") {
        console.log("Test1")
        this.source_original_image()
        this.show_image()
        this._switch_state("display_server_image")
      } else if (start_state === "video_on" && action === "live_video_clicked") {
        this.video_display_off()
        this._switch_state(this.display_or_initial_state())
      } else if (start_state === "video_on" && action === "snap_pic_clicked") {
        this.video_display_off()
        this.source_alternative_image()
        this.show_image()
        this._switch_state("considering_pic")

      } else if (start_state === "considering_pic" && action === "trash_clicked") {
        console.log("Test2")
        this.hide_image()
        this.source_original_image()
        this.video_display_on()
        this._switch_state("video_on")
      } else if (start_state === "considering_pic" && action === "still_image_clicked") {
        console.log("Test3")
        this.video_display_off()
        this._switch_state(this.display_or_initial_state())
      } else if (start_state === "considering_pic" && action === "upload_clicked") {
        console.log("Test4")
        this.video_display_off()
        this._switch_state("display_server_image")
      } else if (start_state === "display_server_image" && action === "still_image_clicked") {
        console.log("Test5")
        if (this.my_profile_page) {
          console.log("this.my_profile_image is True")
          this._switch_state("request_to_start_video")
        } else {

          console.log("No editing someone else's picture.")
        }

      } else if (start_state === "display_server_image" && action === "original_image_available") {
        // console.log("in the new section")
        this.source_original_image()
        this.show_image()
        this._switch_state("display_server_image")
      } else if (start_state === "request_to_start_video" && action === "start_video_button_clicked") {
        this.video_display_on()
        this._switch_state("video_restarted")
      } else if (start_state === "request_to_start_video" && action === "still_image_clicked") {
        this.video_display_off()
        this._switch_state("display_server_image")
      } else if (start_state === "video_restarted" && action === "live_video_clicked") {
        this.source_original_image()
        this.video_display_off()
        this._switch_state("display_server_image")
      } else if (start_state === "video_restarted" && action === "snap_pic_clicked") {
        this.video_display_off()
        this.source_alternative_image()
        this.show_image()
        this._switch_state("considering_alt_pic")
      } else if (start_state === "considering_alt_pic" && action === "trash_clicked") {
        this.hide_image()
        this.video_display_on()
        this._switch_state("video_restarted")
      } else if (start_state === "considering_alt_pic" && action === "upload_clicked") {
        this.video_display_off()
        this._switch_state("display_server_image")
      } else if (start_state === "considering_alt_pic" && action === "still_image_clicked") {
        this.source_original_image()
        this._switch_state("display_server_image")
      }
    },
  },

}
</script>

<style scoped>


@media (min-width: 100px) {
  .profile_img_frame {

    width: calc(var(--profile_pic_size) * 1.2);
    height: calc(var(--profile_pic_size) * 1.2);
    background: var(--pennie_gray);
  }

  #still_image {
    width: calc(var(--profile_pic_size) * 1.1);
    height: calc(var(--profile_pic_size) * 1.1);
  }

}

@media (min-width: 1200px) {
  .profile_img_frame {

    width: calc(var(--profile_pic_size) * 1.35);
    height: calc(var(--profile_pic_size) * 1.35);
    background: var(--pennie_gray);
  }

  #still_image {
    width: calc(var(--profile_pic_size) * 1.2);
    height: calc(var(--profile_pic_size) * 1.2);
  }

}


.button_bank {
  margin-top: 20px;
  width: 250px;
}
</style>