<template>
  <section class="flex_centered_column">
    <inside_banner
        mode="freetime_agency"
        desktop_size="500"
        @match_template_button_clicked="onPickFile('match_template')"
        @baseline_button_clicked="baseline_button_clicked"
        @get_matches_template="get_matches_template"
        @get_hours_report="get_hours_report"
        @get_hours_standard_template="get_hours_upload_template"
        @get_eligibility="get_eligibility_chart"
    ></inside_banner>
    <spinner
        :spinner_up="spinner_up"
    ></spinner>


    <input type="file" style="display: none" ref="fileInput_match_template"
           @change="onMatchTemplatePicked($event,'match_template')">


    <!--    Break Even menu pop up-->
    <universal_pop_up_slot
        :show_popup="baseline_menu_show"
        @universal_popup_bg_clicked="baseline_popup.show = false"
        :backer_color="colors.dark_glass"
    >
      <div class="be_report_sizing">
        <div class="bg_primary_purple flex_centered_column b_rad_10px padding_tb_10 padding_lr_10">
          <div class="font_raleway text_align_center margin_tb_10 text_white text_25 font_weight_800">Enter Base Rates
          </div>
          <label_with_input_text
              ref="std_bill_rate_input"
              label="Std. Bill Rate"
              placeholder_text="23"
              :value=get_base_bill_rate
              label_color="white"
              event_name="std_bill_rate_input_emit"
              present_event="std_bill_rate_input_present"
              @std_bill_rate_input_present="break_even_numbers.back_end_values_present.std_bill_rate = true"
              @std_bill_rate_input_emit="std_bill_rate_input_validator"
              :value_bg_color=colors.new_cyanic_main
              :value_color=colors.black
          ></label_with_input_text>

          <label_with_input_text
              ref="std_base_rate_input"
              label="Base Pay Rate"
              placeholder_text="11"
              :value=get_base_pay_rate
              label_color="white"
              event_name="std_base_pay_input_emit"
              present_event="std_base_rate_input_present"
              @std_base_rate_input_present="break_even_numbers.back_end_values_present.base_pay_rate = true"
              @text_value_present="break_even_numbers.back_end_values_present.std_bill_rate = true"
              @std_base_pay_input_emit="base_pay_rate_input_validator"
              :value_bg_color=colors.new_cyanic_main
              :value_color=colors.black
          ></label_with_input_text>

          <label_with_input_text
              ref="std_payroll_burden_input"
              label="Payroll Burden"
              placeholder_text=".2"
              :value=get_burden_rate
              label_color="white"
              event_name="std_payroll_burden_input_emit"
              present_event="payroll_input_present"
              @payroll_input_present="break_even_numbers.back_end_values_present.payroll_burden = true"
              @std_payroll_burden_input_emit="payroll_burden_input_validator"
              :value_bg_color=colors.new_cyanic_main
              :value_color=colors.black
          ></label_with_input_text>

          <label_with_input_text
              ref="std_base_hours_input"
              label="Base Hours"
              placeholder_text="800"
              :value=get_base_hours
              label_color="white"
              event_name="std_base_hours_input_emit"
              present_event="std_base_hours_input_present"
              @std_base_hours_input_present="break_even_numbers.back_end_values_present.base_hours = true"
              @std_base_hours_input_emit="base_hours_input_validator"
              :value_bg_color=colors.new_cyanic_main
              :value_color=colors.black
          ></label_with_input_text>

          <div class="flex_centered_row margin_t_10 margin_b_10">
            <basic_text_button
                :button_state="baseline_form_complete"
                :button_color="colors.new_cyanic_main"
                :text_color="colors.black"
                :button_color_not_ready="colors.eli_gray"
                :button_not_ready_text_color="colors.pennie_gray"
                button_height="60px"
                button_width="60px"
                border_rad_pct="50%"
                text="Submit"
                text_size="14px"
                :bold="true"
                event_name="emit_baseline_input_values"
                @emit_baseline_input_values="emit_baseline_input_values"
            ></basic_text_button>
          </div>
        </div>
      </div>
    </universal_pop_up_slot>

    <!--  agency message an publication of WonderPerks pop up-->
    <universal_invisible_backer
        :show_backer="show_agency_message_form"
        :backer_color="colors.dark_glass"
        event_name="backer_clicked"
        @backer_clicked="agency_message_form_false"
    ></universal_invisible_backer>


    <!--  Gear menu backer -->
    <universal_invisible_backer
        :show_backer="gear_menu_show"
        :backer_color="colors.dark_glass"
        event_name="backer_clicked"
        @backer_clicked="gear_menu_toggle"
    ></universal_invisible_backer>

    <!--  Payroll menu backer -->
    <universal_invisible_backer
        :show_backer="show_payroll_menu"
        :backer_color="colors.dark_glass"
        event_name="backer_clicked"
        @backer_clicked="toggle_payroll_menu"
    ></universal_invisible_backer>


    <universal_invisible_backer
        :show_backer="hamburger_menu_show"
        :backer_color="colors.dark_glass"
        event_name="backer_clicked"
        @backer_clicked="hamburger_menu_toggle"
    ></universal_invisible_backer>

    <!--Popup Payroll Selection Menu    -->
    <universal_pop_up_slot
        :show_popup="show_payroll_menu"
        @universal_popup_bg_clicked="toggle_payroll_menu"
        :backer_color="colors.dark_glass"
    >
      <div class="wid_85 margin_auto">
        <div class="bg_primary_purple_deep flex_centered_column padding_tb_20 b_rad_25px">
          <div class="font_raleway text_align_center margin_tb_10 text_white text_25 font_weight_800">
            Select Payroll Weeks
          </div>
          <indicator_label
              class="cursor_ptr"
              v-for="week in selectable_payroll_weeks" :key=week
              @click="toggle_week_to_payroll_selection(week)"
              :loaded_message_text="week"
              :loaded_message_text_color="colors.black"
              :loaded_message_bg="colors.new_gold"
              loaded_font_size="18px"

              :ready_message_text="week"
              :ready_message_text_color="colors.black"
              :ready_message_bg="colors.eli_gray"

              ready_font_size="18px"

              :state="check_if_payroll_week_selected(week) ? 'loaded' : 'ready'"
          ></indicator_label>
          <div class="flex_centered_row">
            <basic_text_button
                class="margin_t_30"
                :button_state="selected_payrolls.length > 0"
                :button_color="colors.new_primary_purple"
                button_color_not_ready="gray"
                button_not_ready_text_color="lightgray"
                button_height="100px"
                button_width="100px"
                border_rad_pct="50%"
                text="Download Report"
                :text_color="colors.white"
                text_size="15px"
                :bold="true"
                event_name="weeks_selected"
                @weeks_selected="print_payroll_report(selected_payrolls)"
            ></basic_text_button>
          </div>


        </div>
      </div>
    </universal_pop_up_slot>


    <!--    base-->
    <section class="flex_centered_start_column">
      <div class="flex_centered_row">
        <img @click="go_to_profile_home_route" class="padding_tb_20 ft_logo cursor_ptr"
             src="@/assets/wonder_perks.svg"
             alt="WonderPerks Feature Logo">
      </div>
      <section v-if="!match_file_indicator_state && manual_mode" id="caregiver_data">
        <div class="section_header flex_centered_row">
          <h3>Caregiver Data</h3>
        </div>
        <div class="section_body flex_centered_column">
          <div class="flex_centered_column saved_indicator">
            <div class="flex_centered_row">
              <p>Match File Saved</p>
              <!--              <span v-if="match_file_indicator_state"-->
              <!--                    class="material-symbols-outlined good material_icon">check_circle</span>-->
              <img class="material_icon" v-if="match_file_indicator_state"
                   :src="image_name('check_circle.svg')"
                   alt="Match saved Icon">
              <!--              <span v-if="!match_file_indicator_state"-->
              <!--                    class="material-symbols-outlined bad material_icon">warning</span>-->
              <img class="material_icon" v-if="!match_file_indicator_state"
                   :src="image_name('warning.svg')"
                   alt="Match not saved icon">
            </div>

          </div>

          <div class="flex_centered_column saved_indicator">
            <div class="flex_centered_row">
              <p>Schedule File Selected</p>
              <!--              <span v-if="schedules_file_selected"-->
              <!--                    class="material-symbols-outlined good material_icon">check_circle</span>-->

              <img class="material_icon" v-if="schedules_file_selected"
                   :src="image_name('check_circle.svg')"
                   alt="Schedule file selected Icon">
              <img class="material_icon" v-if="!schedules_file_selected"
                   :src="image_name('warning.svg')"
                   alt="Schedule file not selected Icon">

              <!--              <span v-if="!schedules_file_selected" class="material-symbols-outlined bad material_icon">warning</span>-->
            </div>

            <div class="file_name_indicator">
              <p v-if="get_file_freetime_name_data" id="sch_data_file_name">{{ get_file_freetime_name_data }}</p>
            </div>

          </div>
          <div>
            <basic_slot_button
                class="action_button"
                :button_state="true"
                :button_color="colors.primary_purple_light"
                button_color_not_ready="gray"
                button_height="82px"
                :button_width=action_button_width
                border_rad_pct="0"
                :text_color="colors.white"
                :bold="true"
                event_name="data_file_chooser_clicked"
                @data_file_chooser_clicked="onPickFile('data')"
            >
              <div class="flex_centered_end_row action_button_text">
                <!--                <span class="material-symbols-outlined material_icon neutral">article</span>-->
                <img class="material_icon" :src="image_name('article.svg')" alt="Schedule select button">
                <div>Select Schedules</div>
              </div>
            </basic_slot_button>
            <input type="file" style="display: none" ref="fileInput_data" @change="onFilePicked($event, 'data', true)">
          </div>
        </div>

      </section>

      <!--      =================================================================================================-->
      <!--        Add in the new schedule week selection part here-->

      <section v-if="match_file_indicator_state" id="available_weeks">
        <div class="section_header flex_centered_row">
          <h3>Select Schedules</h3>
        </div>
        <div class="section_body flex_centered_column">
          <div class="flex_centered_column saved_indicator">
            <div class="flex_centered_column sch_button">
              <div v-if="available_freetime_weeks.length > 0">
                <div v-for="(item, index) of available_freetime_weeks" :key="`item-${index}`">
                  <div class="flex_centered_row ">
                    <basic_text_button
                        class="action_button action_button2"
                        :button_state="this.pre_selected_week===item"
                        :button_color="colors.new_cyanic_main"
                        :hover_color="colors.new_cyanic_main"
                        :text_color="colors.black"
                        :button_color_not_ready="colors.gray"
                        :button_not_ready_text_color="colors.morgie_gray"
                        button_height="82px"
                        :button_width=action_button_width
                        border_rad_pct="0"
                        :text=item
                        :button_not_ready_text=item
                        text_size="18px"
                        :bold="true"
                        event_name="set_pre_selected_week"
                        @set_pre_selected_week="set_pre_selected_week(item)"
                    ></basic_text_button>
                  </div>
                </div>
              </div>
              <div v-else>
                <p>No Schedules Available</p>
              </div>


            </div>

          </div>


        </div>

      </section>

      <!--      =================================================================================================-->

      <!--       <section v-if="dry_run_ready_status() || history_reports_available" id="freetime_funding">-->
      <section v-if="dry_run_ready_status()" id="freetime_funding">
        <div class="section_header flex_centered_row">
          <h3>FreeTime Funding</h3>
        </div>
        <div class="section_body flex_centered_column">
          <div class="flex_centered_row">
            <input
                v-model="ehr_form.amount"
                class="ehr_input font_weight_800 font_raleway funding_placeholder"
                :class="{ehr_input_inactive: ehr_form.amount==='' , ehr_input_active: ehr_form.amount !== ''}"
                type="text"
                placeholder="1.00">
          </div>
          <div>
            <basic_slot_button
                class="action_button"
                :button_state="true"
                :button_color="colors.primary_purple_light"
                button_color_not_ready="gray"
                button_height="82px"
                :button_width=action_button_width
                border_rad_pct="0"
                :text_color="colors.white"
                text_size="15px"
                :bold="true"
                event_name="dry_run_clicked"
                @dry_run_clicked="dry_run"
            >
              <div class="flex_centered_end_row action_button_text">
                <!--                <span class="material-symbols-outlined material_icon neutral">upload</span>-->
                <img class="material_icon neutral"
                     :src="image_name('upload.svg')"
                     alt="Test Funding button Icon">
                <div>Test Funding Amount</div>

              </div>
            </basic_slot_button>
          </div>

          <break_even_component
              v-if="this.publish_pending"
              class="be_comp"
              ref="be_component_ref"
              @rerun_dry_run="handle_rerun"
              :ready_to_publish="ready_to_publish"
              :mobile_mode="mobile_mode"
              :initial_funding=funding_for_passing
          ></break_even_component>
        </div>

      </section>


      <section>
        <div class="section_header flex_centered_row">
          <h3>Payroll Reports</h3>
        </div>
        <div class="section_body flex_centered_column" id="payroll_rep_section_body">

          <div>
            <basic_slot_button
                class="action_button action_button2"
                :button_state="true"
                :button_color="colors.white"
                button_color_not_ready="gray"
                button_height="82px"
                :button_width=action_button_width
                border_rad_pct="0"
                :text_color="colors.white"
                text_size="15px"
                :bold="true"
                event_name="dry_run_clicked"
                @dry_run_clicked="toggle_payroll_menu"
            >
              <div class="flex_centered_end_row action_button_text2 ">
                <!--                <span class="material-symbols-outlined material_icon  pr_rep_but">request_quote</span>-->
                <img class="material_icon pr_rep_but" :src="image_name('request_quote.svg')" alt="Icon">
                <div>Payroll Report</div>
              </div>
            </basic_slot_button>

            <basic_slot_button
                class="action_button action_button2"
                :button_state="true"
                :button_color="colors.white"
                button_color_not_ready="gray"
                button_height="82px"
                :button_width=action_button_width
                border_rad_pct="0"
                :text_color="colors.white"
                text_size="16px"
                :bold="true"
                event_name="dry_run_clicked"
                @dry_run_clicked="get_history_csv_report"
            >
              <div class="flex_centered_end_row action_button_text2">
                <!--                <span class="material-symbols-outlined material_icon  pr_rep_but">summarize</span>-->
                <img class="material_icon pr_rep_but" :src="image_name('summarize.svg')" alt="Icon">
                <div>Xlsx Report</div>
              </div>
            </basic_slot_button>
          </div>


        </div>

      </section>


      <section id="break_even" v-if="ready_to_publish"
               class="dry_run_report padding_b_30">


        <div v-if="ready_to_publish" class="screen_block padding_b_30">
          <div class="label_bolder text_align_center text_cyanic margin_tb_10">Agency Message</div>
          <label_with_input_text_area
              ref="message_to_publish"
              :ro_status="edit_message_read_only"
              label="Publish a Message"
              :label_color="colors.white"
              label_text_size="20px"
              :value_bg_color_ready="colors.new_cyanic_main"
              :value_bg_color_not_ready="colors.eli_gray"
              :value_text_color_ready="colors.black"
              :value_text_color_not_ready="colors.black"
              value_text_size_ready="15px"
              value_text_size_not_ready="15px"
              :value=get_freetime_agency_message
              rows="10"
              columns="30"
              placeholder_text="This message will be published to caregivers along with the bonus data."
              @edit_button_clicked="toggle_save_message_button_ready"
          ></label_with_input_text_area>

          <div class="flex_centered_row">
            <basic_text_button
                class="margin_t_10"
                :button_state="save_message_button_ready"
                :button_color="colors.new_cyanic_main"
                :hover_color="colors.new_cyanic_main"
                :text_color="colors.black"
                :button_color_not_ready="colors.gray"
                :button_not_ready_text_color="colors.morgie_gray"
                button_height="80px"
                button_width="80px"
                border_rad_pct="50%"
                text="Save Message"
                button_not_ready_text="Save Message"
                text_size="14px"
                :bold="true"
                event_name="save_agency_message"
                @save_agency_message="save_freetime_pub_message"
            ></basic_text_button>
          </div>
        </div>

        <!--        <div>DRY RUN MARKER1</div>-->

        <div class="screen_block padding_b_30">
          <section>
            <div class="label_bolder text_cyanic text_align_center margin_tb_10">Dry Run</div>
            <!--          <div>Publish: {{publish}}  -&#45;&#45; Pub: {{pub}}</div>-->
            <div id="dry_run_controls" class="flex_space_around_row">

              <basic_toggle
                  :state_disabled="!dry_run_ready_status()"
                  :new_state="pub"
                  @toggle-state_true="publish = true"
                  @toggle-state_false="publish = false"
              ></basic_toggle>
              <div class="flex_centered_row margin_r_30">
                <!--              <img @click="set_current_bonus_report(-1)" src="../../../public/img/images/lt_arrow.svg"-->
                <img @click="set_current_bonus_report(-1)" src="@/assets/lt_arrow.svg"
                     alt="Left Arrow"
                     class="ehr_arrows left_arrows cursor_ptr">
                <div class="ehr_toggle_amt ehr_input bg_cyanic">
                  <div>{{ current_bonus_report_stake }}</div>

                </div>
                <img @click="set_current_bonus_report(1)" src="@/assets/rt_arrow.svg"
                     alt="Right Arrow"
                     class="ehr_arrows right_arrows cursor_ptr">
              </div>

              <basic_text_button
                  class="margin_t_10"
                  :button_state=pub
                  :hover_color="colors.new_orange_main"
                  :button_color="colors.new_cyanic_main"
                  :button_color_not_ready="colors.eli_gray"
                  :button_not_ready_text_color="colors.white"
                  button_height="45px"
                  button_width="45px"
                  border_rad_pct="50%"
                  text="Pub"
                  :text_color="colors.black"
                  text_size="14px"
                  :bold="true"
                  event_name="on_publish"
                  @on_publish="on_publish"
              ></basic_text_button>

            </div>
          </section>

          <summary_report
              v-if="current_summary_report.length"
              :current_summary_history_report="current_summary_report"
          ></summary_report>



        </div>
      </section>
      <section id="history_report" v-if="history_reports_available"
               class="screen_block flex_centered_start_column padding_b_30">
        <section class="flex_centered_column">



          <section>
            <div class="flex_centered_row">
              <div class="section_header flex_centered_row">
                <h3>History</h3>
              </div>
            </div>

            <report_selector
                class="report_selector"
                ref="history_report_toggle"
                left_arrow_image="lt_arrow.svg"
                right_arrow_image="rt_arrow.svg"
                :label_list="history_report_dates"
                @selection_chosen="draw_history_reports"
            ></report_selector>


            <!--      // History report data-->
            <section class="">



              <summary_report
                  v-if="current_summary_history_report.length"
                  :current_summary_history_report="current_summary_history_report"
              ></summary_report>

              <!--            <div class="label_regular_plus text_cyanic margin_tb_20">{{ current_agency_message }}</div>-->
              <!--            <div>MARKER2</div>-->
              <div class="flex_centered_row">
                <p class="agency_message_display" v-if="current_agency_message===''">
                  No Message Available
                </p>
                <p class="agency_message_display" v-if="current_agency_message !== ''">
                  {{ current_agency_message }}
                </p>
              </div>

              <div class="flex_centered_column">
                <caregiver_report
                    :current_caregiver_history_report="current_caregiver_history_report"
                ></caregiver_report>
              </div>

            </section>
          </section>



        </section>

      </section>
    </section>
  </section>


</template>

<script>
/* eslint-disable */

import {
  // add_class_to_body,
  add_class_to_id,
  colors,
  desktop_size, float_to_percent_string, get_profile_route,
  parse_internal_json,
  receive_over_socket,
  remove_class_from_id,
  reset_current_error_message,
  within_inclusive_range, image_name,
  be_main_address, prime_socket, get_pda_home_route, analytic, get_date_string,
  pre_cache_available_freetime_weeks_for_all_your_agencies, get_associated, get_orgs_associated_to_me,
} from "@/library";

import {mapGetters} from "vuex";
import store from "@/store";
import {Database} from "@/client_db";
import axios from "axios";
import basic_slide_over_menu from "@/components/parts/basic_slide_over_menu";
import basic_icon_button from "@/components/parts/basic_icon_button";
import basic_toggle from "@/components/parts/basic_toggle";
import basic_text_button from "@/components/parts/basic_text_button";
import indicator_label from "@/components/parts/indicator_label";
import label_with_input_text from "@/components/parts/label_with_input_text";
import label_with_input_text_area from "@/components/parts/label_with_input_text_area";
import break_even_component from "@/components/FreeTime/break_even_component";
import report_selector from "@/components/FreeTime/report_selector";
import summary_report from "@/components/FreeTime/summary_report";
import caregiver_report from "@/components/FreeTime/caregiver_report";
import universal_pop_up_slot from "@/components/parts/universal_pop_up_slot";
import basic_banner_new from "@/components/parts/basic_banner_new";
import universal_invisible_backer from "@/components/parts/universal_invisible_backer";
import inside_banner from "@/components/parts/inside_banner";
import basic_slot_button from "@/components/parts/basic_slot_button";
import spinner from "@/components/parts/spinner";
import data_box from "@/components/FreeTime/data_box";


export default {
  name: "pda_admin_freetime_widgi2",
  props: [],
  emits: [],
  components: {
    basic_slide_over_menu,
    label_with_input_text,
    basic_text_button,
    indicator_label,
    basic_toggle,
    label_with_input_text_area,
    basic_icon_button,
    break_even_component,
    report_selector,
    summary_report,
    caregiver_report,
    universal_pop_up_slot,
    basic_banner_new,
    universal_invisible_backer,
    inside_banner,
    basic_slot_button,
    spinner,
    data_box
  },

  data() {
    return {
      colors: colors,
      manual_mode: false,
      mobile_mode_x: true,
      show_base_invisible_x: false,
      show_payroll_menu_x: false,
      action_button_width_: 250,
      available_freetime_weeks_: "",
      pre_selected_week: "",
      my_orgs_: "",
      my_name: "",
      ehr_form: {
        amount: '',
        amount_for_passing: ''
      },
      publish: false,
      publish_pending: false,
      data_files: {
        data: '',
        match: '',
        exclude: '',
      },
      agency_message: {
        show_agency_message_form_: false,
        read_only_: true,
        agency_pub_message_: "",
        message_present_: false,
        test_message: "",
        save_message_button_ready_: false,
      },

      break_even_numbers: {
        highest_pay_rate: 0,
        highest_rate_hours: 0,
        highest_pay_hours: 0,

        back_end_values_present: {
          std_bill_rate: false,
          base_pay_rate: false,
          base_hours: false,
          payroll_burden: false
        },
      },
      opaque_slide_menu_x: true,
      hamburger: {
        show: false
      },
      gear: {
        show: false
      },
      baseline_popup: {
        show: false
      },
      selected_payrolls: [],
      current_bonus_report_stake_x: '',
      current_history_report_date_x: '',
      current_summary_report_x: [],
      current_caregiver_report_x: [],
      current_history_summary_report_x: [],
      current_history_caregiver_report_x: [],
      current_agency_message_x: "",
      dry_run_pending_agency_message_x: "",
      reports: {
        summary: {},
        caregiver: {},
        agency_messages: {},
      },
      history: {
        summary: {},
        caregiver: {},
        agency_messages: {},
      },
      report_order: [
        'Start Date',
        'Total Cost',
        'Caregivers',
        'Bonused %',
        'Total Hours',
        'Included Hours',
        'Funded Amount',
        'Maximum Raise',
        'Pool Score',
        'Average FreeTime',
        'Highest FreeTime',
      ],
      report_translator: {
        'Start Date': 'start_date',
        'Total Cost': 'total_cost',
        'Caregivers': 'caregivers',
        'Bonused %': 'bonused_caregivers_percentage',
        'Participating': 'bonused_caregivers',
        'Total Hours': 'total_hours',
        'Included Hours': 'total_bonused_hours',
        'Funded Amount': 'cost_per_hour',
        'Maximum Raise': 'max_ehr_amount',
        'Pool Score': 'max_factor',
        'Average FreeTime': 'avg_bonus',
        'Highest FreeTime': 'highest_bonus_amount',
      },
      report_formats_digits: {
        'Total Cost': 2,
        'Funded Amount': 2,
        'Maximum Raise': 2,
        'Average FreeTime': 2,
        'Highest FreeTime': 2,
      },
    }
  },
  computed: {
    ...mapGetters([
      'get_file_freetime_name_data',
      'get_file_freetime_name_match',
      'get_file_freetime_name_exclude',
      'current_error_message',
      'get_freetime_agency_message',
      'socket_connection_counter',
      'window_width'
    ]),
    my_orgs() {
      return this.my_orgs_
    },

    available_freetime_weeks() {
      console.log("************************inside available weeks....")
      console.log(`**************************Value is ${this.available_freetime_weeks_}`)
      return this.available_freetime_weeks_
    },

    spinner_up() {
      return store.getters.get_spinner_state
    },
    get_baseline_values2() {
      return {
        bill_rate: store.getters.get_freetime_breakeven_avg_bill_rate,
        base_pay: store.getters.get_freetime_breakeven_avg_base_pay,
        burden: store.getters.get_freetime_breakeven_payroll_burden,
        base_hours: store.getters.get_freetime_breakeven_base_hours,
      }
    },
    action_button_width() {
      return this.action_button_width_ + "px"
    },
    history_summary() {
      return this.history.summary
    },
    opaque_slide_menu() {
      return this.opaque_slide_menu_x
    },
    update_page() {
      // console.log("update_page computed property is triggered.")
      return store.getters.universal_page_update
    },

    baseline_menu_show() {
      return this.baseline_popup.show
    },
    hamburger_menu_show() {
      return this.hamburger.show
    },
    gear_menu_show() {
      return this.gear.show
    },


    be_values_clone() {
      let values_source = store.getters.get_freetime_breakeven_values
      let values = this.clone_object(values_source)
      let out_values = {}
      for (const property in values) {
        let new_key = property.toString()
        let replace = ""
        if (isNaN(parseFloat(this.round(values[property], 2)))) {
          replace = values[property]
        } else {
          replace = parseFloat(this.round(values[property], 2))
        }
        out_values[new_key] = replace

      }
      return out_values
    },
    get_base_bill_rate() {
      // console.log("in get base bill rate computed")
      // let values_source = store.getters.get_freetime_breakeven_values
      let values = this.be_values_clone
      try {
        return values['avg_bill_rate']
      } catch (e) {
        return 0.00
      }
    },
    get_base_pay_rate() {
      let values = this.be_values_clone
      try {
        return values['avg_base_pay']
      } catch (e) {
        return 0.00
      }
    },
    get_burden_rate() {
      let values = this.be_values_clone
      try {
        return values['payroll_burden']
      } catch (e) {
        return 0.00
      }
    },
    get_base_hours() {
      let values = this.be_values_clone
      try {
        return values['base_hours']
      } catch (e) {
        return 0.00
      }
    },
    funding_for_passing() {
      return this.ehr_form.amount_for_passing
    },
    save_message_button_ready() {
      return this.agency_message.save_message_button_ready_
    },
    edit_message_read_only() {
      // console.log("in edit_message_read_only computed prop")
      return this.agency_message.read_only_
    },
    history_report_dates() {
      console.log("Running history_report_dates computed")
      console.log(Object.keys(this.history.summary).sort().toString())
      return Object.keys(this.history.summary).sort()
    },
    latest_break_even_numbers() {
      return this.break_even_numbers
    },
    show_agency_message_form() {
      return this.agency_message.show_agency_message_form_
    },
    current_agency_message() {
      return this.current_agency_message_x
    },
    exclude_file_indicator_state() {
      if (store.getters.exclusions_at_server) {
        return "loaded"
      } else if (this.get_file_freetime_name_exclude !== '') {
        return "loaded"
      } else {
        return "unready"
      }
    },
    match_file_indicator_state() {
      return store.getters.match_at_server
      // if (store.getter. match_at_server === true){
      //
      // }
      // if (store.getters.match_at_server) {
      //   return "loaded"
      // } else if (this.get_file_freetime_name_match !== '') {
      //   return "loaded"
      // } else {
      //   return "unready"
      // }
    },
    data_file_state() {
      if (this.get_file_freetime_name_data) {
        return true
      } else {
        return false
      }
    },

    schedules_file_selected() {
      // console.log("checking schedule file selected computed property.")
      let a_value = store.getters.get_file_freetime_name_data
      if (a_value === "") {
        // console.log(`Checking schedules selected is a "". Returning False`)
        // console.log(`Checking schedules selected: ${a_value}`)
        return false
      } else {
        // console.log(`Checking schedules selected is NOT a "". Returning true`);
        // console.log(`Checking schedules selected: ${a_value}`)
        return true
      }
    },
    baseline_form_complete() {
      // console.log("in baseline_form_complete")
      // console.log(this.break_even_numbers.back_end_values_present)

      try {
        if (
            this.break_even_numbers.back_end_values_present.std_bill_rate &&
            this.break_even_numbers.back_end_values_present.payroll_burden &&
            this.break_even_numbers.back_end_values_present.base_hours &&
            this.break_even_numbers.back_end_values_present.base_pay_rate
        ) {
          // console.log("all are present, return true")
          return true
        } else {
          // console.log("not ready, but no error. return false")
          return false
        }
      } catch (error) {
        // console.log("error checking launch button ready status. return false")
        return false
      }

    },

    mobile_mode() {
      return this.mobile_mode_x
    },
    two_column_mode() {
      // return (!this.mobile_mode_x && this.ready_to_publish)
      return !this.mobile_mode && (this.ready_to_publish || this.history_reports_available)
    },
    ready_to_publish() {
      return this.publish_pending && this.current_bonus_report_stake !== ''
    },
    history_reports_available() {
      try {
        if (this.current_history_report_date.length) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }

    },
    show_payroll_menu() {
      return this.show_payroll_menu_x
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    match_at_server() {
      return store.getters.match_at_server
    },
    exclusions_at_server() {
      return store.getters.exclusions_at_server

    },
    selectable_payroll_weeks() {
      console.log("here are the selectable payroll weeks.")
      let ans = this.get_current_plus_more_history_reports()
      console.log(ans)
      return this.get_current_plus_more_history_reports()
    },
    current_bonus_report_stake() {
      // return this.current_bonus_report_stake_x
      return this.round(this.current_bonus_report_stake_x, 2)
      // return parseFloat(this.current_bonus_report_stake_x)
    },
    pub_toggle_enabled_test() {
      return this.dry_run_ready_status()
    },
    pub_toggle_active_test() {
      return this.current_bonus_report_stake !== '' && this.pub_toggle_enabled_test
    },
    dry_pub_active_test() {
      return this.ehr_form.amount !== '' && this.dry_run_ready_status()
    },
    history_report_has_changed() {
      return store.getters.freetime_dropbox_history_report_status
    },
    current_summary_report() {
      return this.current_summary_report_x
    },
    current_summary_report_length() {
      return this.current_summary_report_x.length
    },
    current_history_report_date() {
      return this.current_history_report_date_x
    },
    current_caregiver_report() {
      return this.current_caregiver_report_x
    },
    current_summary_history_report() {
      return this.current_history_summary_report_x
    },
    current_caregiver_history_report() {
      return this.current_history_caregiver_report_x
    },
    org_context() {
      return store.getters.org_context
    },
    pub() {
      return this.publish
    },
  },
  watch: {
    async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },

    async update_page() {
      // console.log("update_page watcher is triggered")
      await this.local_page_update()
    },

    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },

    two_column_mode(new_pre, old_pre) {

      // console.log("inside two column watcher")
      //Going up
      if (new_pre === true) {
        remove_class_from_id('freedom_factor_block', 'margin_b_10')
        const matches = document.querySelectorAll(".screen_block");
        // matches.forEach(function (match) {
        //   // console.log("screen block match")
        //   // console.log(match)
        //   match.classList.remove('screen_block');
        //   match.classList.add('screen_block_no_shadows');
        // });
        //  Going down
      } else if (new_pre === false && old_pre === true) {
        add_class_to_id('freedom_factor_block', 'margin_b_10')
      }
    },

    async current_error_message(new_pre, old_pre) {
      // console.log('Running WATCHER for this current_error_message...')
      // console.log('new value is ' + new_pre)
      // console.log('old value is ' + old_pre)

      if (old_pre === "") {
        this.invisible_backer_on_x = true
        this.current_bonus_report_stake_x = ''
        // console.log('Turning invisible backer ON')
      } else {
        this.invisible_backer_on_x = false
        // console.log('turning invisible backer OFF')
      }
    },


  },
  methods: {
    do_nothing() {
      console.log("You clicked to do nothing.")
    },
    set_pre_selected_week(e) {
      this.pre_selected_week = e
      this.publish_pending = false
      // console.log(`The pre-selected week is ${e}.`)
    },
    image_name(name) {
      return image_name(name)
    },
    include_this_item(item) {
      let includes = ['Start Date', 'Total Cost', 'Caregivers', 'Bonused %', 'Total Hours', 'Funded Amount', 'Maximum Raise', 'Pool Score', 'Highest FreeTime']
      return includes.includes(item);
    },

    get_datum_prefix(data_label) {
      // console.log(`In datum prefix with ${data_label}`)
      if (data_label === "Total Cost") {
        return "$"
      } else if (data_label === "Funded Amount") {
        return "$"
      } else if (data_label === "Maximum Raise") {
        return "$"
      } else if (data_label === "Average FreeTime") {
        return "$"
      } else if (data_label === "Highest FreeTime") {
        return "$"
      } else {
        return ""
      }
    },
    // get_datum_suffix(data_label){
    //   if(data_label === "Bonused %"){
    //     return "%"
    //   }else{
    //     return ""
    //   }
    // },

    get_rounding_digit(data_label) {
      // console.log(`In rounding digits with ${data_label}`)
      let the_twos = ['Total Cost', 'Bonused %', 'Total Hours', 'Included Hours', 'Funded Amount', 'Maximum Raise', 'Pool Score', 'Average FreeTime', 'Highest FreeTime']
      if (the_twos.includes(data_label)) {
        return 2
      } else if (data_label === "Caregivers") {
        return 0
      } else {
        return null
      }

    },

    set_current_history_date_selection(d) {
      this.current_history_report_date_x = d
    },

    async handle_rerun(event) {
      console.log(`What did we get handling the rerun event? ${event}`)
      this.ehr_form.amount = event
      await this.dry_run()

    },

    async local_page_update() {
      await this.load_history_reports_modern()
    },
    async que_analytic(message) {
      await analytic(message)
    },
    round(value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    },
    clone_object(clonee) {
      let clone_str = JSON.stringify(clonee)
      return JSON.parse(clone_str)
    },

    toggle_save_message_button_ready() {
      // console.log("caught the edit button clicked event.")
      this.agency_message.save_message_button_ready_ = !this.agency_message.save_message_button_ready_
      // console.log(`toggle_save_message_button_ready property now equals ${this.save_message_button_ready}`)
    },
    save_freetime_pub_message() {
      let msg = this.$refs.message_to_publish.text_value
      // console.log("in save_freetime_pub_message")
      // console.log(`The text value is ${msg}.`)
      // this.agency_message.agency_pub_message_ = msg
      // this.current_agency_message_x = msg
      this.dry_run_pending_agency_message_x = msg
      // store.commit('set_freetime_agency_message', msg)
      this.$refs.message_to_publish.edit_button_clicked()
    },
    agency_message_form_false() {
      this.agency_message.show_agency_message_form_ = false
    },

    async baseline_button_clicked() {
      await analytic("01:01:16:903")
      // this.hamburger_menu_toggle()
      // console.log("in widgit. Baseline button clicked.")
      this.baseline_popup.show = true
      //This emit tells parent to turn off hamburger menu
      // this.$emit('baseline_button_clicked', true)
    },
    async profile_button_clicked() {
      await analytic("01:01:16:211")
      // console.log("inside profile_button_clicked")
      try {
        this.hamburger_backer_clicked()
        // this.$emit('hamburger_backer_clicked', true)
        // await this.$router.push({path: `${this.get_profile_route_()}`})
        await this.$router.push({path: `${get_pda_home_route()}`})

      } catch (error) {
        // console.log(error)
      }

    },
    async go_to_profile_home_route() {
      await this.$router.push({path: `${get_pda_home_route()}`})
    },
    async pda_home_button_clicked() {
      await analytic("01:01:16:212")
      // console.log("inside profile_button_clicked")
      try {
        this.hamburger_backer_clicked()
        await this.$router.push({path: `${get_pda_home_route()}`})

      } catch (error) {
        // console.log(error)
      }

    },
    hamburger_menu_toggle() {
      // console.log("hamburger menu toggle clicked")
      this.hamburger.show = !this.hamburger.show
    },
    gear_menu_toggle() {
      // console.log("gear menu toggle clicked")
      this.gear.show = !this.gear.show
    },
    hamburger_backer_clicked() {
      // console.log("pop up full dark glass was clicked and is emitting.")
      this.hamburger.show = false
    },
    gear_backer_clicked() {
      // console.log("pop up full dark glass was clicked and is emitting.")
      this.gear_menu_toggle()
      this.gear.show = false
    },

    get_baseline_values() {
      return {
        bill_rate: store.getters.get_freetime_breakeven_avg_bill_rate,
        base_pay: store.getters.get_freetime_breakeven_avg_base_pay,
        burden: store.getters.get_freetime_breakeven_payroll_burden,
        base_hours: store.getters.get_freetime_breakeven_base_hours,
      }
    },
    test_print_message(e) {
      // console.log("The form was pressed.")
      // console.log(e)
    },
    emit_baseline_input_values() {
      // console.log("baseline submit button clicked")
      this.$refs.std_bill_rate_input.emit_state();
      this.$refs.std_base_rate_input.emit_state();
      this.$refs.std_payroll_burden_input.emit_state();
      this.$refs.std_base_hours_input.emit_state();
      // console.log("in emit_baseline_input_values")
      this.update_baseline_values()
      store.commit('notification_enqueue', "Baseline values have been saved to cloud.")
      this.baseline_popup_false()
    },
    std_bill_rate_input_validator(value) {
      // console.log("in std_bill_rate_input_validator")
      // console.log(`Passed is value is ${value}.`)
      // console.log(`Passed is value type is ${typeof value}.`)
      // console.log(`Pretest equals: ${within_inclusive_range(value,1,100)}`)
      if (within_inclusive_range(value, 1, 100)) {
        // this.break_even_numbers.back_end_values.std_bill_rate = value
        store.commit('freetime_break_even_avg_bill_rate', value)
      } else {
        if (value.length === 0) {
          value = "[empty]"
        }
        let message = `${value} is not an acceptable value for the standard bill rate.`
        store.commit('error_enqueue', message)
        this.break_even_numbers.back_end_values_present.std_bill_rate = false
      }
    },
    base_pay_rate_input_validator(value) {
      if (within_inclusive_range(value, 7, 50)) {
        if (store.getters.get_freetime_breakeven_avg_bill_rate > value) {
          store.commit('freetime_break_even_base_pay', value)
        } else {
          let message = "The pay rate may not be greater than the bill rate."
          store.commit('error_enqueue', message)
        }
      } else {
        if (value.length === 0) {
          value = "[empty]"
        }
        let message = `${value} is not an acceptable value for the base pay rate.`
        store.commit('error_enqueue', message)
        this.break_even_numbers.back_end_values_present.base_pay_rate = false
      }
    },

    base_hours_input_validator(value) {
      if (within_inclusive_range(value, 1, 1000000)) {
        // this.break_even_numbers.back_end_values.base_hours = value
        store.commit('freetime_break_even_base_hours', value)
      } else {
        if (value.length === 0) {
          value = "[empty]"
        }
        let message = `${value} is not an acceptable value for base hours.`
        // store.commit('error_enqueue', message)
        store.commit('error_enqueue', message)
        this.break_even_numbers.back_end_values_present.base_hours = false
      }
    },

    payroll_burden_input_validator(value) {
      if (within_inclusive_range(value, 0, 1)) {
        // this.break_even_numbers.back_end_values.payroll_burden = value
        store.commit('freetime_break_even_payroll_burden', value)
      } else {
        // console.log(`Value of value is ${value}`)
        if (value.length === 0) {
          value = "[empty]"
        }
        let message = `${value} is not an acceptable value for payroll burden.`
        store.commit('error_enqueue', message)
        this.break_even_numbers.back_end_values_present.payroll_burden = false
      }
    },
    baseline_items_true() {
      // this.baseline_backer_true()
      // this.baseline_popup_true()
      // this.hamburger_backer_false()
      // this.hamburger_menu_false()
      this.hamburger_backer_clicked()

    },
    baseline_popup_false() {
      // console.log("baseline popup set to false function is starting.................")
      // console.log(`Show popup and backer are ${this.show_baseline_popup_x} and ${this.show_baseline_backer_x}`)
      // console.log("moving them to false")
      // this.$emit('baseline_popup_false', true)
      this.baseline_popup.show = false
      // this.show_baseline_backer_x = false
      // this.hamburger_menu_false()
      // this.hamburger_backer_false()
    },
    baseline_backer_and_form_false() {
      // console.log("Baseline backer clicked")
      this.baseline_popup.show = false
      // this.$emit('baseline_popup_false', true)
      // this.$emit('baseline_backer_clicked', true)
    },

    toggle_payroll_menu() {
      // await analytic("01:01:16:910")
      this.show_payroll_menu_x = !this.show_payroll_menu_x
    },

    toggle_mobile_mode() {
      this.mobile_mode_x = !this.mobile_mode_x
    },

    run_window_width_adjustments(new_w) {
      // console.log("new window size.")
      if (new_w >= 280) {
        this.action_button_width_ = 220
      }
      //

      if (new_w >= 500) {
        // console.log("Activating transparent slide menu")
        this.opaque_slide_menu_x = false
      } else {
        this.opaque_slide_menu_x = true
      }
      // if (new_w >= 375) {
      //   // message = 'The window width >= 375'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 540) {
      //   // message = 'The window width >= 540'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      if (new_w >= 400) {
        // message = 'The window width >= 640'
        this.action_button_width_ = 350
      }
      // if (new_w >= 1024) {
      //   // message = 'The window width >= 1024'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 1200) {
      //   // message = 'The window width >= 1200'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      if (new_w <= desktop_size) {
        // remove_class_to_body('content_large_screen')
        // add_class_to_body('content_no_radial')
        // this.mobile_mode_x = true
        // const matches = document.querySelectorAll(".screen_block_no_shadows");
        // matches.forEach(function (match) {
        //   match.classList.remove('screen_block_no_shadows');
        //   match.classList.add('screen_block');
        // });

      }
      if (new_w >= desktop_size) {
        // remove_class_to_body('content_no_radial')
        // add_class_to_body('content_large_screen')
        // this.mobile_mode_x = false

        // const matches = document.querySelectorAll(".screen_block");
        // matches.forEach(function (match) {
        //   match.classList.remove('screen_block');
        //   match.classList.add('screen_block_no_shadows');
        // });

      }
    },

    onPickFile(f_type) {

      let r = {
        'data': this.$refs.fileInput_data,
        'match': this.$refs.fileInput_match,
        'exclude': this.$refs.fileInput_exclude,
        'match_template': this.$refs.fileInput_match_template,
        'exclude_template': this.$refs.fileInput_exclude_template
      }
      r[f_type].click()

    },

    async onFilePicked(event, f_type, name_saver = false) {
      await analytic("01:01:16:916" + "::" + f_type)
      const files = event.target.files
      const the_file = files[0]
      const the_file_name = the_file.name

      //reset all file refs to ''
      this.$refs.fileInput_data.value = ''

      let the_file_store = 'save_file_freetime_' + f_type

      if (name_saver === true) {
        let the_file_name_store = 'save_file_freetime_name_' + f_type
        store.commit(the_file_name_store, the_file_name)
      }

      store.commit(the_file_store, the_file)

      this.ehr_form.amount = ''
      this.current_bonus_report_stake_x = ''
    },

    async onMatchTemplatePicked(event, f_type) {
      await this.onFilePicked(event, f_type, false)

      const matches_data = new FormData();
      matches_data.append("caller", store.getters.my_name)
      matches_data.append("subscriber", this.org_context)
      matches_data.append("id_token", store.getters.my_id_token)
      matches_data.append("refresh_token", store.getters.my_refresh_token)
      matches_data.append("match_file", store.getters.get_file_freetime_match_template)
      // let endpoint = 'http://192.168.1.10:8087/ft/upload_match_template'
      let endpoint = be_main_address('ft') + 'upload_match_template'

      await axios({
        method: 'post',
        url: endpoint,
        data: matches_data,

      }).then((response) => {
        // console.log(response.data)
        if (response.data['error'] === true) {
          // console.log("Got back an error from the server...")
          // console.log(response.data)
          let message = parse_internal_json(response.data)['payload']['content']['message']
          store.commit('error_enqueue', message)
        }

      }, (error) => {
        // console.log('Error on the match template upload.')
        // console.log(error);
      });
      // this.$emit('hamburger_menu_false', true)
      this.hamburger_backer_clicked()
      this.check_for_match_and_excludes()
      // this.toggle_hamburger_menu()

    },

    // match_file_status_indicator_text() {
    //   // console.log(`matches_at_server is ${store.getters.match_at_server}`)
    //   if (store.getters.match_at_server) {
    //     return "Match File Saved"
    //   } else if (this.get_file_freetime_name_match !== '') {
    //     return this.get_file_freetime_name_match
    //   } else {
    //     return 'No Uploaded Match File'
    //   }
    // },

    match_file_status_indicator_state() {
      if (store.getters.match_at_server) {
        return true
      } else if (this.get_file_freetime_name_match !== '') {
        return true
      } else {
        return false
      }
    },

    exclude_file_status_indicator_state() {
      if (store.getters.exclusions_at_server) {
        return true
      } else if (this.get_file_freetime_name_exclude !== '') {
        return true
      } else {
        return false
      }
    },

    exclusions_file_status_indicator_text() {
      if (store.getters.exclusions_at_server) {
        // if (this.exclusions_at_server) {
        return "Exclude File Saved"
      } else if (this.get_file_freetime_name_exclude !== '') {
        return this.get_file_freetime_name_exclude
      } else {
        return 'No Uploaded Exclusions File'
      }
    },

    get_profile_route_() {
      return get_profile_route()
      // let my_name = store.getters.my_name
      // let r = `/${my_name}/profile`
      // console.log(r)
      // return r
      // await this.$router.push({ path: r})

    },

    payroll_burden_as_string() {
      return float_to_percent_string(store.getters.get_freetime_breakeven_payroll_burden)
    },

    async get_hours_report() {
      await analytic("01:01:16:908")
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }
      // let target_url = 'http://192.168.1.10:8087/ft/generate_hours_report'
      let target_url = be_main_address('ft') + 'generate_hours_report'
      store.commit('set_spinner_state', true)
      axios.post(target_url, data1, {responseType: "blob"})
          .then((response) => {
            if (response.data['error'] === true) {
              let message = parse_internal_json(response.data)['payload']['content']['message']
              store.commit('set_spinner_state', false)
              store.commit('error_enqueue', message)
            } else {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'Hours_Report' + '.xlsx');
              document.body.appendChild(fileLink);
              store.commit('set_spinner_state', false)
              fileLink.click();
            }
          });
    },

    async get_hours_upload_template() {
      await analytic("01:01:16:909")
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }
      // let target_url = 'http://192.168.1.10:8087/ft/get_std_hours_template'
      let target_url = be_main_address('ft') + 'get_std_hours_template'

      if (data1.subscriber === "" | data1.subscriber === undefined) {
        return null
      }

      axios.post(target_url, data1, {responseType: "blob"})
          .then((response) => {
            // console.log(response)
            if (response.data['error'] === true) {
              // console.log("IN ERROR...................................")
              let message = parse_internal_json(response.data)['payload']['content']['message']
              store.commit('error_enqueue', message)
            } else {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'FreeTime_Standard_Hours' + '.xlsx');
              document.body.appendChild(fileLink);

              fileLink.click();
            }

          });
    },

    async get_eligibility_chart() {
      await analytic("01:01:16:906")
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }
      // let target_url = 'http://192.168.1.10:8087/ft/get_matches_template'
      let target_url = be_main_address('ft') + 'get_eligibility'

      if (data1.subscriber === "" | data1.subscriber === undefined) {
        return null
      }
      store.commit('set_spinner_state', true)
      axios.post(target_url, data1, {responseType: "blob"})
          .then((response) => {
            if (response.data['error'] === true) {
              let message = parse_internal_json(response.data)['payload']['content']['message']
              store.commit('error_enqueue', message)
              store.commit('set_spinner_state', false)
            } else {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'FreeTime_Eligibility' + '.xlsx');
              document.body.appendChild(fileLink);

              fileLink.click();
              store.commit('set_spinner_state', false)
            }

          });
    },

    async get_matches_template() {
      await analytic("01:01:16:906")
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }
      // let target_url = 'http://192.168.1.10:8087/ft/get_matches_template'
      let target_url = be_main_address('ft') + 'get_matches_template'

      if (data1.subscriber === "" | data1.subscriber === undefined) {
        return null
      }

      axios.post(target_url, data1, {responseType: "blob"})
          .then((response) => {
            if (response.data['error'] === true) {
              let message = parse_internal_json(response.data)['payload']['content']['message']
              store.commit('error_enqueue', message)
            } else {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'FreeTime_Matches' + '.xlsx');
              document.body.appendChild(fileLink);

              fileLink.click();
            }

          });
    },

    async get_excludes_template() {
      await analytic("01:01:16:907")
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }
      // let target_url = 'http://192.168.1.10:8087/ft/get_excludes_template'
      let target_url = be_main_address('ft') + 'get_excludes_template'

      axios.post(target_url, data1, {responseType: "blob"})
          .then((response) => {
            if (response.data['error'] === true) {
              let message = parse_internal_json(response.data)['payload']['content']['message']
              store.commit('error_enqueue', message)
            } else {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'FreeTime_Excludes' + '.xlsx');
              document.body.appendChild(fileLink);

              fileLink.click();
            }

          });
    },

    async onExclusionsTemplatePicked(event, f_type) {
      this.onFilePicked(event, f_type, false)

      const excludes_data = new FormData();
      excludes_data.append("caller", store.getters.my_name)
      excludes_data.append("subscriber", this.org_context)
      excludes_data.append("id_token", store.getters.my_id_token)
      excludes_data.append("refresh_token", store.getters.my_refresh_token)
      excludes_data.append("exclude_file", store.getters.get_file_freetime_exclude_template)
      // let endpoint = 'http://192.168.1.10:8087/ft/upload_excludes_template'
      let endpoint = be_main_address('ft') + 'upload_excludes_template'


      await axios({
        method: 'post',
        url: endpoint,
        data: excludes_data,

      }).then((response) => {
        // console.log(response.data)
        if (response.data['error'] === true) {
          // console.log("Got back an error from the server...")
          // console.log(response.data)
          let message = parse_internal_json(response.data)['payload']['content']['message']
          store.commit('error_enqueue', message)
          // return_res = false
        }

      }, (error) => {
        // console.log('Error on the excludes template upload.')
        // console.log(error);
      });
      // this.$emit('hamburger_menu_false', true)
      this.hamburger_backer_clicked()
      this.check_for_match_and_excludes()
    },


    async get_available_freetime_weeks() {
      // console.log('!!!!!!!!^^^^^^^^^^^^^^^^^6Get available FReetime Weeks')
      let weeks_key = `${store.getters.org_context}:freetime_weeks`
      // console.log(`Weeks key is ${weeks_key}`)
      let cached_weeks = await store.dispatch("get_cache_value", weeks_key)
      // console.log("Here is what initally came back from the cache...")
      // console.log(cached_weeks)
      cached_weeks = JSON.parse(cached_weeks)
      // console.log(`Cached weeks is ${cached_weeks}`)
      if (!cached_weeks) {
        cached_weeks = []
      }
      return cached_weeks
      // this.available_freetime_weeks_ = cached_weeks
    },

    async warm_the_cache() {
      // Handle the dry runs in advance to speed things up for the user.
      // Get the available weeks if any.
      let weeks_key = `${store.getters.org_context}:freetime_weeks`
      let cached_weeks = await store.dispatch("get_cache_value", weeks_key)
      cached_weeks = JSON.parse(cached_weeks)

      if (!cached_weeks) {
        console.log("There were no cached weeks to work with. Returning null.")
        return null
      }

      let target_url = be_main_address('ft') + 'warm_cache'
      // let target_url = 'http://192.168.1.10:8087/ft/get_non_subscribers'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: store.getters.org_context,
        available_weeks: cached_weeks
      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                // this.non_subscribing_pdas_ = response.data
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },


    check_for_match_and_excludes() {
      // let target_url = 'http://192.168.1.10:8087/ft/check_for_match_and_excludes_file'
      let target_url = be_main_address('ft') + 'check_for_match_and_excludes_file'
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }

      if (data1.subscriber.length === 0 || data1.subscriber === undefined) {
        return null
      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.data.error === false) {
              console.log("What did I get back as to the matches?")
              // console.log(JSON.stringify(response))
              console.log((response))
              store.commit("save_exclusions_at_server", response.data.data['exclusions'])
              store.commit("save_match_at_server", response.data.data['match'])
            } else {
              let message = parse_internal_json(response.data)['payload']['content']['message']
              store.commit('error_enqueue', message)
            }
          })
    },

    async update_baseline_values() {
      await analytic("01:01:16:915")
      // console.log("inside update baseline value axios call.")
      // let target_url = 'http://192.168.1.10:8087/ft/update_break_even_values'
      let target_url = be_main_address('ft') + 'update_break_even_values'
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        values: this.get_baseline_values()
      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.data.error === false) {
              // store.commit("save_exclusions_at_server", response.data.data['exclusions'])
              // store.commit("save_match_at_server", response.data.data['match'])
            } else {
              // console.log("in error response")
              // console.log(response)
              let message = parse_internal_json(response.data)['payload']['content']['message']
              store.commit('error_enqueue', message)
            }
          })
    },

    async get_backend_baseline_values() {
      // console.log("inside update baseline value axios call.")
      // let target_url = 'http://192.168.1.10:8087/ft/get_break_even_values'
      let target_url = be_main_address('ft') + 'get_break_even_values'
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
      }

      axios.post(target_url, data1)
          .then((response) => {
            // console.log("in the response")
            // console.log(response)
            if (response.data.error === false && response.data.success === true) {
              store.commit("freetime_break_even_avg_bill_rate", response.data.data['bill_rate'])
              store.commit("freetime_break_even_base_hours", response.data.data['base_hours'])
              store.commit("freetime_break_even_payroll_burden", response.data.data['burden'])
              store.commit("freetime_break_even_base_pay", response.data.data['base_pay'])
            } else if (response.data.error === false && response.data.success === false) {
              // console.log(response.data.message)
              // Baseline values are recalculated and reset on each dry run. If no values ar available for a new pda client,
              // Just plug in something reasonable as a filler until real values are calculated.
              store.commit("freetime_break_even_avg_bill_rate", "30")
              store.commit("freetime_break_even_base_hours", "600")
              store.commit("freetime_break_even_payroll_burden", ".20")
              store.commit("freetime_break_even_base_pay", "13.50")

              // store.commit('notification_enqueue', "You have no saved baseline values. You need to set them.")
            } else {
              // console.log("in error response")
              // console.log(response)
              let message = parse_internal_json(response.data)['payload']['content']['message']

              store.commit('error_enqueue', message)
            }
          })
    },

    async load_history_reports_modern() {
      // console.log("inside MODERN history reports")

      let search = {
        key: "",
        additional_context: [['div', 'o&a'], ['div', 'weekly_summaries']],
        base_context: store.getters.find_current_preamble
      }

      let all_docs = await this.db.db_search(search)
      console.log("Found the following history documents")
      console.log(JSON.stringify(all_docs))
      console.log(all_docs)
      console.log(`Found ${all_docs.length} docs.`)

      for (const c of all_docs) {
        // console.log("what is c?")
        // console.log(c)
        // console.log("what is all docs length?")
        // console.log((all_docs.length).toString())
        let start_date = c.summary.start_date
        // console.log(`The start date is ${start_date}`)
        let summary = c.summary
        let caregiver = c.caregivers
        let agency_message = c.message
        this.history.summary[start_date] = this.create_dry_summary_report(summary)
        this.history.caregiver[start_date] = this.create_dry_caregiver_report(caregiver)
        this.history.agency_messages[start_date] = agency_message
      }

      // console.log("Let's set the most current history report date...")
      let history_dates = Object.keys(this.history.summary).sort()
      this.current_history_report_date_x = history_dates[history_dates.length - 1]
      // console.log(`Most current date is ${this.current_history_report_date_x}`)
      this.current_history_summary_report_x = this.history.summary[this.current_history_report_date_x]
      this.current_history_caregiver_report_x = this.history.caregiver[this.current_history_report_date_x]
      this.current_agency_message_x = this.history.agency_messages[this.current_history_report_date_x]

      console.log(`Most current summary report is ........................${this.current_history_summary_report_x}`)
      console.log(this.current_history_summary_report_x)
      console.log(`Most current summary report type is ........................${typeof this.current_history_summary_report_x}`)
      // console.log("Here are the new history dates")
      // console.log(history_dates.toString())
      //
      // this.draw_history_reports(this.current_history_report_date_x)
      //  // this.$refs.history_report_toggle.set_to_most_current();
      // try {
      //   this.$refs.history_report_toggle.set_to_most_current();
      // } catch (error) {
      //   console.log("in load_history_reports_modern, set_to_most_current threw an error.")
      // }
    },


    create_dry_summary_report(data_obj) {
      let out = []
      for (let prettykey of this.report_order) {
        let data_key = this.report_translator[prettykey]
        out.push([prettykey, data_obj[data_key]])
      }
      // console.log("Here is the dry summary report")
      return out
    },

    sort_by_last_name(data) {
      // console.log('Sorting names')
      // console.log(JSON.stringify(data))

      // Sort the keys by last name or first word if only one word present, ignoring 'cna'
      const sortedKeys = Object.keys(data).sort((a, b) => {
        // Function to extract last name, ignoring 'cna'
        const extractLastName = (key) => {
           const words = key.toLowerCase().replace(/\s+/g, ' ').split(' ');
          // Remove 'cna' if present
          const filteredWords = words.filter(word => word !== 'cna');

          // console.log(filteredWords.length)
          // console.log(filteredWords)
          // Return the last word
          return filteredWords.pop();
        };

        // Extract last names from keys
        const aLastName = extractLastName(a);
        const bLastName = extractLastName(b);

        // Compare last names or first word if only one word present
        return aLastName.localeCompare(bLastName);
      });

      return sortedKeys;
    },


    create_dry_caregiver_report(data_obj) {
      let out = [['Name', 'Hours', 'Raise', 'FreeTime']]
      let names_list = this.sort_by_last_name(data_obj)
      for (let n of names_list) {
        let cg = data_obj[n]
        let an_arr = [cg['name'], cg['hours'], cg['ehr'], cg['bonus']]
        out.push(an_arr)
      }
      return out
    },

    // toggle_baseline_popup_state() {
    //   this.show_baseline_popup_x = !this.show_baseline_popup_x
    // },

    check_if_payroll_week_selected(week) {
      return this.selected_payrolls.includes(week);
    },

    local_reset_error_message() {
      reset_current_error_message()
    },


    async local_message_handler(from_server_json) {
      // console.log("Message from the socket server is ...")
      // console.log(from_server_json)
      let update = await receive_over_socket(from_server_json)
      // console.log(`Just got something on the socket: Update? ${update}`)
      if (update) {
        store.commit('increment_universal_page_update', true)
      }
    },

    dry_run_ready_status2() {
      return (this.match_file_status_indicator_state() && this.get_file_freetime_name_data !== '') || this.dry_run_ready_status_pre_selected_file();
    },

    dry_run_ready_status() {
      let file_selected_state = this.match_file_status_indicator_state() && this.get_file_freetime_name_data !== ''
      if (file_selected_state) {
        return true
      } else if (!file_selected_state && this.pre_selected_week !== "") {
        return true
      } else {
        return false
      }
    },


    get_current_plus_more_history_reports() {
      //this is to populate the payroll week selection pop up with dates for the user to choose
      const num_of_total_reports = 5
      let history_dates = Object.keys(this.history.summary).sort()
      console.log("Here is the history dates.....")
      console.log(history_dates)
      let current_history_report_index = history_dates.indexOf(this.current_history_report_date)
      let n = 0
      if (current_history_report_index >= num_of_total_reports - 1) {
        n = num_of_total_reports - 1
      } else {
        n = current_history_report_index
      }
      let dates_to_report = history_dates.slice(current_history_report_index - n, current_history_report_index + 1);
      return dates_to_report.reverse()
    },

    async print_history_report() {
      await analytic("pda_adin_freetime_widget2::print_history_report")
      // console.log(`print_history_report for ${this.current_history_report_date}`)
      let start_date = this.current_history_report_date
      // console.log("start_date is ")
      // console.log(start_date)
      // console.log(typeof start_date)
      let data1 = {
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        start_date: start_date,
      }
      let pdf_url = be_main_address('freetime') + 'get_history_pdf'

      axios.post(pdf_url, data1, {responseType: "blob"}).then((response) => {
        // console.log("Getting back server respons from get history pdf....")
        // console.log(response)
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', start_date + '.pdf');
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },

    async print_payroll_report(weeks_array) {
      await analytic("front_end|pda_admin_freetime_widget2::print_payroll_report")
      // let start_date = this.current_history_report_date
      this.selected_payrolls = []
      if (weeks_array.length === 0) {
        this.toggle_payroll_menu()
        return null
      }
      let data1 = {
        id_token: store.getters.my_id_token,
        // id_token: "bad_token",
        refresh_token: store.getters.my_refresh_token,
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        start_dates: JSON.stringify(weeks_array),
      }
      // let pdf_url = 'http://192.168.1.10:8087/ft/get_payroll_report'
      let pdf_url = be_main_address('ft') + 'get_payroll_report'

      try {
        axios.post(pdf_url, data1, {responseType: "blob"}).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'Payroll_Report' + '.pdf');
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      } catch (error) {
        //should get message from bc concerning this error
        // console.log(error)
      }

      this.toggle_payroll_menu()
    },

    async get_history_csv_report() {
      await analytic("01:01:16:912")
      let start_date = this.current_history_report_date
      let data1 = {
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        start_date: start_date,
      }
      // let pdf_url = 'http://192.168.1.10:8087/ft/testing/get_history_csv'
      // let pdf_url = 'http://192.168.1.10:8087/ft/get_history_csv'
      let pdf_url = be_main_address('ft') + 'get_history_csv'

      axios.post(pdf_url, data1, {responseType: "blob"}).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'FreeTime Data-' + start_date + '.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },

    toggle_week_to_payroll_selection(week) {
      if (this.selected_payrolls.includes(week)) {
        const newArray = this.selected_payrolls.filter(item => item !== week)
        this.selected_payrolls = newArray
      } else {
        this.selected_payrolls.push(week)
      }
      this.selected_payrolls.reverse()
    },

    prepare_curve_payload(run_type) {
      let write_to_db = ''
      if (run_type === 'publish') {
        write_to_db = 'true'
      } else {
        write_to_db = 'false'
      }

      const data1 = new FormData();
      data1.append("caller", store.getters.my_name)
      data1.append("subscriber", this.org_context)
      data1.append("format", "clearcare")
      data1.append("curve_params", JSON.stringify({max_actual_raise: this.current_bonus_report_stake}))
      data1.append("write", write_to_db)
      data1.append("hours", store.getters.get_file_freetime_data)
      data1.append("id_token", store.getters.my_id_token)
      data1.append("message", this.dry_run_pending_agency_message_x)
      data1.append("refresh_token", store.getters.my_refresh_token)
      data1.append("pre_selected_week", this.pre_selected_week)
      return data1
    },

    break_even_calc(billrate, basepay, freetime_stake, payroll_burden_percentage, hours_worked) {
      // console.log("BE params passed in...")
      // console.log(billrate, basepay, freetime_stake, payroll_burden_percentage, hours_worked)
      let payroll_burden_no_freetime = payroll_burden_percentage * basepay
      let payroll_burden_with_freetime = payroll_burden_percentage * (basepay + freetime_stake)
      let profit_with_freetime = billrate - basepay - freetime_stake - payroll_burden_with_freetime
      let profit_no_freetime = billrate - basepay - payroll_burden_no_freetime
      let ft_ratio = profit_no_freetime / profit_with_freetime
      // print(ft_ratio)
      let break_even_hours = hours_worked * ft_ratio
      break_even_hours = break_even_hours.toFixed(2)

      return [break_even_hours, profit_with_freetime, profit_no_freetime]

    },

    async curve_post(target, data1) {
      let endpoint = ''
      if (target === 'publish') {
        await analytic("01:01:16:901")
        endpoint = be_main_address('freetime') + 'publish'
        // endpoint = 'http://192.168.1.10:8087/ft/publish'
      } else {
        // endpoint = 'http://192.168.1.10:8087/ft/dry_run'
        await analytic("01:01:16:902")
        endpoint = be_main_address('freetime') + 'dry_run'
        target = 'dry run'
      }

      let return_res = true

      await axios({
        method: 'post',
        url: endpoint,
        data: data1,

      }).then((response) => {
        // console.log(response.data)
        if (response.data['error'] === true) {
          console.log("Got back an error from the server...")
          console.log(response.data)
          // console.log(parse_internal_json(response.data))
          // console.log(parse_internal_json(response.data)['message'])
          // let parsed_error_res = parse_internal_json(response.data['payload'])['message']
          let parsed_error_res = parse_internal_json(response.data)['message']
          // console.log(parsed_error_res)
          analytic("01:01:16:914::" + parsed_error_res)
          store.commit('error_enqueue', parsed_error_res)
          // console.log(response.data)
          return_res = false
          return parse_internal_json(response.data)

        }
        if (target !== 'publish' && return_res === true) {
          // console.log("Dry Run Response")
          // console.log(response)
          // console.log(response['data']['data'])
          // console.log(response.data['message'])
          let res = response.data['data']
          let res_obj = JSON.parse(res)
          let res_obj_summary = res_obj['summary']
          let res_obj_caregivers = res_obj['caregivers']
          console.log(res_obj_summary)

          this.current_summary_report_x = this.create_dry_summary_report(res_obj['summary'])
          this.current_caregiver_report_x = this.create_dry_caregiver_report(res_obj['caregivers'])
          // this.current_agency_message_x = res_obj['message']
          this.reports.summary[res_obj_summary['cost_per_hour']] = this.current_summary_report_x
          this.reports.caregiver[res_obj_summary['cost_per_hour']] = this.current_caregiver_report_x
          this.reports.agency_messages[res_obj_summary['cost_per_hour']] = res_obj['message']

          console.log("Getting back response object...")
          // let multiplier = res_obj_summary['max_ehr_amount'] / res_obj_summary['cost_per_hour']
          let multiplier = res_obj_summary['max_factor']
          let funded_amount = res_obj_summary['cost_per_hour']
          let highest_bonus_amount = res_obj_summary['highest_bonus_amount']
          // let high_rate_name = res_obj_summary['max_ehr_names'][0]
          // let highest_bonus_hours = res_obj_caregivers[high_rate_name]['hours']
          console.log("break even average payrate from vuex is...")
          console.log(parseFloat(store.getters.get_freetime_breakeven_avg_base_pay))
          let highest_pay_rate = parseFloat((res_obj_summary['max_ehr_amount'] +
              parseFloat(store.getters.get_freetime_breakeven_avg_base_pay)).toFixed(2))
          console.log("Calculated highest pay rate is")
          console.log(highest_pay_rate)

          //I need to iterate the caregivers dict and find each caregiver with the highest bonus value.
          //Then find which caregiver hours is the smallest.  This is the hours I need.

          // console.log("LOOKING FOR LOWEST HOURS WITH MAX BONUS AMOUNT")
          let highest_bonus = 0
          let smallest_hours = 99999999
          // let cg_nums = [highest_bonus, smallest_hours]
          for (const cg_name in res_obj_caregivers) {
            // console.log(`${cg_name}: ${res_obj_caregivers[cg_name]}`);

            let cg_data = res_obj_caregivers[cg_name]
            let hours = cg_data['hours']
            let bonus = cg_data['bonus']
            // console.log(`Working with ${cg_name} and her ${hours} hours and ${bonus} bonus.`)


            if (bonus > highest_bonus) {
              //If bonus is a new high, then reset lowest hours to this cg
              console.log(`${bonus} is the new highest bonus with ${hours} hours from ${cg_name}.`)
              highest_bonus = bonus
              smallest_hours = hours
            } else if (bonus === highest_bonus && hours < smallest_hours) {
              console.log(`${cg_name}'s ${bonus} bonus is equal to the old highest bonus but ${hours} hours is less than the old smallest hours.`)
              smallest_hours = hours
            } else {
              //pass
            }

          }
          // let high_rate_name = res_obj_summary['max_ehr_names'][0]
          console.log(`The smallest number of hours that still got the max bonus if ${highest_bonus} was ${smallest_hours}.`)
          let highest_bonus_hours = smallest_hours


          let values = store.getters.get_freetime_breakeven_values
          values['multiplier'] = multiplier
          values['funded_amount'] = funded_amount
          values['highest_bonus'] = highest_bonus_amount
          values['highest_pay_rate'] = highest_pay_rate
          values['highest_rate_hours'] = highest_bonus_hours
          store.commit('set_break_even_values', values)


        } else {
          // let res_obj_summary = res_obj['summary']
          // this.reports.agency_messages[res_obj_summary['cost_per_hour']] = message
        }

      }, (error) => {
        // console.log('Error on the ' + target + ' run!!!!!')
        // console.log(error);
        analytic("01:01:16:914::" + error)
      });
      // return return_res
      return return_res
    },

    async on_publish() {

      // console.log("You have clicked the publish button")
      store.commit('set_spinner_state', true)
      let data1 = this.prepare_curve_payload('publish')
      await this.curve_post('publish', data1)
      this.current_summary_report_x = []
      this.dry_run_pending_agency_message_x = ""
      this.publish = false
      this.publish_pending = false
      this.current_caregiver_report_x = []
      this.current_bonus_report_stake_x = ''
      store.commit('set_freetime_agency_message', "")
      await this.load_history_reports_modern()
      await pre_cache_available_freetime_weeks_for_all_your_agencies(this.db, this.my_orgs)
      await this.get_available_freetime_weeks()

      try {
        this.$refs.history_report_toggle.set_to_most_current();
      } catch (error) {
        // console.log(error)
      }
      store.commit('set_spinner_state', false)
      let m = "Your FreeTime report has been queued for publishing and will appear in the history list soon."
      store.commit('notification_enqueue', m)

    },


    async dry_run() {
      // console.log(`${}`)
      // console.log(`In side dry run`)
      if (
          // I added in the third condition, which is a bool, because match_file_status_indicator_state can be fooled
          // if the wrong value is stored in vuex.
          (this.match_file_status_indicator_state &&
              this.match_file_indicator_state &&
              this.get_file_freetime_name_data !== '' &&
              this.ehr_form.amount !== "") ||
          (this.match_file_status_indicator_state &&
              this.match_file_indicator_state &&
              this.pre_selected_week !== '' &&
              this.ehr_form.amount !== "")
      ) {

        store.commit('set_spinner_state', true)

        this.current_bonus_report_stake_x = this.ehr_form.amount
        let data1 = this.prepare_curve_payload('dry')
        let result = await this.curve_post('dry', data1)

        if (result) {
          store.commit('set_spinner_state', false)
          // console.log(`The result was: ${result}`)
          // console.log("got back results from dry run curve_post...")
          // console.log(result)
          // console.log(result.data)
          // console.log(result.data.error)

          // if(result.data.error === true){
          //   store.commit('error_enqueue', result.data.message)
          //   return null
          // }
          this.publish_pending = true
          this.ehr_form.amount_for_passing = this.ehr_form.amount
          this.ehr_form.amount = ''
          this.publish = false
          await this.get_backend_baseline_values()
          try {
            this.$refs.be_component_ref.recalculate_and_update_all_values()
          } catch (e) {
            //pass
          }
        } else {
          store.commit('set_spinner_state', false)
          // store.commit('error_enqueue', "The dry run is missing something. Did you include a funding amount?")
          // console.log("Looks like I did not get back results from dry run curve_post...")
          // store.commit('error_enqueue', "No results returned from dry run for some reason.")
        }
        this.ehr_form.amount = ''

      } else {
        //pass
      }
      console.log("Setting state to false after dry run........................................................")
      store.commit('set_spinner_state', false)

    },

    set_current_bonus_report(direction) {
      // console.log(`In set current bonus report`)
      // console.log(`Direction is ${direction}`)
      this.publish = false
      let stakes_array = Object.keys(this.reports.summary).sort()
      // console.log(`The stakes array ${stakes_array}`)
      for (let index = 0; index < stakes_array.length; ++index) {
        // console.log("Inside........")
        // console.log("What is the current_bonus_report_stake")
        // console.log(this.current_bonus_report_stake)
        // console.log(typeof this.current_bonus_report_stake)
        // console.log(`What is stakes array? ${typeof stakes_array}`)
        // console.log(stakes_array.length)


        if (parseFloat(this.current_bonus_report_stake) === parseFloat(stakes_array[index])) {
          // console.log("Found current stake in the array at index " + index)
          if (index + direction < 0) {
            // console.log("In index + direction < 0")
          } else if (index + direction > (stakes_array.length - 1)) {
            // console.log("(index + direction > (stakes_array.length - 1))")
          } else {
            this.current_bonus_report_stake_x = stakes_array[index + direction]
            // console.log("first off, what is the current_bonus_report_stake_x and type:")
            // console.log(`Current bonus report stake x : ${this.current_bonus_report_stake_x} and ${typeof this.current_bonus_report_stake_x}`)
            // console.log('updated current bonus report stake')
            // console.log("What is the reports summary.....")
            // console.log(this.reports.summary)
            let reports_array = Object.keys(this.reports.summary).sort()
            // console.log("what is the reports array...")
            // console.log(reports_array)
            for (let index = 0; index < reports_array.length; ++index) {
              // console.log(`Reports Array Index ${index}: ${reports_array[index]} and ${typeof reports_array[index]}`)
            }

            //    Possibly something to look at later, but the reason I am checking on types here is that
            // the stake amount gets written as a string or float, but things like '.90' get traslated to .9
            // and so problems arise. This is sloppy but in appears to work.

            console.log(`current_bonus_report_stake: ${this.current_bonus_report_stake} and ${typeof this.current_bonus_report_stake}`)
            this.current_summary_report_x = this.reports.summary[this.current_bonus_report_stake]
            if (this.current_summary_report_x) {
              // console.log("Got the goods on the first attempt...")
            }
            if (this.current_summary_report_x === undefined) {
              // console.log("current_summary_report_x came up as undefined. Covvert stake to float and retry.")
              this.current_summary_report_x = this.reports.summary[parseFloat(this.current_bonus_report_stake)]
              // console.log("Tried again with current summary reoprt x as a float and got....")
              // console.log(this.current_summary_report_x)
              // console.log(`what about checking index? which happens to be ${index} of type ${typeof index}`)
              // console.log(this.reports.summary[index])
              // this.current_summary_report_x = this.reports.summary[index]
            }

            this.current_caregiver_report_x = this.reports.caregiver[this.current_bonus_report_stake]
            // console.log("The new current summary report is.....")
            // console.log(this.current_summary_report_x)
            // this.current_report_x = JSON.parse(store.state.FreeTime.reports.summary[this.current_bonus_report_stake])
            // console.log("Got to here!!!!!!!")
            break
          }
        } else {
          console.log("Down in the Nope section.")
        }
      }
    },

    draw_history_reports(report_date) {
      // console.log("report date returned")
      // console.log(report_date)
      let dd = new Date(report_date)
      report_date = get_date_string(dd, 3)
      // console.log(report_date)
      this.current_history_report_date_x = report_date
      let formatted_history_summary_report = this.create_dry_summary_report(this.history.summary[this.current_history_report_date])
      let formatted_history_caregiver_report = this.create_dry_caregiver_report(this.history.caregiver[this.current_history_report_date])
      this.current_history_summary_report_x = formatted_history_summary_report
      this.current_history_caregiver_report_x = formatted_history_caregiver_report
      this.current_agency_message_x = this.history.agency_messages[this.current_history_report_date]
      this.current_history_summary_report_x = this.history.summary[this.current_history_report_date]
      this.current_history_caregiver_report_x = this.history.caregiver[this.current_history_report_date]
    },
    set_current_history_report(direction) {
      let history_array = Object.keys(this.history.summary).sort()
      for (let index = 0; index < history_array.length; ++index) {
        if (this.current_history_report_date === history_array[index]) {
          if (index + direction < 0) {
            //pass
          } else if (index + direction > (history_array.length - 1)) {
            //pass
          } else {
            this.current_history_report_date_x = history_array[index + direction]

            let formatted_history_summary_report = this.create_dry_summary_report(this.history.summary[this.current_history_report_date])
            let formatted_history_caregiver_report = this.create_dry_caregiver_report(this.history.caregiver[this.current_history_report_date])
            this.current_history_summary_report_x = formatted_history_summary_report
            this.current_history_caregiver_report_x = formatted_history_caregiver_report
            this.current_agency_message_x = this.history.agency_messages[this.current_history_report_date]


            this.current_history_summary_report_x = this.history.summary[this.current_history_report_date]
            this.current_history_caregiver_report_x = this.history.caregiver[this.current_history_report_date]
            break
          }
        } else {
          //pass
        }
      }
    },

  },
  async mounted() {
    console.log("RUNNING MOUNTED")
    this.db = new Database();
    await prime_socket(this.local_message_handler)
    // console.log("STarting the weeks check process")
    // this.my_orgs_ = await get_associated(this.db, 'org')
    let me = localStorage.getItem('username')
    // console.log(`Who am I? ${me}`)
    this.my_orgs_ = await get_orgs_associated_to_me(this.db, me)
    console.log("Back with my_orgs, now checking the weeks cache")
    // console.log(`What are my orgs? ${JSON.stringify(this.my_orgs_)}`)
    this.available_freetime_weeks_ = await this.get_available_freetime_weeks()
    console.log(`Weeks cache was ${JSON.stringify(this.available_freetime_weeks_)}`)
    console.log(`Weeks cache from computed property was ${JSON.stringify(this.available_freetime_weeks)}`)
    if(this.available_freetime_weeks_.length === 0){
      // console.log("No available weeks in cache. Run pre-cacher")
      await pre_cache_available_freetime_weeks_for_all_your_agencies(this.db, this.my_orgs)
      // console.log('Back from pre-cacher')
       this.available_freetime_weeks_ = await this.get_available_freetime_weeks()
      // console.log(`After pre-cache, available weeks is ${JSON.stringify(this.available_freetime_weeks_)}`)
    }else{
      // console.log(`THere were available weeks: ${JSON.stringify(this.available_freetime_weeks_)}`)
    }
    await this.warm_the_cache()

    await this.check_for_match_and_excludes()
    await this.get_backend_baseline_values()
    await this.load_history_reports_modern()
    // await this.check_for_match_and_excludes()
    // await this.get_backend_baseline_values()
    // console.log(this.get_baseline_values2)
  },

}
</script>

<style scoped>


.agency_message_display {
  background-color: var(--pennie_gray);
  color: black;
  max-width: 90%;
  white-space: pre-line;
}

input[type='file'] {
  color: transparent;
  margin-right: 10px;
}

input::placeholder {
  font-weight: bold;
  opacity: .2;
  color: black;
}

.dry_run_report {
  grid-area: dry_run_report;
}

#history_report {
  grid-area: history_report;
}

.ehr_arrows {
  width: 1.2rem;
  margin-top: .4rem;
}


.ehr_toggle_amt {
  text-align: center;
  padding-right: 1rem;
  padding-left: 1rem;
}

.ehr_input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.report_table {
  max-width: 100vw;
  margin-top: 1rem;
  font-family: 'Raleway', sans-serif
}

.table_key th {
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  font-size: 1rem;
}

.table_value {
  text-align: center;
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
}

td {
  text-align: center;
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
}

tr:nth-child(even) {
  background-color: white;
}

tr:nth-child(odd) {
  background-color: var(--eli_gray);
}

.be_report_sizing {
  width: 75%;
  margin: auto;
}

@media (min-width: 280px) {

  .agency_message_display {
    background-color: var(--pennie_gray);
    color: black;
    max-width: 95vw;
    white-space: pre-line;
    margin-top: 40px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: start;
    overflow-wrap: break-word;
    font-size: 18px;
  }


  .report_selector {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .pr_rep_but {
    margin-right: 20px;
    color: var(--new-primary-purple-deep);

  }

  #payroll_rep_section_body {
    padding-top: 20px;
  }

  #break_even {
    margin-top: 50px;
  }

  .action_button_text {
    color: white;
    font-size: 16px;
    font-family: Raleway, sans-serif;
    font-weight: 800;
  }

  .action_button_text2 {
    color: var(--new-primary-purple-deep);
    font-size: 16px;
    font-family: Raleway, sans-serif;
    font-weight: 800;
    /*margin-right: 20px;*/
  }


  .saved_indicator {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .saved_indicator p {
    font-weight: 800;
    font-size: 16px;
    line-height: 1.6;
  }

  .saved_indicator .material_icon {
    margin-left: 15px;
  }

  .action_button {
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .action_button2 {
    border: 3px solid var(--morgie_gray);
  }


  .action_button_text .material_icon {
    margin-right: 5px;
  }

  .material_icon {
    height: 26px;

  }

  .section_header {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: var(--new-primary-purple-deep);
    width: 100%;
    margin-top: 40px;
    max-width: 100vw;
  }

  .section_body {
    border: 1px solid black;
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 100vw;
  }

  .section_header h3 {
    color: white;
    font-size: 22px;
  }


  .good {
    color: green;
  }

  .bad {
    color: red;
  }

  .neutral {
    color: white
  }

  .ft_logo {
    width: 200px
  }

  .left_arrows {
    margin-right: 10px;
  }

  .right_arrows {
    margin-left: 10px;
  }

  .pdf_buttons_flex {
    width: 250px;
    margin-top: 35px;
  }
}


@media (min-width: 300px) {

}

@media (min-width: 330px) {

}

@media (min-width: 450px) {


  .left_arrows {
    margin-right: 15px;
  }

  .right_arrows {
    margin-left: 15px;
  }


  .pdf_buttons_flex {
    width: 350px;
    margin-top: 35px;
  }


}

@media (min-width: 768px) {

}

@media (min-width: 1024px) {

}


@media (min-width: 1000px) {

  input {
    font-size: 22px;

  }

  .file_name_indicator p {
  }

  #sch_data_file_name {
    font-size: 18px;
  }

  #break_even {
    margin-top: 50px;
  }

  .action_button_text {
    color: white;
    font-size: 22px;
  }


  .saved_indicator {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .saved_indicator p {
    font-weight: 800;
    font-size: 25px;
    line-height: 1.6;
  }


  .saved_indicator .material_icon {
    margin-left: 15px;
  }

  .action_button {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .action_button_text .material_icon {
    margin-right: 15px;
  }


  .material_icon {
    height: 45px;

  }

  .section_header {
    padding: 30px;
    background-color: var(--new-primary-purple-deep);
    width: 600px;
    margin-top: 70px;
  }

  .section_body {
    border: 1px solid black;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .section_header h3 {
    color: white;
    font-size: 38px;
  }


  .ft_logo {
    width: 400px
  }


}

@media (min-width: 2000px) {
  .be_comp {
    margin-top: 40px;
  }

  .summary_items {
    width: 460px;
  }

  .summary_item {
    /*margin-right: 40px;*/
    /*margin-left: 40px;*/
    margin-top: 30px;
  }

}

@media (min-width: 3000px) {

}


</style>