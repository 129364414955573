<template>

  <section class="core_c">
    <basic_page_infrastructure
        @local_page_update="local_page_update"
    ></basic_page_infrastructure>

    <section class="personal_profile flex_centered_column" id="profile_content">


      <!-- Association form-->
      <universal_pop_up_slot
          :show_popup="associate_form_up"
          @universal_popup_bg_clicked="toggle_associate_form"
          :backer_color="colors.dark_glass"
      >
        <div class="association_form_sizing">
          <div class="bg_primary_purple-light flex_centered_column padding_tb_20 padding_b_30">
            <div class="font_raleway text_align_center margin_tb_10 text_white text_30 font_weight_800">Meet</div>
            <label_with_input_text
                class="font_raleway font_weight_800"
                ref="accepted_code"
                :autofocus="true"
                label="Accept Agency Invitation"
                placeholder_text="123456"
                value=""
                input_size="16px"
                input_b_rad="0"
                input_char_spacing="5px"
                input_weight="800"
                label_color="black"
                label_size="22px"
                event_name="submit_acceptance_code"
                present_event="code_present"
                @code_present="tester_tools"
                @submit_acceptance_code="catch_accepted_code"
                :value_bg_color=colors.white
                :value_color=colors.black
            ></label_with_input_text>

            <div class="flex_centered_row">
              <basic_text_button
                  :button_state="true"
                  :button_color="colors.new_gold"
                  button_color_not_ready="gray"
                  button_height="52px"
                  button_width="150px"
                  border_rad_pct="0"
                  text="Accept"
                  :text_color="colors.black"
                  text_size="15px"
                  :bold="true"
                  event_name="send_assoc_code_clicked"
                  @send_assoc_code_clicked="accept_invitation"
              ></basic_text_button>
            </div>
            <div class="flex_centered_column wid_75 margin_t_25 text_white ">
              <p class="font_weight_800">Don't have an agency invitation?</p>
              <p class="margin_t_10 font_weight_800">Find an agency through StarFinder.</p>
              <basic_text_button
                  class="margin_t_25"
                  :button_state="true"
                  :button_color="colors.new_primary_purple_deep"
                  :button_color_not_ready="colors.new_primary_purple_deep"
                  button_height="52px"
                  button_width="150px"
                  border_rad_pct="0"
                  text="StarFinder"
                  :text_color="colors.white"
                  text_size="15px"
                  :bold="true"
                  event_name="send_assoc_code_clicked"
                  @send_assoc_code_clicked="spyglass_route"
              ></basic_text_button>
            </div>

          </div>

        </div>
      </universal_pop_up_slot>


      <!--=============================================================================================-->
      <!--=============================================================================================-->
      <inside_banner
          :mode="determine_profile"
          @meet_button_clicked="associate_up_hamburger_down"
          :show_hamburger="hamburger_menu_up"
          @call_user="call_user"
          @sms_user="sms_user"
      >
      </inside_banner>

      <!--=============================================================================================-->
      <!--=============================================================================================-->


      <div class="flex_centered_row">
        <h3 class="font_akshar margin_tb_20">{{ p_name || users_name }}</h3>
      </div>

      <camera_widget
          ref="camera_widgit"
          :personal_gear_available="personal_gear_available"
          :btn1_c="colors.new_orange_main"
          :btn2_c="colors.new_orange_main"
          :btn3_c="colors.new_cyanic_main"
          :btn4_c="colors.new_cyanic_main"
          btn1_icon='camera_on.svg'
          btn2_icon='delete.svg'
          btn3_icon='camera_snap.svg'
          btn4_icon='cloud-upload.svg'
          image1_w="50px"
          image2_w="40px"
          image3_w="55px"
          image4_w="55px"
          button_border_rad="50%"
          button_h="80px"
          button_w="80px"
          :frame_text_color="colors.pennie_gray"
          :frame_color="colors.eli_gray"
          :old_pic="profile_image"
          :disable_controls="personal_gear_available===false"
          :my_profile_page="personal_gear_available"
          event_name="save_pic"
          @save_pic="save_profile_image"
      ></camera_widget>

      <div v-if="personal_gear_available === false" class="font_raleway margin_t_10 text_eli_gray">{{ my_org_rank }}
      </div>


      <div class="flex_start_row badge_row">


        <div v-if="personal_joined_date">
          <div class="badge_box since">
            <div class="tooltip">
                    <span class="tooltiptext ">
                      <p>Tiny Magic Hat Member Since {{ personal_joined_date }}</p>
                    </span>
            </div>
            <p>SINCE</p>
            <p>{{ personal_joined_date }}</p>
          </div>
        </div>

        <div v-if="personal_freetime_hours">
          <div class="badge_box  ft_hours">
            <div class="tooltip">
                          <span class="tooltiptext  ">
                            <p>WonderPerks Hours Logged: {{ personal_freetime_hours }}</p>
                          </span>
            </div>
            <div class="flex_centered_row">
              <div class="flex_centered_column">
                <img @click="do_nothing" class="ft_logo"
                     src="@/assets/wonder_perks.svg"
                     alt="Freetime Feature Logo">
              </div>
            </div>
            <p>{{ personal_freetime_hours }}</p>
          </div>
        </div>

      </div>


      <div v-if="personal_gear_available === false" class="margin_t_30 flex_centered_column margin_t_20">
        <sd_summary_table
            :cg_name=this.users_name
            :starting_width=window_width
            :org_rank=viewer_org_rank
            @activate_spinner=handle_summary_table_spinner
        ></sd_summary_table>
      </div>


      <!--       <div>-->
      <!--         <p>{{ p_name }}</p>-->
      <!--       </div>-->

      <div class="margin_t_30 flex_centered_column margin_t_20 margin_auto">


        <div v-if="my_services.length === 0 && personal_gear_available">
          <div
              class="margin_auto agencies_box padding_b_50 padding_t_10 bg_lauren_gray flex_centered_column">
            <h3 class="text_align_center">Your Services</h3>
            <div class=" flex_wrap agency_cards">
              <div @click="get_new_route('service', 'speedydelivery')" class="  " v-for="item in ['speedydelivery']"
                   :key="item">
                <div class="flex_centered_column agency_card">
                  <!--                  <img v-if="agency_logos[item]" class="agency_logo" :src="agency_logos[item]">-->
                  <!--                  <img v-if="item==='tmh_admin'" src="@/assets/rabbit_2_line.svg" class="tmh_agency_logo"-->

                  <!--                       alt="Tiny Magic Hat Main Logo">-->
                  <img v-if="item==='speedydelivery'" src="@/assets/popdocs.svg" class="tmh_agency_logo"

                       alt="Speedy Delivery Logo">
                  <!--                  <p v-if="item !=='tmh_admin' && item !=='speedydelivery'" class="agency_name text_align_center">-->
                  <!--                    {{ pretty_names['services'][item] }}-->
                  <!--                  </p>-->
                </div>
              </div>

            </div>

          </div>
        </div>


        <div v-if="my_services.length > 0 && personal_gear_available">
          <div
              class="margin_auto agencies_box padding_b_50 padding_t_10 bg_lauren_gray flex_centered_column">
            <h3 class="text_align_center">Your Services</h3>
            <div class=" flex_wrap agency_cards">
              <div @click="get_new_route('service', item)" class="  " v-for="item in my_services"
                   :key="item.message">
                <div class="flex_centered_column agency_card">
                  <img v-if="agency_logos[item]" class="agency_logo" :src="agency_logos[item]">
                  <img v-if="item==='tmh_admin'" src="../../assets/re_color_stack_stroke.svg" class="tmh_agency_logo"

                       alt="Tiny Magic Hat Main Logo">
                  <img v-if="item==='speedydelivery'" src="@/assets/popdocs.svg" class="tmh_agency_logo"
                       alt="Speedy Delivery Logo">



                  <p v-if="item !=='tmh_admin' && item !=='speedydelivery'" class="agency_name text_align_center">
                    {{ pretty_names['services'][item] }}
                  </p>
                </div>
              </div>

            </div>

          </div>
        </div>


        <div class="margin_t_30 flex_centered_column margin_t_20 margin_auto">
          <div v-if="my_orgs.length === 0 && personal_gear_available" class="">
            <div
                class="margin_auto agencies_box  padding_t_10 bg_lauren_gray flex_centered_column">
              <h3 class="text_align_center">Your Agencies</h3>
              <div class=" flex_wrap agency_cards">

                <div class="agency_card add_agency " @click="associate_up_hamburger_down">

                  <div class="flex_centered_column height_100p">
                    <div class="">
                      <!--                      <span class="material-symbols-outlined material_icon cursor_ptr"-->
                      <!--                      >add_business</span>-->
                      <img class="material_icon cursor_ptr"
                           :src="image_name('add_business.svg')"
                           alt="Add agency icon">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>


      <div class="margin_t_30 flex_centered_column margin_t_20 margin_auto">
        <div v-if="my_orgs.length >= 1">
          <div
              class="margin_auto agencies_box  padding_t_10 bg_lauren_gray flex_centered_column">
            <h3 class="text_align_center">Your Agencies</h3>
            <div class=" flex_wrap agency_cards">
              <div @click="get_new_route('org', item)" class="  " v-for="item in my_orgs"
                   :key="item.message">
                <div class="flex_centered_column agency_card">
                  <img v-if="agency_logos[item]" class="agency_logo" :src="agency_logos[item]">
                  <img v-if="item==='tmh_admin'" src="../../assets/re_color_stack_stroke.svg" class="tmh_agency_logo"

                       alt="Tiny Magic Hat Main Logo">
<!--                  <p v-if="item !=='tmh_admin'" class="agency_name text_align_center">{{ pretty_names['orgs'][item] }} </p>-->
                </div>
              </div>
              <div class="agency_card add_agency " @click="associate_up_hamburger_down">

                <div class="flex_centered_column height_100p">
                  <div class="">
                    <img class="material_icon"
                         :src="image_name('add_business.svg')"
                         alt="Add agency icon.">
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>


      <!--      <div v-if="full_name">-->
      <div v-if=true>
        <basic_personal_info_box
            class="margin_t_20"
            :personal_gear_available="personal_gear_available"
            v-if="full_name"
            :full_name="full_name"
            :full_street_address="full_street_address"
            :phone_number="profile_phone_number"
            :email="profile_email"
            :city_state_zip="city_state_zip_string"
        ></basic_personal_info_box>
      </div>


      <div v-if="!full_name && personal_gear_available"  class="screen_block bg_whitesmoke b_rad_10px
            padding_tb_20 margin_lr_10 margin_t_20 font_raleway font_weight_800 text_align_center cursor_ptr bb padding_lr_10">

        <h3>Populate Your Profile</h3>

        <div class="flex_centered_row">
          <div class="flex_centered_column">
            <basic_text_button
            class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
            :hover_color=colors.cyanic_blue
            :hover_text_color=colors.white
            :button_state="true"
            :button_color="colors.new_orange_main"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="150px"
            border_rad_pct="0"
            text="Profile"
            text_size="14px"
            :bold="true"
            event_name="profile_button_clicked"
            @profile_button_clicked="non_contextual_route('info')"
            v-on:keyup.enter="non_contextual_route('info')"
            tabindex="0"
        ></basic_text_button>
          </div>
        </div>

      </div>

      <div v-if="!full_name && !personal_gear_available" class="screen_block bg_whitesmoke b_rad_10px
            padding_tb_20 margin_lr_10 margin_t_20 font_raleway font_weight_800 text_align_center">
        Seaching for profile information...
      </div>

      <div v-if="sg_ad_present" class="screen_block bg_orange1 cursor_ptr
      padding_tb_20 margin_lr_10 margin_t_20 font_raleway text_20 font_weight_800 text_align_center"
           @click="go_to_agency_profile_based_on_clicked_ad()">
        Click here to go directly to the ad you selected
      </div>

      <!--=================================================================================================================== -->
    </section>
  </section>

  <outside_footer
      class="margin_t_25 footer_c"
      @link_clicked="hamburger_menu_false()"
  >
  </outside_footer>


</template>

<script>
/* eslint-disable */
import {
  remove_class_to_body,
  desktop_size,
  get_new_url,
  get_associated,
  colors,
  scroll_to_top,
  parse_internal_json,
  prime_socket,
  receive_over_socket,
  be_main_address,
  route_prep,
  get_pda_home_route,
  entity_type_service_map,
  get_rank_from_cache,
  get_date_string,
  num_is_between_inclusive,
  mobile_detection,
  timestamp,
  image_name,
  get_agency_from_ad_id,
  get_speedydelivery_materials,
  get_agency_logo_from_localdatabase,
  log_sg_ad_click,
  get_logo_image_from_server, generate_user_preferred_name_simple,
  pre_cache_available_freetime_weeks_for_all_your_agencies, detect_connection_speed,
} from "@/library";

import {get_preferred_name} from "@/library2";

import store from "@/store";
import {mapGetters} from "vuex";
import inside_banner from "@/components/parts/inside_banner";
import camera_widget from "@/components/parts/camera_widget";
import notification_popup from "@/components/basics/notification_popup";
import label_with_input_text from "@/components/parts/label_with_input_text";
import basic_slide_over_menu from "@/components/parts/basic_slide_over_menu";
import basic_text_button from "@/components/parts/basic_text_button";
import error_popup_queue from "@/components/basics/error_popup_queue";
import basic_icon_button from "@/components/parts/basic_icon_button";
import universal_pop_up_slot from "@/components/parts/universal_pop_up_slot";
import basic_personal_info_box from "@/components/parts/basic_personal_info_box";
import hello_landing from "@/components/orgs/pda/hello_landing";
import outside_footer from "@/components/parts/outside_footer";
import sd_summary_table from "@/components/SpeedyDelivery/sd_summary_table.vue";

import {Database} from "@/client_db";
import axios from "axios";
// import network_status_box from "@/components/parts/network_status_box.vue";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure.vue";



export default {
  name: "profile_personal",

  async mounted(){
    await this.get_personal_badge_data()
    await detect_connection_speed()
    localStorage.setItem('SD_selected_cg_username', "")
  },


  components: {
    basic_page_infrastructure,
    notification_popup,
    basic_icon_button,
    label_with_input_text,
    basic_slide_over_menu,
    basic_text_button,
    error_popup_queue,
    universal_pop_up_slot,
    basic_personal_info_box,
    hello_landing,
    camera_widget,
    inside_banner,
    outside_footer,
    sd_summary_table,
    // network_status_box
  },

  data() {
    return {
      colors: colors,
      mobile_mode_x: true,
      opaque_slide_menu_x: false,
      very_small_screen_x: false,
      hamburger_menu_up_x: false,
      profile_form_up_x: false,
      associate_form_up_x: false,
      assoc_acceptance_code: "",
      personal_gear_available: false,
      profile_image_: "",
      profile_image_uuid_: "",
      profile_text: {},
      p_name_: "Searching...",
      my_org_rank_: "",
      focus_count: 0,
      active_sg_ad_: false,
      user_badge_data: {},
      current_badge_data_present_: false,
      phone_from_server: "",
      my_orgs_: [],
      agency_logos: {},
      pretty_names: {
        'orgs': {},
        'services': {}
      },
      my_services_: [],
      service_str: 'service',
      org_str: 'org',
      my_people_: [],
      verbose_console: false,
    }
  },


  watch: {
    async users_name(new_name, old_name) {
      console.log("in user name watch")
      console.log(`Old is ${old_name} and new in ${new_name}`)
      this.current_badge_data_present_ = false
      await this.get_personal_badge_data()

    },
    async update_page() {
      // console.log("update_page watcher is triggered")
      await this.local_page_update()
    },
    window_width(new_w) {
      // console.log("Inside window_width launcher...")
      this.run_window_width_adjustments(new_w)
    },
    // profile_image(){
    // console.log("INSIDE PROFILE IMAGE WATCH")
    // console.log(this.profile_image)
    // },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'last_context',
      'good_socket_connection',
      'network_speed',
      'token_time'
    ]),


    // time_left_on_token(){
    //   return token_time_left()
    // },



    p_name() {
      if (this.p_name_ === this.last_context) {
        return ""
      } else {
        return this.p_name_
      }

    },
    // big_screen(){
    //   return this.big_screen_
    // },
    current_badge_data_present() {
      return this.current_badge_data_present_
    },
    personal_badge_data() {
      if (this.current_badge_data_present) {
        return this.user_badge_data[this.users_name]
      } else {
        return {}
      }

    },

    personal_joined_date() {
      try {
        if (this.current_badge_data_present) {
          return this.user_badge_data[this.users_name]['joined']
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },

    personal_freetime_hours() {
      try {
        if (this.current_badge_data_present) {
          return this.user_badge_data[this.users_name]['ft_hours']
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },


    profile_content_div_height() {
      try {
        return document.getElementById("profile_content").clientHeight
      } catch (error) {
        return null
      }

    },
    content_less_footer_height() {
      // let content_no_footer = document.getElementById("profile_content")
      let h = this.profile_content_div_height
      console.log(`Profile content is ${h}`)
      if (h) {
        return h + 50
      } else {
        return 900
      }

    },
    sg_ad() {
      return this.active_sg_ad_
    },
    sg_ad_present() {
      return this.active_sg_ad_ && this.personal_gear_available
    },


    determine_profile() {
      if (this.personal_gear_available) {
        return "profileyou_home"
      } else {
        return "profileother_home"
      }
    },
    is_mobile() {
      return mobile_detection()
    },
    very_small_screen() {
      return this.very_small_screen_x
    },
    bottom_offset() {
      if (this.very_small_screen) {
        // cl("small screen is true")
        return "100vh"
      } else {
        // cl("Big screen")
        return "0"
      }
    },
    my_org_rank() {
      return this.my_org_rank_
    },
    viewer_org_rank(){
      let current_org = store.getters.org_context
      let viewer = localStorage.getItem('username')
      return get_rank_from_cache(viewer, current_org)
    },

    full_name() {
      let first = this.profile_first_name
      let middle = this.profile_middle_name
      let last = this.profile_last_name
      let nickname = this.profile_nick_name
      let na = ""
      let list = []

      if (!first || !last) {
        return na
      } else {
        list.push(first)
      }

      if (nickname) {
        nickname.replace("'", "\u2019")
        nickname = "\"" + nickname + "\""
        list.push(nickname)
      }

      if (middle) {
        middle.replace("'", "\u2019")
        list.push(middle)
      }

      list.push(last)
      return list.join(" ")

    },
    full_street_address() {
      let one = this.profile_address_name
      let two = this.profile_address2_name
      if (one === "") {
        return ""
      } else if (two === "") {
        return one
      } else {
        return one + ", " + two
      }
    },

    city_state_zip_string() {
      let city = this.profile_city_name
      let state = this.profile_state_name
      let zip = this.profile_zip_name
      if (!(city && state && zip)) {
        return ""
      } else {
        return `${city}, ${state} ${zip}`
      }
    },

    profile_phone_number() {
      try {
        return this.profile_text.phone_number
      } catch (error) {
        if (this.phone_from_server) {
          return this.phone_from_server
        } else {
          return ""
        }
      }

    },


    profile_first_name() {
      try {
        return this.profile_text.first_name
      } catch (error) {
        return ""
      }

    },
    profile_nick_name() {
      try {
        return this.profile_text.nick_name
      } catch (error) {
        return ""
      }
    },
    profile_middle_name() {
      try {
        return this.profile_text.middle_name
      } catch (error) {
        return ""
      }
    },
    profile_last_name() {
      try {
        return this.profile_text.last_name
      } catch (error) {
        return ""
      }
    },
    profile_address_name() {
      try {
        return this.profile_text.street_address
      } catch (error) {
        return ""
      }
    },
    profile_address2_name() {
      try {
        return this.profile_text.street_address2
      } catch (error) {
        return ""
      }
    },
    profile_city_name() {
      try {
        return this.profile_text.city
      } catch (error) {
        return ""
      }
    },
    profile_state_name() {
      try {
        return this.profile_text.state
      } catch (error) {
        return ""
      }
    },
    profile_zip_name() {
      try {
        return this.profile_text.zip
      } catch (error) {
        return ""
      }
    },
    profile_email() {
      try {
        return this.profile_text.email
      } catch (error) {
        return ""
      }
    },

    users_name() {
      // return localStorage.getItem('username')
      return this.last_context
    },


    acceptance_code_entered() {
      return this.assoc_acceptance_code
    },
    hamburger_menu_up() {
      // console.log(`Hamburger menu profile is now ${this.hamburger_menu_up_x}`)
      return this.hamburger_menu_up_x
    },
    profile_form_up() {
      // console.log(`returning profile_form_up_x which is ${this.profile_form_up_x}`)
      return this.profile_form_up_x
    },
    associate_form_up() {
      return this.associate_form_up_x
    },
    opaque_slide_menu() {
      return this.opaque_slide_menu_x
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    update_page() {
      // console.log("update_page computed property is triggered.")
      return store.getters.universal_page_update
    },
    indb_has_changed() {
      return store.getters.freetime_dropbox_indb_update_status
    },
    current_preamble_changed() {
      return store.getters.find_current_preamble
    },
    my_orgs() {
      return this.my_orgs_
    },
    my_services() {
      return this.my_services_
    },
    profile_image() {
      // console.log("inside the profile-image computed prop bc of image chang.")
      return this.profile_image_
    },
  },
  methods: {



    async users_name2() {
      let u_name = store.getters.my_name
      let p_name = await generate_user_preferred_name_simple(u_name, this.db)
      return p_name
    },
    image_name(name) {
      return image_name(name)
    },
    open_spinner() {
      store.commit('set_spinner_state', true)
    },

    close_spinner() {
      store.commit('set_spinner_state', false)
    },
    handle_summary_table_spinner(the_bool) {
      console.log(`Inside handle spinner with ${the_bool}`)
      if (the_bool) {
        this.open_spinner()
      } else {
        this.close_spinner()
      }
    },
    async get_phone_number_from_server() {
      let target_url = be_main_address('main_api') + 'get_user_phone_number'

      let the_agency = store.getters.org_context
      if(!the_agency){
        //Without agency context, this call will fail.
        return null
      }


      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        agency: the_agency,
        name: this.users_name,
      }
      // console.log("Here is the data1 for the get assistant log details call.")
      // console.log(data1)

      // this.stats_agency_selected
      await axios.post(target_url, data1)
          .then((response) => {
                // console.log("This is the response from user exists name check")
                //  store.commit('notification_enqueue', response.data['message'])
                if (response.data.error === false) {
                  console.log(response.data)
                  return response.data.data['phone_number']

                } else {
                  console.log("Something went wrong with Get user phone number attempt.")
                  console.log(response.data)
                }
              }
          )

    },

    async get_personal_badge_data() {
      // console.log("-> Started in get personal badges");

      if(this.users_name === 'speedydelivery'){
        //This is a front-end edge case that happens when navigating back and forth between the SD interface
        //and the personal profile. It does not break the UI, but the error in annoying. There is likely a less hacky
        //fix, but I just don't think it is worth the trouble at this time to investigate.
        return null
      }



      // let data = store.getters.get_personal_badge_data
      let user_data = await store.dispatch("get_user_badge_data", this.users_name)
      // console.log("->Badge data retrieved from Vuex ", user_data);
      // let badge_len = Object.keys(data).length
      let user_badge_len = Object.keys(user_data).length
      // console.log("-> data.length", user_badge_len);

      if (user_badge_len !== 0) {
        // console.log("-> Found some data...", user_data);
        this.current_badge_data_present_ = true
        this.user_badge_data[this.users_name] = user_data
        return user_data
      } else {
        let target = be_main_address('user_profile') + "get_personal_badges"
        let data1 = {
          "caller": store.getters.my_name,
          "subscriber": store.getters.org_context,
          "the_person": this.users_name,
          "id_token": store.getters.my_id_token,
          "refresh_token": store.getters.my_refresh_token,
        }
        await axios({
          method: 'post',
          url: target,
          data: data1,
        }).then((response) => {
          console.log(response)
          if (response.data['error'] === true) {
            let message = response.data.message
            store.commit('error_enqueue', message)
          } else if (response.data['error'] === false && response.data['success'] === false) {
            // store.commit('error_enqueue', "Nothing to see here.")
            //pass
          } else {
            let badge_data_dict = response.data.data
            console.log("-> badge_data_dict from back end", badge_data_dict);
            console.log("-> this.users_name is currently", this.users_name);
            // store.dispatch('set_personal_badge_data', badge_data_dict)
            store.dispatch('set_user_badge_data', [this.users_name, badge_data_dict])
            //Since I just downloaded it on mount, I know it is not exprired
            this.user_badge_data[this.users_name] = badge_data_dict
            this.current_badge_data_present_ = true


          }
        }, (error) => {
          console.log('Error on checking rank within organization..')
          console.log(error);
        });
      }


    },


    do_nothing() {
      console.log("doing nothing")
    },

    async get_login_message() {
      console.log("******************************LAUNCHING GET LOGIN MESSAGE***********************************")
      let target = be_main_address('main_api') + "get_login_message"
      let data1 = {
        "caller": store.getters.my_name,
        "subscriber": "",
        "the_person": this.users_name,
        "id_token": store.getters.my_id_token,
        "refresh_token": store.getters.my_refresh_token,
      }
      await axios({
        method: 'post',
        url: target,
        data: data1,
      }).then((response) => {
        console.log(response)
        if (response.data['error'] === true) {
          let message = response.data.message
          store.commit('error_enqueue', message)
        } else if (response.data['error'] === false && response.data['success'] === false) {
          // store.commit('error_enqueue', "Nothing to see here.")
          //pass
        } else if (response.data['error'] === false && response.data['success'] === true) {
          let message = response.data.message
          store.commit('notification_enqueue', message)
        } else {
          //Should not land here
        }
      }, (error) => {
        console.log('Error on checking rank within organization..')
        console.log(error);
      });

    },


    async non_contextual_route(route = null) {
      this.hamburger_menu_false()
      console.log("Current Context")
      console.log("")
      let current = localStorage.getItem('dev_latest_route')

      store.commit('update_context_changing_route', false)
      let new_url = current + '/' + route

      await this.$router.push({path: new_url})
    },

    async get_rank_with_org() {
      // if this profile is viewed by some other user through an org, get rank within that org
      if (!this.personal_gear_available) {
        let current_org = store.getters.org_context
        let users_rank = get_rank_from_cache(this.users_name, current_org)
        if (users_rank) {
          this.my_org_rank_ = users_rank
          return null
        }
        let current_entity_type = await this.db.get_current_entity_type_modern()
        let entity_related_service = entity_type_service_map(current_entity_type)
        let target = be_main_address(entity_related_service) + 'get_others_rank'
        let data1 = {
          "caller": store.getters.my_name,
          "other": this.users_name,
          "subscriber": current_org,
          "id_token": store.getters.my_id_token,
          "refresh_token": store.getters.my_refresh_token,
        }
        await axios({
          method: 'post',
          url: target,
          data: data1,
        }).then((response) => {
          console.log("Checking org rank....")
          console.log(response)
          if (response.data['error'] === true) {
            let message = response.data.message
            store.commit('error_enqueue', message)
          } else {
            let rank = response.data.data
            this.my_org_rank_ = rank
            store.commit('update_rank_cache', [this.users_name, current_org, rank])
          }
        }, (error) => {
          console.log('Error on checking rank within organization, early error.')

          console.log(error);
        });

      } else {
        //Profile not being viewed through organization.
      }
    },

    get_profile_image_from_server(uuid) {

      // console.log("You just clicked get_profile_image_from_server.")
      let data1 = {
        caller: store.getters.my_name,
        uuid: uuid,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }


      let target_url = be_main_address('user_profile') + 'get_profile_image'
      return axios.post(target_url, data1)

    },

    // async get_profile_image_from_db() {
    //   // console.log("inside get_profile_image_from_db.")
    //   // console.log("what is the current preamble?")
    //   // console.log(`Current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)
    //
    //   let search = {
    //     key: 'profile_image',
    //     additional_context: [['div', 'profile'], ['div', 'images']],
    //     base_context: store.getters.find_current_preamble
    //   }
    //
    //   let db_target_item = await this.db.db_search(search)
    //   let image_value = db_target_item[0]
    //   // console.log("Found this image in the database...")
    //   // console.log(image_value)
    //
    //
    //   if (image_value === 'on server') {
    //     // console.log("Image was not available locally in indexedDB.")
    //     //image has not been yet saved locally. Get the UUID for backend search
    //     let uuid_search = {
    //       key: 'uuid',
    //       additional_context: [['div', 'profile'], ['div', 'images']],
    //       base_context: store.getters.find_current_preamble
    //     }
    //
    //     // cl("Looking in local database for uuid")
    //     let db_item = await this.db.db_search(uuid_search)
    //     let uuid = db_item[0]
    //     //Get the image string from the server
    //
    //     // cl(`Should have a uuid: ${uuid}`)
    //     // console.log("Printing hash dict ahead of image search.")
    //     // console.log(await make_hash_dict(this.db))
    //     console.log("launching get_profile_image_from_server")
    //     this.profile_image_ = this.get_profile_image_from_server(uuid)
    //         .then(response => {
    //           // cl("back from get_profile_image_from_server")
    //           // cl(`img is ${img}`)
    //           let the_data_only = parse_internal_json(response.data)
    //           // console.log("parsed the_data_only from server is...")
    //           // console.log(the_data_only)
    //           // console.log(the_data_only['data'])
    //           // return new Promise(resolve => resolve(the_data_only['data']));
    //           let img = the_data_only['data']
    //           this.profile_image_ = img
    //
    //           // Now to save the image in the indexed db database
    //           let new_key = []
    //
    //           let search_key_components = ['base_context', 'additional_context']
    //           for (const component of search_key_components) {
    //             for (const element of uuid_search[component]) {
    //               for (const item of element) {
    //                 new_key.push(item)
    //               }
    //               // console.log(`New key is currently ${JSON.stringify(new_key)}`)
    //             }
    //           }
    //           let new_key_string = '/'
    //           for (const word of new_key) {
    //             new_key_string = new_key_string + word + '/'
    //           }
    //           new_key_string = new_key_string + uuid
    //
    //           let db_core_value = {
    //             "main_key": "profile_image",
    //             "profile_image": img,
    //             "type": "image",
    //             "uuid": uuid,
    //           }
    //           let db_whole_value = {
    //             timestamp: get_date_string(null, 5),
    //             value: db_core_value
    //           }
    //           // cl("add the image to the db under...")
    //           // cl(new_key_string)
    //           return this.db.addKeyValue(new_key_string, db_whole_value)
    //
    //         }).then((dexie_res) => {
    //           // console.log("Image has been added to database.")
    //           // console.log(`key used by dexie for save wase: ${dexie_res}`)
    //         })
    //   } else {
    //     // console.log("Image was not 'on_server', so what did we find??")
    //     // console.log(image_value)
    //     return image_value
    //   }
    //
    // },

    async save_profile_image_locally(profile_image_str, uuid) {
      let new_key = []
      let search_key_components = ['base_context', 'additional_context']
      let uuid_search = {
        key: 'uuid',
        additional_context: [['div', 'profile'], ['div', 'images']],
        base_context: store.getters.find_current_preamble
      }

      for (const component of search_key_components) {
        for (const element of uuid_search[component]) {
          for (const item of element) {
            new_key.push(item)
          }
          // console.log(`New key is currently ${JSON.stringify(new_key)}`)
        }
      }
      let new_key_string = '/'
      for (const word of new_key) {
        new_key_string = new_key_string + word + '/'
      }
      new_key_string = new_key_string + uuid

      let db_core_value = {
        "main_key": "profile_image",
        "profile_image": profile_image_str,
        "type": "image",
        "uuid": uuid,
      }
      let db_whole_value = {
        timestamp: get_date_string(null, 5),
        value: db_core_value
      }
      // cl("add the image to the db under...")
      // cl(new_key_string)
      return await this.db.addKeyValue(new_key_string, db_whole_value)
    },

    async get_profile_image_from_db() {
      console.log("inside get_profile_image_from_db.")
      // console.log("what is the current preamble?")
      // console.log(`Current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)

      let search = {
        key: 'profile_image',
        additional_context: [['div', 'profile'], ['div', 'images']],
        base_context: store.getters.find_current_preamble
      }

      let db_target_item = await this.db.db_search(search)
      let image_value = db_target_item[0]

      if (image_value === 'on server') {
        console.log("It was found that the image is not available locally, but is on the server.")
        let uuid_search = {
          key: 'uuid',
          additional_context: [['div', 'profile'], ['div', 'images']],
          base_context: store.getters.find_current_preamble
        }
        let db_item = await this.db.db_search(uuid_search)
        let uuid = db_item[0]
        this.profile_image_uuid_ = uuid
        console.log("launching get_profile_image_from_server")
        return this.get_profile_image_from_server(uuid)
            .then(response => {
              let the_data_only = parse_internal_json(response.data)
              // Maybe I could save the pic locally at this point?
              this.save_profile_image_locally(the_data_only['data'], uuid)
              return the_data_only['data']
            })
      } else {
        console.log("It was found that the image IS available locally.")
        return image_value
      }
    },


    async get_profile_text_from_db() {
      // console.log("inside get profile image from database.")

      let save_key = "cached_profile_data:" + store.getters.find_current_preamble
      let cached_profile_data = await store.dispatch("get_cache_value", save_key)
      // if (cached_profile_data) {
      //   console.log(`^^^^^^^^^^^^^^^^^^^^^^^^^^^Found a cached_profile_data: ${cached_profile_data}`)
      //   return cached_profile_data
      // }

      let search = {
        key: 'private_profile_data',
        additional_context: [['div', 'profile'], ['div', 'text_data']],
        base_context: store.getters.find_current_preamble
      }


      let db_search_result = await this.db.db_search(search)
      if (db_search_result) {
        let c = {
          'key': save_key,
          'value': db_search_result[0],
          'cache_time': 3600
        }
        // console.log(`^^^^^^^^^^^^^^^^^^^^^^^^^^^Caching a profile_data...`)
        store.commit("set_cache_value", c)
        return db_search_result[0]
      } else {
        console.log("No profile data was found for this user.")
      }


    },

    async simple_go_to_route(route) {
      await this.$router.push({path: '/' + route})
    },
    async save_profile_image() {
      console.log("You just clicked save_profile_image.")
      let data1 = {
        caller: store.getters.my_name,
        profile_image: store.getters.get_profile_image,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }

      let target_url = be_main_address('user_profile') + 'save_profile_image'
      axios.post(target_url, data1)
          .then((response) => {
            let the_data_only = parse_internal_json(response.data)
            console.log("parsed the_data_only from web call server is...")
            console.log(the_data_only)
            try {
              store.commit('error_enqueue', the_data_only.payload.content.message)
            } catch (error) {
              store.commit('notification_enqueue', the_data_only.message)
            }
            // this.local_page_update()

          });
    },

    async save_profile_info() {
      let data1 = {
        caller: store.getters.my_name,
        profile_text: this.profile_text,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }
      // console.log(data1)

      let target_url = be_main_address('user_profile') + 'save_personal_info'
      // console.log(target_url)
      axios.post(target_url, data1)
          .then((response) => {
            // console.log("response from server for save personal info is..")
            // console.log(typeof response)
            // console.log(response)
            let the_data_only = parse_internal_json(response.data)
            // console.log("parsed the_data_only from server is...")
            // console.log(the_data_only)

            try {
              store.commit('error_enqueue', the_data_only.payload.content.message)
            } catch (error) {
              store.commit('notification_enqueue', the_data_only.message)
            }
            // this.local_page_update()

          });
    },

    async profile_button_clicked() {
      // console.log("inside profile_button_clicked")
      try {
        // this.$emit('hamburger_backer_clicked', true)
        await this.$router.push({path: `${get_pda_home_route()}`})
        // let route = get_profile_route()
        // console.log("You clicked a button that makes a profile route:")
        // console.log(route)
        // await this.$router.push({path: route})

      } catch (error) {
        console.log(error)
      }

    },

    tester() {
      // console.log("got an event that the save image button was clicked.")
      // this.$refs.camera_widgit.toggle_controls_active();
    },

    tester_tools() {
      // console.log("got an event that the tools hamburger button was clicked.")
      // this.$refs.camera_widgit.toggle_controls_active();
    },

    get_ad_id_from_localstorage() {
      let ad_id_data_json = localStorage.getItem('clicked_sg_ad')
      // console.log(`The ad id json string is ${ad_id_data_json}`)
      if (ad_id_data_json) {
        let ad_id_data = JSON.parse(ad_id_data_json)
        let ad_id = ad_id_data['ad_id']
        let ts = ad_id_data['ts']
        let now = timestamp()
        let A_DAY_IN_MILLISECONDS = 3600 * 24 * 1000
        // let TEN_SECONDS_IN_MILLISECONDS = 10 * 1000
        let expired = ts + A_DAY_IN_MILLISECONDS
        if (now > expired) {
          //  This ad is 'expired', remove from local storage. sg_button defaults to false
          console.log('StarFinder ad is expired. Removing the token')
          localStorage.setItem('clicked_sg_ad', '')
          return ""
        } else {
          // AD is still 'active'
          return ad_id
        }

      }
    },
    async go_to_agency_profile_based_on_clicked_ad() {
      // console.log(`In personal profile go_to_agency_profile_based_on_clicked_ad`)
      // console.log(`This.sg_ad is ${this.sg_ad}`)
      let agency = await get_agency_from_ad_id(this.sg_ad)
      console.log(`Agency returned was ${agency}`)
      if (agency === 'no token') {
        return null
      }
      // console.log(`The agency returned was ${agency}`)
      if (agency) {
        store.commit('spyglass_agency_landing_public_set_agency_name', agency)
        let r = `spyglass/${agency}/landing`
        await log_sg_ad_click(agency, this.sg_ad)
        await this.simple_go_to_route(r)
      } else {
        store.commit('error_enqueue', "Your ad has expired or has been retired by the agency.")
        localStorage.setItem('clicked_sg_ad', "")
        this.active_sg_ad_ = false
      }
    },
    form_focused() {
      this.focus_count = this.focus_count + 1
      let w = this.window_width
      if (this.focus_count > 0 && this.mobile_mode) {
        let all_about_you = document.getElementById("all_about_you");
        let all_about_you_submit = document.getElementById("all_about_you_submit");
        all_about_you.style.paddingTop = "350px"
        all_about_you_submit.style.paddingBottom = "50px"
      }
    },
    form_blurred() {
      let all_about_you = document.getElementById("all_about_you");
      let all_about_you_submit = document.getElementById("all_about_you_submit");
      all_about_you.style.paddingTop = "0"
      all_about_you_submit.style.paddingBottom = "0"
    },
    call_user() {
      // console.log("in caller")
      let num = this.profile_phone_number
      let full_num = 'tel:' + num
      window.open(full_num);
    },
    sms_user() {
      // console.log("in caller")
      let num = this.profile_phone_number
      let full_num = 'sms:' + num
      window.open(full_num);
    },
    // async queue_notification() {
    //   let m = "This is a test notification. There is a reasonable amount of text here to give you ad idea " +
    //       "what this might look like with a real notification."
    //   store.commit('notification_enqueue', m)
    // }
    // ,
    // async queue_error() {
    //   let m = "This is a test error message. There is a reasonable amount of text here to give you ad idea " +
    //       "what this might look like with a real error."
    //   store.commit('error_enqueue', m)
    // }
    // ,

    async prepare_auto_log_out() {
      store.commit('set_activate_log_out', true)
      this.toggle_hamburger_menu()
      await route_prep(this.$router, '')
    },

    async back_to_the_front() {
      // console.log("Back to the front.")
      this.toggle_hamburger_menu()
      await route_prep(this.$router, '')
      // await route_prep(this.$router, 'hello_landing')
    },
    tester_tools_create_assoc() {
      console.log("THe Create association code on the PERSONAL PROFILE ASSOC was clicked.")
      // this.$refs.camera_widgit.toggle_controls_active();
    },
    async personal_gear_eligible() {
      // console.log("STARTING PERSONAL GEAR ELIGIBLE...")
      let search = {
        key: 'widgit',
        additional_context: [['div', 'widgits']],
        // additional_context: [],
        base_context: store.getters.find_current_preamble
      }
      let route_perms_array = await this.db.db_search(search)

      for (let k of route_perms_array) {
        if (k === "personal_profile_editing") {
          // console.log("Route permission has been discovered.")
          return true
        } else {
          // console.log(k)
        }
      }
      // console.log("returning false from checking on the personal profile editing widgit.")
      return false
    },

    catch_accepted_code(code) {
      console.log(`CATCHING THE ACCEPTANCE CODE... ${code}`)
      this.assoc_acceptance_code = code
    },

    async catch_profile_data() {

      // cl("Catching the profile data.")
      // this.profile_text = {
      //   "first_name": this.$refs.input_firstname.text_value_,
      //   "nick_name": this.$refs.input_nick_name.text_value_,
      //   "middle_name": this.$refs.input_middlename.text_value_,
      //   "last_name": this.$refs.input_lastname.text_value_,
      //   "street_address": this.$refs.input_streetaddress.text_value_,
      //   "street_address2": this.$refs.input_streetaddress2.text_value_,
      //   "city": this.$refs.input_city.text_value_,
      //   "state": this.$refs.input_state.text_value_,
      //   "zip": this.$refs.input_zip.text_value_,
      //   "email": this.$refs.input_email.text_value_,
      // }

      this.profile_text = {
        "first_name": this.$refs.input_firstname.get_value(),
        "nick_name": this.$refs.input_nick_name.get_value(),
        "middle_name": this.$refs.input_middlename.get_value(),
        "last_name": this.$refs.input_lastname.get_value(),
        "street_address": this.$refs.input_streetaddress.get_value(),
        "street_address2": this.$refs.input_streetaddress2.get_value(),
        "city": this.$refs.input_city.get_value(),
        "state": this.$refs.input_state.get_value()(),
        "zip": this.$refs.input_zip.get_value(),
        "email": this.$refs.input_email.get_value(),
      }

      //Limit the max characters to 75
      for (const property in this.profile_text) {
        let shortened = this.profile_text[property].slice(0, 75)
        this.profile_text[property] = shortened

      }

      console.log(JSON.stringify(this.profile_text))
      this.toggle_profile_form()
      await this.save_profile_info()

    },

    accept_invitation() {
      // console.log("Attemptintg to print the acceptance code")
      this.$refs.accepted_code.emit_state()
      console.log(this.acceptance_code_entered)

      let data1 = {
        caller: store.getters.my_name,
        code: this.acceptance_code_entered,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }
      // let target_url = "http://127.0.0.1:8088/assocs/accept_association"
      let target_url = be_main_address('assocs') + 'accept_association'
      axios.post(target_url, data1)
          .then((response) => {
            // console.log("Association Code Acceptance response from server is...")
            // console.log(typeof response)
            // console.log(response)
            let the_data_only = parse_internal_json(response.data)
            console.log("parsed the_data_only from server is...")
            console.log(the_data_only)
            try {
              store.commit('error_enqueue', the_data_only.payload.content.message)
            } catch (error) {
              store.commit('notification_enqueue', the_data_only.message)

            }

          });
      this.toggle_associate_form()

    },

    async local_page_update() {
      // console.log("^^^^^^^^^^^^^^^^Running local page update")

      // console.log("Running the local page update.")
      try {
        this.profile_image_ = await this.get_profile_image_from_db()
        this.my_orgs_ = await get_associated(this.db, 'org')
        // console.log("Here is my_orgs_.....")
        // console.log(this.my_orgs_.toString())

        //looking for agency logos
        for (const org of this.my_orgs_) {


          //first check the vuex cache...
          let save_key = "cached_agency_logo:" + org
          let cached_value = await store.dispatch("get_cache_value", save_key)
          let logo_r
          if (cached_value) {
            // console.log(`^^^^^^^^^^^^^^^^^^^^^^^^^^^Found a cached value for: ${save_key}`)
            logo_r = cached_value
          } else {
            //Nothing in the vuex cache, so lets check indexed db.
            logo_r = await get_agency_logo_from_localdatabase(this.db, org)
          }


          // console.log("Looking for a logo")
          // console.log(logo_r)

          if (logo_r === "on server") {
            //There was no logo saved locally, so we now need to make a network call.
            console.log("AGency logo on server, not yet found locally. Let's check the server.")
            let uuid_search = {
              key: 'uuid',
              additional_context: [['org', org], ['div', 'profile'], ['div', 'images']],
              base_context: store.getters.find_current_preamble
            }

            // cl("Looking in local database for uuid")
            let db_item = await this.db.db_search(uuid_search)
            let uuid = db_item[0]
            logo_r = await get_logo_image_from_server(uuid)
            logo_r = logo_r['data']['data']
            this.agency_logos[org] = logo_r

            //Let's cache the value we found on the server.
            let c = {
              'key': save_key,
              'value': logo_r,
            }
            // console.log(`^^^^^^^^^^^^^^^^^^^^^^^^^^^Caching an agency logo for ${org}`)
            store.commit("set_cache_value", c)


            //Now we need to write this new image file to the local database
            //Create the datakey to store the item.
            let new_key = []
            let search_key_components = ['base_context', 'additional_context']
            for (const component of search_key_components) {
              for (const element of uuid_search[component]) {
                for (const item of element) {
                  new_key.push(item)
                }
                // console.log(`New key is currently ${JSON.stringify(new_key)}`)
              }
            }

            //So string it all together into a new key
            let new_key_string = '/'
            for (const word of new_key) {
              new_key_string = new_key_string + word + '/'
            }
            new_key_string = new_key_string + uuid

            //Now store the data under the new key in the local database.
            let db_core_value = {
              "main_key": "logo_image",
              "logo_image": this.agency_logos[org],
              "type": "image",
              "uuid": uuid,
            }
            let db_whole_value = {
              timestamp: get_date_string(null, 5),
              value: db_core_value
            }
            // cl("add the image to the db under...")
            // cl(new_key_string)
            await this.db.addKeyValue(new_key_string, db_whole_value)
          } else {
            // console.log("found a logo for the agency and it was")
            // console.log(logo_r)
            this.agency_logos[org] = logo_r
          }
        }


        for (const org of this.my_orgs_) {
          let p_name = await this.get_pretty_name(org, this.org_str)
          this.pretty_names['orgs'][org] = p_name
        }


        // this.profile_image_ = await this.get_profile_image_from_db()

        // console.log("Here is the profile image from datadbase.")
        // console.log(this.profile_image_)
        this.profile_text = await this.get_profile_text_from_db()
        if (!this.profile_phone_number) {
          this.phone_from_server = await this.get_phone_number_from_server()
        }
        // this.profile_text_exp_ = await get_user_profile_data(this.db, this.last_context, true)
        this.p_name_ = await get_preferred_name(this.db, this.last_context, this.verbose_console)
        // console.log("got profile text from database...")
        // console.log(JSON.stringify(this.profile_text))
        // save profile text to state for use on the new All About You info page.
        store.commit('set_profile_text', this.profile_text)

        this.personal_gear_available = await this.personal_gear_eligible()


        this.my_services_ = await get_associated(this.db, this.service_str)
        // console.log('THESE ARE THE SERVICES THAT i FOUND IN THE DATABASE')
        // console.log(this.my_services_)


        // console.log("Looking for pretty name for services.")
        for (const service of this.my_services_) {
          let p_name = await this.get_pretty_name(service, this.service_str)
          // console.log(`The service was ${service} and the pretty name was ${p_name}.`)
          this.pretty_names['services'][service] = p_name
        }
      } catch (error) {
        console.log("Local page update could not access the database.")
        console.log(error)
      }
    },

    toggle_hamburger_menu() {
      // console.log(`In toggle hamburger menu. Before toggle Hamburger menu is ${this.hamburger_menu_up}.`)
      this.hamburger_menu_up_x = !this.hamburger_menu_up_x
      // console.log(`After toggle Hamburger menu is ${this.hamburger_menu_up}.`)
    },

    hamburger_menu_false() {
      this.hamburger_menu_up_x = false
    },

    toggle_profile_form() {
      this.profile_form_up_x = !this.profile_form_up_x
    },

    toggle_associate_form() {
      this.associate_form_up_x = !this.associate_form_up_x
    },

    hamburger_down() {
      this.hamburger_menu_up_x = false
    },

    profile_up_hamburger_down() {
      // console.log("profile up burger down")
      this.profile_form_up_x = true
      this.hamburger_menu_up_x = false
    },
    associate_up_hamburger_down() {
      this.associate_form_up_x = true
      this.hamburger_menu_up_x = false
    },
    associate_down_hamburger_down() {
      this.associate_form_up_x = false
      this.hamburger_menu_up_x = false
    },

    async get_pretty_name(reg_name, ent_type) {
      //first check the vuex cache
      let value_to_return
      let save_key = "cached_org_pretty_name:" + reg_name
      let cached_value = await store.dispatch("get_cache_value", save_key)
      if (cached_value) {
        // console.log(`^^^^^^^^^^^^^^^^^^^^^^^^^^^Found a cached p_name to immediately return: ${cached_value}`)
        return cached_value
      }


      let current_preamble_clone = store.getters.find_current_preamble
      let search = {
        'key': 'pretty_name',
        'base_context': current_preamble_clone,
        'additional_context': [[ent_type, reg_name], ['div', 'misc']]
      }
      let pretty_names_array = await this.db.db_search(search)
      if (pretty_names_array.length > 1) {
        value_to_return = reg_name
        // return reg_name
      } else if (pretty_names_array.length === 0) {
        //No pretty name was found for this item. Send back original name.
        value_to_return = reg_name
        // return reg_name
      } else {
        //Exactly one pretty name was found for this item.  Return it.
        //first let's cache this item
        value_to_return = pretty_names_array[0]

      }
      let c = {
        'key': save_key,
        'value': value_to_return,
      }
      // console.log(`^^^^^^^^^^^^^^^^^^^^^^^^^^^Caching a pretty name: ${value_to_return}`)
      store.commit("set_cache_value", c)

      return value_to_return
    },

    run_window_width_adjustments(new_w) {

      remove_class_to_body('content')
      remove_class_to_body('content_large_screen')
      remove_class_to_body('bg_white')
      // remove_class_to_body('content_no_radial')

      if (num_is_between_inclusive(100, 500, new_w)) {
        this.mobile_mode_x = true
        this.very_small_screen_x = false
        this.opaque_slide_menu_x = true
        // this.big_screen_ = false
      }
      if (num_is_between_inclusive(501, 5000, new_w)) {
        this.mobile_mode_x = false
        this.very_small_screen_x = false
        this.opaque_slide_menu_x = false
        // this.big_screen_ = false
      }
      //  if (num_is_between_inclusive(2000, 5000, new_w)) {
      //   this.big_screen_ = true
      // }


      if (new_w <= desktop_size) {
        // remove_class_to_body('content_large_screen')
        // add_class_to_body('content_no_radial')
        this.mobile_mode_x = true
        const matches = document.querySelectorAll(".screen_block_no_shadows");
        matches.forEach(function (match) {
          match.classList.remove('screen_block_no_shadows');
          match.classList.add('screen_block');
        });
      } else {
        console.log("size is DESKTOP SIZE")
        // add_class_to_body('content_large_screen')
        this.mobile_mode_x = false
        this.mobile_mode_x = false
      }
    },

    async get_new_route(envelope, context) {
      // console.log("inside get_new_route function")
      const preamble = [envelope, context]
      // console.log("envelope and context are:")
      // console.log(envelope)
      // console.log(context)
      await store.dispatch('stack_proposed', preamble)
      let new_url = await get_new_url(this.db)
      // console.log("Returned new url is...")
      // console.log(new_url)
      await this.$router.push({path: new_url})
    },

    profile_route(envelope, context) {
      const preamble = [envelope, context]
      store.dispatch('stack_proposed', preamble)
      let new_url = `/${context}/profile`
      this.$router.push({path: new_url})
    },
    spyglass_route() {
      this.associate_down_hamburger_down()
      this.$router.push({path: '/spyglass'})
    },
    async local_message_handler(from_server_json) {
      console.log("Getting message from socket server ...")
      let update = await receive_over_socket(from_server_json)
      console.log(`Update from socket? ${update}`)
      if (update) {
        try {
          console.log(`Launching direct command ${update['message']}`)
          let direct_command = update['message']
          let value = update['data']
          if (direct_command === "update_other_rank") {
            let promotee = value[0]
            let org = value[1]
            let rank = value[2]
            let promotion_data = [promotee, org, rank]
            store.commit('update_rank_cache', promotion_data)
            await this.get_rank_with_org()

          } else {
            console.log("We have an update that is NOT a direct command.")
            console.log("Run local page update")
            await this.local_page_update()
            return null
          }
        } catch (error) {
          store.commit('error_enqueue', "Update from socket has failed for some reason.")
          await this.local_page_update()
          return null
        }
      } else {
        // console.log("no update")
      }
    },

  },
  async updated() {
    // this.current_badge_data_present_ = false
    // this.profile_image_ = await this.get_profile_image_from_db()

    // console.log("Running updated in personal profiles")
    // this.profile_text_exp_ = await get_user_profile_data(this.db, this.users_name, true)
    this.personal_gear_available = await this.personal_gear_eligible()
  },



  async beforeMount() {
    // console.log("********************************************************************Running beforeMount")
    this.db = new Database();
    await prime_socket(this.local_message_handler)
    await this.local_page_update()
    await this.get_rank_with_org()
    await pre_cache_available_freetime_weeks_for_all_your_agencies(this.db, this.my_orgs)
    // add_class_to_body('white_content')
    await store.dispatch('notification_restart')

    if (this.window_width > 500) {
      this.opaque_slide_menu_x = false
      this.very_small_screen_x = false
    } else {
      this.opaque_slide_menu_x = true
      this.very_small_screen_x = true
    }

  },
}
</script>

<style scoped>
@import '../../common.css';


.association_form_sizing {
  width: 85%;
  margin: auto;
}


.wide_button {
  width: 85%;
}


@media (min-width: 100px) {

  h3 {
    font-size: 33px;
  }

  .badge_box:hover .tooltiptext {
    visibility: visible;
  }

  .tooltiptext p {
    color: white;
  }

  .profile_box {
    /*margin-bottom: 120px;*/
    padding-bottom: 50px;
  }

  /* .badge_box:hover .tooltiptext_ft_hours {*/
  /*  visibility: visible;*/
  /*}*/
  .tooltip {
    position: relative;
    display: inline-block;
    /*border-bottom: 1px dotted black; !* If you want dots under the hoverable text *!*/
  }

  /* Tooltip text */
  .badge_box .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    bottom: 1000%;
    /*left: 50%;*/
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
    margin-bottom: 20px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }


  .badge_row {
    width: 240px;
    margin-top: 20px;
  }

  .badge_box {
    cursor: pointer;
    height: 60px;
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 10px;
    padding: 2px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .since {
    background: var(--new-primary-purple-deep);
    color: white;
    margin-left: 0;
    /*border: red 3px solid;*/
  }

  .since p {
    font-size: 14px;
    font-weight: 800;
  }

  .ft_logo {
    width: 50px;
    margin-bottom: 3px;
  }

  .ft_hours {
    /*background: var(--cyanic-blue);*/
    background: var(--lauren_gray);
    /*color: var(--darker_gray);*/
    border: var(--eli_gray) 1px solid;
  }

  .ft_hours p {
    font-size: 13px;
    font-weight: 800;
    color: var(--dark_gray);
  }

  .ft_hours .tooltiptext p {
    font-size: 13px;
    font-weight: 800;
    color: white;
  }

  .agencies_box {
    max-width: 500px;
    width: 98vw;
  }

  .agency_cards {
    /*max-width: 90%;*/
    display: flex;
    /*flex-direction: column;*/
    justify-content: center;
    /*align-items: center;*/
    /*align-content: center;*/
  }

  .agency_card {
    background-color: white;
    width: 150px;
    min-height: 116px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 132px;
  }

  .agency_card:hover {
    background-color: var(--eli_gray);
    transition: all 0.4s;
  }

  .add_agency {
    margin-bottom: 50px;
  }

  .agency_name {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    margin-top: 10px;
  }

  .agency_logo {
    width: auto;
    height: auto;
    max-width: 100px;
    display: block;
  }

  .tmh_agency_logo {
    width: 100px;
  }

  .material_icon {
    height: 50px;
  }

  body.keyboard {
    height: calc(100% + 500px); /* add padding for keyboard */
  }


}

@media (min-width: 700px) {
  .personal_profile {
  }


  body.keyboard {
    height: calc(100%); /* add padding for keyboard */
  }

}

@media (min-width: 1200px) {

  h3 {
    font-size: 38px;
  }

  .badge_row {
    width: 270px;
  }
}

@media (min-width: 1200px) {

  .agencies_box {
    max-width: 640px;
    width: 98vw;
  }

  .agency_cards {
    max-width: 520px;
    padding-bottom: 50px;
  }

  .agency_card {
    background-color: white;
    width: 240px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 232px;
  }

  .agency_card:hover {
    background-color: var(--eli_gray);
    transition: all 0.4s;
  }

  .add_agency {
    margin-bottom: 50px;
  }

  .agency_name {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    margin-top: 10px;
    padding-bottom: 20px;
  }

  .agency_logo {
    width: auto;
    height: auto;
    max-width: 200px;
    display: block;
  }

  .tmh_agency_logo {
    width: 200px;
    /*padding-top: 50px;*/
  }

  .material_icon {
    height: 70px;
  }

  .profile_box {
    margin-bottom: 120px;
    padding-bottom: 50px;
  }

}

@media (min-width: 2000px) {


}


</style>