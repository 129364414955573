<template>
  <section class="core_c flex_centered_start_column">
    <notification_popup></notification_popup>
    <error_popup_queue></error_popup_queue>
    <outside_banner_std></outside_banner_std>
    <spinner
        :spinner_up="spinner_up"
    ></spinner>


    <universal_pop_up_slot
        :show_popup="tos_modal"
        @universal_popup_bg_clicked="tos_modal_false"
        :backer_color="colors.white"
    >
      <section class="tos_main flex_centered_column">
        <div class="bg_morgie_gray wid_100vw flex_centered_row logo_backing">
          <img v-if="!show_color_logo" src="@/assets/re_white_stacked.svg" alt="Tiny Magic Hat Logo"
               class="tmh_logo">
          <!--            <img v-if="show_color_logo" src="@/assets/rabbit_2_line.svg" alt="Tiny Magic Hat Logo" class="tmh_logo">-->
          <img v-if="show_color_logo" src="@/assets/re_one_line_tmh_stroke_no_tag.svg" alt="Tiny Magic Hat Logo" class="tmh_logo">
        </div>
        <!--          <h1>Tiny Magic Hat <br> Terms of Service</h1>-->
        <h1>Terms of Service</h1>
        <p>Tiny Magic Hat has updated its Terms of Service.</p>
        <p>You will need to agree to the updated Terms of Service before logging in.</p>
        <div class="flex_centered_row opt_in_row">
          <div class="flex_centered_column" @click="toggle_tos_agree">
            <div v-if="!tos_agree" class="check_box shadow_inner cursor_ptr"></div>
            <div v-if="tos_agree">
              <img class="check_box_icon cursor_ptr"
                   src="@/assets/check-mark.svg"
                   alt="check box image">
            </div>
          </div>
          <div class="spacer"></div>
          <p v-if="tos_agree" class="opt_in_text_yes">I agree to Tiny Magic Hat's updated
            <span @click='simple_route_prep("tos")' class="cursor_ptr text_primary_purple">Terms of Service</span>.
          </p>
          <p v-if="!tos_agree" class="opt_in_text_no">I agree to Tiny Magic Hat's updated
            <span @click='simple_route_prep("tos")' class="cursor_ptr text_primary_purple font_weight_600">Terms of Service</span>.
          </p>

        </div>
        <div class="spacer"></div>
        <basic_text_button
            class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
            :hover_color=colors.cyanic_blue
            :hover_text_color=colors.white
            :button_state=tos_agree
            :button_color="colors.new_orange_main"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="150px"
            border_rad_pct="0"
            text="LOGIN"
            text_size="14px"
            :bold="true"
            event_name="login_button_clicked"
            @login_button_clicked="log_in"
            tabindex="0"
            v-on:keyup.enter="log_in"
        ></basic_text_button>
      </section>
    </universal_pop_up_slot>


    <div class="flex_centered_row">
      <div class="flex_fully_centered_column margin_t_40 main_column">

        <h4 class="margin_b_20 login text_align_center">Secure Log in</h4>
        <input_with_label
            ref="login_name_box"
            class="login_input"
            label="Your User Name"
            @emit_state="catch_username"
        ></input_with_label>


        <input_with_label
            ref="login_password_box"
            class="login_input"
            label="Your Password"
            type="password"
            @emit_state="catch_password"
        ></input_with_label>

        <input_with_label
            ref="totp_password_box"
            class="login_input"
            label="Your TOTP"
            @emit_state="catch_totp"
            @enter_pressed="log_in"
        ></input_with_label>


        <basic_text_button
            class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
            :hover_color=colors.cyanic_blue
            :hover_text_color=colors.white
            :button_state="true"
            :button_color="colors.new_orange_main"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            button_width="150px"
            border_rad_pct="0"
            text="LOG IN"
            text_size="14px"
            :bold="true"
            event_name="login_button_clicked"
            @login_button_clicked="log_in"
            v-on:keyup.enter="log_in"
            tabindex="0"
        ></basic_text_button>

      </div>
    </div>

    <div class="flex_space_between_row  " id="links_row">
      <div class="flex_centered_row cursor_ptr links" @click='simple_route_prep("mfa_forgot_pw")'>Secure Reset Password</div>
      <div class="flex_centered_row cursor_ptr links" @click='simple_route_prep("mfa_register")'>Secure Sign Up</div>
    </div>
  </section>

  <outside_footer class="footer_c">
  </outside_footer>

</template>

<script>

/* eslint-disable */
import axios from "axios";
import {Database} from '@/client_db';
import {
  make_hash_dict,
  parse_internal_json, be_main_address, analytic, remove_class_to_body,
} from "@/library";
import store from "@/store";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import basic_text_button from "@/components/parts/basic_text_button";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import input_with_label from "@/components/parts/input_with_label";
import spinner from "@/components/parts/spinner";
import universal_pop_up_slot from "@/components/parts/universal_pop_up_slot";
import {
  colors
} from "@/library";

export default {
  components: {
    basic_text_button,
    error_popup_queue,
    notification_popup,
    outside_banner_std,
    outside_footer,
    input_with_label,
    spinner,
    universal_pop_up_slot
  },
  data() {
    return {
      colors: colors,
      name: '',
      password: '',
      totp: '',
      next_route: '',
      show_tos_modal_: false,
      show_color_logo_: true,
      tos_agree_: false,
      db: null,
      the_data: "",
    }
  },
  created() {
    this.db = new Database();
    console.log("Here in the db mfa_login.created has created....")
    console.log(this.db)
    store.commit('create_initial_defaults')
  },
  mounted() {
    this.db = new Database();
    console.log("Here in the db mfa_login.mounted has created....")
    console.log(this.db)
  },
  unmounted() {
    remove_class_to_body('bg_white')
  },
  methods: {
    tester() {
      // console.log("button was pressed.")
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (this.num_is_between_inclusive(100, 369, new_w)) {
        this.show_color_logo_ = false
      }
      if (this.num_is_between_inclusive(370, 399, new_w)) {
        this.show_color_logo_ = false
      }
      if (this.num_is_between_inclusive(400, 800, new_w)) {
        this.show_color_logo_ = false
      }
      if (this.num_is_between_inclusive(501, 1499, new_w)) {
        this.show_color_logo_ = false
      }
      if (this.num_is_between_inclusive(1500, 6000, new_w)) {
        this.show_color_logo_ = true
      }

    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
    catch_password(pw) {
      this.password = pw.trim()
    },
    catch_totp(pw) {
      this.totp = pw.trim()
    },
    catch_username(name) {
      this.name = name.toLowerCase().trim()
    },

    toggle_tos_agree() {
      this.tos_agree_ = !this.tos_agree_
    },
    tos_agree_true() {
      this.tos_agree_ = true
    },
    tos_agree_false() {
      this.tos_agree_ = false
    },

    tos_modal_true() {
      this.show_tos_modal_ = true
    },
    tos_modal_false() {
      this.show_tos_modal_ = false
    },

    async simple_route_prep(r, analytic_log = null) {
      // console.log("in simple route prep")
      if (analytic_log) {
        console.log("Internal Routing log recorded.")
        analytic(analytic_log)
      }
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      await this.$router.push({path: this_route})
    },

    async log_in() {

      this.tos_modal_false()
      // console.log("Getting ready to launch log in...")
      store.commit('set_spinner_state', true)
      let the_router = this.$router
      console.log("Calling login with this db")
      console.log(this.db)
      let fhd = await make_hash_dict(this.db)
      let the_res
      // console.log('hash dict value is...')
      // console.log(fhd)

      // console.log(`before login name is ${this.name}`)
      // console.log(`before login password is ${this.password}`)
      this.$refs.login_name_box.emit_state()
      this.$refs.login_password_box.emit_state()
      this.$refs.totp_password_box.emit_state()

      // console.log(`Name is ${this.name}`)
      // console.log(`Password is ${this.password}`)

      let error_out
      if (this.name.length === 0) {
        // console.log("name length was 0, no good")
        store.commit('error_enqueue', "You must provide a user name to login.")
        error_out = true
      }
      if (this.password.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a password to login.")
        error_out = true
      }
      if (this.totp.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a TOTP to login.")
        error_out = true
      }
      if (error_out === true) {
        // console.log("Ran into a login error, returning null.")
        store.commit('set_spinner_state', false)
        return null
      }

      // console.log("Getting ready to make axios call.")
      let target = be_main_address('main_api') + "mfa_log_in"
      // console.log("what is the address to call?")
      console.log(target)

      return await axios.post(target, {
        name: this.name,
        password: this.password,
        totp: this.totp,
        flat_hash_assets: fhd,
        agree_new_terms: this.tos_agree,
      })
          .catch(function (error) {
            console.log("in the catch")
            console.log(error)
            this.tos_agree_false()
            store.commit('set_spinner_state', false)
            if (error.response) {
              console.log("Something went wrong with your login.")
              store.commit('error_enqueue', "Bad login. Check name and password.")
              console.log(error)
              // error_out = true
              return null
            } else if (error.request) {
              let online = window.navigator.onLine;
              let message
              if (online) {
                message = "There are problems with your network connection, or Tiny Magic Hat may be down."
              } else {
                message = "You appear to have lost your network connection."
              }
              store.commit('error_enqueue', message)
              // error_out = true
              return null
            } else {
              // console.log("in the error catch all")
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              let message = "There are problems with your network connection, or Tiny Magic Hat may be down."
              store.commit('error_enqueue', message)
              // error_out = true
              return null
            }
          })
          .then((response) => {
            this.tos_agree_false()
            console.log("and the response DIRECTLY FROM MAIN API...")
            console.log(response)
            this.the_data = response.data



            if (response.data.error === false) {
              // the_res = response
              return this.process_asset_package(response.data)

            } else {
              if (response.data.data === 'throw_terms_modal') {
                //  This user's TOS agreement in out of date. We need to re-up
                store.commit('set_spinner_state', false)
                console.log("Need to show the agree to tos modal")
                this.tos_modal_true()
                return null

              } else {
                console.log("the_data_only.error must be TRUE")
                store.commit('error_enqueue', response.data.message)
                // error_out = true
                store.commit('set_spinner_state', false)
                return null
              }
            }
          })
          .then((response) => {
            //got back the processed asset package
            store.commit('set_spinner_state', false)
            const preamble = ['people', this.name]
            // this.next_route = `/${this.name}/profile`
            // console.log("this.next_route")
            // console.log(this.next_route)
            return store.dispatch('stack_proposed', preamble)
          }).then((response) => {
            this.next_route = `/${this.name}/profile`
            analytic({
              'message': this.next_route,
               'event': 'trying to build a profile route.'
            })
            return true
          })
          .then((response) => {
            this.next_route = `/${this.name}/profile`
            return this.$router.push({path: `/${this.name}/profile`})
          }).then((response) => {
            store.commit('save_name', this.name)
            this.name = ''
            this.password = ''
            this.the_data = ''
            console.log(fhd)
            return true
          })

    },


    // async log_in2() {
    //   this.tos_modal_false()
    //   // console.log("Getting ready to launch log in...")
    //   store.commit('set_spinner_state', true)
    //   let the_router = this.$router
    //   console.log("Calling login with this db")
    //   console.log(this.db)
    //   let fhd = await make_hash_dict(this.db)
    //   let the_res
    //   // console.log('hash dict value is...')
    //   // console.log(fhd)
    //
    //   // console.log(`before login name is ${this.name}`)
    //   // console.log(`before login password is ${this.password}`)
    //   this.$refs.login_name_box.emit_state()
    //   this.$refs.login_password_box.emit_state()
    //   this.$refs.totp_password_box.emit_state()
    //
    //   // console.log(`Name is ${this.name}`)
    //   // console.log(`Password is ${this.password}`)
    //
    //   let error_out
    //   if (this.name.length === 0) {
    //     // console.log("name length was 0, no good")
    //     store.commit('error_enqueue', "You must provide a user name to login.")
    //     error_out = true
    //   }
    //   if (this.password.length === 0) {
    //     // console.log("password length was 0, no good")
    //     store.commit('error_enqueue', "You must provide a password to login.")
    //     error_out = true
    //   }
    //   if (this.totp.length === 0) {
    //     // console.log("password length was 0, no good")
    //     store.commit('error_enqueue', "You must provide a TOTP to login.")
    //     error_out = true
    //   }
    //   if (error_out === true) {
    //     // console.log("Ran into a login error, returning null.")
    //     store.commit('set_spinner_state', false)
    //     return null
    //   }
    //
    //   // console.log("Getting ready to make axios call.")
    //   let target = be_main_address('main_api') + "mfa_log_in"
    //   // console.log("what is the address to call?")
    //   console.log(target)
    //   await axios.post(target, {
    //     name: this.name,
    //     password: this.password,
    //     totp: this.totp,
    //     flat_hash_assets: fhd,
    //     agree_new_terms: this.tos_agree,
    //   }).catch(function (error) {
    //     console.log("in the catch")
    //     console.log(error)
    //     this.tos_agree_false()
    //     store.commit('set_spinner_state', false)
    //     if (error.response) {
    //       console.log("Something went wrong with your login.")
    //       store.commit('error_enqueue', "Bad login. Check name and password.")
    //       console.log(error)
    //       error_out = true
    //       return null
    //     } else if (error.request) {
    //       let online = window.navigator.onLine;
    //       let message
    //       if (online) {
    //         message = "There are problems with your network connection, or Tiny Magic Hat may be down."
    //       } else {
    //         message = "You appear to have lost your network connection."
    //       }
    //       store.commit('error_enqueue', message)
    //       error_out = true
    //       return null
    //     } else {
    //       // console.log("in the error catch all")
    //       // Something happened in setting up the request that triggered an Error
    //       console.log('Error', error.message);
    //       let message = "There are problems with your network connection, or Tiny Magic Hat may be down."
    //       store.commit('error_enqueue', message)
    //       error_out = true
    //       return null
    //     }
    //   }).then((response) => {
    //     this.tos_agree_false()
    //     console.log("and the response DIRECTLY FROM MAIN API...")
    //     console.log(response)
    //     if (response.data.error === false) {
    //       the_res = response
    //
    //     } else {
    //       if (response.data.data === 'throw_terms_modal') {
    //         //  This user's TOS agreement in out of date. We need to re-up
    //         store.commit('set_spinner_state', false)
    //         console.log("Need to show the agree to tos modal")
    //         this.tos_modal_true()
    //         return null
    //
    //       } else {
    //         console.log("the_data_only.error must be TRUE")
    //         store.commit('error_enqueue', response.data.message)
    //         error_out = true
    //         store.commit('set_spinner_state', false)
    //         return null
    //       }
    //     }
    //     store.commit('set_spinner_state', false)
    //
    //   })
    //
    //   // console.log("response from server was NOT AN ERROR....................")
    //   store.commit("clear_route_history")
    //   // console.log("here is teh asset package.")
    //   // console.log(the_res.data)
    //
    //   analytic({
    //     'event': 'Attempt to print the response.',
    //     'message': "Get ready to attempt to print",
    //   })
    //
    //   // analytic({
    //   //    'event': 'What is the response?',
    //   //    'message': the_res,
    //   //  })
    //
    //   let l = Object.keys(the_res['data']).length
    //
    //   analytic({
    //     'event': 'What is the response["data"]?',
    //     'message': l,
    //   })
    //
    //
    //   // analytic({
    //   //    'event': 'What is the response.data?',
    //   //    'message': the_res.data.length,
    //   //  })
    //
    //   await this.process_asset_package(the_res.data)
    //   console.log("Finishing process the assets.")
    //   const preamble = ['people', this.name]
    //   this.next_route = `/${this.name}/profile`
    //   // console.log("this.next_route")
    //   // console.log(this.next_route)
    //   await store.dispatch('stack_proposed', preamble)
    //   this.spinner_up_ = false
    //   await the_router.push({path: this.next_route})
    //   store.commit('save_name', this.name)
    //   this.name = ''
    //   this.password = ''
    //   console.log(fhd)
    // },

    async process_asset_package(asset_package) {
      // console.log("inside processing asset package.")
      asset_package = this.the_data
      //save jwt authentication token in vuex

      analytic({
        'event': 'MFA Login--Before Save ID and Refresh Tokens',
        'message': 'APPLE TESTING 1',
      })

      // analytic({
      //   'event': 'Looking at the length of the asset package',
      //   'message': asset_package.length,
      // })


      // analytic({
      //   'event': 'Looking at the type of the asset package',
      //   'message': typeof asset_package,
      // })
      //
      // analytic({
      //   'event': 'Trying to read the id_token',
      //   'message': asset_package['id_token'],
      // })


      this.$store.commit('save_id_token', asset_package['id_token'])
      this.$store.commit('save_refresh_token', asset_package['refresh_token'])
      analytic({
        'event': 'MFA Login--After Save ID and Refresh, Before Parse Internal JSON',
        'message': 'APPLE TESTING 2',
      })
      // console.log('Printing token from store: ' + this.$store.getters.my_token);

      // console.log("getting ready to parse the assets")
      let the_assets = parse_internal_json(asset_package['assets'])

      analytic({
        'event': 'MFA Login--After Parse Internal JSON, before adding keys to database',
        'message': 'APPLE TESTING 3',
      })

      // console.log(JSON.stringify(the_assets))
      for (let [key, value] of Object.entries(the_assets)) {
        // console.log(`Adding ${key} to the database.`)
        await this.db.addKeyValue(key, value);
      }

      analytic({
        'event': 'MFA Login--After adding keys to database',
        'message': 'APPLE TESTING 4',
      })

      // console.log("done with processing the asset package")
    },
  },
  computed: {
    spinner_up() {
      return store.getters.get_spinner_state
    },
    show_color_logo() {
      return this.show_color_logo_
    },
    tos_modal() {
      return this.show_tos_modal_
    },
    tos_agree() {
      return this.tos_agree_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>
@import '../../common.css';


header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}


form label {
  /*font-size: 5.5vw;*/
  /*font-family: 'Lobster', cursive;*/
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--black);
}


form label[for=user-name] {
  margin-top: 2vh;
}

form input {
  /*padding-left: 2vw;*/
  margin-bottom: 2.25vh;
  font-size: 6vw;
  padding-left: 17px;;
  /*text-align: center;*/
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  height: 22px;
  width: 50vw;
}


@media (min-width: 100px) {

  .tmh_logo {
    width: 85px;
  }

  .logo_backing {
    width: 100vw;
    background-color: var(--eli_gray);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .tos_main h1 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;
  }

  .tos_main p {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 225px;
  }

  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;
  }

  .login_input {
    width: 85vw;
    max-width: 400px;
  }

  .login {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  #links_row {
    width: 98vw;
    max-width: 400px;
    margin-bottom: 40px;
    color: var(--primary-purple);
  }


  .main_column {
    max-width: 75%;
    padding-bottom: 80px;
  }

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);

  }

  .spacer {
    width: 10px;
  }

  .check_box_icon {
    height: 30px;
  }

  .opt_in_row {
    margin-top: 10px;
    width: 250px;
  }

  .opt_in_text_yes {
    width: 200px;
    color: var(--primary-purple);
    font-weight: bold;
  }

  .opt_in_text_no {
    width: 200px;
    color: var(--wyatt_gray);
    font-weight: normal;
  }


}

@media (min-width: 500px) {


  .main_column {
    max-width: 75%;
  }

}


@media (min-width: 770px) {


  .main_column {
    max-width: 75%;
  }


}

@media (min-width: 800px) {
  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;

  }
}

@media (min-width: 1200px) {
  .spacer {
    width: 30px;
  }

  .check_box {
    background-color: var(--lauren_gray);
    height: 30px;
    width: 30px;
    border: 1px solid var(--black);
  }

  .check_box_icon {
    height: 30px;

  }

  .opt_in_row {
    margin-top: 30px;
    margin-bottom: 50px;
    width: 500px;
  }

  .opt_in_text_yes {
    width: 350px;
    color: var(--primary-purple);
    font-weight: bold;
  }

  .opt_in_text_no {
    width: 350px;
    color: var(--wyatt_gray);
    font-weight: normal;
  }
}

@media (min-width: 1500px) {
  .tmh_logo {
    width: 350px;
  }

  .logo_backing {
    width: 100vw;
    background-color: unset;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .tos_main h1 {
    font-size: 38px;
    text-align: center;
    margin-bottom: 20px;
  }

  .tos_main p {
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 350px;
  }

}


</style>