<template>
  <div class="core_c">

    <inside_banner
        mode="speedydelivery_agency"
        :owner_perm="admins_perm"
        @agency_info_clicked="do_nothing"
        @invite_clicked="do_nothing"
        @promote_clicked="do_nothing"
    >
    </inside_banner>

    <basic_page_infrastructure
        @local_page_update="do_nothing"
    ></basic_page_infrastructure>

    <input type="file" style="display: none" ref="fileInput_caregiver_document"
           @change="onDocumentUpload($event)">

    <section class="flex_centered_start_column">
      <!--     <h2 class="text_eli_gray">Tiny Magic Hat Terms of Service</h2>-->
      <div class="flex_centered_row">
        <img @click="go_back" class="padding_tb_20 sd_logo cursor_ptr"
             src="@/assets/popdocs.svg"
             alt="PopDocs Feature Logo">
      </div>

      <div class="flex_centered_row">
         <h2 class="text_eli_gray text_align_center">Caregiver Document Upload</h2>
      </div>

       <camera_widget
            v-if="true"
            ref="camera_widgit"
            :personal_gear_available="false"
            :btn1_c="colors.new_orange_main"
            :btn2_c="colors.new_orange_main"
            :btn3_c="colors.new_cyanic_main"
            :btn4_c="colors.new_cyanic_main"
            btn1_icon='camera_on.svg'
            btn2_icon='delete.svg'
            btn3_icon='camera_snap.svg'
            btn4_icon='cloud-upload.svg'
            image1_w="50px"
            image2_w="40px"
            image3_w="55px"
            image4_w="55px"
            button_border_rad="50%"
            button_h="80px"
            button_w="80px"
            :frame_text_color="colors.pennie_gray"
            :frame_color="colors.eli_gray"
            :old_pic="current_cg_profile_img"
            :disable_controls="true"
            :my_profile_page="false"
            event_name="save_pic"
            @save_pic="do_nothing"
        ></camera_widget>

      <h4 class="text_eli_gray cg_name">{{ get_current_cg_pname() }}</h4>

       <basic_text_button
            v-if="show_sd_report_dl_button"
            class="margin_tb_20"
            :button_state="true"
            :button_color="colors.new_orange_main"
            :hover_color="colors.cyanic_blue"
            :text_color="colors.black"
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="60px"
            :button_width=action_button_width
            border_rad_pct="0%"
            text="Get SpeedyDelivery Report"
            text_size="14px"
            :bold="true"
            event_name="emit_baseline_input_values"
            @emit_baseline_input_values="get_speedy_report"
        ></basic_text_button>


      <div>

      </div>

      <coversheets_widget
         :selected_caregiver=this.get_selected_cg
      ></coversheets_widget>


<!--      <document_upload_widget-->
<!--         ref="drivers_license_widget"-->
<!--         document_type="drivers_license"-->
<!--         button_text="Select Document"-->
<!--         button_text2="Upload Document"-->
<!--         heading_text="Driver's License"-->
<!--         pretty_doc_name="Driver's License"-->
<!--         @upload_doc="upload_document('drivers_license_widget', $event)"-->
<!--      >-->
<!--      </document_upload_widget>-->

<!--       <document_upload_widget-->
<!--         ref="car_insurance_widget"-->
<!--         document_type="car_insurance"-->
<!--         button_text="Select Document"-->
<!--         button_text2="Upload Document"-->
<!--         heading_text="Car Insurance"-->
<!--         pretty_doc_name="Car Insurance"-->
<!--         @upload_doc="upload_document('car_insurance_widget', $event)"-->
<!--      >-->
<!--      </document_upload_widget>-->

<!--      <document_upload_widget-->
<!--         ref="driving_record_widget"-->
<!--         document_type="driving_record"-->
<!--         button_text="Select Document"-->
<!--         button_text2="Upload Document"-->
<!--         heading_text="Driving Record"-->
<!--         pretty_doc_name="Driving Record"-->
<!--         @upload_doc="upload_document('driving_record_widget', $event)"-->
<!--      >-->
<!--      </document_upload_widget>-->


<!--       <document_upload_widget-->
<!--         ref="covid_record_widget"-->
<!--         document_type="covid_record"-->
<!--         button_text="Select Document"-->
<!--         button_text2="Upload Document"-->
<!--         heading_text="Covid Test Record"-->
<!--         pretty_doc_name="Covid Test Record"-->
<!--         @upload_doc="upload_document('covid_record_widget', $event)"-->
<!--      >-->
<!--      </document_upload_widget>-->


<!--       <document_upload_widget-->
<!--         ref="drug_test_widget"-->
<!--         document_type="drug_test"-->
<!--         button_text="Select Document"-->
<!--         button_text2="Upload Document"-->
<!--         heading_text="Drug Test"-->
<!--         pretty_doc_name="Drug Test"-->
<!--         @upload_doc="upload_document('drug_test_widget', $event)"-->
<!--      >-->
<!--      </document_upload_widget>-->


<!--       <document_upload_widget-->
<!--         ref="criminal_background_check_widget"-->
<!--         document_type="criminal_background_check"-->
<!--         button_text="Select Document"-->
<!--         button_text2="Upload Document"-->
<!--         heading_text="Criminal Background Check"-->
<!--         pretty_doc_name="Criminal Background Check"-->
<!--         @upload_doc="upload_document('criminal_background_check_widget', $event)"-->
<!--      >-->
<!--      </document_upload_widget>-->

<!--       <document_upload_widget-->
<!--         ref="sex_offender_registry_record_widget"-->
<!--         document_type="sex_offender_check"-->
<!--         button_text="Select Document"-->
<!--         button_text2="Upload Document"-->
<!--         heading_text="Sex Offender Registry Check"-->
<!--         pretty_doc_name="Sex Offender Check"-->
<!--         @upload_doc="upload_document('sex_offender_registry_record_widget', $event)"-->
<!--      >-->
<!--      </document_upload_widget>-->

<!--       <document_upload_widget-->
<!--         ref="oig_exclusions_record_widget"-->
<!--         document_type="oig_check"-->
<!--         button_text="Select Document"-->
<!--         button_text2="Upload Document"-->
<!--         heading_text="OIG Exclusions Check"-->
<!--         pretty_doc_name="OIG Exclusions Check"-->
<!--         @upload_doc="upload_document('oig_exclusions_record_widget', $event)"-->
<!--      ></document_upload_widget>-->

<!--       <document_upload_widget-->
<!--         ref="cna_certification_widget"-->
<!--         document_type="cna_cert"-->
<!--         button_text="Select Document"-->
<!--         button_text2="Upload Document"-->
<!--         heading_text="CNA Certification"-->
<!--         pretty_doc_name="CNA Certification"-->
<!--         @upload_doc="upload_document('cna_certification_widget', $event)"-->
<!--      ></document_upload_widget>-->

<!--      <document_upload_widget-->
<!--         ref="nursing_certification_widget"-->
<!--         document_type="nursing_cert"-->
<!--         button_text="Select Document"-->
<!--         button_text2="Upload Document"-->
<!--         heading_text="Nursing Certification"-->
<!--         pretty_doc_name="Nursing Certification"-->
<!--         @upload_doc="upload_document('nursing_certification_widget', $event)"-->
<!--      ></document_upload_widget>-->

<!--       <document_upload_widget-->
<!--         ref="e_verify_widget"-->
<!--         document_type="e_verify"-->
<!--         button_text="Select Document"-->
<!--         button_text2="Upload Document"-->
<!--         heading_text="E-Verify"-->
<!--         pretty_doc_name="E-Verify"-->
<!--         @upload_doc="upload_document('e_verify_widget', $event)"-->
<!--      ></document_upload_widget>-->

<!--      <document_upload_widget-->
<!--         ref="social_security_card_widget"-->
<!--         document_type="social_security_card"-->
<!--         button_text="Not Yet Operational"-->
<!--         button_text2="Upload Document"-->
<!--         heading_text="Social Security Card"-->
<!--         pretty_doc_name="Social Security Card"-->
<!--         deactivate="true"-->
<!--         @upload_doc="do_nothing"-->
<!--      ></document_upload_widget>-->

    </section>

  </div>
  <outside_footer class="footer_c margin_t_50"></outside_footer>
</template>

<script>
/* eslint-disable */
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import outside_banner_std from "@/components/parts/outside_banner_std";
import inside_banner from "@/components/parts/inside_banner";
import outside_footer from "@/components/parts/outside_footer";
import spinner from "@/components/parts/spinner";
import basic_text_button from "@/components/parts/basic_text_button";
import store from "@/store";
// import image_upload_widget_general from "@/components/parts/image_upload_widget_general";
import camera_widget from "@/components/parts/camera_widget";
import document_upload_widget from "@/components/SpeedyDelivery/document_upload_widget";
import coversheets_widget from "@/components/SpeedyDelivery/coversheets_widget.vue";
import {
  get_uuid,
  round,
  colors,
  desktop_size,
  scroll_to_top,
  get_pda_home_route,
  be_main_address,
  parse_internal_json,
  analytic,
  fileToDataUri,
  reset_current_speedydelivery_upload_data_vuex,
  get_date_string,
  open_spinner, close_spinner
} from "@/library";
import {Database} from "@/client_db";
import {mapGetters} from "vuex";
// import Image_upload_widgit_general from "@/components/parts/image_upload_widget_general";
import axios from "axios";

export default {
  name: "upload_caregiver_document",
  async mounted() {
    this.db = new Database();
    this.id_ = get_uuid()
    scroll_to_top()
    await this.check_for_general_admin_ui_perm()
    this.run_window_width_adjustments(this.windowWidth)

    // console.log("Mounting the upload caregiver documents page.")
    // console.log("The store before changing pages...")
    //   console.log(JSON.stringify(store.state))
    // console.log("THe map")
    // console.log(store.getters.get_pname_to_tmhname_map)


    console.log(`Speedy User is ${store.getters.get_speedydelivery_agency_selected_caregiver}`)
    console.log(`Subscriber is ${store.getters.org_context}`)

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },
  async unmounted(){
    console.log("Inside unmounted")
    // this.reset_selected_caregiver()
  },


  props: [],
  components: {
    // Image_upload_widgit_general,
    outside_footer,
    outside_banner_std,
    spinner,
    basic_text_button,
    basic_page_infrastructure,
    inside_banner,
    camera_widget,
    // image_upload_widget_general,
    document_upload_widget,
    coversheets_widget
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      admins_perm_: false,
      button_height_: "80px",
      button_width_: "100px",
      button_img_size_: "80px",
      current_document_type_: "",
    }
  },
  methods: {
    do_nothing(something) {
      console.log(`do nothing with ${something}`)

    },

    get_current_cg_pname(){
      return store.getters.get_speedydelivery_agency_selected_caregiver_pname
    },

    reset_selected_caregiver() {
      console.log("INside reset selected caregiver.")
      // this.selected_caregiver_pname_ = ""
      // this.selected_caregiver_user_name_ = ""
      // this.caregiver_defined_ = false
      store.commit('set_speedydelivery_agency_selected_caregiver', "")
      store.commit('set_speedydelivery_agency_selected_caregiver_pname', "")
      store.commit('set_speedydelivery_current_caregiver_profile_image', "")
    },

    async upload_document(widget_ref, doc_package) {
      console.log("here is what I got....")
      console.log(widget_ref)
      console.log(doc_package)
      this.$refs[widget_ref].reset()
      open_spinner()
      let res = await this.send_doc_to_server(doc_package)
      if(res){
        // store.commit("notification_enqueue", `A ${doc_package['doc_pretty_name']} has been uploaded.`)
        store.commit('speedydelivery_remove_caregiver_to_no_docs_list', this.get_speedydelivery_agency_selected_caregiver)
        close_spinner()
      }else{
         store.commit("error_enqueue", `Something went wrong with the upload.`)
        close_spinner()
      }
    },

    async send_doc_to_server(doc_package){
      const matches_data = new FormData();
      matches_data.append("caller", store.getters.my_name)
      matches_data.append("speedy_user", store.getters.get_speedydelivery_agency_selected_caregiver)
      matches_data.append("subscriber", store.getters.org_context)
      matches_data.append("id_token", store.getters.my_id_token)
      matches_data.append("refresh_token", store.getters.my_refresh_token)
      matches_data.append("document_type", doc_package['doc_type'])
      matches_data.append("document", doc_package['doc_content'])
      matches_data.append("document_expiration", doc_package['doc_expiration'])
      // let endpoint = 'http://192.168.1.10:8087/ft/upload_match_template'
      let endpoint = be_main_address('sd') + 'upload_caregiver_document'
      console.log("Ready to make the document upload call.")


      return await axios({
        method: 'post',
        url: endpoint,
        data: matches_data,

      }).then((response) => {
        console.log(response)
        if (response.data['error'] === true) {
          console.log("Got back an error from the server...")
          console.log(response.data)
          let message = parse_internal_json(response.data)['message']
          store.commit('error_enqueue', message)
          return false
        } else {
          console.log("Got back after sending caregiver doc to Speedy....")
          console.log(response)
          let message = parse_internal_json(response.data)['message']
          console.log(message)
          store.commit('notification_enqueue', message)
          this.current_document_type_ = ""
          return true
          // reset_current_speedydelivery_upload_data_vuex()
        }

      }, (error) => {
        // console.log('Error on the match template upload.')
        // console.log(error);
      });
    },
    async go_back() {
      await this.$router.back()
    },
    async go_to_profile_home_route() {
      await this.$router.push({path: `${get_pda_home_route()}`})
    },



    async onFilePicked(event, name_saver = false) {
      // analytic("01:01:16:916" + "::" + f_type)
      let files = event.target.files
      let the_file = files[0]
      let the_file_name = the_file.name


      const myArray = the_file_name.split(".");
      let file_ext = myArray[1];
      // let exts = ["jpeg",  "jpg", "pdf"]
      let exts = ["pdf"]
      if (exts.includes(file_ext)) {
        // console.log("ok file")
      } else {
        let m = `Caregiver document uploads must be in a PDF file format. Your file was a "${file_ext}".`
        store.commit('error_enqueue', m)
        // console.log("NOT OK")
        return null
      }

      //Translate the pdf file into a base64 string
      the_file = await fileToDataUri(the_file).then(orig_src => {
        console.log("File translated to base64 is")
        console.log(orig_src)
        this.$refs.fileInput_caregiver_document.value = ''
        return orig_src
      })


      let the_file_store = 'save_file_speedydelivery_' + this.current_document_type
      console.log(`THE FILE STORE IS.....${the_file_store}`)
      console.log(`THE FILE is.....`)
      console.log(the_file)

      console.log(`Speedy User is ${store.getters.get_speedydelivery_agency_selected_caregiver}`)
      console.log(`Subscriber is ${store.getters.org_context}`)

      if (name_saver === true) {
        // let the_file_name_store = 'save_file_speedydelivery_name_' + this.current_document_type
        store.commit(the_file_store, the_file)
      }


      console.log(`Ready to upload the file: ${the_file_name}`)
      // store.commit(the_file_store, the_file)

      // this.ehr_form.amount = ''
      // this.current_bonus_report_stake_x = ''
    },

    async onDocumentUpload(event) {
      await this.onFilePicked(event, true)

      if (!this.current_document_type) {
        store.commit("error_enqueue", "Please refresh page and try again.")
        return null
      }

      if (store.getters.get_speedydelivery_agency_selected_caregiver === "") {
        store.commit("error_enqueue", "No caregiver is selected. Go back to the main agency SpeedyDelivery interface and select a caregiver.")
        return null
      }

      //I need to get the file from the store and to have previously saved it.

      const matches_data = new FormData();
      matches_data.append("caller", store.getters.my_name)
      matches_data.append("speedy_user", store.getters.get_speedydelivery_agency_selected_caregiver)
      matches_data.append("subscriber", store.getters.org_context)
      matches_data.append("id_token", store.getters.my_id_token)
      matches_data.append("refresh_token", store.getters.my_refresh_token)
      matches_data.append("document_type", this.current_document_type)
      matches_data.append("document", await store.dispatch('get_current_speedydelivery_document', this.current_document_type))
      // let endpoint = 'http://192.168.1.10:8087/ft/upload_match_template'
      let endpoint = be_main_address('sd') + 'upload_caregiver_document'
      console.log("Ready to make the document upload call.")


      await axios({
        method: 'post',
        url: endpoint,
        data: matches_data,

      }).then((response) => {
        console.log(response)
        if (response.data['error'] === true) {
          // console.log("Got back an error from the server...")
          // console.log(response.data)
          let message = parse_internal_json(response.data)['message']
          store.commit('error_enqueue', message)
        } else {
          console.log("Got back after sending caregiver doc to Speedy....")
          console.log(response)
          let message = parse_internal_json(response.data)['message']
          store.commit('notification_enqueue', message)
          this.current_document_type_ = ""
          reset_current_speedydelivery_upload_data_vuex()
        }

      }, (error) => {
        // console.log('Error on the match template upload.')
        // console.log(error);
      });

    },

    run_window_width_adjustments(new_w) {
      if (new_w > 100) {
        this.button_img_size_ = "80px"
        this.button_height_ = "60px"
        this.button_width_ = "250px"

      }
      if (new_w >= 280) {
        this.button_height_ = "60px"
        this.button_width_ = "250px"
      }
      if (new_w >= 400) {
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 500) {

        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }
      if (new_w > 1000) {
        // console.log(`New size is ${new_w}`)
        this.button_height_ = "60px"
        this.button_width_ = "350px"
      }

      if (new_w > 1500) {
        // console.log(`New size is ${new_w}`)
        // this.button_height = "80px"
        // this.button_width_ = "100px"
      }

    },

    simple_route_prep(r) {
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
    async check_for_general_admin_ui_perm() {
      // console.log("inside get profile image from database.")
      let search = {
        key: 'route',
        additional_context: [['div', 'o&a'], ['div', 'perms']],
        base_context: store.getters.find_current_preamble
      }
      let profile_data = await this.db.db_search(search)
      // console.log(profile_data)
      let target = "speedydelivery:agency"
      if (profile_data.includes(target)) {
        this.admins_perm_ = true
        console.log("You have the speedydelivery general admins perm")
        return true
      } else {
        this.admins_perm_ = false
        console.log("You don't have the speedydelivery general admins perm")
        return false
      }
    },
    async get_speedy_report() {
      // analytic("01:01:16:908")
      console.log("in get speedy report")
      if (store.getters.get_speedydelivery_agency_selected_caregiver === "") {
        console.log("in get speedy report...No one was selected.")
        return null
      }
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        speedy_user: store.getters.get_speedydelivery_agency_selected_caregiver,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }
      let target_url = be_main_address('sm') + 'download_speedydelivery_report'

      if (this.caregiver_has_no_docs) {
        console.log("This caregiver has no docs")
        let message = `${store.getters.get_speedydelivery_agency_selected_caregiver_pname} has no SpeedyDelivery documents.`
        store.commit('error_enqueue', message)
      }

      console.log("in get speedy report....making the call.")
      open_spinner()
      return axios.post(target_url, data1, {responseType: "blob"})
          .then((response) => {
            if (response.data['error'] === true) {
              let message = parse_internal_json(response.data)['payload']['content']['message']
              store.commit('error_enqueue', message)
              close_spinner()
            } else {
              let fileURL = window.URL.createObjectURL(new Blob([response.data]));
              let fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'SpeedyDeliveryReport' + '.pdf');
              fileLink.setAttribute('download', `SDReport_${get_date_string(null, 6)}_${store.getters.get_speedydelivery_agency_selected_caregiver}.pdf`);
              document.body.appendChild(fileLink);

              fileLink.click();
              close_spinner()
            }
          });
    },
  },
  computed: {
    ...mapGetters([
      'get_speedydelivery_agency_selected_caregiver',
      'get_speedydelivery_agency_selected_caregiver_pname',
        'get_speedydelivery_current_profile_image',
        'get_speedydelivery_no_doc_caregivers'
    ]),
    get_selected_cg(){
      return this.get_speedydelivery_agency_selected_caregiver
    },
     caregiver_has_no_docs() {
      return this.get_speedydelivery_no_doc_caregivers.includes(this.get_speedydelivery_agency_selected_caregiver)
    },
    show_sd_report_dl_button(){

      if(this.caregiver_has_no_docs){
        return false
      }else{
        return true
      }
    },
    current_cg_profile_img(){
      return this.get_speedydelivery_current_profile_image
    },
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    current_document_type() {
      return this.current_document_type_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    admins_perm() {
      return this.admins_perm_
    },
    action_button_width() {
      return this.button_width_
    },
    action_button_height() {
      return this.button_height_
    },

  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>
<style scoped>
@import '../../common.css';

@media (min-width: 100px) {
  h1 {
    color: white;
  }

  h2 {
    font-size: 26px;
    margin-bottom: 30px;
    margin-top: 20px;
    width: 280px;
  }


  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 25px;
    text-align: center
  }


  .standard_method p {
    font-size: 20px;
  }

  .mfa_method p {
    font-size: 20px;
  }


}

@media (min-width: 280px) {
  .sd_logo {
    width: 150px

  }

  .cg_name{
    margin-top: 20px;
  }

}

@media (min-width: 500px) {
}

@media (min-width: 800px) {
}

@media (min-width: 1000px) {
  h2 {
    font-size: 38px;
    width: 350px;
  }
  .sd_logo {
    width: 220px

  }
}

@media (min-width: 2000px) {
  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 32px;
  }

  h2 {
    font-size: 38px;
    width: 350px;
  }
  .sd_logo {
    width: 250px

  }

}
</style>