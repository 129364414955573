<template>
  <headers
      title="Helping Caregivers"
      meta_description="Tiny Magic Hat makes caregiving a better job for professional caregivers."
      og_description="Tiny Magic Hat helps caregivers with increased pay and more convenience with jobs closer to home."
      og_title="Tiny Magic Hat Helps Caregivers"
  ></headers>
  <div id="page_top_x"></div>
  <section id="for_cgs_main">
    <notification_popup></notification_popup>
    <outside_banner_std></outside_banner_std>
    <spinner
        :spinner_up="spinner_up"
    ></spinner>

    <section class="flex_centered_column">


      <section id="top_selector_section" class="flex_centered_column wid_100vw">
        <section class="flex_centered_column" id="tmh_cg_features_intro">
          <Transition name="entry_fade_in">
            <h1 v-if="show_fade">You help others. Let us help you.</h1>
          </Transition>
          <p>Tiny Magic Hat's features are designed to remove the hard parts from getting a job at a home care
            agency.</p>
        </section>
        <div class="flex_centered_row flex_wrap feature_cards_coll">
          <feature_card2
              class="cursor_ptr"
              bg_color="white"
              icon="icon_good_docs_white.svg"
              desc="SpeedyDelivery-docs-icon"
              :icon_color="colors.white"
              :icon_bg_color="colors.cyanic_blue"
              :icon_width="40"
              arrow_size="40"
              :arrow="true"
              text_logo="popdocs_text_only.svg"
              :active="feature_card_picked==='speedydelivery'"
              text_logo_width="150"
              @main_btn_clicked="activate_section_vis('speedydelivery')"
          >
            <template v-slot:body>
              <p>Easily complete your caregiver training before orientation, all from home.</p>
            </template>
          </feature_card2>

          <feature_card2
              class="cursor_ptr"
              bg_color="white"
              icon="icon_group_white.svg"
              desc="StarFinder icon"
              :icon_color="colors.white"
              :icon_bg_color="colors.primary_purple"
              :icon_width="40"
              arrow_size="40"
              :arrow="true"
              text_logo="starfinder_text_only.svg"
              :active="feature_card_picked==='spyglass'"
              text_logo_width="150"
              @main_btn_clicked="activate_section_vis('spyglass')"
          >
            <template v-slot:body>
              <p>Find a nearby client whose care needs match your busy schedule.</p>
            </template>
          </feature_card2>

          <feature_card2
              class="cursor_ptr"
              bg_color="white"
              icon="icon_cash_white.svg"
              desc="WonderPerks-icon"
              :icon_color="colors.white"
              :icon_bg_color="colors.new_gold"
              :icon_width="40"
              arrow_size="40"
              :arrow="true"
              text_logo="wonder_perks_text_only.svg"
              :active="feature_card_picked==='freetime'"
              text_logo_width="150"
              @main_btn_clicked="activate_section_vis('freetime')"
          >
            <template v-slot:body>
              <p>Dramatically boost how much you take home as a full-time caregiver.</p>
            </template>
          </feature_card2>
        </div>

      </section>


      <selection_indicator
          v-if="!mobile_mode"
          class="sel_indicator"
          :pick="box_picked"
          @card_picked="set_feature_card_picked"
      ></selection_indicator>


      <div class="feature_page_main">


        <section class="flex_centered_column ">
          <div id="marker"></div>

          <!--          <div id="sd_marker"></div>-->
          <Transition name="entry_fade_in">


            <div v-show="active_section_speedydelivery" class="flex_centered_column"
                 id="for_cgs_content_speedydelivery">


              <div id="speedydelivery_top_section" class="flex_centered_column">
                <div id="sd_marker"></div>
                <img class=" padding_b_20" id="speedydelivery_content_logo"
                     src="@/assets/popdocs.svg"
                     alt="PopDocs Logo" @click="activate_section_vis('speedydelivery')">
                <div class="feature_heading_text">
                  <h2 class="">Share your information</h2>
                </div>
                <p>Getting pre-qualified as a caregiver before you contact an agency is very smart.</p></div>


              <div class="flex_centered_row all_check_boxes flex_wrap">


                <div class="flex_space_between_row check_box_group">
                  <img class="check_box_icon"
                       src="@/assets/check-mark.svg"
                       alt="check box image">
                  <div class="flex_left_aligned_column check_box_set">
                    <h3>Smart move</h3>
                    <p>There's always paperwork involved in starting a new position. Make sure you've completed yours,
                      easily from home, ahead of time.</p>
                  </div>
                </div>

                <div class="flex_space_between_row check_box_group">
                  <img class="check_box_icon"
                       src="@/assets/check-mark.svg"
                       alt="check box image">
                  <div class="flex_left_aligned_column check_box_set">
                    <h3>Acquire new skills</h3>
                    <p>Educational modules are emailed to you as a pdf. Study when its convenient.</p>
                  </div>
                </div>

                <div class="flex_space_between_row check_box_group">
                  <img class="check_box_icon"
                       src="@/assets/check-mark.svg"
                       alt="check box image">
                  <div class="flex_left_aligned_column check_box_set ">
                    <h3>Prove you have what it takes</h3>
                    <p>Complete the included multiple-choice test and mail in your results. We'll upload it to the
                      network</p>
                  </div>
                </div>

                <div class="flex_space_between_row check_box_group">
                  <img class="check_box_icon"
                       src="@/assets/check-mark.svg"
                       alt="check box image">
                  <div class="flex_left_aligned_column check_box_set">
                    <h3>Agencies receive your qualifications</h3>
                    <p>Agencies can quickly and easily access your test results when you give them them the OK.</p>
                  </div>
                </div>
              </div>


              <img class="cursor_ptr video_thumbnail"
                   src="@/assets/speedydelivery_play_comp-min.jpg"
                   alt="SpeedyDelivery Video for caregivers thumbnail"
                   @click="speedy_delivery_video_make_true">


              <video_modal
                  :show_modal="speedydelivery_vid_up"
                  :video_id="videos.speedydelivery.video_id"
                  :base_height="videos.base_video_height"
                  :base_width="videos.base_video_width"
                  @bg_clicked="videos.speedydelivery.modal_up = false"
              ></video_modal>


              <div class="flex_centered_row wid_95" id="speedydelivery_wrapper">
                <section class="flex_centered_column" id="cg_conclusion_speedydelivery">
                  <h2>So how can you pre-qualify?</h2>
                  <div>
                    <p>After you create an account and log in, submit this form and we'll email your materials.</p>


                    <div class="flex_centered_row">
                      <div class="flex_centered_column">
                        <!--                        <p class="margin_auto"><a href="/register">I need to register an account first >></a></p>-->
                        <basic_text_button
                            class="padding_lr_6 padding_tb_6 go_to_register_button"
                            :hover_color=colors.cyanic_blue
                            :hover_text_color=colors.white
                            :button_state="true"
                            :button_color="colors.new_orange_main"
                            :text_color=colors.white
                            :button_color_not_ready="colors.eli_gray"
                            :button_not_ready_text_color="colors.pennie_gray"
                            button_height="52px"
                            button_width="150px"
                            border_rad_pct="0"
                            text="SIGN UP"
                            text_size="14px"
                            :bold="true"
                            event_name="learn_more_button_clicked"
                            @learn_more_button_clicked="go_to_register"
                        ></basic_text_button>
                      </div>
                    </div>


                    <!--                    <input_with_label-->
                    <!--                        label="State"-->
                    <!--                    ></input_with_label>-->

                    <dropdown_datalist
                        ref="sd_state"
                        label="State"
                        :data_list=states
                        @value-selected="state_selected"
                    ></dropdown_datalist>

                    <dropdown_datalist
                        ref="sd_county"
                        label="County"
                        :data_list=counties
                        @value-selected="county_selected"
                    ></dropdown_datalist>

                    <input_with_label
                        ref="sd_email"
                        label="Email"
                        @emit_state="sd_email_request_entered"
                    ></input_with_label>


                    <basic_text_button
                        class="margin_t_10"
                        :button_state="true"
                        :button_color="colors.cyanic_blue"
                        :text_color="colors.white"
                        :button_color_not_ready="colors.gray"
                        :button_not_ready_text_color="colors.morgie_gray"
                        :hover_color="colors.primary_purple"
                        :hover_text_color="colors.white"
                        button_height="52px"
                        button_width="150px"
                        border_rad_pct="0"
                        text="GET STARTED"
                        button_not_ready_text="Retire Ad"
                        text_size="14px"
                        :bold="true"
                        event_name="speedydelivery_materials_request"
                        @speedydelivery_materials_request="speedydelivery_materials_request"
                    ></basic_text_button>
                  </div>
                </section>
              </div>
            </div>
          </Transition>


          <!--          <div id="sg_marker"></div>-->
          <Transition name="entry_fade_in">
            <div v-show="active_section_spyglass" class="flex_centered_column" id="for_cgs_content_spyglass">
              <!--              <div id="">-->
              <Transition>
                <img class=" padding_tb_20" id="spyglass_content_logo"
                     src="@/assets/starfinder.svg"
                     alt="StarFinder Logo">
              </Transition>
              <div class="feature_heading_text"><h2 class="">Spot Opportunity</h2></div>

              <div class="flex_centered_row flex_wrap">
                <div id="sg_marker"></div>
                <div class="flex_centered_row">
                  <div class="flex_centered_start_column spyglass_cards_header">
                    <!--                    <div id="sg_marker"></div>-->
                    <h2 v-if="spyglass_full_desktop">Find your perfect client</h2>
                    <p class="text_primary_purple_deep font_weight_800">StarFinder lets you see what schedules are
                      available in your area now.</p>
                  </div>

                </div>


                <div class="flex_centered_row flex_wrap spyglass_cards_coll">

                  <feature_card4
                      class=""
                      bg_color="transparent"
                      icon="icon_browse_schedules_white.svg"
                      desc="Browse schedules-icon"
                      :icon_color="colors.white"
                      :icon_bg_color="colors.primary_purple"
                      @main_btn_clicked="do_nothing"
                  >
                    <template v-slot:body>
                      <h3>Browse Schedules</h3>
                      <p>StarFinder shows you what schedules local agencies are trying to fill.</p>
                    </template>
                  </feature_card4>


                  <feature_card4
                      class=""
                      bg_color="transparent"
                      icon="icon_person_pin_white.svg"
                      desc="Browse schedules-icon"
                      :icon_color="colors.white"
                      :icon_bg_color="colors.new_gold"
                      @main_btn_clicked="do_nothing"
                  >
                    <template v-slot:body>
                      <h3>Find a Nearby Client</h3>
                      <p>You can see the client's neighborhood so you know if the client is close
                        to your home.</p>
                    </template>
                  </feature_card4>


                  <feature_card4
                      class=""
                      bg_color="transparent"
                      icon="icon_days_and_times_white.svg"
                      desc="Days and times icon"
                      :icon_color="colors.white"
                      :icon_bg_color="colors.new_orange_main"
                      @main_btn_clicked="do_nothing"
                  >
                    <template v-slot:body>
                      <h3>Days and Time Included</h3>
                      <p>StarFinder also shows you the days and times the client is requesting service, so you know
                        if its something you can fit in your schedule.</p>
                    </template>
                  </feature_card4>


                  <feature_card4
                      class=""
                      bg_color="transparent"
                      icon="icon_happy_client_white.svg"
                      desc="Perfect client icon"
                      :icon_color="colors.white"
                      :icon_bg_color="colors.cyanic_blue"
                      @main_btn_clicked="do_nothing"
                  >
                    <template v-slot:body>
                      <h3>Claim your Perfect Client</h3>
                      <p>If everything looks good, contact the agency with just a few clicks, without ever leaving
                        home.</p>
                    </template>
                  </feature_card4>


                </div>
              </div>


              <section class="flex_centered_row flex_wrap">
                <div>
                  <img class="cursor_ptr video_thumbnail"
                       src="@/assets/spyglass_play_comp-min.jpg"
                       alt="StarFinder Video for caregivers thumbnail" @click="spyglass_video_make_true">


                  <video_modal
                      :show_modal="spyglass_vid_up"
                      :video_id="videos.spyglass.video_id"
                      :base_height="videos.base_video_height"
                      :base_width="videos.base_video_width"
                      @bg_clicked="videos.spyglass.modal_up = false"
                  ></video_modal>
                </div>
                <div class="" id=ok_show_me_col>
                  <h3>Okay, show me the schedules.</h3>
                  <basic_text_button
                      class="padding_lr_6 padding_tb_6 go_to_schs_button"
                      :hover_color=colors.new_orange_main
                      :hover_text_color=colors.white
                      :button_state="true"
                      :button_color="colors.primary_purple"
                      :text_color=colors.white
                      :button_color_not_ready="colors.eli_gray"
                      :button_not_ready_text_color="colors.pennie_gray"
                      button_height="52px"
                      button_width="150px"
                      border_rad_pct="0"
                      text="Schedules"
                      text_size="17px"
                      :bold="true"
                      event_name="learn_more_button_clicked"
                      @learn_more_button_clicked="go_to_schedules"
                  ></basic_text_button>
                </div>
              </section>

            </div>
          </Transition>


          <div id="ft_marker"></div>
          <Transition name="entry_fade_in">
            <div v-show="active_section_freetime" class="flex_centered_column" id="for_cgs_content_freetime">
              <!--               <div id="ft_marker"></div>-->
              <img class=" " id="freetime_content_logo"
                   src="@/assets/wonder_perks.svg"
                   alt="WonderPerks Logo">
              <!--              <div id="ft_marker"></div>-->
              <div class="feature_heading_text"><h2 class="">Increase your pay</h2></div>
              <div id="top_side_content_freetime">
              </div>


              <div class="flex_space_between_row" id="freetime_content_part1">
                <div class="flex_centered_column" id="freetime_content_part1_A">
                  <div class="flex_centered_column">
                    <!--                     <div id="ft_marker"></div>-->
                    <h3>Give Yourself a Raise</h3>
                    <div class="">
                      <p> Caregivers working <strong>more than 32 hours per week </strong>can dramatically increase
                        their effective
                        hourly rate.</p>
                    </div>
                  </div>
                  <div class="flex_centered_column">
                    <h3>How Dramatically?</h3>
                    <p> WonderPerks bonuses for a full-time (38 hour) caregivers
                      can range from about $150 to $250 per week.</p>

                    <basic_text_button
                        class="padding_lr_6 padding_tb_6 go_to_freetime_calcs_proof"
                        :hover_color=colors.cyanic_blue
                        :hover_text_color=colors.white
                        :button_state="true"
                        :button_color="colors.primary_purple"
                        :text_color=colors.white
                        :button_color_not_ready="colors.eli_gray"
                        :button_not_ready_text_color="colors.pennie_gray"
                        button_height="52px"
                        button_width="150px"
                        border_rad_pct="0"
                        text="Show Me"
                        text_size="17px"
                        :bold="true"
                        event_name="learn_more_button_clicked"
                        @learn_more_button_clicked="show_freetime_calcs_toggle"
                    ></basic_text_button>

                    <freetime_calcs
                        :show_modal="show_freetime_calcs"
                        @bg_clicked="show_freetime_calcs_toggle"
                    ></freetime_calcs>


                  </div>
                </div>
                <div class="flex_centered_column" id="freetime_content_part1_B">
                  <img class="cursor_ptr video_thumbnail" id="ft_video_thumbnail"
                       src="@/assets/freetime_thumbnail_play_comp-min.jpg"
                       alt="WonderPerks Video for caregivers thumbnail" @click="freetime_video_make_true">

                  <video_modal
                      :show_modal="freetime_vid_up"
                      :video_id="videos.freetime.video_id"
                      :base_height="videos.base_video_height"
                      :base_width="videos.base_video_width"
                      @bg_clicked="videos.freetime.modal_up = false"
                  ></video_modal>
                </div>
              </div>


              <carousel_container
                  class="carousel"
                  :my_slides="get_carousel_quotes()"
                  :body_color="colors.cyanic_blue"
              ></carousel_container>


              <section class="flex_left_aligned_column" id="cg_conclusion_freetime">

                <div class="flex_centered_row flex_wrap" id="freetime_content_part2">
                  <div class="flex_start_column" id="freetime_content_part2_A">
                    <div class="flex_centered_column">
                      <div id="get_your_bonus" class="font_akshar font_weight_800">So how can you get a WonderPerks
                        bonus?
                      </div>
                    </div>
                  </div>
                  <div class="flex_centered_row flex_wrap" id="freetime_content_part2_B">

                    <feature_card4
                        class=""
                        bg_color="transparent"
                         icon="icon_network_white.svg"
                         desc="Network icon"
                        :icon_color="colors.white"
                        :icon_bg_color="colors.cyanic_blue"
                        @main_btn_clicked="do_nothing"
                    >
                      <template v-slot:body>
                        <h3>Tiny Magic Hat's Network</h3>
                        <p>You need to work with an agency that's in Tiny Magic Hat's network. Bonuses begin when you
                          have
                          worked 32 hours in a week and increase quickly until the maximum bonus is earned at 38
                          hours.</p>
                      </template>
                    </feature_card4>
                    <feature_card4
                        class=""
                        bg_color="transparent"
                         icon="icon_money_white.svg"
                         desc="Bonus icon"
                        :icon_color="colors.white"
                        :icon_bg_color="colors.new_gold"
                        @main_btn_clicked="do_nothing"
                    >
                      <template v-slot:body>
                        <h3>Bonus Payment</h3>
                        <p>Money that you earn through WonderPerks appears on your paycheck as a bonus.</p>
                      </template>


                    </feature_card4>
                    <feature_card4
                        class=""
                        bg_color="transparent"
                        icon="icon_over_time_white.svg"
                         desc="Overtime icon"
                        :icon_color="colors.white"
                        :icon_bg_color="colors.new_orange_main"
                        @main_btn_clicked="do_nothing"
                    >
                      <template v-slot:body>
                        <h3>Still Earn Overtime</h3>
                        <p>WonderPerks does not affect standard over-time in any way.</p>
                      </template>


                    </feature_card4>
                    <feature_card4
                        class=""
                        bg_color="transparent"
                         icon=icon_browse_schedules_white.svg
                         desc="Search schedules icon"
                        :icon_color="colors.white"
                        :icon_bg_color="colors.primary_purple"
                        @main_btn_clicked="do_nothing"
                    >
                      <template v-slot:body>
                        <h3>Search Schedules</h3>
                        <p>Click here to search convenient schedules currently available from in-network agencies.</p>
                        <div id="freetime_schs_button">
                          <basic_text_button
                              class="padding_lr_6 padding_tb_6  "
                              :hover_color=colors.cyanic_blue
                              :hover_text_color=colors.white
                              :button_state="true"
                              :button_color="colors.new_orange_main"
                              :text_color=colors.white
                              :button_color_not_ready="colors.eli_gray"
                              :button_not_ready_text_color="colors.pennie_gray"
                              button_height="52px"
                              button_width="150px"
                              border_rad_pct="0"
                              text="Schedules"
                              text_size="17px"
                              :bold="true"
                              event_name="learn_more_button_clicked"
                              @learn_more_button_clicked="go_to_schedules"
                          ></basic_text_button>
                        </div>
                      </template>


                    </feature_card4>


                  </div>

                </div>


              </section>


            </div>
          </Transition>

        </section>

        <div class="flex_centered_row">
          <div class="flex_centered_column text_primary_purple padding_tb_30 font_raleway font_weight_800 cursor_ptr"
               @click="scroll_to_top">
            TOP
          </div>
        </div>

      </div>


      <outside_footer
          @link_clicked="hamburger_menu_false()">
      </outside_footer>
      <error_popup_queue></error_popup_queue>
    </section>


  </section>
</template>

<script>
/* eslint-disable */
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import notification_popup from "@/components/basics/notification_popup";
import error_popup_queue from "@/components/basics/error_popup_queue";
import outside_bounce_box from "@/components/parts/outside_bounce_box";
import video_modal from "@/components/parts/video_modal";
import home_card from "@/components/parts/home_card";
import label_with_input_text from "@/components/parts/label_with_input_text";
import input_with_label from "@/components/parts/input_with_label";
import basic_text_button from "@/components/parts/basic_text_button";
import selection_indicator from "@/components/parts/selection_indicator";
import freetime_calcs from "@/components/parts/freetime_calcs";
import carousel_container from "@/components/parts/my_carousel/carousel_container";
import feature_card2 from "@/components/parts/feature_card2";
import feature_card4 from "@/components/parts/feature_card4";
import dropdown_datalist from "@/components/parts/dropdown_datalist";
import spinner from "@/components/parts/spinner";
import headers from "@/components/parts/headers";


// import carousel from "@/components/parts/carousel/Carousel";
import {
  get_counties,
  states,
  validate_email,
  analytic,
  dual_route,
  good_token_now,
  num_is_between_inclusive,
  reentry_route,
  scroll_to_element,
  add_class_to_body,
  remove_class_to_body,
  colors,
  scroll_to_top,
  be_main_address,
  parse_internal_json,
  get_speedydelivery_materials,
  timestamp, inner_route,

} from "@/library";
import store from "@/store";
import axios from "axios";

export default {
  name: "for_caregivers",

  async mounted() {


    this.run_window_width_adjustments(this.windowWidth)
    window.prerenderReady = true
    const observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        console.log("Observation test")
        // this.show_spyglass_logo_ = true
      }
    }, {
      // root: document.getElementById('for_cgs_content_spyglass'),
      // threshold: 1.0
    });

    this.set_feature_card_picked(store.getters.get_for_caregivers_active_section)

    const elementToWatch = document.getElementById('spyglass_content_logo')
    observer.observe(elementToWatch)

    this.run_window_width_adjustments(this.windowWidth)
    // scroll_to_element("page_top_x")
    this.toggle_fade()
  },

  async unmounted() {
    // let duration = await store.dispatch('log_mount_time', {
    //   'url': localStorage.getItem('dev_latest_route'),
    //   'mount_time': undefined,
    //   'unmount_time': timestamp(true),
    // })
    //  if(duration){
    //  analytic(`${localStorage.getItem('dev_latest_route')}::unmounted|duration:${duration}`)
    // }

  },
  async deactivated() {
    console.log("Running deactivated")
  },
  data() {
    return {
      colors: colors,
      spyglass_full_desktop_break: 1528,
      speedydelivery_email_request: "",
      states: states,
      a_county: "",
      a_state: "",
      show_freetime_calcs_: false,
      show_fade_: false,
      show_spyglass_logo_: false,
      show_hamburger_menu_: false,
      mobile_mode_x: true,
      desktop_cutoff: 800,
      agency_color: colors.new_cyanic_main,
      feature_card_colors: {
        picked: colors.cyanic_background,
        unpicked: colors.cyanic_background,
        speedydelivery: colors.cyanic_background,
        spyglass: colors.cyanic_background,
        freetime: colors.cyanic_background,
      },
      feature_card_logo_width: {
        speedydelivery: "150px",
        spyglass: "150px",
        freetime: "150px",
      },
      feature_card_picked: "",
      videos: {
        base_video_height: 180,
        base_video_width: 320,
        freetime: {
          video_id: "755300990",
          modal_up: false
        },
        spyglass: {
          video_id: "755298908",
          modal_up: false
        },
        speedydelivery: {
          video_id: "755297326",
          modal_up: false
        },
      },

    }
  },
  components: {
    outside_footer,
    notification_popup,
    error_popup_queue,
    outside_bounce_box,
    video_modal,
    home_card,
    // feature_card1,
    label_with_input_text,
    input_with_label,
    basic_text_button,
    selection_indicator,
    // carousel,
    carousel_container,
    freetime_calcs,
    feature_card2,
    feature_card4,
    dropdown_datalist,
    outside_banner_std,
    spinner,
    headers
  },
  methods: {
    get_carousel_quotes() {
      // return  store.commit('get_carousel_quotes', 'for_agencies')
      return store.getters.get_for_caregivers_carousel_quotes
    },
    async speedy_delivery_video_make_true() {
      analytic(`SpeedyDelivery|So how can you prequalify?::video modal opened:CTA clicked`)
      this.videos.speedydelivery.modal_up = true
    },
    async freetime_video_make_true() {
      analytic(`WonderPerks|Caregiver page video::video modal opened:CTA clicked`)
      this.videos.freetime.modal_up = true
    },
    async spyglass_video_make_true() {
      analytic(`StarFinder|OK, show me the schedules::video modal opened:CTA clicked`)
      this.videos.spyglass.modal_up = true
    },
    state_selected(the_state) {
      console.log(`${the_state} was selected as the state.`)
      this.a_state = the_state
    },
    county_selected(the_county) {
      console.log(`${the_county} was selected as the county.`)
      this.a_county = the_county
    },
    sd_email_request_entered(email) {
      console.log("caught email")
      console.log(email)
      if (validate_email(email)) {
        console.log("email is valid")
        this.speedydelivery_email_request = email
      } else if (email !== "") {
        console.log("in the else if")
        this.speedydelivery_email_request = ""
        store.commit('error_enqueue', `${email} does not appear to be a valid email address.`)

      } else {
        console.log("in the else")
        //pass
      }
    },
    show_freetime_calcs_toggle() {
      this.show_freetime_calcs_ = !this.show_freetime_calcs_
    },
    async speedydelivery_materials_request() {

      console.log(this.speedydelivery_email_request)
      console.log(this.a_state)
      console.log(this.a_county)

      if (!(this.speedydelivery_email_request && this.a_state && this.a_county)) {
        let m = "You'll need to complete the form to get started with SpeeedyDelivery."
        store.commit('notification_enqueue', m)
        return null
      }
      localStorage.setItem("sd_materials_req_email", this.speedydelivery_email_request.substring(0, 100).toLowerCase())
      localStorage.setItem("sd_materials_req_state", this.a_state.substring(0, 50))
      localStorage.setItem("sd_materials_req_county", this.a_county.substring(0, 50))

      if (!good_token_now()) {
        let m = "You need a free user account to use SpeedyDelivery. Click the sign up button " +
            "to go to the registration page."
        store.commit('notification_enqueue', m)
        return null
      }


      await get_speedydelivery_materials()
      let message = "Thank you. Your materials should be on the way within a few minutes. Check your inbox."
      store.commit('notification_enqueue', message)

      this.speedydelivery_email_request = ""
      this.a_state = ""
      this.a_county = ""
      this.$refs.sd_state.reset_state()
      this.$refs.sd_county.reset_state()
      this.$refs.sd_email.reset_state()

    },
    set_feature_card_picked(s) {
      console.log(`Setting feature card to ${s}`)
      this.feature_card_picked = s
      this.activate_section_vis(s)
    },
    scroll_to_top() {
      scroll_to_top()
    },
    do_nothing() {
      console.log("nothing")
    },
    play_video(name) {
      if (name === 'freetime') {
        this.videos.freetime.modal_up = true
      } else if (name === 'spyglass') {
        this.videos.spyglass.modal_up = true
      } else {
        this.videos.speedydelivery.modal_up = true
      }
    },

    set_feature_colors(name) {
      // console.log("setting the feature colors")
      this.feature_card_colors.speedydelivery = this.feature_card_colors.unpicked
      this.feature_card_colors.spyglass = this.feature_card_colors.unpicked
      this.feature_card_colors.freetime = this.feature_card_colors.unpicked
      if (name === 'freetime') {
        // console.log("changing freetimes colors")
        // console.log(this.feature_card_colors.freetime)
        this.feature_card_colors.freetime = this.feature_card_colors.picked
        // console.log(this.feature_card_colors.freetime)
      } else if (name === 'spyglass') {
        this.feature_card_colors.spyglass = this.feature_card_colors.picked

      } else {
        this.feature_card_colors.speedydelivery = this.feature_card_colors.picked
      }
    },
    get_marker(m) {
      if (m === 'freetime') {
        return 'ft_marker'
      } else if (m === 'spyglass') {
        return 'sg_marker'
      } else {
        return 'sd_marker'
      }
    },

    activate_section_vis(section) {
      console.log(`${section} selection was clicked.`)
      this.set_feature_colors(section)
      this.feature_card_picked = section
      store.commit('activate_section_for_caregivers', section)
      if (this.mobile_mode) {
        console.log("Yep, we're in mobile mode.......................................")
        let marker = this.get_marker(section)
        scroll_to_element(marker, 0)
      }
    },

    toggle_fade() {
      this.show_fade_ = !this.show_fade_
    },
    //  toggle_siz() {
    //   this.show_fade_ = !this.show_fade_
    // },
    dual_route_wrapper(route, inner = null) {
      console.log(`Inside dual route wropper with innor of ${innen}`)
      this.hamburger_menu_false()
      let current = localStorage.getItem('dev_latest_route')
      current = current.substring(1)
      if (current === route) {
        scroll_to_element(inner)
        return null
      }
      dual_route(this.$router, route, inner)
    },
    async go_to_schedules() {
      analytic(`Okay, show me the schedules::CTA clicked`)
      // this.hamburger_menu_false()
      await this.$router.push({path: 'spyglass'})
    },
    async go_to_register() {
      // this.hamburger_menu_false()
      await this.$router.push({path: 'sms_opt_in'})
    },
    async log_out_user() {
      this.hamburger_menu_false()
      store.commit("clear_local_storage")
      store.commit('create_initial_defaults')
      indexedDB.deleteDatabase('database');
      await store.dispatch('notification_restart')
      location.reload()
      analytic("01:01:01:01")
    },
    hamburger_menu_false() {
      this.show_hamburger_menu_ = false
    },
    hamburger_menu_toggle() {
      this.show_hamburger_menu_ = !this.show_hamburger_menu_
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }

      if (num_is_between_inclusive(100, 300, new_w)) {
        this.feature_card_logo_width.freetime = "125px"
        this.feature_card_logo_width.spyglass = "125px"
        this.feature_card_logo_width.speedydelivery = "125px"
      }
      if (num_is_between_inclusive(301, 500, new_w)) {
        this.feature_card_logo_width.freetime = "150px"
        this.feature_card_logo_width.spyglass = "150px"
        this.feature_card_logo_width.speedydelivery = "150px"
      }
      if (num_is_between_inclusive(501, 5000, new_w)) {
        this.feature_card_logo_width.freetime = "210px"
        this.feature_card_logo_width.spyglass = "210px"
        this.feature_card_logo_width.speedydelivery = "210px"
      }


    },
  },
  computed: {

    // states(){
    //   return this.states_
    // },
    spinner_up() {
      return store.getters.get_spinner_state
    },

    counties() {
      if (this.a_state !== "") {
        return get_counties(this.a_state)
      } else {
        return []
      }
    },
    spyglass_full_desktop() {
      return this.windowWidth >= this.spyglass_full_desktop_break
    },
    show_freetime_calcs() {
      return this.show_freetime_calcs_
    },
    box_picked() {
      let picked = this.feature_card_picked
      if (picked === 'speedydelivery') {
        return 1
      } else if (picked === 'spyglass') {
        return 2
      } else {
        return 3
      }
    },

    // sd_width() {
    //   return this.feature_card_logo_width.speedydelivery
    // },
    // sg_width() {
    //   return this.feature_card_logo_width.spyglass
    // },
    // ft_width() {
    //   return this.feature_card_logo_width.freetime
    // },
    // sd_color() {
    //   return colors.cyanic_background
    // },
    // sg_color() {
    //   return colors.cyanic_background
    // },
    // ft_color() {
    //   return colors.cyanic_background
    // },
    show_fade() {
      return this.show_fade_
    },

    freetime_vid_up() {
      return this.videos.freetime.modal_up
    },
    spyglass_vid_up() {
      return this.videos.spyglass.modal_up
    },
    speedydelivery_vid_up() {
      return this.videos.speedydelivery.modal_up
    },


    active_section() {
      return store.getters.get_for_caregivers_active_section
    },

    active_section_spyglass() {
      let target = 'spyglass'
      if (this.mobile_mode) {
        return true
      } else {
        return store.getters.get_for_caregivers_active_section === target;
      }
    },
    active_section_speedydelivery() {
      let target = 'speedydelivery'
      if (this.mobile_mode) {
        return true
      } else {
        return store.getters.get_for_caregivers_active_section === target;
      }
    },
    active_section_freetime() {
      let target = 'freetime'
      if (this.mobile_mode) {
        return true
      } else {
        return store.getters.get_for_caregivers_active_section === target;
      }
    },


    // show_spyglass_logo() {
    //   return this.show_spyglass_logo_
    // },
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    reentry_route() {
      return reentry_route()
    },
    good_token() {
      return good_token_now()
    },
    mobile_mode() {
      return this.mobile_mode_x
    },

  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>

<style scoped>

h2 {
  color: var(--new-primary-purple-deep);
}

#feature-buttons_set img {
  transition: scale 1s ease-out;
}

#feature-buttons_set img:hover {
  transform: scale(1.25);
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);*/
}


.entry_fade_in-enter-active {
  transition: opacity 3s ease;
}

.entry_fade_in-leave-active {
  transition: opacity 0s ease;
}

.entry_fade_in-enter-from,
.entry_fade_in-leave-to {
  opacity: 0;
}


.watch_vid_link {
  margin-top: 30px;
}

#for_cgs_main {
  width: 100vw;
}

@media (min-width: 100px) {

  #freetime_schs_button {
    margin-top: 40px;
  }


  #freetime_content_part2_B {
    margin-top: 25px;
    align-items: start;
  }


  #freetime_content_part2_B p {
    max-width: 375px;
    font-size: 15px;
  }

  #freetime_content_part2_B h3 {
    font-size: 22px;
  }


  #get_your_bonus {
    font-size: 25px;
    color: var(--new-primary-purple-deep);
    text-align: center;
    margin-top: 32px;
    max-width: 300px;
  }

  .freetime_feature_cards_second {
    /*margin-top: -30px;*/
  }

  .freetime_feature_cards_third {
    margin-top: 0;
  }


  .freetime_feature_cards_fourth {
    /*margin-top: -30px;*/
  }

  .go_to_freetime_calcs_proof {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  #freetime_content_part1 {
    /*width: 260px;*/
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  #freetime_content_part1 h3 {
    font-size: 22px;
    margin-top: 30px;
  }


  #freetime_content_part2_A h3 {
    /*font-size: 26px;*/
    width: 286px;
    margin-top: 40px;
  }

  .go_to_register_button {
    margin-bottom: 30px;
    margin-top: 20px;
  }

  #ok_show_me {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /*height: 200px;*/
    max-width: 282px;
  }

  #ok_show_me div {
    margin-left: 20px;
    margin-right: 20px;
  }

  #ok_show_me_col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    /*margin-bottom: 40px;*/
  }

  #ok_show_me_col h3 {
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
  }


  .spyglass_cards_header {
    /*margin-top: 74px;*/
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .spyglass_cards_header h2 {
    max-width: 235px;
    /*font-size: 20px;*/
  }

  .spyglass_cards_coll {
    margin-bottom: 40px;
  }

  .spyglass_cards_coll h3 {
    font-size: 22px;
    margin-bottom: 5px;
    box-sizing: border-box;
  }

  .spyglass_cards_coll p {
    text-align: left;
  }

  /*.spyglass_cards_header h3{*/
  /*   max-width: 235px;*/
  /*  font-size: 18px;*/
  /*}*/
  .spyglass_cards_header p {
    width: 68%;
    /*text-align: left;*/
  }

  #speedydelivery_top_section {
    margin-top: 20px;
  }

  .feature_cards_coll p {
    font-size: 13px;
    text-align: left;
  }

  .feature_cards_coll {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  #cg_conclusion_speedydelivery div {
    padding: 5px;
  }


  .all_check_boxes {
    max-width: 1900px;
    margin-bottom: 40px;
    padding-left: 10px;
  }

  .check_box_group {
    margin-bottom: 5px;
    max-width: 523px;
    align-items: start;
  }

  .check_box_icon {
    width: 42px;
    margin-right: 10px;
  }

  .check_box_set h3 {
    font-size: 22px;
  }


  p {
    max-width: 95vw;
    /*padding-left: 20px;*/
    /*padding-right: 10px;*/
  }

  .feature_page_main {
    white-space: normal;
    word-break: break-word;
    width: 100vw;
  }

  h2 {
    font-size: 25px;
    text-align: center;
    padding-bottom: 20px;
  }

  .feature_heading_text {
    text-align: center;
    display: flex;
    justify-content: center;

  }

  #top_selector_section {
    background-color: var(--header-text);
    /*background: linear-gradient(var(--header-text) 20%, #00ffff 80%, var(--header-text));*/
    /*background-color: var(--cyanic_background);*/
    /*padding-bottom: 50px;*/
  }

  #tmh_cg_features_intro h1 {
    color: var(--new-primary-purple-deep);
    max-width: 250px;
  }


  .video_thumbnail {
    max-width: 99%;
    height: auto;
    width: 95vw;
  }

  #for_cgs_main {
    box-sizing: border-box;
  }


  #spyglass_content_logo {
    width: 51%;
    max-width: 250px;
    margin-bottom: 0;
    /*margin-top: 40px;*/
  }

  #speedydelivery_content_logo {
    /*width: 45%;*/
    /*max-width: 200px;*/
    width: 235px;
  }

  #freetime_content_logo {
    width: 45%;
    max-width: 200px;
    padding-bottom: 20px;
  }


  #feature-buttons_set {
    margin-top: 50px;
  }


  #tmh_cg_features_intro {
    max-width: 800px;
    margin-top: 60px;
  }

  #tmh_cg_features_intro h1 {
    text-align: center;
    font-size: 30px;
    /*margin-bottom: 30px;*/
    /* max-width: 800px;*/
  }

  #tmh_cg_features_intro p {
    margin-top: 20px;
    font-size: 15px;
    padding-left: 20px;
    padding-right: 20px;
  }


  #top_side_content_freetime {
    /*width: 100vw;*/
  }

  #for_cgs_content_freetime {
    padding-top: 80px;
  }

  #for_cgs_content_freetime h1 {
    font-size: 25px;
    /*width: 280px;*/
    max-width: 350px;
    text-align: center;
    padding: 10px 10px 20px 10px;
    box-sizing: border-box;
  }

  #for_cgs_content_freetime h2 {
    width: 100%;
    text-align: center;
  }

  #freetime_content_part1_A {
    width: 260px;

  }

  #freetime_content_part1_A p {
    margin-bottom: 20px;
    font-size: 15px;
    text-align: left;
  }

  #freetime_content_part1_A h3 {
    margin-bottom: 20px;
    font-size: 26px;
    text-align: center;
  }

  /*#for_cgs_content_freetime p {*/
  /*  margin-bottom: 20px;*/
  /*  font-size: 15px;*/
  /*  text-align: left;*/
  /*}*/
  #for_cgs_content_spyglass {
    box-sizing: border-box;
    /*max-width: 60%;*/
    /*padding: 20px;*/
    background-color: var(--cyanic_background);
    width: 100vw;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #for_cgs_content_spyglass h1 {
    font-size: 25px;
    /*width: 280px;*/
    max-width: 350px;
    text-align: center;
    padding: 10px 10px 20px 10px;
    box-sizing: border-box;
  }

  #for_cgs_content_spyglass h2 {
    width: 100%;
    text-align: center;
  }

  #for_cgs_content_spyglass p {
    /*max-width: 60%;*/
    margin-bottom: 20px;
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
    font-size: 15px;
  }

  #spyglass_content_logo img {
    width: 250px;
  }


  #for_cgs_content_speedydelivery {
    /*max-width: 60%;*/
    /*padding: 20px;*/
    padding-bottom: 80px;
    padding-top: 80px;
  }

  #for_cgs_content_speedydelivery h1 {
    font-size: 25px;
    /*width: 280px;*/
    max-width: 350px;
    text-align: center;
    padding: 10px 10px 20px 10px;
    box-sizing: border-box;
  }

  #for_cgs_content_speedydelivery h2 {
    width: 72%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #for_cgs_content_speedydelivery p {
    margin-bottom: 35px;
    font-size: 15px;
  }

  .a_bounce_box {
    height: 190px;
    margin-top: 20px;
  }

  #want_example {
    font-size: 25px;

  }

  h3 {
    color: var(--new-primary-purple-deep);
  }

  .a_bounce_box h3 {
    font-size: 25px;
    text-align: center;
    margin-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;

  }

  .a_bounce_box p {
    /*font-size: 25px;*/
    /*text-align: center;*/
    /*margin-bottom: 30px;*/
    padding-left: 20px;
    padding-right: 20px;
  }

  #box1, #box2, #box3, #box4, #box5, #box6, #box7, #box8 {
    width: 100%;
    max-width: 350px;
  }

  #box1_sg {
    height: 130px;
  }

  #box1_sg, #box2_sg, #box3_sg, #box4_sg, #box5_sg, #box6_sg, #box7_sg, #box8_sg {
    width: 100%;
    max-width: 350px;
  }

  #box1_sg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  #box1_sd, #box2_sd, #box3_sd, #box4_sd, #box5_sd, #box6_sd, #box7_sd, #box8_sd {
    width: 100%;
    max-width: 350px;
  }


  #box2 img {

    width: 350px;
    position: absolute;
    z-index: 110;
  }

  #box3 img {
    max-width: 95vw;
    /*overflow: hidden;*/
    position: absolute;
    z-index: 110;
  }

  #box6 img {
    position: absolute;
    z-index: 110;
    max-width: 95vw;
    /*margin-top: 300px;*/
  }

  #box7 img {
    position: absolute;
    z-index: 110;
    max-width: 95vw;
  }


  #box2_sg img {
    max-width: 95vw;
    position: absolute;
    z-index: 110;
  }

  #box3_sg img {
    max-width: 95vw;
    position: absolute;
    z-index: 110;
  }

  #box6_sg img {
    position: absolute;
    z-index: 110;
    max-width: 95vw;
    /*margin-top: 300px;*/
  }

  #box7_sg img {
    position: absolute;
    z-index: 110;
    max-width: 95vw;
  }


  #box2_sd img {
    width: 300px;
  }

  #box3_sd img {
    width: 300px;
  }

  #box6_sd img {
    width: 300px;
  }

  #box7_sd img {
    width: 200px;
  }

  #box2 img {
    width: 300px;
  }

  #box3 img {
    width: 300px;
  }

  #box6 img {
    width: 300px;
  }

  #box7 img {
    width: 200px;
  }


  #box2_sg img {
    width: 300px;
  }

  #box3_sg img {
    width: 300px;
  }

  #box6_sg img {
    width: 300px;
  }

  #box7_sg img {
    width: 200px;
  }


  #box2_sd img {
    max-width: 95vw;
    position: absolute;
    z-index: 110;
  }

  #box3_sd img {
    max-width: 95vw;
    position: absolute;
    z-index: 110;
  }

  #box6_sd img {
    position: absolute;
    z-index: 110;
    max-width: 95vw;
    /*margin-top: 300px;*/
  }

  #box7_sd img {
    position: absolute;
    z-index: 110;
    max-width: 95vw;
  }


  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 3px;
    margin-right: 3px;
    text-decoration: none;
    padding-right: 10px;
    padding-left: 10px;
  }

  #go_to_schs_button_freetime {
    margin-top: 20px;
  }

  /*#cg_conclusion_freetime {*/
  /*  margin-top: 40px;*/
  /*  margin-bottom: 50px;*/
  /*  max-width: 86vw;*/
  /*}*/
  /*#cg_conclusion_freetime h3 {*/
  /*  margin-bottom: 30px;*/
  /*  !*text-align: center;*!*/
  /*  font-size: 22px;*/
  /*}*/
  /*#cg_conclusion_freetime p {*/
  /*  max-width: 650px;*/
  /*}*/
  #cg_conclusion_spyglass {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  #cg_conclusion_spyglass h3 {
    margin-bottom: 30px;
    text-align: center;
    font-size: 25px;
  }

  #cg_conclusion_spyglass p {
    max-width: 650px;
  }

  #cg_conclusion_speedydelivery {
    margin-top: 40px;
  }

  #cg_conclusion_speedydelivery h3 {
    /*padding: 30px;*/
    padding-bottom: 20px;
    text-align: center;
    font-size: 31px;
  }

  #cg_conclusion_speedydelivery p {
    max-width: 650px;
    /*padding-bottom: 20px;*/
  }

  .cg_bounce_box {
    margin-bottom: 100px;
  }

  .carousel {
    padding-bottom: 30px;
  }

  .go_to_schs_button {
    margin-top: 40px;
  }
}

mai

@media (min-width: 400px) {

  .feature_cards_coll p {
    font-size: 16px;
    text-align: left;
  }

  #tmh_cg_features_intro h1 {
    color: var(--new-primary-purple-deep);
    max-width: 300px;
  }

  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
    margin-left: 3px;
    margin-right: 3px;
    text-decoration: none;
  }
}

@media (min-width: 500px) {

  .feature_cards_coll p {
    font-size: 18px;
    text-align: left;
  }

  #freetime_content_part1_B {
    /*margin-left: 50px;*/
    /*margin-right: 50px;;*/
  }

  .spyglass_cards_coll h3 {
    font-size: 26px;
    /*margin-bottom: 20px;*/
    /*box-sizing: border-box;*/
  }

  #tmh_cg_features_intro h1 {
    color: var(--new-primary-purple-deep);
    font-size: 43px;
    text-align: center;
    max-width: 350px;
  }

  #tmh_cg_features_intro p {
    font-size: 25px;
  }
}

@media (min-width: 680px) {
  #freetime_content_part1_B {
    /*margin-left: 70px;*/
    /*margin-right: 70px;;*/
  }
}

@media (min-width: 720px) {


  #ft_video_thumbnail {
    width: 54vw;
    max-width: 750px;
  }


  #ok_show_me_col {
    margin-bottom: 0;
    justify-content: space-around;
  }

  #ok_show_me_col h3 {
    max-width: 75%;
  }

  #cg_conclusion_spyglass {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  /*.feature_cards_coll p {*/
  /*  font-size: 25px;*/
  /*}*/
  #box1 {
    text-align: right;
  }

  #box4 {
    text-align: left;
  }

  #box1_sg {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .video_thumbnail {
    max-width: 99%;
    height: auto;
    width: 435px;
  }
}


@media (min-width: 800px) {

  .spyglass_cards_header p {
    width: 75%;
  }

  #for_cgs_content_spyglass {
    /*max-width: 60%;*/
    padding: 20px;
    background-color: white;
    /*width: 100vw;*/
  }

  .a_bounce_box {
    height: 220px;
    margin-top: 20px;
  }

  .sel_indicator {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  #tmh_cg_features_intro h1 {
    max-width: 400px;
  }

  #about_link {
    padding-right: 20px;
  }

  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;

  }

  .all_check_boxes {
    max-width: 1000px;
  }

  #freetime_content_part1 {
    padding: 20px;
  }
}


@media (min-width: 1000px) {


  #ok_show_me {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 219px;
    width: 720px;
    max-width: 10000px;
  }

  #ok_show_me div {
    margin-top: 30px;
  }


  #cg_conclusion_spyglass {
    margin-top: 0;
  }

  .go_to_schs_button {
    margin-top: 5px;
  }


}

@media (min-width: 1175px) {


  #freetime_content_part2_A h3 {
    font-size: 25px;
  }

  .freetime_feature_cards_second {
    margin-top: 0;
  }

  .freetime_feature_cards_third {
    /*margin-top: -30px;*/
  }

  .freetime_feature_cards_fourth {
    /*margin-top: -31px;*/
  }

}

@media (min-width: 1200px) {

  #ok_show_me_col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  /*#cg_conclusion_freetime{*/
  /*  max-width: unset;*/
  /*  width: 1200px;*/
  /*}*/
  #freetime_content_part2_B {
    width: 1080px;
  }

  #freetime_content_part2_A h3 {
    font-size: 25px;
    width: 300px;
    margin-top: 40px;
  }

  #freetime_content_part1 {
    min-width: 1150px;
    max-width: 1400px;
    justify-content: space-around;
    margin-bottom: 55px;
  }

  .go_to_freetime_calcs_proof {
    margin-top: 30px;
    align-self: flex-end;
  }

  #freetime_content_part1_A {
    margin-right: 30px;
  }

  #freetime_content_part1_A p {
    text-align: right;
  }

  #freetime_content_part1_A h3 {
    align-self: flex-end;
  }

  #freetime_content_part1_B {
    margin-left: 30px;
  }

  #freetime_content_part1 h3 {
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  #freetime_content_part1 p {
    width: 400px;
  }


  .feature_cards_coll {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .spyglass_cards_coll {
    width: 1020px;
    margin-top: 40px;
    /*margin-bottom: 80px;*/
  }


  #speedydelivery_content_logo {
    width: 300px;
  }

  #speedydelivery_top_section {
    margin-bottom: 40px;
  }

  #for_cgs_content_speedydelivery p {
    font-size: 20px;
  }

  #for_cgs_content_speedydelivery h2 {
    width: 100%;
  }

  .a_bounce_box {
    height: 220px;
    margin-top: 20px;
  }

  #tmh_cg_features_intro h1 {
    color: var(--new-primary-purple-deep);
    font-size: 50px;
  }

  #tmh_cg_features_intro p {
    font-size: 30px;
  }

  #box1, #box2, #box3, #box4, #box5, #box6, #box7, #box8 {
    width: 95vw;
    max-width: 500px;
  }

  #box1_sg, #box2_sg, #box3_sg, #box4_sg, #box5_sg, #box6_sg, #box7_sg, #box8_sg {
    width: 95vw;
    max-width: 500px;
  }

  #box1_sd, #box2_sd, #box3_sd, #box4_sd, #box5_sd, #box6_sd, #box7_sd, #box8_sd {
    width: 95vw;
    max-width: 500px;
  }

}

@media (min-width: 1380px) {
  #get_your_bonus {
    text-align: right;
    /*font-size: 26px;*/
    /*width: 286px;*/
    /*margin-top: 40px;*/
  }
}


@media (min-width: 1568px) {


  .spyglass_cards_header {
    margin-top: 74px;
  }

  .spyglass_cards_header p {
    width: 59%;
    /*text-align: right;*/
  }

  #cg_conclusion_spyglass h3 {
    margin-top: 40px;
  }


}


@media (min-width: 1600px) {

  #freetime_content_part1 {
    min-width: 1390px;
  }

  /*#cg_conclusion_freetime p{*/
  /*  text-align: left;*/
  /*}*/
  .all_check_boxes {
    max-width: 1500px;
    margin-bottom: 40px;
    padding-left: 10px;
  }

  .check_box_group {
    /*margin-bottom: 5px;*/
    max-width: 523px;
    margin: 20px;
  }

  /*.check_box_icon {*/
  /*width: 50px;*/
  /*margin-right: 10px;*/
  /*}*/
  .check_box_set h3 {
    font-size: 22px;
  }
}

@media (min-width: 2000px) {

  .all_check_boxes {
    max-width: 1500px;
    margin-bottom: 40px;
    padding-left: 10px;
  }

  .check_box_group {
    margin-bottom: 20px;
    max-width: 523px;
  }

  .check_box_set h3 {
    font-size: 22px;
  }

  #for_cgs_content_speedydelivery p {
    font-size: 24px;
  }

  .video_thumbnail {
    max-width: 99%;
    height: auto;
    width: 450px;
    padding-top: 40px;
  }

  .a_bounce_box {
    height: 250px;
    margin-top: 20px;
  }

  #speedydelivery_content_logo {
    max-width: 500px;
  }

  #spyglass_content_logo {
    /*width: 280px;*/
    max-width: 275px;
  }

  #freetime_content_logo {
    max-width: 300px;
  }

  #for_cgs_content_freetime p {
    margin-bottom: 20px;
    font-size: 20px;
  }

  #tmh_cg_features_intro {
    margin-top: 100px;
    max-width: 1000px;
  }

  #tmh_cg_features_intro h1 {
    margin-bottom: 30px;
    max-width: 800px;
    font-size: 50px;
  }

  #tmh_cg_features_intro p {

    /*line-height: 40px;*/
    font-size: 25px;
  }

  #for_cgs_content_freetime {
    margin-bottom: 100px;
  }

  #for_cgs_content_freetime h1 {
    margin-bottom: 50px;
    margin-top: 30px;
    font-size: 40px;
    max-width: 450px;
  }

  /*#cg_conclusion_freetime {*/
  /*  margin-top: 40px;*/
  /*  width:1420px ;*/
  /*}*/
  /*#cg_conclusion_freetime a {*/
  /*  font-family: 'Raleway', sans-serif;*/
  /*  font-size: 20px;*/
  /*  font-weight: 300;*/
  /*}*/
  /*#cg_conclusion_freetime h3 {*/
  /*  !*margin-top: 80px;*!*/
  /*  !*padding-bottom: 35px;*!*/
  /*}*/
  /*#cg_conclusion_freetime p {*/
  /*  font-size: 20px;*/
  /*  max-width: unset;*/
  /*  width: 500px;*/
  /*}*/
  #for_cgs_content_spyglass {
    margin-bottom: 100px;
  }

  #for_cgs_content_spyglass h1 {
    margin-bottom: 50px;
    margin-top: 30px;
    font-size: 40px;
    max-width: 450px;
  }

  #for_cgs_content_spyglass img {
    /*margin-top: 80px;*/
  }

  #cg_conclusion_spyglass {
    margin-top: 80px;
    width: 80%;
  }

  #cg_conclusion_spyglass a {
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 300;

  }

  #cg_conclusion_spyglass h3 {
    margin-top: 80px;
    padding-bottom: 35px;
  }

  #cg_conclusion_spyglass p {
    font-size: 25px;
    max-width: 800px;
  }


  #for_cgs_content_speedydelivery {
    margin-bottom: 100px;
  }

  #for_cgs_content_speedydelivery h1 {
    margin-bottom: 50px;
    margin-top: 30px;
    font-size: 40px;
    max-width: 450px;
  }

  /*#for_cgs_content_speedydelivery img {*/
  /*  margin-top: 80px;*/
  /*}*/
  #cg_conclusion_speedydelivery {
    margin-top: 80px;
    width: 80%;
  }

  #cg_conclusion_speedydelivery a {
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 300;

  }

  #cg_conclusion_speedydelivery h3 {
    /*margin-top: 80px;*/
    padding-bottom: 35px;
  }

  #cg_conclusion_speedydelivery p {
    font-size: 25px;
    max-width: 800px;
  }


  #box1, #box2, #box3, #box4, #box5, #box6, #box7, #box8 {
    width: 95vw;
    max-width: 650px;
  }

  #box1_sg, #box2_sg, #box3_sg, #box4_sg, #box5_sg, #box6_sg, #box7_sg, #box8_sg {
    width: 95vw;
    max-width: 450px;
  }

  #box1_sd, #box2_sd, #box3_sd, #box4_sd, #box5_sd, #box6_sd, #box7_sd, #box8_sd {
    width: 95vw;
    max-width: 720px;
  }

  .links {
    font-family: 'Raleway', sans-serif;
    font-size: 25px;
    margin-left: 5px;
    margin-right: 5px;
    padding-right: 30px;
    text-decoration: none;
  }


  #top_side_content_freetime p {
    font-size: 25px;
  }

  #top_side_content_spyglass p {
    font-size: 25px;
    max-width: 1000px;
  }

  #top_side_content_speedydelivery p {
    font-size: 25px;
    max-width: 1000px;
  }

  .cg_bounce_box {
    max-width: 95vw;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .a_bounce_box h3 {
    margin-bottom: 25px;
    /*padding-top: 50px;*/
  }


  #box1 p, #box4 p, #box5 p, #box8 p {
    padding-left: 60px;
    padding-right: 60px;
    /*line-height: 20px;*/
    /*letter-spacing: 2px;*/

  }

  /*#box2 img {*/
  /*  width: 350px;*/
  /*}*/
  #box1_sg p, #box4_sg p, #box5_sg p, #box8_sg p {
    padding-left: 60px;
    padding-right: 60px;
    /*line-height: 20px;*/
    /*letter-spacing: 2px;*/

  }

  #box2_sg img {
    width: 350px;
  }

  #box1_sd p, #box4_sd p, #box5_sd p, #box8_sd p {
    padding-left: 60px;
    padding-right: 60px;
    /*line-height: 20px;*/
    /*letter-spacing: 2px;*/

  }

  .freetime_feature_cards_second {
    margin-top: 35px;
  }

}


</style>