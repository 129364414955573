<template>
  <section class="flex_centered_column">
    <inside_banner
        mode="speedydelivery_user"
    ></inside_banner>
    <basic_page_infrastructure
        @local_page_update="do_nothing"
    ></basic_page_infrastructure>


  </section>

  <section class="flex_centered_start_column">
    <div class="flex_centered_row">
      <img @click="go_to_profile_home_route" class="padding_tb_20 sd_logo cursor_ptr"
           src="@/assets/popdocs.svg"
           alt="PopDocs Feature Logo">
    </div>
  </section>


  <section class="mods_complete flex_centered_row">
    <div class="flex_centered_column">

      <div class="flex_centered_row">
        <div class="flex_centered_column">
          <h1 class='heading' v-if="any_completed_modules">Completed Modules</h1>
        </div>
      </div>

      <div class="flex_centered_row module_icons_row flex_wrap">
        <div v-for="(item, index) of icon_data" :key="`item-${index}`">

          <div v-for="(item1, index1) of completed_modules" :key="`item-${index1}`">
            <div v-if="item1.module_name===item.name" class="tooltip_container">
              <!--              <div class="tooltip">-->
              <!--                <span class="tooltiptext">{{ item.toolptip }}</span>-->
              <!--              </div>-->

              <!--                <basic_icon_button-->
              <!--                    ref="btn4"-->
              <!--                    class="margin_lr_10 cursor_ptr"-->
              <!--                    :ready="true"-->
              <!--                    button_color="transparent"-->
              <!--                    :button_color_not_ready="colors.pennie_gray"-->
              <!--                    :button_height=module_button_size-->
              <!--                    :button_width=module_button_size-->
              <!--                    border_rad_pct='button_border_rad'-->
              <!--                    :image_path=item.image-->
              <!--                    :image_width=module_image_size-->
              <!--                    alt_text="Save Image"-->
              <!--                    event_name="go_to_module_page"-->
              <!--                    @go_to_module_page="do_nothing(item.route)"-->
              <!--                ></basic_icon_button>-->
              <module_card
                  class="margin_t_20"
                  :image_path=item.image
                  :image_text=item.toolptip
                  @mod_card_clicked="do_nothing"
              ></module_card>

            </div>
          </div>
        </div>
      </div>
    </div>

  </section>


  <section class="what_is_sd flex_centered_column">
    <div class="flex_centered_row">
      <h2 class="text_align_center heading" id="what_is_sd">What is PopDocs?</h2>
    </div>

    <div>
      <img class="cursor_ptr video_thumbnail"
           src="@/assets/speedydelivery_play_comp-min.jpg"
           alt="SpeedyDelivery Video for caregivers thumbnail"
           @click="speedy_delivery_video_make_true">


      <video_modal
          :show_modal="speedydelivery_vid_up"
          :video_id="videos.speedydelivery.video_id"
          :base_height="videos.base_video_height"
          :base_width="videos.base_video_width"
          @bg_clicked="videos.speedydelivery.modal_up = false"
      ></video_modal>
    </div>


    <div class="flex_centered_column what_is_sd_text">
      <p><strong>With PopDocs, the educational modules you've completed and your personal documentation
      like driver's licence, car insurance, criminal background check and others come with you if you
      ever move to another agency.</strong></p>
      <p><strong>Both your current agency and new agencies will have instant access to all your documentation.</strong></p>
      <!--      <p>Maintaining your caregiving skills is part of the job of being a professional caregiver and home care agencies-->
      <!--        are-->
      <!--        required to ensure that caregiving staff members are up-to-date with state-required continuing education-->
      <!--        modules.-->
      <!--      </p>-->
      <!--      <p>Unfortunately, if you leave one agency and start with another, you will need to retake the basic educational-->
      <!--        modules as the new agency must also ensure that their caregiving staff have been properly trained according to-->
      <!--        state regulations.</p>-->
      <!--      <p>With SpeedyDelivery, there is no need to re-take the same educational modules if you move to-->
      <!--        another agency within our network.</p>-->
      <!--      <p>Within moments of meeting you for the first time, your new agency will have access to proof of all of the-->
      <!--        modules you have already completed, thus saving you time and effort.</p>-->
      <!--      <p>To complete a module, download and study the module before taking a short test demonstrating you understand the-->
      <!--        material.</p>-->
    </div>

  </section>


  <section class="available_modules flex_centered_column">
    <div class="flex_centered_row">
      <div class="flex_centered_column">
        <h2 class="heading">Available Modules</h2>
        <div class="flex_centered_row module_icons_row flex_wrap">
          <div v-for="(item, index) of not_completed_modules" :key="`item-${index}`">
            <div class="tooltip_container">
              <div class="tooltip">
                <span class="tooltiptext">{{ item.toolptip }}</span>
              </div>

              <!--              <basic_icon_button-->
              <!--                  ref="btn4"-->
              <!--                  class="margin_tb_20 margin_lr_10 cursor_ptr"-->
              <!--                  :ready="true"-->
              <!--                  button_color="transparent"-->
              <!--                  :button_color_not_ready="colors.pennie_gray"-->
              <!--                  :button_height=module_button_size-->
              <!--                  :button_width=module_button_size-->
              <!--                  border_rad_pct='button_border_rad'-->
              <!--                  :image_path=item.image-->
              <!--                  :image_width=module_image_size-->
              <!--                  alt_text="Save Image"-->
              <!--                  event_name="go_to_module_page"-->
              <!--                  @go_to_module_page="go_to_module_page(item.route)"-->
              <!--              ></basic_icon_button>-->
              <module_card
                  class="margin_t_20"
                  :image_path=item.image
                  :image_text=item.toolptip
                  @mod_card_clicked="go_to_module_page(item.route)"
              ></module_card>
            </div>
          </div>
        </div>
      </div>

    </div>

  </section>

</template>

<script>
/* eslint-disable */
import inside_banner from "@/components/parts/inside_banner";
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import basic_icon_button from "@/components/parts/basic_icon_button";
import store from "@/store";
import {mapGetters} from "vuex";
import video_modal from "@/components/parts/video_modal";
import module_card from "@/components/SpeedyDelivery/module_card.vue";
import {
  // add_class_to_body,
  // desktop_size,
  get_pda_home_route,
  // remove_class_to_body,
  prime_socket,
  colors,
  speedy_icon_data,
  receive_over_socket, compare_timestamp, analytic, get_profile_route
} from "@/library";
import {Database} from "@/client_db";

export default {
  name: "speedydelivery_main_user_interface",
  async mounted() {
    this.db = new Database();
    await this.load_completed_modules()
    await prime_socket(this.local_message_handler)
  },
  data() {
    return {
      db: "",
      button_size_: "100px",
      button_img_size_: "80px",
      colors: colors,
      completed_modules: {},
      videos: {
        base_video_height: 180,
        base_video_width: 320,
        freetime: {
          video_id: "755300990",
          modal_up: false
        },
        spyglass: {
          video_id: "755298908",
          modal_up: false
        },
        speedydelivery: {
          video_id: "755297326",
          modal_up: false
        },
      },
      icon_data: speedy_icon_data,

    }
  },
  components: {
    inside_banner,
    basic_page_infrastructure,
    basic_icon_button,
    video_modal,
    module_card
  },
  methods: {
    do_nothing() {
      // pass
    },
    async load_completed_modules() {
      let substring = `/people/${this.my_name}/service/speedydelivery/modules/`
      console.log(`The substring to search is ${substring}`)

      let all_docs = await this.db.db_substring_search(substring, null, false)

      // console.log("Found the following Speedy documents")
      // console.log(all_docs)

      // console.log("before sort")
      // console.log(all_docs)
      let res = all_docs.sort(compare_timestamp)
      // console.log(res)

      for (const c of all_docs) {
        //Search the results, keep the 'passed' tests, then only keep the latest past test
        let current_key = c.module_name
        if (!this.completed_modules.hasOwnProperty(current_key)) {
          // console.log(`${current_key} was not in the completed modules.`)
          //We have no passed tests regarding this module.
          if (c.passed === 1) {
            //This is the first passed test for this module we have seen. Keep it.
            // console.log(`${current_key} was not in the completed modules and it was a pass, so keep it.`)
            this.completed_modules[current_key] = c
          } else {
            console.log(`${current_key} was not in the completed modules but it was a fail, so skip.`)
            //This is a failed test, so we just move along
          }

        } else {
          //We have seen this module before.  Does this c have a later date than the previous?
          // console.log(`${current_key} WAS in the completed modules, but is this instance more recent?`)
          let new_time = c.timestamp
          let old_time = this.completed_modules[current_key].timestamp
          if (new_time > old_time && c.passed === 1) {
            // console.log(`${current_key} WAS in the completed modules, and it is more recent and it was a pass, so keep it.`)
            this.completed_modules[current_key] = c
          } else {
            // console.log(`${current_key} WAS in the completed modules, bit it was old or a fail, so skip it.`)
            //The first version saved is more recent. Ignore this old version.
          }
        }

      }


      // console.log('These are the remaining module documents after the filter.')
      // console.log(this.completed_modules)
    },
    async local_page_update() {
      // await this.load_history_reports_modern()
      // console.log("Running the code that runs when the page gets an update oven the socket server.")
      await this.load_completed_modules()
    },
    async local_message_handler(from_server_json) {
      // console.log("Message from the socket server is ...")
      // console.log(from_server_json)
      let update = await receive_over_socket(from_server_json)
      // console.log(`Just got something on the socket: Update? ${update}`)
      if (update) {
        store.commit('increment_universal_page_update', true)
      }
    },
    async go_to_module_page(module_name) {
      await this.non_contextual_route(module_name)
    },
    async non_contextual_route(route = null) {
      // this.hamburger_menu_false()

      console.log("Inside non-context changing route function is speedy.")
      let current = localStorage.getItem('dev_latest_route')
      console.log(`Current is ${current}`)

      store.commit('update_context_changing_route', false)
      let new_url = current + '/' + route

      await this.$router.push({path: new_url})
    },
    profile_route() {
      return get_profile_route()
    },
    async go_to_profile_home_route() {
      await this.$router.push({path: `${this.profile_route()}`})
    },
    run_window_width_adjustments(new_w) {
      if (new_w > 100) {
        // remove_class_to_body('content_no_radial')
        // remove_class_to_body('content_large_screen')
        this.button_size_ = "100px"
        this.button_img_size_ = "80px"
      }
      if (new_w > 500) {

        this.button_size_ = "100px"
        this.button_img_size_ = "80px"
      }
      if (new_w > 1000) {
        // console.log(`New size is ${new_w}`)
        this.button_size_ = "110px"
        this.button_img_size_ = "90px"
      }

      if (new_w > 1500) {
        // console.log(`New size is ${new_w}`)
        this.button_size_ = "110px"
        this.button_img_size_ = "90px"
      }

    },
    async simple_go_to_route(route) {
      await this.$router.push({path: '/' + route})
    },
    async speedy_delivery_video_make_true() {
      await analytic(`SpeedyDelivery|So how can you prequalify?::video modal opened:CTA clicked`)
      this.videos.speedydelivery.modal_up = true
    },
  },
  watch: {

    async update_page() {
      console.log("update_page watcher is triggered")
      await this.local_page_update()
    },

    window_width(new_w) {
      console.log("Running Window width watch..")
      this.run_window_width_adjustments(new_w)
    },

  },
  computed: {
    ...mapGetters([
      'window_width',
      'my_name'
    ]),

    not_completed_modules() {
      // Filter the objects based on whether their 'name' key is not in the 'strings' array

      let objects = speedy_icon_data
      let strings = Object.keys(this.completed_modules);
      return objects.filter(obj => !strings.includes(obj.name));
    },

    speedydelivery_vid_up() {
      return this.videos.speedydelivery.modal_up
    },
    any_completed_modules() {
      if (Object.keys(this.completed_modules).length > 0) {
        return true
      } else {
        return false
      }
    },
    update_page() {
      console.log("update_page computed property is triggered.")
      return store.getters.universal_page_update
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    module_button_size() {
      console.log("Running the module size computed property.")
      return this.button_size_
    },
    module_image_size() {
      return this.button_img_size_
    },
  },
}
</script>

<style scoped>
@media (min-width: 100px) {

  .tooltip_container {

  }

  .tooltip_container:hover .tooltiptext {
    visibility: visible;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    /*border-bottom: 1px dotted black; !* If you want dots under the hoverable text *!*/
  }

  .tooltip_container .tooltiptext {
    visibility: hidden;
    width: 130px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    bottom: 1000%;
    /*left: 50%;*/
    margin-left: 10px; /* Use half of the width (120/2 = 60), to center the tooltip */
    margin-bottom: -20px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  .sd_logo {
    width: 200px;
  }

  #what_is_sd{
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .heading {
    font-size: 30px;
    margin-top: 20px;
  //margin-bottom: 20px; width: 85%;
    text-align: center;
  }

  h2 .heading {
    margin-top: 40px;
  }

  .module_logo {
    width: 100px;
  }

  .complete_schedules {
    /* display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*align-content: center;*/
  }

  .video_thumbnail {
    max-width: 99%;
    height: auto;
    width: 95vw;
    margin-bottom: 20px;
  }


  .what_is_sd {
    padding-left: 10px;
    padding-right: 10px;
  }

  .what_is_sd p {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .what_is_sd_text p {
    font-size: 18px;
    max-width: 500px;
  }
}

@media (min-width: 720px) {
  .video_thumbnail {
    max-width: 99%;
    height: auto;
    width: 435px;
  }
}

@media (min-width: 1000px) {

  .heading {
    font-size: 40px;
    margin-top: 20px;
    width: 85%;
    text-align: center;
  }

  .sd_logo {
    width: 300px;
    margin-bottom: 50px;
  }

  .what_is_sd_text {
    width: 50%;
  }

  .what_is_sd_text p {
    margin-top: 15px;
    line-height: 2rem;
  }

  .complete_schedules h2 {
    margin-top: 40px;

  }

  .what_is_sd h2 {
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .available_modules h2 {
    margin-top: 40px;
  }

  .module_icons_row {
    max-width: 900px;
  }

  .sd_history h2 {
    margin-top: 40px;
  }
}

@media (min-width: 2000px) {
  .video_thumbnail {
    max-width: 99%;
    height: auto;
    width: 450px;
    padding-top: 40px;
  }
}
</style>