<template>

  <div class="the_summary">
    <!--    <div class="" v-for="(item, index) of current_summary_history_report" :key="`item-${index}`">-->
    <!--      <div class='box_header summary_item' v-if="'Start Date' === item[0]">-->
    <!--        <p class="text_align_center">{{ item[0] }}</p>-->
    <!--        <data_box-->
    <!--            :value=item[1]-->
    <!--            :prefix=get_datum_prefix(item[0])-->
    <!--            :suffix=null-->
    <!--            :round_digits=get_rounding_digit(item[0])-->
    <!--            :bg_color=colors.extra_light_purple-->
    <!--        ></data_box>-->
    <!--      </div>-->
    <!--    </div>-->


    <div class='box_header summary_item'>
      <p class="text_align_center">Start Date</p>
      <data_box
          :value='data_dict["Start Date"]'
          :prefix='get_datum_prefix("Start Date")'
          :suffix=null
          :round_digits='get_rounding_digit("Start Date")'
          :bg_color=colors.extra_light_purple
      ></data_box>
    </div>


    <!--    <div class=" summary_items  flex_wrap">-->
    <!--      <div class="" v-for="(item, index) of current_summary_history_report" :key="`item-${index}`">-->
    <!--        <div v-if="include_this_item(item[0])" class="box_header summary_item">-->
    <!--                  <div>-->
    <!--                    <p class="text_align_center">{{ item[0] }}</p>-->
    <!--                    <data_box-->
    <!--                        :value=item[1]-->
    <!--                        :prefix=get_datum_prefix(item[0])-->
    <!--                        :suffix=null-->
    <!--                        :round_digits=get_rounding_digit(item[0])-->
    <!--                        :bg_color=colors.extra_light_purple-->
    <!--                    ></data_box>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    <div class="summary_items flex_wrap">-->
    <!--      <div class="" v-for="(value, key, index) of data_dict" :key="`item-${index}`">-->
    <!--        <div class="box_header summary_item">-->
    <!--            <p class="text_align_center">{{ key }}</p>-->
    <!--            <data_box-->
    <!--                :value=value-->
    <!--                :prefix=get_datum_prefix(key)-->
    <!--                :suffix=null-->
    <!--                :round_digits=get_rounding_digit(key)-->
    <!--                :bg_color=colors.extra_light_purple-->
    <!--            ></data_box>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="summary_items flex_wrap">
      <div v-for="(value, index) of report_order" :key="`item-${index}`">
        <div class="box_header summary_item">
          <p class="text_align_center">{{ value }}</p>
          <data_box
              :value=data_dict[value]
              :prefix=get_datum_prefix(value)
              :suffix=null
              :round_digits=get_rounding_digit(value)
              :bg_color=colors.extra_light_purple
          ></data_box>
        </div>
      </div>
    </div>


  </div>

</template>

<script>
/* eslint-disable */
import data_box from "@/components/FreeTime/data_box";
import {colors} from "@/library";

export default {
  name: "summary_report",
  props: ['current_summary_history_report'],
  components: {
    data_box
  },
  mounted() {
    console.log("in summary report component.  For data I got............")
    // console.log(thi.current_summary_history_report)

    for (const element of this.current_summary_history_report) {
      // console.log(element);
      let name = element[0]
      if (name === 'Pool Score') {
        name = 'WonderPerks Score'
      }
      this.data_dict[name] = element[1]
    }
    // console.log(this.data_dict)
  },
  updated() {
    for (const element of this.current_summary_history_report) {
      // console.log(element);
      let name = element[0]
      if (name === 'Pool Score') {
        name = 'FreeTime Score'
      }
      this.data_dict[name] = element[1]
    }

  },
  data() {
    return {
      colors: colors,
      data_dict: {},
      report_order: ['Total Hours', 'Total Cost', 'Caregivers', 'Bonused %', 'Funded Amount', 'Maximum Raise', 'Highest FreeTime', 'FreeTime Score']
    }
  },
  methods: {
    include_this_item(item) {
      // console.log(`.................In include this item with ${item}`)
      let includes = ['Total Cost', 'Caregivers', 'Bonused %', 'Total Hours', 'Funded Amount', 'Maximum Raise', 'Pool Score', 'Highest FreeTime']
      return includes.includes(item);
    },

    get_datum_prefix(data_label) {
      // console.log(`In datum prefix with ${data_label}`)
      if (data_label === "Total Cost") {
        return "$"
      } else if (data_label === "Funded Amount") {
        return "$"
      } else if (data_label === "Maximum Raise") {
        return "$"
      } else if (data_label === "Average FreeTime") {
        return "$"
      } else if (data_label === "Highest FreeTime") {
        return "$"
      } else {
        return ""
      }
    },
    // get_datum_suffix(data_label){
    //   if(data_label === "Bonused %"){
    //     return "%"
    //   }else{
    //     return ""
    //   }
    // },

    get_rounding_digit(data_label) {
      // console.log(`In rounding digits with ${data_label}`)
      let the_twos = ['Total Cost', 'Bonused %', 'Total Hours', 'Included Hours', 'Funded Amount', 'Maximum Raise', 'Pool Score', 'Average FreeTime', 'Highest FreeTime']
      if (the_twos.includes(data_label)) {
        return 2
      } else if (data_label === "Caregivers") {
        return 0
      } else {
        return null
      }

    },
  },
}
</script>

<style scoped>
.report_table {
  max-width: 100%;
  margin-top: 1rem;
  font-family: 'Raleway', sans-serif
}

.table_key th {
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  font-size: 1rem;
}

.table_value {
  text-align: center;
  /*font-size: 4.5vw;*/
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
}

td {
  text-align: center;
  /*font-size: 4.5vw;*/
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
}

tr:nth-child(even) {
  background-color: white;
}

tr:nth-child(odd) {
  /*background-color: var(--primary-purple-light);*/
  /*background-color: var(--pennie_gray);*/
  /*background-color: #cbc9c9;*/
  background-color: var(--eli_gray);
  /*background-color: var(--cyanic-blue);*/
}


@media (min-width: 100px) {

  .the_summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
  }

  .box_header p {
    font-size: 15px;
    font-weight: 800;
    margin-bottom: 2px;
  }

  .summary_items {
    width: 280px;
    display: flex;
    justify-content: center;
  }

  .summary_item {
    margin-top: 15px;
    /*flex: 3;*/
    margin-left: 4px;
    margin-right: 4px;
  }


}

@media (min-width: 2000px) {

  .summary_items {
    width: 460px;
  }

  .summary_item {
    /*margin-right: 40px;*/
    /*margin-left: 40px;*/
    margin-top: 30px;
  }

}

</style>