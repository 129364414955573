<template>
  <!--if ready to publish, show the dry run report-->
  <div v-if="true"
       class="screen_block dry_run_report padding_b_30">

    <div class="padding_b_10 be_main">
      <!--      <div class="label_bolder text_cyanic text_align_center margin_tb_10">Bonus Statistics</div>-->
      <div class="flex_centered_row">

        <h3 class="header flex_centered_row text_align_center">WonderPerks Trial Estimates</h3>
      </div>

      <div class="margin_auto flex_fully_centered_column">

        <!--        ===============================================================================-->
        <div class="flex_space_between_row row">
          <div class="flex_centered_column summary_items">
            <p class="text_align_center unit_label_text">Top WonderPerks Hourly Rate</p>
            <data_box
                class="data_box_style data_box_style_left"
                :value=local_highest_hourly_rate
                prefix="$"
                :suffix=null
                round_digits=2
                :bg_color=colors.extra_light_purple
            ></data_box>
          </div>
          <div class="flex_centered_column summary_items">
            <p class="text_align_center unit_label_text"
            >Hourly Funded Amount</p>
            <data_box
                class="data_box_style data_box_style_right"
                :value=local_required_funded_amount
                prefix="$"
                :suffix=null
                round_digits=2
                :bg_color=colors.very_light_purple
            ></data_box>
          </div>
        </div>

        <div class="flex_space_between_row short_row">
           <img class="material_icon cursor_ptr bg_cyanic" @click="highest_pay_big_left()"
                   :src="image_name('keyboard_double_arrow_left.svg')"
                   alt="10x less arrow Icon">
           <img class="material_icon cursor_ptr bg_cyanic" @click="highest_pay_small_left()"
                   :src="image_name('keyboard_arrow_left.svg')"
                   alt="1x less arrow Icon">
           <img class="material_icon cursor_ptr bg_cyanic" @click="highest_pay_small_right()"
                   :src="image_name('keyboard_arrow_right.svg')"
                   alt="1x more arrow Icon">
           <img class="material_icon cursor_ptr bg_cyanic" @click="highest_pay_big_right()"
                   :src="image_name('keyboard_double_arrow_right.svg')"
                   alt="10x more arrow Icon">
        </div>

        <div class="flex_space_between_row row">

          <div class="flex_centered_column summary_items">
            <p class="unit_label_text">Highest WonderPerks Bonus</p>
            <!--Remember that WonderPerks bonusus get adjusted for deminimus bonuses, so you can't just multiply the ehr * hours to get the bonus amount. -->
            <data_box
                class="data_box_style data_box_style_left"
                :value=local_highest_bonus
                prefix="$"
                :suffix=null
                round_digits=2
                :bg_color=colors.super_light_purple
            ></data_box>
          </div>

          <div class="flex_centered_column summary_items">
            <p class="unit_label_text unit_label_text_exception_1">40-Hour WonderPerks Rate</p>
            <data_box
                class="data_box_style data_box_style_right"
                :value=local_fourty_hour_rate
                prefix="$"
                :suffix=null
                round_digits=2
                :bg_color=colors.super_light_purple
            ></data_box>
          </div>
        </div>

        <div class="flex_space_between_row row">
          <div class="flex_centered_column summary_items">
            <p class="unit_label_text">Break Even Hours</p>
            <data_box
                class="data_box_style data_box_style_left"
                :value=local_break_even_hours
                round_digits=2
                :bg_color=colors.super_light_purple
            ></data_box>
          </div>
          <div class="flex_centered_column summary_items">
            <p class="unit_label_text unit_label_text_exception_2">Pool Score</p>
            <data_box
                class="data_box_style data_box_style_right"
                :value=get_fixed_multiplier
                round_digits=3
                :bg_color=colors.super_light_purple
            ></data_box>
          </div>
        </div>

        <div class="flex_centered_row">
          <basic_slot_button
              class="action_button"
              :button_state="true"
              :button_color="colors.primary_purple_light"
              button_color_not_ready="gray"
              button_height="52px"
              :button_width=action_button_width
              border_rad_pct="0"
              :text_color="colors.white"
              text_size="15px"
              :bold="true"
              event_name="rerun"
              @rerun="rerun"
          >
            <div class="flex_centered_end_row ">
<!--              <span class="material-symbols-outlined material_icon ">refresh</span>-->
               <img class="material_icon"
                   :src="image_name('refresh_white.svg')"
                   alt="Refresh Icon">
              <div class="text_white a_button_text">Apply Changes</div>
            </div>
          </basic_slot_button>
        </div>

        <h3 class="header flex_centered_row">Statistics</h3>

        <div class="flex_space_between_row row">
          <div class="flex_centered_column summary_items">
            <p class="unit_label_text">Avg. Bill Rate</p>
            <data_box
                class="data_box_style data_box_style_left"
                :value=get_base_bill_rate.toString()
                prefix="$"
                :suffix=null
                round_digits=2
                :bg_color=colors.pennie_gray
            ></data_box>
          </div>
          <div class="flex_centered_column summary_items">
            <p class="unit_label_text">Avg. Std. Pay</p>
            <data_box
                class="data_box_style data_box_style_right"
                :value=get_base_pay_rate
                prefix="$"
                :suffix=null
                round_digits=2
                :bg_color=colors.pennie_gray
            ></data_box>
          </div>
        </div>

        <div class="flex_space_between_row row">
          <div class="flex_centered_column summary_items">
            <p class="unit_label_text">Payroll Burden</p>
            <data_box
                class="data_box_style data_box_style_left"
                :value=payroll_burden_as_string()
                :bg_color=colors.pennie_gray
            ></data_box>
          </div>
          <div class="flex_centered_column summary_items">
            <p class="unit_label_text">Total Hours</p>
            <data_box
                class="data_box_style data_box_style_right"
                :value=get_base_hours
                :bg_color=colors.pennie_gray
            ></data_box>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
/* eslint-disable vue/no-unused-components */
import basic_text_button from "@/components/parts/basic_text_button";
import data_box from "@/components/FreeTime/data_box";
import {
  colors,
  float_to_percent_string, image_name,

} from "@/library";

import store from "@/store";
import label_value_non_editable from "@/components/parts/label_value_non_editable";
import label_value_adjustable from "@/components/parts/label_value_adjustable";
import basic_slot_button from "@/components/parts/basic_slot_button";

export default {
  name: "break_even_calculator_component",
  props: ['ready_to_publish', 'mobile_mode', 'initial_funding'],
  components: {
    label_value_non_editable,
    label_value_adjustable,
    basic_text_button,
    data_box,
    basic_slot_button
  },
  async beforeMount() {
    // console.log("break even component before mounted.")
    // let values = this.be_values_clone
    // console.log(`values are ${JSON.stringify(values)}`)
    this.highest_hourly_rate_local = this.highest_hourly_rate_computed
    try {
      this.recalculate_and_update_all_values()
    } catch (error) {
      // console.log(error)
    }


  },
  mounted() {
    this.run_window_width_adjustments(this.windowWidth)
  },
  async updated() {

  },
  data() {
    return {
      colors: colors,
      highest_hourly_rate_local: "unripe",
      break_even_hours_local: "unripe",
      highest_bonus_local: "unripe",
      forty_hour_rate_local: "unripe",
      required_funded_amount_local: "unripe",
      action_button_width_: 250
    }
  },
  watch: {
    initial_funding() {
      // console.log("WATCHER ====initial_funding watcher triggered.")
      this.highest_hourly_rate_local = this.highest_hourly_rate_computed
      this.recalculate_and_update_all_values()
    },
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
  computed: {
    windowWidth() {
      return store.state.windowWidth;
    },
    action_button_width() {
      return this.action_button_width_ + "px"
    },
    local_highest_hourly_rate() {
      // console.log("inside local_highest_hourly_rate")
      return this.highest_hourly_rate_local
    },
    local_break_even_hours() {
      return this.break_even_hours_local
    },
    local_highest_bonus() {
      return this.highest_bonus_local
    },
    local_fourty_hour_rate() {
      console.log("inside local_fourty_hour_rate computed")
      return this.forty_hour_rate_local
    },
    local_required_funded_amount() {
      return this.required_funded_amount_local
    },

    be_values_clone() {
      let values_source = store.getters.get_freetime_breakeven_values
      let values = this.clone_object(values_source)
      let out_values = {}
      for (const property in values) {
        let new_key = property.toString()
        let replace = ""
        if (isNaN(parseFloat(this.round(values[property], 2)))) {
          replace = values[property]
        } else {
          replace = parseFloat(this.round(values[property], 2))
        }
        out_values[new_key] = replace

      }
      return out_values
    },
    highest_hourly_rate_computed() {
      let values = this.be_values_clone
      // console.log("*********************************************************")
      // console.log("*********************************************************")
      // console.log("*********************************************************")
      // console.log(`values are ${JSON.stringify(values)}`)
      let highest_bonus = values['highest_bonus']
      // console.log(`highest_bonus is ${highest_bonus}`)
      let hour_with_highest_bonus = values['highest_rate_hours']
      // console.log(`hour_with_highest_bonus are ${hour_with_highest_bonus}`)
      let base_rate = values['avg_base_pay']
      // console.log(`base_rate are ${base_rate}`)
      let hhr = (highest_bonus / hour_with_highest_bonus + base_rate).toFixed(2)
      // console.log(`Top FreeTime Hourly Rate computed is ${hhr}`)
      return hhr
      // return store.getters.get_freetime_breakeven_highest_rate_hours
    },
    get_fixed_multiplier() {
      let values = this.be_values_clone
      let m = values['multiplier']
      return m.toFixed(3)
    },
    calculate_funded_amount_and_save() {
      try {
        console.log("in calculate_funded_amount and save")
        // let values_source = store.getters.get_freetime_breakeven_values
        let values = this.be_values_clone
        console.log(`WHAT ARE THE VALUES: values are ${JSON.stringify(values)}`)
        // let highestRateHours = values['highest_rate_hours']
        // console.log(`in calculate funded amount: highestRateHours is ${highestRateHours}`)
        // console.log(`in calculate funded amount: values is ${JSON.stringify(values)}`)
        // console.log(`highestratehours is ${highestRateHours}`)
        // console.log("")
        // let highestBonusHours = this.break_even_numbers.highest_pay_hours
        // let highestBonus = this.calculate_highest_freetime
        // console.log(`in calculate funded amount: highestBonus is ${highestBonus}`)
        // let mul = values['multiplier']
        // console.log(`in calculate funded amount: multiplier is ${mul}`)
        // console.log(`And the fundede amount was... ${(highestBonus / (mul * highestRateHours)).toFixed(2)}`)
        // let new_funded_amount = (highestBonus / (mul * highestRateHours)).toFixed(2)

        let dialed_in_ft_rate = values['highest_pay_rate']
        let base_pay = values['avg_base_pay']
        let pool_score = values['multiplier']
        let new_required_funding_amount = (dialed_in_ft_rate-base_pay)/(pool_score)

        values['funded_amount'] = new_required_funding_amount
        store.commit('set_break_even_values', values)
        return new_required_funding_amount
      } catch (e) {
        return "error"
      }


    },
    // calculate_highest_freetime() {
    //
    //   console.log("in calculate_highest_freetime")
    //   console.log("in calculate_highest_freetime")
    //   console.log("in calculate_highest_freetime")
    //   console.log("in calculate_highest_freetime")
    //   console.log("in calculate_highest_freetime")
    //   // let values_source = store.getters.get_freetime_breakeven_values
    //
    //   let values = this.be_values_clone
    //   console.log(`calculate_highest_freetime: values are ${JSON.stringify(values)}`)
    //   let highhours = values['highest_rate_hours']
    //   let highest_ehr = values['highest_pay_rate'] - values['avg_base_pay']
    //   let ans = parseFloat((highhours * highest_ehr).toFixed(2))
    //
    //   if (!ans) {
    //     return "error"
    //   } else {
    //     values['highest_bonus'] = ans
    //     store.commit('set_break_even_values', values)
    //     return ans
    //   }
    // },

    calculate_highest_freetime() {

      let values = this.be_values_clone
      // console.log(`calculate_highest_freetime: values are ${JSON.stringify(values)}`)

      try {
        let hrh = values['highest_rate_hours']
        let pool_score = values['multiplier']
        let funded = values['funded_amount']
        values['highest_bonus'] = hrh * pool_score * funded
        store.commit('set_break_even_values', values)
        return parseFloat(values['highest_bonus'])
      } catch (error) {
        return "error"
      }
    }
    ,

    calculate_forty_hour_rate() {
      // console.log("in calc 40 hour rate")
      // let values_source = store.getters.get_freetime_breakeven_values
      let values = this.be_values_clone
      // console.log(values)
      // console.log(`Highest pay hours is ${h}`)
      try {

        let forty_rate = (values['highest_bonus'] / 40 + values['avg_base_pay']).toFixed(2)
        values['forty_hour_rate_local'] = forty_rate
        store.commit('set_break_even_values', values)
        // console.log("forty rate")
        // console.log(typeof forty_rate)
        return forty_rate
        // if (h < 40) {
        //   return "n/a"
        // }
        // let base_rate = values['avg_base_pay']
        // let chf = this.calculate_highest_freetime
        // let forty_hour_rate = ((chf / 40) + base_rate).toFixed(2)
        // return forty_hour_rate
      } catch (error) {
        return "n/a"
      }
    },
    get_base_bill_rate() {
      console.log("in get base bill rate computed")
      // let values_source = store.getters.get_freetime_breakeven_values
      let values = this.be_values_clone
      console.log(values)
      try {
        return values['avg_bill_rate']
      } catch (e) {
        return 0.00
      }
    },
    get_base_pay_rate() {
      let values = this.be_values_clone
      try {
        return values['avg_base_pay']
      } catch (e) {
        return 0.00
      }
    },
    get_burden_rate() {
      let values = this.be_values_clone
      try {
        return values['payroll_burden']
      } catch (e) {
        return 0.00
      }
    },
    get_base_hours() {
      let values = this.be_values_clone
      try {
        return values['base_hours']
      } catch (e) {
        return 0.00
      }
    },
    get_break_even_hours() {
      // let values = store.getters.get_freetime_breakeven_values
      // let beh = values['break_even_hours']
      let values = this.be_values_clone
      // console.log(store.state.freetime.break_even.break_even_hours)
      try {
        return values['break_even_hours']
      } catch (e) {
        return "None"
      }
    },
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    image_name(name) {
      return image_name(name)
    },
    rerun() {
      console.log(`Dry Run ReRun: ${this.local_required_funded_amount} of type ${typeof this.local_required_funded_amount}`)
      this.$emit('rerun_dry_run', this.local_required_funded_amount)
    },
    round(value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    },
    recalculate_and_update_all_values() {
      console.log("launching recalculate_and_update_all_values")
      console.log("launching recalculate_and_update_all_values")
      console.log("launching recalculate_and_update_all_values")
      console.log("launching recalculate_and_update_all_values")
      console.log("launching recalculate_and_update_all_values")
      this.break_even_hours_local = this.calculate_and_store_break_even_hours()
      this.highest_bonus_local = this.calculate_highest_freetime
      this.forty_hour_rate_local = this.calculate_forty_hour_rate
      this.required_funded_amount_local = this.calculate_funded_amount_and_save
    },
    clone_object(clonee) {
      let clone_str = JSON.stringify(clonee)
      return JSON.parse(clone_str)
    },

    calculate_and_store_break_even_hours() {
      console.log(`calculate_and_store_break_even_hours`)
      try {
        // console.log("in calc break even hours")

        let funded = parseFloat(this.calculate_funded_amount_and_save)
        let values = this.be_values_clone
        let br = values['avg_bill_rate']
        let bp = values['avg_base_pay']
        let burden = values['payroll_burden']
        let hours = values['base_hours']


        let be_return = this.break_even_calc(br, bp, funded, burden, hours)
        // console.log(`break even func calc return value is ${be_return}`)
        // store.commit('freetime_break_even_break_even_hours', be_return[0])
        hours = be_return[0]
        values['break_even_hours'] = hours
        // console.log(`I just set break even hours to ${hours}`)
        store.commit('set_break_even_values', values)
        // console.log(`values are ${JSON.stringify(values)}`)
        return parseFloat(hours)
      } catch (e) {
        return 0.00
      }

    },
    highest_pay_big_left() {
      this.adjust_highest_pay(-.10)
      this.recalculate_and_update_all_values()
    },
    highest_pay_small_left() {
      this.adjust_highest_pay(-.01)
      this.recalculate_and_update_all_values()
    },
    highest_pay_big_right() {
      this.adjust_highest_pay(.10)
      this.recalculate_and_update_all_values()
    },
    highest_pay_small_right() {
      this.adjust_highest_pay(.01)
      this.recalculate_and_update_all_values()
    },
    payroll_burden_as_string() {
      return float_to_percent_string(store.getters.get_freetime_breakeven_payroll_burden)
    },
    adjust_highest_pay(adj) {
      //Make adjustment to the highest pay rate so long as the change is higher than the base pay rate.
      let values = this.be_values_clone
      // console.log(`adjust_highest_pay BEFORE change highest pay rate: ${JSON.stringify(values)}`)

      // console.log(`adjust pay function with ${adj}`)
      // console.log(typeof adj)


      let highest_pay_rate = values['highest_pay_rate']
      // let proposed_highest = highest_pay_rate + adj
      // console.log("proposed_highest")
      // console.log(proposed_highest)
      // console.log(typeof proposed_highest)
      if (highest_pay_rate + adj > values['avg_base_pay']) {
        values['highest_pay_rate'] = this.round(values['highest_pay_rate'] + adj, 2)
      }
      this.highest_hourly_rate_local = parseFloat(values['highest_pay_rate'])
      store.commit('set_break_even_values', values)
      // console.log(`adjust_highest_pay AFTER  change highest pay rate: ${JSON.stringify(values)}`)
    },
    break_even_calc(billrate, basepay, freetime_stake, payroll_burden_percentage, hours_worked) {
      // console.log("BE params passed in...")
      // console.log(billrate, basepay, freetime_stake, payroll_burden_percentage, hours_worked)
      let payroll_burden_no_freetime = payroll_burden_percentage * basepay
      let payroll_burden_with_freetime = payroll_burden_percentage * (basepay + freetime_stake)

      let profit_with_freetime = billrate - basepay - freetime_stake - payroll_burden_with_freetime
      let profit_no_freetime = billrate - basepay - payroll_burden_no_freetime
      let ft_ratio = profit_no_freetime / profit_with_freetime
      // print(ft_ratio)
      let break_even_hours = hours_worked * ft_ratio
      break_even_hours = break_even_hours.toFixed(2)

      return [break_even_hours, profit_with_freetime, profit_no_freetime]

    },
    run_window_width_adjustments(new_w) {
      // console.log("new window size.")
      if (new_w >= 280) {
        this.action_button_width_ = 220
      }
      //

      if (new_w >= 500) {
        // console.log("Activating transparent slide menu")
        // this.opaque_slide_menu_x = false
      } else {
        // this.opaque_slide_menu_x = true
      }
      // if (new_w >= 375) {
      //   // message = 'The window width >= 375'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 540) {
      //   // message = 'The window width >= 540'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      if (new_w >= 400) {
        // message = 'The window width >= 640'
        this.action_button_width_ = 350
      }
      // if (new_w >= 1024) {
      //   // message = 'The window width >= 1024'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 1200) {
      //   // message = 'The window width >= 1200'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w <= desktop_size) {
      // remove_class_to_body('content_large_screen')
      // add_class_to_body('content_no_radial')
      // this.mobile_mode_x = true
      // const matches = document.querySelectorAll(".screen_block_no_shadows");
      // matches.forEach(function (match) {
      //   match.classList.remove('screen_block_no_shadows');
      //   match.classList.add('screen_block');
      // });

      // }
      // if (new_w >= desktop_size) {
      // remove_class_to_body('content_no_radial')
      // add_class_to_body('content_large_screen')
      // this.mobile_mode_x = false

      // const matches = document.querySelectorAll(".screen_block");
      // matches.forEach(function (match) {
      //   match.classList.remove('screen_block');
      //   match.classList.add('screen_block_no_shadows');
      // });

      // }
    },
  },

}
</script>

<style scoped>

@media (min-width: 100px) {

  .action_button{
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .data_box_style{
    margin-bottom: 20px;
  }

  .data_box_style_left{
    margin-right: 2px;
  }

   .data_box_style_right{
    margin-left: 2px;
  }
  .short_row{
    margin-top: 20px;
    margin-bottom: 20px;
  }


  .material_icon {
    color: white;
    border-radius: 50%;
    font-size: 40px;
    margin-right: 10px;
    margin-left: 10px;
  }

  h3 {
    color: white;
    background-color: var(--new-primary-purple-deep);
    font-family: 'Akshar', sans-serif;
    font-size: 25px;
    max-width: 85%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .header{
    margin-top: 30px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
  }
  .unit_label_text{
    max-width: 139px;
    text-align: center;
    margin-top: 20px;
    font-weight: 800;
    margin-bottom: 5px;
  }
  .unit_label_text_exception_1{
    max-width: 131px;
  }
   .unit_label_text_exception_2{
    max-width: 88px;
  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 2000px) {
  .be_main p {
    text-align: center;
    font-weight: 800;
  }

  .row {
    min-width: 500px;
  }

  .short_row {
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 250px;
  }

  .summary_items {
    margin-top: 40px;
    margin-bottom: 40px;
    min-width: 262px;
  }

  .summary_items p {
    margin-bottom: 25px;
  }

  .header {
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: 500px;
  }

  .material_icon {
    color: white;
    border-radius: 50%;
    font-size: 40px;
  }

  .minus_1 {
    margin-right: 15px;
  }

  .plus_1 {
    margin-left: 15px;
  }

  .action_button {
    padding: 15px 10px;
    margin-bottom: 100px;
    margin-top: 40px;
  }

  .a_button_text {
    font-family: Raleway, sans-serif;
    font-weight: 800;
    font-size: 22px;
    margin-left: 10px;
  }

}

</style>