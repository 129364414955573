<template>

  <div class="flex_centered_column">
    <error_popup_queue></error_popup_queue>

    <notification_popup></notification_popup>

    <basic_banner_new
        :banner_bg="colors.eli_gray"
        banner_height="80px"
        banner_width="100%"
        icon_width="100px"
    >
      <template v-slot:icon>
        <img @click="go_home" class="cursor_ptr center_banner_icon margin_tb_10"
             :src="require('@/assets/' + 're_one_line_tmh_stroke.svg')" alt="Center icon">
      </template>

    </basic_banner_new>


    <div class="screen_block banner_margin_top bg_eli_gray padding_t_10">
      <div class="label_regular_plus text_align_center margin_tb_10">Utility Tabs</div>
      <div class="flex_centered_row">
        <div class="flex_space_around_row wid_95 margin_tb_20">

          <basic_text_button
              class="shadow_3d"
              :button_state="true"
              :button_color="colors.pennie_gray"
              button_color_not_ready="gray"
              :button_height="button_size"
              :button_width="button_size"
              border_rad_pct="50%"
              text="GraphDB"
              :text_color="colors.black"
              text_size="12px"
              :bold="true"
              event_name="graphdb_tab"
              @graphdb_tab="open_new_tab('https://graph.tinymagichat.com:7473')"
          ></basic_text_button>

          <basic_text_button
              class="shadow_3d"
              :button_state="true"
              :button_color="colors.pennie_gray"
              button_color_not_ready="gray"
              :button_height="button_size"
              :button_width="button_size"
              border_rad_pct="50%"
              text="DocDB"
              :text_color="colors.black"
              text_size="12px"
              :bold="true"
              event_name="docdb_tab"
              @docdb_tab="open_new_tab('https://docdb.tinymagichat.com')"
          ></basic_text_button>

          <basic_text_button
              class="shadow_3d"
              :button_state="true"
              :button_color="colors.pennie_gray"
              button_color_not_ready="gray"
              :button_height="button_size"
              :button_width="button_size"
              border_rad_pct="50%"
              text="Logs"
              :text_color="colors.black"
              text_size="12px"
              :bold="true"
              event_name="logs_tab"
              @logs_tab="open_new_tab('https://logs.tinymagichat.com')"
          ></basic_text_button>

          <basic_text_button
              class="shadow_3d"
              :button_state="true"
              :button_color="colors.pennie_gray"
              button_color_not_ready="gray"
              :button_height="button_size"
              :button_width="button_size"
              border_rad_pct="50%"
              text="Email"
              :text_color="colors.black"
              text_size="12px"
              :bold="true"
              event_name="email_tab"
              @email_tab="open_new_tab('https://email.tinymagichat.com')"
          ></basic_text_button>

        </div>
      </div>


    </div>
    <div class="feature_buttons padding_b_5 margin_auto bg_whitesmoke margin_t_20">

      <div class="flex_space_around_row margin_tb_20 flex_wrap">

        <basic_icon_button
            v-if="true"
            class="cursor_ptr shadow_3d margin_t_20"
            :button_color="colors.white"
            :button_color_not_ready="colors.whitesmoke"
            :ready="true"
            :button_height="button_size"
            :button_width="button_size"
            border_rad_pct="50%"
            image_path='re_hat_only.svg'
            image_width="35px"
            alt_text="TMH Internals panel button"
            event_name="go_to_tmh_internals"
            @go_to_tmh_internals="go_to_tmh_internals"
        ></basic_icon_button>

        <basic_icon_button
            v-if="true"
            class="cursor_ptr shadow_3d margin_t_20"
            :button_color="colors.white"
            :button_color_not_ready="colors.pennie_gray"
            :ready="true"
            :button_height="button_size"
            :button_width="button_size"
            border_rad_pct="50%"
            image_path='starfinder.svg'
            image_width="40px"
            alt_text="StarFinder panel button"
            event_name="go_to_tmh_spyglass"
            @go_to_tmh_spyglass="go_to_tmh_spyglass"
        ></basic_icon_button>

        <basic_icon_button
            v-if="true"
            class="cursor_ptr shadow_3d margin_t_20"
            :button_color="colors.white"
            :button_color_not_ready="colors.pennie_gray"
            :ready="true"
            :button_height="button_size"
            :button_width="button_size"
            border_rad_pct="50%"
            image_path='wonder_perks.svg'
            image_width="40px"
            alt_text="WonderPerks Panel button"
            event_name="freetime_controls"
            @freetime_controls="go_to_freetime"
        ></basic_icon_button>


        <basic_icon_button
            v-if="true"
            class="cursor_ptr shadow_3d margin_t_20"
            :button_color="colors.white"
            :button_color_not_ready="colors.pennie_gray"
            :ready="true"
            :button_height="button_size"
            :button_width="button_size"
            border_rad_pct="50%"
            image_path='popdocs_text_only_stroke.svg'
            image_width="50px"
            alt_text="SpeedyDelivery panel button"
            event_name="go_to_speedydelivery"
            @go_to_speedydelivery="go_to_speedydelivery"
        ></basic_icon_button>

        <basic_icon_button
            v-if="true"
            class="cursor_ptr shadow_3d margin_t_20"
            :button_color="colors.white"
            :button_color_not_ready="colors.pennie_gray"
            :ready="true"
            :button_height="button_size"
            :button_width="button_size"
            border_rad_pct="50%"
            image_path='illumina.svg'
            image_width="40px"
            alt_text="Vortex panel button"
            event_name="go_to_vortex"
            @go_to_vortex="go_to_vortex"
        ></basic_icon_button>

        <basic_text_button
            class="shadow_3d margin_t_20"
            :button_state="true"
            :button_color="colors.new_primary_purple"
            button_color_not_ready="gray"
            :button_height="button_size"
            :button_width="button_size"
            border_rad_pct="50%"
            text="Mother PDA"
            :text_color="colors.white"
            text_size="12px"
            :bold="true"
            event_name="motherpda_controls"
            @motherpda_controls="go_to_pda_mother"
        ></basic_text_button>

         <basic_icon_button
            v-if="true"
            class="cursor_ptr shadow_3d margin_t_20"
            :button_color="colors.new_cyanic_main"
            :button_color_not_ready="colors.pennie_gray"
            :ready="true"
            :button_height="button_size"
            :button_width="button_size"
            border_rad_pct="50%"
            image_path='geography.svg'
            image_width="40px"
            alt_text="Geography panel button"
            event_name="go_to_tmh_geography"
            @go_to_tmh_geography="go_to_tmh_geography"
        ></basic_icon_button>


        <basic_icon_button
            v-if="true"
            class="cursor_ptr shadow_3d margin_t_20"
            :button_color="colors.pennie_gray"
            :button_color_not_ready="colors.pennie_gray"
            :ready="true"
            :button_height="button_size"
            :button_width="button_size"
            border_rad_pct="50%"
            image_path='forums_icon.svg'
            image_width="30px"
            alt_text="Forums panel button"
            event_name="go_to_forums"
            @go_to_forums="go_to_forums"
        ></basic_icon_button>

         <basic_icon_button
            v-if="true"
            class="cursor_ptr shadow_3d margin_t_20"
            :button_color="colors.pennie_gray"
            :button_color_not_ready="colors.pennie_gray"
            :ready="true"
            :button_height="button_size"
            :button_width="button_size"
            border_rad_pct="50%"
            image_path='dashboard.svg'
            image_width="30px"
            alt_text="Dashboard panel button"
            event_name="go_to_dashboard"
            @go_to_dashboard="go_to_dashboard"
        ></basic_icon_button>

      </div>

    </div>

    <router-view></router-view>
  </div>

</template>

<script>
import store from "@/store";
import {
  add_class_to_body, receive_over_socket, reset_current_error_message, desktop_size, get_new_url,
  get_profile_route, remove_class_to_body, prime_socket, colors, route_prep
} from "@/library";
import {Database} from "@/client_db";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import {mapGetters} from "vuex";
import basic_banner_new from "@/components/parts/basic_banner_new";
import basic_text_button from "@/components/parts/basic_text_button";
import basic_icon_button from "@/components/parts/basic_icon_button";

export default {
// components: {
//   basic_toggle
// },
  name: "tmh_admin_main",
  components: {
    basic_banner_new,
    notification_popup,
    error_popup_queue,
    basic_text_button,
    basic_icon_button
  },
  data() {
    return {
      colors: colors,
      button_size_: "55px"
      // model_this: false
    }
  },
  async beforeMount() {
    // console.log("Running beforeMount")
    this.db = new Database();
    await store.dispatch('notification_restart')
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)
    // await this.local_page_update()
    // await store.dispatch('notification_restart')
  },

  async mounted() {
    // console.log("running mounted hook")
  },
  methods: {
    tester() {
      console.log("something was clicked.")
    },

    open_new_tab(URL) {
      window.open(URL, '_blank');
    },

    profile_route() {
      return get_profile_route()
    },

    async go_home() {
      let analytic = '01:01:18:210'
      let home_route = get_profile_route(false)
      await route_prep(this.$router, home_route, analytic)
    },


    async get_new_route(envelope, context) {
      // console.log("inside get_new_route function")
      const preamble = [envelope, context]
      // console.log("envelope and context are:")
      // console.log(envelope)
      // console.log(context)
      store.dispatch('stack_proposed', preamble)
      // console.log("fire get_new_url")
      let new_url = await get_new_url(this.db)
      // console.log("Returned new url is...")
      // console.log(new_url)
      await this.$router.push({path: new_url})
    },
    async back_to_main() {
      // let current_route = store.getters.latest_route
      let new_url = '/tinymagichat/main'
      await this.$router.push({path: new_url})
    },
    async go_to_tmh_internals() {
      // let current_route = store.getters.latest_route
      store.commit('update_context_changing_route', false)
      let new_url = '/tinymagichat/main/tmh'
      await this.$router.push({path: new_url})
    },

    async go_to_freetime() {
      // let current_route = store.getters.latest_route
      store.commit('update_context_changing_route', false)
      let new_url = '/tinymagichat/main/freetime'
      await this.$router.push({path: new_url})
    },

     async go_to_pda_mother() {
      // let current_route = store.getters.latest_route
      store.commit('update_context_changing_route', false)
      let new_url = '/tinymagichat/main/pda_mother'
      await this.$router.push({path: new_url})
    },

    async go_to_speedydelivery() {
      // let current_route = store.getters.latest_route
      store.commit('update_context_changing_route', false)
      let new_url = '/tinymagichat/main/speedydelivery'
      await this.$router.push({path: new_url})
    },

    async go_to_vortex() {
      // let current_route = store.getters.latest_route
      store.commit('update_context_changing_route', false)
      let new_url = '/tinymagichat/main/vortex'
      await this.$router.push({path: new_url})
    },

    async go_to_forums() {
      // let current_route = store.getters.latest_route
      store.commit('update_context_changing_route', false)
      let new_url = '/tinymagichat/main/forums'
      await this.$router.push({path: new_url})
    },

    async go_to_dashboard() {
      // let current_route = store.getters.latest_route
      store.commit('update_context_changing_route', false)
      let new_url = '/tinymagichat/main/dashboard'
      await this.$router.push({path: new_url})
    },

    async go_to_tmh_spyglass() {
      // let current_route = store.getters.latest_route
      store.commit('update_context_changing_route', false)
      let new_url = '/tinymagichat/main/spyglass'
      await this.$router.push({path: new_url})
    },

    async go_to_tmh_geography() {
      // let current_route = store.getters.latest_route
      store.commit('update_context_changing_route', false)
      let new_url = '/tinymagichat/main/geography'
      await this.$router.push({path: new_url})
    },
    run_window_width_adjustments(new_w) {
      // if (new_w >= 280) {
      //   this.mobile_mode_x = true
      // }
      //
      if (new_w < 450) {
        // remove_class_to_body('content_no_radial')
        remove_class_to_body('content_large_screen')
      }
      if (new_w >= 450) {

        remove_class_to_body('content_large_screen')
        // add_class_to_body('content_no_radial')
        // this.mobile_mode_x = true
      }
      if (new_w >= 100 && new_w < 300) {
        // console.log(`New size is ${new_w}`)
        this.button_size_ = "55px"
      }

      if (new_w >= 300 && new_w < 1000) {
        // console.log(`New size is ${new_w}`)
        this.button_size_ = "65px"
      }

      // if (new_w >= 540) {
      //   // message = 'The window width >= 540'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 640) {
      //   // message = 'The window width >= 640'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 1024) {
      //   // message = 'The window width >= 1024'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      // if (new_w >= 1200) {
      //   // message = 'The window width >= 1200'
      //   document.querySelector('body').setAttribute('class', 'content_no_radial')
      // }
      if (new_w <= desktop_size) {
        remove_class_to_body('content_large_screen')
        // add_class_to_body('content_no_radial')
        this.mobile_mode_x = true
        const matches = document.querySelectorAll(".screen_block_no_shadows");
        matches.forEach(function (match) {
          match.classList.remove('screen_block_no_shadows');
          match.classList.add('screen_block');
        });

      }
      if (new_w >= desktop_size) {
        // remove_class_to_body('content_no_radial')
        add_class_to_body('content_large_screen')
        this.mobile_mode_x = false

        // const matches = document.querySelectorAll(".screen_block");
        // matches.forEach(function (match) {
        //   match.classList.remove('screen_block');
        //   match.classList.add('screen_block_no_shadows');
        // });

      }
    },
    async local_message_handler(from_server_json) {
      // console.log("Message from the socket server is ...")
      // console.log(from_server_json)
      let update = await receive_over_socket(from_server_json)
      if (update) {
        console.log("local update has been commanded, but not executed.")
        // store.commit('freetime_dropbox_history_report_update', true)

      }
    },
    local_reset_error_message() {
      reset_current_error_message()
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    }
  },
  computed: {
    ...mapGetters([
      'current_error_message'
    ]),
    windowWidth() {
      return store.state.windowWidth;
    },
    button_size() {
      return this.button_size_
    },
  },
}
</script>

<style scoped>

.tmh_icon {
  width: 100px;
  padding-top: 3vh;
}

.banner_margin_top {
  margin-top: var(--banner_offset3);
}

@media (min-width: 100px) {
 .feature_buttons{
    max-width: 700px;
   width: 98vw;
  }
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

</style>