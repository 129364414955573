<template>
  <div class="screen_block margin_tb_20  flex_centered_row margin_auto bg_white">
    <img class="freetime_color padding_tb_20"
         src="@/assets/wonder_perks.svg"
         alt="WonderPerks Logo">
  </div>
   <spinner
        :spinner_up="spinner_up"
     ></spinner>

  <select_and_launch_2
      class="margin_auto bg_whitesmoke font_raleway font_weight_800"
      label='Subscribe a PDA to WonderPerks'
      :label_color=colors.black
      selection_title="PDAs"
      button_text="Subscribe"
      :values="non_subscribing_pdas"
      event_name="create_pda_subscription"
      @create_pda_subscription="create_pda_subscription"
  ></select_and_launch_2>

  <select_and_launch_2
      class="margin_auto bg_whitesmoke  font_raleway font_weight_800 margin_t_20"
      label='Suspend a PDA from WonderPerks'
      :label_color=colors.black
      selection_title="PDAs"
      button_text="Suspend"
      :values="good_standing_subs"
      event_name="suspend_pda_subscription"
      @suspend_pda_subscription="suspend_pda_subscription"
  ></select_and_launch_2>

<!--  <div class="screen_block text_white">-->
<!--    get list of all subscribers, suspended subs, terminated subs-->
<!--    What about subscription history?-->
<!--  </div>-->
</template>

<script>
import select_and_launch_2 from "@/components/parts/select_and_launch_2";
import spinner from "@/components/parts/spinner";
import store from "@/store";
import axios from "axios";
import {mapGetters} from "vuex";
import {
  parse_internal_json, colors, be_main_address
} from "@/library";

export default {
  name: "tmh_freetime_controls",
  data() {
    return {
      colors: colors,
      selected_subscriber_: "",
      selected_suspendee_: "",
      selected_terminatee_: "",
      good_standing_subs_: null,
      non_subscribing_pdas_: null
    }
  },

  components: {
    select_and_launch_2,
    spinner
  },
  async mounted() {
    // console.log("tmh_freetime_controls running mounted hook")
    await this.local_page_update()
  },
  methods: {


    async local_page_update() {
      await this.get_list_of_good_subscribers()
      await this.get_list_of_non_subscribers()
    },
    async create_pda_subscription(state) {
      console.log(`Caught the create subscription event for ${state}.`)
      this.selected_subscriber_ = state
      await this.subscribe(state)
      await this.local_page_update()
    },
    async suspend_pda_subscription(state) {
      console.log(`Caught the suspend subscription event for ${state}.`)
      this.selected_suspendee_ = state
      await this.suspend(state)
      await this.local_page_update()
    },
    // async terminate_pda_subscription(state){
    //   console.log(`Caught the terminate subscription event for ${state}.`)
    //   this.selected_terminatee_ = state
    //   await this.delete(state)
    //   await this.local_page_update()
    // },

    async get_list_of_non_subscribers() {
      let target_url = be_main_address('ft') + 'get_non_subscribers'
      // let target_url = 'http://192.168.1.10:8087/ft/get_non_subscribers'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: null
      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              }else{
                this.non_subscribing_pdas_ = response.data
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    async get_list_of_good_subscribers() {
      let target_url = be_main_address('ft') + 'get_good_subscribers'
      // let target_url = 'http://192.168.1.10:8087/ft/get_good_subscribers'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: null
      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" | response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                this.good_standing_subs_ = response.data
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    async subscribe(subscribee) {
      store.commit('set_spinner_state', true)
      let target_url = be_main_address('ft') + 'create_subscription'
      // let target_url = 'http://192.168.1.10:8087/ft/create_subscription'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        // subscriber: "",
        subscribing_agency: subscribee

      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" | response.status === 200) {
              console.log("in axios...response.data is")
              console.log(response.data)
              console.log(typeof response.data)
              let message = `${subscribee} has been subscribed to WonderPerks.`
              store.commit('notification_enqueue', message)
            } else {
               console.log("SERVER ERROR")
              console.log(response)
              store.commit('set_spinner_state', false)
              return "this is an error"
            }
            store.commit('set_spinner_state', false)
          })
    },
    async suspend(subscriber) {
      // let target_url = 'http://192.168.1.10:8087/ft/suspend_subscription'
      let target_url = be_main_address('ft') + 'suspend_subscription'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: subscriber
      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" | response.status === 200) {
              console.log("in axios suspend...response.data is")
              console.log(response.data)
              console.log(typeof response.data)
            } else {
             console.log("SERVER ERROR")
              console.log(response)
              return "this is an error"
            }
          })
    },
    // async delete(subscriber){
    //   let target_url = 'http://192.168.1.10:8087/ft/delete_subscription'
    //   let data1 = {
    //     caller: store.getters.my_name,
    //     id_token: store.getters.my_id_token,
    //     refresh_token: store.getters.my_refresh_token,
    //     subscriber: subscriber
    //   }
    //
    //   axios.post(target_url, data1)
    //       .then((response) => {
    //         if (response.statusText === "OK") {
    //           console.log("in axios destroy...response.data is")
    //           console.log(response.data)
    //           console.log(typeof response.data)
    //
    //           // return JSON.parse(response.data)
    //           // this.good_standing_subs_ = response.data
    //           // return response.data
    //           // store.commit("save_exclusions_at_server", response.data.data['exclusions'])
    //           // store.commit("save_match_at_server", response.data.data['match'])
    //         } else {
    //           console.log("in axios ERROR")
    //           console.log(response)
    //           return "this is an error"
    //         }
    //       })
    // },
  },
  computed: {
    ...mapGetters([
      'current_error_message'
    ]),
    spinner_up(){
      return store.getters.get_spinner_state
    },
    selected_subscriber() {
      return this.selected_subscriber_
    },
    selected_suspendee() {
      return this.selected_suspendee_
    },
    selected_terminatee() {
      return this.selected_terminatee_
    },
    good_standing_subs() {
      return this.good_standing_subs_
    },
    non_subscribing_pdas() {
      return this.non_subscribing_pdas_
    },
  },

}
</script>

<style scoped>
.freetime_color {
  width: 120px;
  /*padding-top: .67rem;*/
}
</style>