<template>
  <section class="flex_centered_column">
    <inside_banner mode="freetime_cg"></inside_banner>
    <img class="padding_tb_20 ft_logo"  @click="go_to_profile_home_route"
         src="@/assets/wonder_perks.svg"
         alt="Freetime Feature Logo">
    <!--    <h1 class="main_title">FreeTime Bonus</h1>-->
    <div class="flex_space_between_column top_side_content">
      <div class="flex_centered_row ">
        <div class="text_primary_purple_deep bonus_label">Bonus</div>
        <div class="left_right_item_spacer"></div>
        <div v-if="last_bonus_value" class="text_primary_purple_deep bonus_label">${{ last_bonus_value }}</div>
        <div v-else class="text_primary_purple_deep bonus_label">None</div>
        <div class="flex_centered_column">

<!--          <span class="material-symbols-outlined material_icon cursor_ptr"-->
<!--                @click="non_contextual_route('help')">help</span>-->
          <img class="material_icon" @click="non_contextual_route('help')"
                   :src="image_name('help.svg')"
                   alt=" Icon">
        </div>
      </div>
      <div class="flex_space_between_row">
        <div class="flex_centered_column">
          <h4>Week</h4>
          <h3 v-if="last_bonus_date">{{ last_bonus_date }}</h3>
          <h3 v-else>No Week</h3>
        </div>
        <div class="left_right_column_spacer"></div>
        <div class="flex_centered_column">
          <div class="flex_centered_row ">
            <h4> Total Hours</h4>

          </div>
          <h3 v-if="last_bonus_hours">{{ last_bonus_hours }}</h3>
          <h3 v-else>No Hours</h3>
        </div>
      </div>
      <div class="flex_centered_column">
        <h4>WonderPerks Hourly Rate</h4>
        <h3 v-if="last_bonus_ehr">${{ last_bonus_ehr }}</h3>
        <h3 v-else>None</h3>
      </div>
    </div>
    <agency_message
        class="agency_message"
        :message="last_agency_message"
        :text_color="colors.wyatt_gray"
        :bg_color="colors.pennie_gray"
    ></agency_message>

    <div v-if="report_dates">
      <h1 class="history_title text_align_center">History</h1>
      <report_selector
          class="margin_b_20 margin_t_10"
          left_arrow_image="lt_arrow.svg"
          right_arrow_image="rt_arrow.svg"
          :label_list=report_dates
          :bg_color="colors.white"
          @selection_chosen="set_current_history_date_selection"
      ></report_selector>

      <div class="flex_space_between_column">
        <div class="flex_centered_row ">
          <div class="text_primary_purple_deep bonus_label">Bonus</div>


          <div class="left_right_item_spacer"></div>
          <div v-if="current_history_bonus" class="text_primary_purple_deep bonus_label">${{
              current_history_bonus
            }}
          </div>
          <div v-else class="text_primary_purple_deep bonus_label">None</div>
        </div>
        <div class="flex_centered_row ">
          <div class="text_primary_purple_deep bonus_label">Bonus YTD</div>
          <div class="left_right_item_spacer"></div>
          <div v-if="current_bonus_ytd" class="text_primary_purple_deep bonus_label">${{ current_bonus_ytd }}</div>
          <div v-else class="text_primary_purple_deep bonus_label">None</div>
        </div>
        <div class="flex_space_between_row bonus_label bottom_side_content">
          <div class="flex_centered_column">
            <h4>Total Hours</h4>
            <h3 v-if="current_history_hours">{{ current_history_hours }}</h3>
            <h3 v-else>No Hours</h3>
          </div>
<!--          <div class="left_right_column_spacer"></div>-->
          <div class="flex_centered_column">

            <h4>WonderPerks Hourly Rate</h4>
            <h3 v-if="current_history_ehr">${{ current_history_ehr }}</h3>
            <h3 v-else>No Rate</h3>
          </div>
        </div>
        <div class="flex_centered_column bonus_label">
          <div class="text_primary_purple_deep bonus_label">Agency Bonuses</div>
          <div class="flex_space_between_row agency_bonuses bonus_label">
            <div class="flex_centered_column">
              <h4>Top Bonus</h4>
              <h3 v-if="highest_bonus">${{ highest_bonus }}</h3>
              <h3 v-else>None</h3>
            </div>
            <div class="flex_centered_column">
              <h4>Total YTD</h4>
              <h3 v-if="top_ytd_sum_bonus">${{ top_ytd_sum_bonus }}</h3>
              <h3 v-else>None</h3>
            </div>
          </div>

        </div>
      </div>


      <agency_message
          class="agency_message"
          :message="current_history_agency_message"
          :text_color="colors.wyatt_gray"
          :bg_color="colors.pennie_gray"
      ></agency_message>
    </div>


    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
  </section>

</template>
<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable no-unused-vars */

import {Database} from "@/client_db";
import axios from "axios";
import label_value_non_editable from "@/components/parts/label_value_non_editable";
import caregiver_main_display from "@/components/FreeTime/caregiver_main_display";
import report_selector from "@/components/FreeTime/report_selector";
import agency_message from "@/components/FreeTime/agency_message";
import basic_slide_over_menu from "@/components/parts/basic_slide_over_menu";
import basic_text_button from "@/components/parts/basic_text_button";
import universal_invisible_backer from "@/components/parts/universal_invisible_backer";
import basic_icon_button from "@/components/parts/basic_icon_button";
import basic_banner_new from "@/components/parts/basic_banner_new";
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import caregiver_main_display2 from "@/components/FreeTime/caregiver_main_display2";
import outside_footer from "@/components/parts/outside_footer";
import inside_banner from "@/components/parts/inside_banner";

import {
  colors, prime_socket, receive_over_socket, round, be_main_address,
  get_profile_route, get_pda_home_route, get_date_string, image_name,
} from "@/library";
import store from "@/store";
import {mapGetters} from "vuex";

export default {
  name: "caregiver_freetime_widgit2",
  props: [],
  components: {
    label_value_non_editable,
    caregiver_main_display,
    caregiver_main_display2,
    report_selector,
    agency_message,
    basic_slide_over_menu,
    basic_text_button,
    universal_invisible_backer,
    basic_icon_button,
    basic_banner_new,
    error_popup_queue,
    notification_popup,
    inside_banner,
    outside_footer
  },


  async beforeMount() {
    // console.log("Caregiver widget mounted")
    await store.dispatch('notification_restart')
    await prime_socket(this.local_message_handler)
    this.db = new Database();
    await this.local_page_update()
  },


  data() {
    return {
      colors: colors,
      hamburger_menu_up_x: false,
      hamburger: {
        show: false
      },
      temp_list: ['one', 'two', 'three'],
      history: {},
      last_report_date_x: "",
      last_report_bonus_value_x: "",
      last_bonus_value_: "",
      last_bonus_date_: "",
      last_bonus_hours_: "",
      last_bonus_ehr_: "",
      current_history_date_: "",
      current_history_bonus_: "",
      current_history_top_bonus_: "",
      current_history_bonus_ytd_: "",
      current_history_top_bonus_ytd_: "",
      current_bonus_ytd_: "0.00",
      highest_bonuses_: {},
      top_ytd_bonuses_: "",
      bonus_messages_: {},

    }
  },
  methods: {
    tester(d) {
      console.log(`You selected the date ${d}.`)
    },
     async go_to_profile_home_route() {
      await this.$router.push({path: `${get_pda_home_route()}`})
    },
    image_name(name) {
      return image_name(name)
    },
    async non_contextual_route(route = null) {
      // this.hamburger_menu_false()
      console.log("Current Context")
      console.log("")
      let current = localStorage.getItem('dev_latest_route')

      store.commit('update_context_changing_route', false)
      let new_url = current + '/' + route

      await this.$router.push({path: new_url})
    },
    do_nothing() {
      //pass
    },

    hamburger_menu_toggle() {
      console.log("Hamburger toggled")
      this.hamburger.show = !this.hamburger.show
    },
    // hamburger_backer_clicked() {
    //   // console.log("pop up full dark glass was clicked and is emitting.")
    //   this.$emit('hamburger_backer_clicked', true)
    //   // this.$emit(this.event_name, true)
    // },
    async pda_home_button_clicked() {
      // console.log("inside profile_button_clicked")
      try {
        // this.$emit('hamburger_backer_clicked', true)
        await this.$router.push({path: `${get_pda_home_route()}`})

      } catch (error) {
        console.log(error)
      }

    },
    async profile_button_clicked() {
      // console.log("inside profile_button_clicked")
      try {
        // this.$emit('hamburger_backer_clicked', true)
        await this.$router.push({path: `${get_profile_route()}`})

      } catch (error) {
        console.log(error)
      }

    },

    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },

    async local_page_update() {
      console.log("Running caregiver FreeTime Interface local page update.")
      await this.get_bonus_ytd()
      await this.load_caregiver_reports()
      await this.get_top_bonuses_for_each_week()
      await this.get_ytd_top_bonuses_sum()
      await this.get_agency_messages_for_each_week()
    },
    async get_ytd_top_bonuses_sum() {
      // console.log("inside get_ytd_top_bonuses_sum")
      let target_url = be_main_address('freetime') + 'get_sum_top_ytd_bonuses'
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }

      if (data1.subscriber.length === 0 || data1.subscriber === undefined) {
        return null
      }

      axios.post(target_url, data1)
          .then((response) => {
            // console.log("RESPONSE FROM get_ytd_top_bonuses_sum")
            // console.log(response.data)
            // console.log(parse_internal_json(response))
            this.top_ytd_bonuses_ = response.data['top_ytd_sum']
          })
    },
    async get_agency_messages_for_each_week() {
      // console.log("inside get_agency_messages_for_each_week")
      let target_url = be_main_address('freetime') + 'get_agency_messages'
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }

      if (data1.subscriber.length === 0 || data1.subscriber === undefined) {
        return null
      }

      axios.post(target_url, data1)
          .then((response) => {
            // console.log("RESPONSE FROM get_agency_messages_for_each_week")
            // console.log(response.data)

            // console.log("Iterating the collect agency messages response")
            for (const start_date in response.data) {
              // console.log(start_date)
              // console.log(response.data[start_date])
              let a_bonus_set = this.history[start_date]
              a_bonus_set.agency_message = response.data[start_date]
              // console.log(a_bonus_set)

            }
          })
    },
    async get_top_bonuses_for_each_week() {
      // console.log("inside get_top_bonuses_for_each_week")
      let target_url = be_main_address('freetime') + 'get_top_bonuses'
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token
      }

      if (data1.subscriber.length === 0 || data1.subscriber === undefined) {
        return null
      }

      axios.post(target_url, data1)
          .then((response) => {
            // console.log("RESPONSE FROM get_top_bonuses_for_each_week")
            // console.log(response.data)
            // console.log(parse_internal_json(response))
            this.highest_bonuses_ = response.data
            // if (response.data.error === false) {
            //   store.commit("save_exclusions_at_server", response.data.data['exclusions'])
            //   store.commit("save_match_at_server", response.data.data['match'])
            // } else {
            //   let message = parse_internal_json(response.data)['payload']['content']['message']
            //   store.commit('error_enqueue', message)
            // }
          })
    },
    set_current_history_date_selection(d) {
      // console.log(`Current history date set is ${d}`)
      let dd = new Date(d)

      this.current_history_date_ = get_date_string(dd, 3)
    },
    async get_bonus_ytd() {
      // console.log("inside get_bonus_ytd")

      let search = {
        key: "",
        additional_context: [[store.getters.my_name, 'weekly_bonus_data']],
        base_context: store.getters.find_current_preamble
      }

      let all_docs = await this.db.db_search(search)
      console.log("what is cargiver weekly bonus data length?")
      console.log((all_docs.length).toString())

      let total = 0
      let current_year_str = new Date().getFullYear().toString()
      // let current_year_str = "2021"

      for (const c of all_docs) {
        // console.log(`Looking to match ${current_year_str} to ${c.start_date.slice(0, 4)}.`)

        if (c.start_date.slice(0, 4) === current_year_str) {
          // console.log("matched")
          // console.log(c.bonus)
          // console.log(typeof c.bonus)
          total = total + c.bonus
          // console.log(`now the total is ${total}`)
        } else {
          // console.log(`${c.start_date.slice(0, 4)} did not match.`)
        }
      }
      // console.log(`Grand Total bonus ytd is ${total.toString()}`)
      this.current_bonus_ytd_ = total.toString()
    },

    async load_caregiver_reports() {
      // console.log("inside MODERN history reports")

      let search = {
        key: "",
        additional_context: [[store.getters.my_name, 'weekly_bonus_data']],
        base_context: store.getters.find_current_preamble
      }

      // console.log(JSON.stringify(search))

      let all_docs = await this.db.db_search(search)
      // console.log("Found the following Caregiver bonus documents")
      // console.log(JSON.stringify(all_docs))
      // console.log(`Found ${all_docs.length} docs.`)

      if (all_docs.length === 0) {
        return null
      }

      for (const c of all_docs) {

        let weekly_bonus = {
          'start_date': c.start_date,
          'bonus': c.bonus,
          'hours': c.hours,
          'ehr': c.ehr,
          'agency_message': ""
        }

        this.history[c.start_date] = weekly_bonus
      }
      // console.log(JSON.stringify(this.history))

      let history_dates = Object.keys(this.history).sort()
      this.current_history_date_ = history_dates[history_dates.length - 1]
      this.last_report_date_x = this.current_history_date_

      this.last_bonus_value_ = this.history[this.last_report_date_x].bonus
      this.last_bonus_date_ = this.last_report_date_x
      this.last_bonus_hours_ = this.history[this.last_report_date_x].hours
      this.last_bonus_ehr_ = this.history[this.last_report_date_x].ehr
      // this.current_agency_message_x = this.history.agency_messages[this.current_history_report_date_x]
    },

  },
  watch: {
    async update_page() {
      console.log("update_page watcher is triggered")
      await this.local_page_update()
    },
     async socket_connection_counter(old_val, new_val){
        await prime_socket(this.local_message_handler)
    },
  },
  computed: {
     ...mapGetters([
      'socket_connection_counter',
      'window_width'

    ]),
    // windowWidth() {
    //   return store.state.windowWidth;
    // },
    hamburger_menu_show() {
      return this.hamburger.show
    },
    update_page() {
      // console.log("update_page computed property is triggered.")
      return store.getters.universal_page_update
    },

    report_dates() {
      // return ['2022-11-22', '2022-11-15']
      console.log("here are the dates")
      if (Object.keys(this.history).length === 0) {
        return null
      }
      console.log(Object.keys(this.history))
      return Object.keys(this.history).sort()
    },
    highest_bonus() {
      // console.log(`HIGHESST BONUS VALUE IS ${this.highest_bonuses_[this.current_history_date]}`)
      if (this.highest_bonuses_[this.current_history_date] === undefined) {
        return "None"
      } else {
        let hb = this.highest_bonuses_[this.current_history_date]
        return round(hb.toString(), 2)
      }
    },
    top_ytd_sum_bonus() {
      try {
        return round(this.top_ytd_bonuses_.toString(), 2)
      } catch (error) {
        return "0.00"
      }
    },
    current_bonus_ytd() {
      try {
        return round(this.current_bonus_ytd_.toString(), 2)
      } catch (error) {
        return "None"
      }
    },
    current_history_date() {
      return this.current_history_date_
    },
    current_history_bonus() {
      try {
        let the_bonus = this.history[this.current_history_date].bonus
        if (the_bonus === 0) {
          return "0.00"
        } else {
          return round(the_bonus.toString(), 2)
        }
        // return this.history[this.current_history_date].bonus
      } catch (error) {
        return null
      }
    },
    current_history_hours() {
      try {
        return this.history[this.current_history_date].hours
      } catch (error) {
        return "None"
      }
    },
    current_history_ehr() {
      try {
        let the_ehr = this.history[this.current_history_date].ehr
        if (the_ehr === 0) {
          return "0.00"
        } else {
          return round(the_ehr.toString(), 2)
        }
      } catch (error) {
        return "None"
      }
    },

    last_bonus_value() {
      if (this.last_bonus_value_ === "") {
        return null
      }
      try {
        return round(this.last_bonus_value_.toString(), 2)
      } catch (error) {
        return "0.00"
      }
    },
    last_bonus_date() {
      if (this.last_bonus_date_ === "") {
        return null
      } else {
        return this.last_bonus_date_
      }

    },
    last_bonus_hours() {
      if (this.last_bonus_hours_ === "") {
        return null
      } else {
        return this.last_bonus_hours_
      }
    },
    last_bonus_ehr() {
      if (this.last_bonus_ehr_ === "") {
        return null
      } else {
        return round(this.last_bonus_ehr_.toString(), 2)
      }
    },

    last_date_in_history() {
      let history_dates = Object.keys(this.history).sort()
      let last_date = history_dates[history_dates.length - 1]
      return last_date
    },
    current_history_agency_message() {
      try {
        return this.history[this.current_history_date].agency_message
      } catch (error) {
        return "No current history message"
      }
    },
    last_agency_message() {
      try {
        return this.history[this.last_date_in_history].agency_message
      } catch (error) {
        return "No current message"
      }

    },


  }
}

</script>

<style scoped>


.cg_main_display {
  max-width: 100%;
}

.ft_info {
  max-width: 100%;
}


.cg_ft_widgit {
  background-color: var(--new-primary-purple);

}

@media (min-width: 100px) {


  .agency_bonuses{
    width: 250px;
  }

  .ft_logo {
    width: 150px
  }


  .bottom_side_content{
    width: 270px;
  }

  .agency_message {
    font-size: 20px;
  }

  .material_icon {
    width: 30px;
    margin-left: 20px;
    color: var(--new-primary-purple);
  }

  .bonus_label {
    color: var(--primary-purple);
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    margin-top: 20px;
  }

  h1 {
    font-size: 30px;
    margin-top: 20px;
  }

  h4 {
    font-family: 'Raleway', sans-serif;
    color: var(--wyatt_gray);
    font-weight: 300;
    margin-top: 20px;
    text-align: center;

  }

  h3 {
    font-family: 'Raleway', sans-serif;
    color: black;
    font-size: 20px;

  }

  .left_right_item_spacer {
    width: 30px;
  }

  .left_right_column_spacer {
    width: 50px;
  }

  .cg_main_display {
    max-width: 100vw;
  }

  .ft_info {
    max-width: 100vw;
  }
}

@media (min-width: 500px) {
  .cg_main_display {
    max-width: 80vw;
  }

  .ft_info {
    max-width: 80vw;
  }
}

@media (min-width: 600px) {
  .cg_main_display {
    max-width: 70vw;
  }

  .ft_info {
    max-width: 70vw;
  }
}

@media (min-width: 700px) {
  .cg_main_display {
    max-width: 70vw;
  }

  .ft_info {
    max-width: 700px;
  }
}


@media (min-width: 800px) {
  .cg_main_display {
    max-width: 50vw;
  }


}

@media (min-width: 1000px) {
  .cg_main_display {
    max-width: 500px;
  }


}

@media (min-width: 1200px) {

   .ft_logo {
    width: 250px
  }

  .bottom_side_content{
    width: 600px;
  }


  h4 {
    font-family: 'Raleway', sans-serif;
    color: var(--wyatt_gray);
    font-weight: 300;
    margin-top: 20px;
    text-align: center;
     font-size: 30px;

  }

  h3 {
    font-family: 'Raleway', sans-serif;
    color: black;
    font-size: 30px;

  }

  .bonus_label {
    color: var(--primary-purple);
    font-size: 35px;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
     margin-top: 40px;
  }

  .agency_bonuses h3 {
    color: var(--cyanic-blue);
  }

  .agency_bonuses h4 {
    color: var(--new-primary-purple-deep);
  }

  .agency_message {
    width: 600px;
    margin-top: 40px;
    padding: 20px;
  }

  .main_title {
    margin-bottom: 40px;
  }

  .history_title {
    margin-top: 70px;
  }

  .top_side_content {
    height: 325px;
  }

  .bottom_side_content {
    /*height: 325px;*/
  }

  .cg_main_display {
    max-width: 500px;
  }

  .left_right_column_spacer {
    width: 250px;
  }



  .agency_bonuses {
    width: 500px;
  }

  .material_icon {
    font-size: 50px;
    margin-left: 20px;
    color: var(--new-primary-purple);
  }

}

</style>