<template>

  <div class=" flex_centered_column  ft_stamp_body">
    <div class="  font_raleway   ft_stat_items">
      <div class="ft_stat_item_pair">
        <p class="ft_value">Base Pay Rate:</p>
        <div class="ft_item_spacer "></div>
<!--        <p>$13.00</p>-->
        <p class="ft_value">${{ base }}</p>
      </div>
      <div class="small_ft_hat_frame margin_tb_10 b_rad_10px flex_start_row " id="with_ft">
      <p class="with ft_value">With</p>
      <img class="small_ft_hat"  :src="require('@/assets/' + 'wonder_perks_text_only.svg')">
      <!--      <img class="small_ft_hat" src="demo_logo.bmp">-->
    </div>

      <div class="ft_item_spacer"></div>

      <div class="ft_stat_item_pair">
        <p class="ft_value">Full Time Avg:</p>
        <div class="ft_item_spacer"></div>
        <p class="ft_value">${{ fulltime_avg }}</p>
<!--        <p class="ft_value">$18.25</p>-->
      </div>

      <div class="ft_item_spacer"></div>

      <div class="ft_stat_item_pair">
        <p class="ft_value">Full Time High:</p>
        <div class="ft_item_spacer"></div>
<!--        <p class="ft_value">$20.23</p>-->
        <p class="ft_value">${{ fulltime_high }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FreeTime_stamp_trad_ad_sms",
  props: [
    "base",
    "fulltime_avg",
    "fulltime_high"
  ],
}
</script>

<style scoped>
.small_ft_hat {
  width: 190px;
}

@media (min-width: 100px) {

  p{
    font-weight: 800;
  };

  .small_ft_hat {
    height: 60%;
    /*margin-bottom: 5px;*/
  }

  .ft_value{
    color: var(--trad-ad-ft-stamp-value-text);
    font-weight: 800;
  }

  .small_ft_hat_frame {
    background-color: transparent;
    align-items: center;
  }

  .with{
    margin-right: 10px;
  }
  .ft_stamp_body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 7px;
    background-color: var(--trad-ad-freetime-stamp-bg-sms);
    max-width: 300px;
  }

  .ft_stamp_body p{
    font-size: 20px;
  }

  .ft_stat_item_pair {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 275px;
  }

  .ft_item_spacer {
    flex-grow: 1;
  }

  .ft_stat_items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    width: 300px
  }
}


@media (min-width: 360px) {

  .ft_stamp_body {
    padding: 15px;
  }
}

@media (min-width: 1200px) {
  .small_ft_hat {
    height: unset;
    width: 200px;
    margin-left: 25px;
    margin-bottom: 5px;
  }

  .ft_stat_item_pair {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 275px;
  }

  #with_ft{
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .ft_stamp_body {
    padding: unset;
    padding-left: 40px;
    padding-right: 70px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .posted{

  }

}

@media (min-width: 1400px){
   .ft_stamp_body p{
    font-size: 20px;
  }

  .ft_stat_item_pair {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /*align-items: center;*/
    /*align-content: flex-start;*/
    width: 275px;
  }
}

</style>