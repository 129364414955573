<template>
  <section class="screen_block bg_new_gold_thin ">
    <div class="flex_end_row margin_lr_20 margin_t_10 flex_align_items_center max_w_vw">
       <div class="flex_grow">
        <h3>Plus</h3>
      </div>

      <basic_toggle ref="ad_handle_toggle"
                    class="margin_lr_10"
                    uuid=""
                    :state_disabled="false"
                    @toggle-state_true="toggle_is_true"
                    @toggle-state_false="toggle_is_false"
      ></basic_toggle>
      <basic_text_button
          class="margin_t_10"
          :button_state="buttons_active"
          :hover_color="colors.new_orange_main"
          :button_color="colors.new_orange_main"
          :text_color="colors.black"
          :button_color_not_ready="colors.gray"
          :button_not_ready_text_color="colors.morgie_gray"
          button_height="40px"
          button_width="100px"
          border_rad_pct="5%"
          text="Retire Ad"
          button_not_ready_text="Retire Ad"
          text_size="14px"
          :bold="true"
          event_name="retire_ad_click"
          @retire_ad_click="retire_ad"
      ></basic_text_button>
      <basic_text_button
          class="margin_t_10 margin_l_10"
          :button_state="buttons_active"
          :hover_color="colors.new_cyanic_main"
          :button_color="colors.new_cyanic_main"
          :text_color="colors.black"
          :button_color_not_ready="colors.gray"
          :button_not_ready_text_color="colors.morgie_gray"
          button_height="40px"
          button_width="100px"
          border_rad_pct="5%"
          text="Extend Ad"
          button_not_ready_text="Extend Ad"
          text_size="14px"
          :bold="true"
          event_name="extend_ad_click"
          @extend_ad_click="extend_ad"
      ></basic_text_button>
    </div>
    <div class="text_30 margin_tb_10 margin_lr_10 handle_headline_text  font_raleway font_weight_800">{{ headline }}</div>
    <div class="margin_lr_10 handle_body_text font_raleway">{{ body }}</div>
    <div class="margin_lr_10 handle_body_text font_raleway font_weight_800">{{ ad_handle_values.client_name }}</div>
    <div class="margin_lr_10 handle_body_text font_raleway font_weight_800">{{ getDayAndTime(ad_handle_values.start_time) }}</div>
    <div class="ad_handle_details flex_start_row flex_wrap bg_morgie_gray ">
      <div class="margin_lr_10 margin_tb_10 padding_tb_5 font_raleway  padding_lr_6 bg_whitesmoke b_rad_5px">Expires:
        {{ expires_pretty }}
      </div>
      <div class="margin_lr_10 margin_tb_10 padding_tb_5 font_raleway  padding_lr_6 bg_whitesmoke b_rad_5px">Pub:
        {{ user_publisher }}
      </div>
      <div class="margin_lr_10 margin_tb_10 padding_tb_5 font_raleway  padding_lr_6 bg_whitesmoke b_rad_5px">Neighborhood:
        {{ neighborhood }}
      </div>
      <div v-if="ad_handle_values['is_plus_ad']==='True'"
           class="margin_lr_10 margin_tb_10 padding_tb_5 font_raleway  padding_lr_6 bg_whitesmoke b_rad_5px">StarFinder+:
        ${{ ad_handle_values['plus_rate'] }}/hour
      </div>
    </div>


    <div class="ad_handle_details flex_start_row flex_wrap bg_cyanic_mid padding_tb_10">
<!--      <div v-if="ad_handle_values.length>0">Views</div>-->
      <div v-if="ad_handle_values['views']" class="views margin_tb_5 margin_l_10 wid_100  font_raleway font_weight_800">Views ({{Object.keys(ad_handle_values['views']).length}})</div>

      <div v-for="(item, index) of ad_handle_values['views']" :key="`item-${index}`" @click="handle_view_click(item)"
           class="margin_lr_10 margin_tb_10 padding_tb_5  padding_lr_6 bg_whitesmoke b_rad_5px cursor_ptr font_raleway">
        {{ ad_handle_values['translations'][item] }}
      </div>

    </div>
    <div class="ad_handle_details flex_start_row flex_wrap bg_wyatt_gray padding_tb_10">
       <div v-if="!isEmpty(ad_handle_values['clicks'])"   class="clicks margin_tb_5 wid_100 margin_l_10  font_raleway font_weight_800 text_white" >Clicks ({{Object.keys(ad_handle_values['clicks']).length}})</div>
      <div v-for="(item, index) of ad_handle_values['clicks']" :key="`item-${index}` " @click="handle_click_click(item)"
           class="margin_lr_10 margin_tb_10 padding_tb_5  padding_lr_6 bg_whitesmoke b_rad_5px cursor_ptr font_raleway">
        {{ ad_handle_values['translations'][item] }}
      </div>

    </div>

  </section>
</template>

<script>
import basic_toggle from "@/components/parts/basic_toggle";
import basic_text_button from "@/components/parts/basic_text_button";
import {
  colors, isEmpty, translate_utc_std_timestring_to_local_pretty_timestring
} from "@/library";
import store from "@/store";
import {Database} from "@/client_db";
import {getDayAndTime, getDayOfWeek} from "../../../dates_library";
// import router from "@/router";

export default {
  name: "plus_ad_handle",
  components: {
    basic_toggle,
    basic_text_button
  },
  mounted() {
    this.db = new Database();
    console.log("Here is the passed in ad handle values.")
    console.log(this.ad_handle_values)
  },
  props: ['body', 'headline', 'neighborhood', 'expires', 'user_publisher', 'ad_id', 'ad_handle_values'],
  data() {
    return {
      colors: colors,
      toggle_disabled_: false,
      buttons_active_: false,
      cg_interested_list: [],
    }
  },
  methods: {
    isEmpty,
    getDayAndTime,
    getDayOfWeek,

    do_nothing() {
      console.log("doing nothing")
    },

    async handle_view_click(name){
      // store.commit(`Click handling: "${uuid}"`, 'notification_enqueue')
      console.log(`View clicked ${name}`)
      // let tmh_name = this.ad_handle_values.translations[name]
      // store.commit('notification_enqueue', `${tmh_name} Clicked`)
    },

     async handle_click_click(name){
      // store.commit(`Click handling: "${this.ad_handle_values['uuid']}"`, 'notification_enqueue')
      // store.commit('notification_enqueue', `Click handling: "${this.ad_handle_values['uuid']}"`)

      // console.log(`Handlinin click id  ${this.ad_handle_values['uuid']}`)
      // console.log(`The current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)
      // store.commit('notification_enqueue', `${name} Clicked`)
      // console.log(this.ad_handle_values.translations)
      // let tmh_name = this.ad_handle_values.translations[name]
       let tmh_name = name
      // store.commit('notification_enqueue', `${tmh_name} Clicked`)
       store.commit('update_context_changing_route', false)
       // console.log("Just set this is a non-context changing route.")
       // console.log(`The current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)


       let latest = localStorage.getItem('dev_latest_route')
       // console.log(`Here is the current latest: ${latest}`)
      let this_route = `${latest}/plus/interested/${tmh_name}/${this.ad_handle_values['uuid']}`
       // console.log("Here is the attempted route.")
       // console.log(this_route)
      // store.commit('save_latest_route', this_route)
      // console.log(`Going to ${this_route}`)
      await this.$router.push({path: this_route})


    },


    not_implemented_notification() {
      if (this.buttons_active) {
        let message = "This feature is not yet implemented."
        store.commit('notification_enqueue', message)
        this.toggle_is_false()

      }

    },
    toggle_is_true() {
      this.buttons_active_ = true
    },
    toggle_is_false() {
      this.buttons_active_ = false
      this.$refs.ad_handle_toggle.toggle_state_to_false()
    },
    // extent_ad(){
    //   this.emit_id("extent_ad")
    // },
    retire_ad() {
      console.log(`Retiringt the ad id: ${this.ad_id}`)
      if (this.buttons_active) {
        this.emit_id("retire_ad")
        this.toggle_is_false()
      }
    },
    extend_ad() {
      console.log(`Extending the ad id: ${this.ad_id}`)
      if (this.buttons_active) {
        this.emit_id("extend_ad")
        this.toggle_is_false()
      }
    },
    emit_id(name) {
      this.$emit(name, this.ad_id)
    },
  },
  computed: {
    toggle_disabled() {
      return this.toggle_disabled_
    },
    buttons_active() {
      return this.buttons_active_
    },
    expires_pretty() {
      return translate_utc_std_timestring_to_local_pretty_timestring(this.expires)
    },

  },
}
</script>

<style scoped>

@media (min-width: 100px) {
  .handle_headline_text {
    margin: 10px
  }
  .views{
    font-size: 20px;
  }

  .clicks{
    font-size: 20px;
  }

  .handle_body_text {
    margin: 15px
  }

  .ad_handle_details {
    font-size: 15px;
    max-width: 100vw;
  }

}


</style>