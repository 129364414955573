<template>
  <section>
    <h3>WonderPerks Bonus</h3>
  </section>
  <outside_footer class="footer_c"></outside_footer>


</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable vue/no-unused-vars */
/* eslint-disable no-unused-vars */
import {colors} from "@/library";
import inside_banner from "@/components/parts/inside_banner";
import outside_footer from "@/components/parts/outside_footer";
// import top_label_value_non_editable from "@/components/parts/top_label_value_non_editable";

export default {
  name: "caregiver_main_display",
  components: {
    inside_banner,
    outside_footer
    // top_label_value_non_editable
  },
  props: [

  ],
  data() {
    return {
      colors: colors
    }
  },

}
</script>

<style scoped>

.main_display {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "left_top    right_top"
                       "left_bottom right_bottom"
                       "left_very_bottom right_very_bottom";
}

#top_left {
  grid-area: left_top;

}

#top_right {
  grid-area: right_top;
}

#bottom_left {
  grid-area: left_bottom;
}

#bottom_right {
  grid-area: right_bottom;
}

#left_very_bottom {
  grid-area: left_very_bottom;
}

#right_very_bottom {
  grid-area: right_very_bottom;
}

</style>