<template>
  <div class="core_c ">
    <error_popup_queue></error_popup_queue>
    <notification_popup></notification_popup>
    <landing_banner></landing_banner>


    <div class="flex_centered_row ">
      <div class="flex_centered_start_column">


        <div class="top_section wid_100vw  ">
          <div class="top_section_content ">

            <div v-if="!mobile_mode" class="flex_centered_column">
              <div>
                <img class="padding_tb_20" id="cg_topper_img"
                     src="@/assets/stairs2.svg"
                     alt="Four people holding hands on stairs.">
              </div>
            </div>

            <div class="flex_centered_column right_side">
              <h3 class="text_align_center">Find a Caregiving Job Close to Home</h3>
              <p class="text_align_center top_text">
                Tiny Magic Hat lets you browse available schedules from the best local agencies that include client
                neighborhoods so you can find a client close to home.
              </p>

              <basic_text_button
                  class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
                  :hover_color=colors.cyanic_blue
                  :hover_text_color=colors.white
                  :button_state="true"
                  :button_color="colors.new_orange_main"
                  :text_color=colors.white
                  :button_color_not_ready="colors.eli_gray"
                  :button_not_ready_text_color="colors.pennie_gray"
                  button_height="52px"
                  :button_width=button_wid
                  border_rad_pct="0"
                  text="BROWSE SCHEDULES NOW"
                  text_size="14px"
                  :bold="true"
                  event_name="browse_button_clicked"
                  @browse_button_clicked='simple_route_prep("spyglass")'
              ></basic_text_button>
            </div>
          </div>
        </div>

        <!--        <div class=" ">-->
        <carousel_container
            class="carousel"
            :my_slides="get_carousel_quotes()"
            :body_color="colors.new_cyanic_main"
        ></carousel_container>
        <!--        </div>-->

        <div class="below_carousel">
          <h3 class="text_align_center">How does it work? Easy.</h3>
        </div>

        <div class="cards">
          <home_card2
              class=" margin_t_20 first_card"
              base_video_height="180"
              base_video_width="320"
              video_id=""
              button_text="Learn More"
              @main_btn_clicked="do_nothing"
              button_color=""
               icon="icon_browse_schedules_black.svg"
              desc="Check out StarFinder"
              :hide_button="true"
              :headline_color="colors.primary_purple"
              button_text_color=""
              :hover_color="colors.cyanic_blue"
              :hover_text_color="colors.white"
              :icon_color="colors.white"
              :icon_bg_color="colors.new_orange_main_light"
          >
            <template v-slot:headline>
              <div>Browse Available Schedules</div>
            </template>
            <template v-slot:body>
              <div>Agencies are constantly updating available schedules, check regularly for schedules that fit your
                needs.
              </div>
            </template>
          </home_card2>
          <home_card2
              class=" second_card "
              base_video_height="180"
              base_video_width="320"
              video_id=""
              button_text="Learn More"
              @main_btn_clicked="do_nothing"
              button_color=""
               icon="icon_group_black.svg"
              desc="Find a job Easy"
              :hide_button="true"
              :headline_color="colors.primary_purple"
              button_text_color=""
              :hover_color="colors.cyanic_blue"
              :hover_text_color="colors.white"
              :icon_color="colors.white"
              :icon_bg_color="colors.new_gold"
          >
            <template v-slot:headline>
              <div>Connect with the Agency</div>
            </template>
            <template v-slot:body>
              <div>You'll be talking with a representative from the agency within a few seconds. *You do need to
                register your free account first.
              </div>
            </template>
          </home_card2>
        </div>

        <basic_text_button
            class="padding_lr_6 padding_tb_6 bottom_button"
            :hover_color=colors.cyanic_blue
            :hover_text_color=colors.white
            :button_state="true"
            :button_color="colors.new_orange_main"
            :text_color=colors.white
            :button_color_not_ready="colors.eli_gray"
            :button_not_ready_text_color="colors.pennie_gray"
            button_height="52px"
            :button_width=button_wid
            border_rad_pct="0"
            text="BROWSE SCHEDULES NOW"
            text_size="14px"
            :bold="true"
            event_name="browse_button_clicked"
            @browse_button_clicked='simple_route_prep("spyglass")'
        ></basic_text_button>


      </div>

    </div>


  </div>
  <outside_footer_landing class="footer_c"></outside_footer_landing>

</template>

<script>
/* eslint-disable */
import error_popup_queue from "@/components/basics/error_popup_queue";
import notification_popup from "@/components/basics/notification_popup";
import outside_banner_std from "@/components/parts/outside_banner_std";
import outside_footer from "@/components/parts/outside_footer";
import basic_text_button from "@/components/parts/basic_text_button";
import video_modal from "@/components/parts/video_modal";
import input_with_label from "@/components/parts/input_with_label";
import carousel_container from "@/components/parts/my_carousel/carousel_container";
import home_card2 from "@/components/parts/home_card2";
import landing_banner from "@/components/parts/landing_banner";
import outside_footer_landing from "@/components/parts/outside_footer_landing";
import store from "@/store";
import {
  get_uuid,
  round,
  colors,
  desktop_size,
  scroll_to_top,
  validate_phonenumber,
  validate_email,
  be_main_address, parse_internal_json, get_ip_from_header, detect_connection_speed
} from "@/library";
import {Database} from "@/client_db";
import axios from "axios";

export default {
  name: "check_out_spyglass",
  created() {
    this.db = new Database();
    store.commit('create_initial_defaults')
  },
  async mounted() {
    this.id_ = get_uuid()
    this.scroll_to_top()
    this.run_window_width_adjustments(this.windowWidth)
    let ip_string = false
    ip_string = await get_ip_from_header()

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
    await detect_connection_speed()
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [],
  components: {
    error_popup_queue,
    notification_popup,
    outside_footer,
    outside_banner_std,
    basic_text_button,
    input_with_label,
    video_modal,
    carousel_container,
    home_card2,
    landing_banner,
    outside_footer_landing
  },
  data() {
    return {
      id_: "",
      colors: colors,
      mobile_mode_x: true,
      name: '',
      password: '',
      email: '',
      phone_number: '',
      next_route: '',
      button_wid_: 250,
      show_hamburger_menu_: false,
      desktop_cutoff: 1000,
      v_def_height: 310,
      v_def_width: 150,
      video_modal: {
        state: false,
      },
      video: {
        height: "310",
        width: "150"
      },
      // my_slides: [
      //   {text1: 'WonderPerks bonuses have really make a big difference on my paycheck.', text2: 'Darcie P.', id: 1},
      //   {text1: 'Who\'s there?', text2: 'More text', id: 2},
      //   {text1: 'Tank.', text2: 'More text', id: 3},
      //   {text1: 'Tank who?', text2: 'More text', id: 4},
      //   {text1: 'You\'re welcome', text2: 'More text', id: 5},
      //   {text1: 'Oh, stop it, you\'re just killing me!', text2: '--Wyatt', id: 6},
      // ],

    }
  },
  methods: {
    do_nothing() {
      console.log("do nothing")
    },
    tester() {
      // console.log("button was pressed.")
    },
    get_carousel_quotes() {
      // return  store.commit('get_carousel_quotes', 'for_agencies')
      return store.getters.get_home_carousel_quotes
    },
    video_modal_false() {
      this.video_modal.state = false
    },

    video_modal_true() {
      console.log("changing video modal to true")
      this.video_modal.state = true
    },
    scroll_to_top() {
      scroll_to_top()
    },
    run_window_width_adjustments(new_w) {
      // console.log("Running window adjustments")
      if (new_w >= this.desktop_cutoff) {
        this.mobile_mode_x = false
      } else {
        this.mobile_mode_x = true
      }
      if (this.num_is_between_inclusive(100, 300, new_w)) {
        this.v_scale = 1.2
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
        this.button_wid_ = 250
      }
      if (this.num_is_between_inclusive(301, 500, new_w)) {
        this.v_scale = 1.5
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(501, 700, new_w)) {
        this.v_scale = 1.75
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(501, 900, new_w)) {
        this.v_scale = 2.0
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
      }
      if (this.num_is_between_inclusive(901, 1199, new_w)) {
        this.v_scale = 3.0
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
        console.log(`Height: ${this.video.height}`)
        console.log(`Width: ${this.video.width}`)
      }
      if (this.num_is_between_inclusive(1200, 9000, new_w)) {
        this.v_scale = 3.0
        this.video.height = this.v_def_height * this.v_scale
        this.video.width = this.v_def_width * this.v_scale
        this.button_wid_ = 300
      }

    },
    num_is_between_inclusive(bottom, top, num) {
      return bottom <= num && top >= num;
    },
    hamburger_menu_toggle() {
      this.show_hamburger_menu_ = !this.show_hamburger_menu_
    },

    hamburger_menu_false() {
      this.show_hamburger_menu_ = false
    },

    catch_password(pw) {
      this.password = pw.trim()
      store.commit('save_password', this.password)
    },

    catch_phone_number(phone_number) {
      let phone = phone_number.trim()
      //  remove all non-digits
      phone = phone.replace(/\D/g, '');
      // validate
      if (validate_phonenumber(phone)) {
        this.phone_number = phone
      } else {
        // let m = phone_number + "is not valid."
        // store.commit('error_enqueue', m)
        return null
      }
    },

    catch_email(content) {

      if (validate_email(content)) {
        this.email = content.trim().slice(0, 100)
      } else {
        // let m = content + "is not a valid email address."
        // store.commit('error_enqueue', m)
        return null
      }
    },
    catch_username(name) {
      this.name = name.toLowerCase().trim()
      store.commit('save_name', this.name)
    },
    simple_route_prep(r) {
      this.hamburger_menu_false()
      // console.log("in simple route prep")
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
    async register() {
      // console.log("Getting ready to launch register...")
      // let the_router = this.$router

      this.$refs.register_name_box.emit_state()
      this.$refs.register_password_box.emit_state()
      this.$refs.register_phone_number_box.emit_state()
      this.$refs.register_email_box.emit_state()

      // this.$refs.register_email.emit_state()

      let error_out
      if (this.name.length === 0) {
        // console.log("name length was 0, no good")
        store.commit('error_enqueue', "You must provide a user name to register.")
        error_out = true
      }
      if (this.password.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a password to register.")
        error_out = true
      }
      if (this.email.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a valid email to register.")
        error_out = true
      }
      if (this.phone_number.length === 0) {
        // console.log("password length was 0, no good")
        store.commit('error_enqueue', "You must provide a phone number to register.")
        error_out = true
      }
      if (error_out === true) {
        // console.log("Ran into a login error, returning null.")
        return null
      }

      // console.log("Getting ready to make axios call.")
      let target = be_main_address('main_api') + "create_new_user"
      console.log("create new user address")
      console.log(target)

      console.log(`Phone Number: ${this.phone_number}`)
      await axios.post(target, {
        name: this.name,
        password: this.password,
        phone_number: this.phone_number,
        email: this.email
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          // console.log("Something went wrong with your login.")
          store.commit('error_enqueue', "Bad registration. Check name, password and phone.")
          console.log(error)
          error_out = true
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          error_out = true
          return null
        } else {
          // console.log("in the error catch all")
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
          error_out = true
          return null
        }
      }).then((response) => {
            console.log("Got good response to register.");
            console.log(response.data);
            store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {

              this.$store.commit('save_name', this.name)
              this.$store.commit('save_phone', this.phone_number)
              this.name = ''
              this.phone_number = ''
              this.password = ''
              this.$router.push('/register_confirm');
            } else {
              console.log("Something went wrong with register method.")
              console.log(response.data)
            }
          }
      )

    },

    async process_asset_package(asset_package) {
      // console.log("inside processing asset package.")

      //save jwt authentication token in vuex
      this.$store.commit('save_id_token', asset_package['id_token'])
      this.$store.commit('save_refresh_token', asset_package['refresh_token'])
      // console.log('Printing token from store: ' + this.$store.getters.my_token);

      // console.log("getting ready to parse the assets")
      let the_assets = parse_internal_json(asset_package['assets'])
      // console.log(JSON.stringify(the_assets))
      for (let [key, value] of Object.entries(the_assets)) {
        // console.log(`Adding ${key} to the database.`)
        await this.db.addKeyValue(key, value);
      }

      // console.log("done with processing the asset package")
    },

  },
  computed: {
    id() {
      return this.id_
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    button_wid() {
      return `${this.button_wid_}px`
    },

    modal_up() {
      return this.video_modal.state
    },
    show_hamburger_menu() {
      return this.show_hamburger_menu_
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    v_width() {
      return this.video.width
    },
    v_height() {
      return this.video.height
    },
  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },

}
</script>
<style scoped>
@import '../../../../common.css';


header {
  grid-area: top;
  display: flex;
  justify-content: center;
}

figure {
  margin: 0;
}


form label {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  color: var(--black);
}


form label[for=user-name] {
  margin-top: 2vh;
}

form input {
  margin-bottom: 2.25vh;
  font-size: 6vw;
  padding-left: 17px;;
  font-family: 'Raleway', sans-serif;
  border-radius: 2em;
  height: 22px;
  width: 50vw;
}


@media (min-width: 100px) {

  .first_card {
    margin-top: -10px;
    margin-bottom: -50px;
  }

  .second_card {
    /*margin-top: -30px;*/
    margin-bottom: 50px;

  }

  h3 {
    font-size: 27px;
    padding-top: 50px;
    padding-bottom: 50px;
    /*padding-right: 20px;*/
    /*padding-left: 20px;*/
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 280px;
    /*margin-bottom: -20px;*/
  }

  p {
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .top_section {
    background: linear-gradient(180deg, var(--new-primary-purple-very-deep), var(--new-primary-purple-deep) 40.0%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

  }

  .top_section_content {
    padding-bottom: 50px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .right_side h3 {
    color: white;
  }

  .right_side p {
    color: white;
  }

  .carousel {
    margin-top: 50px;
  }

  .bottom_button {
    margin-bottom: 50px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

}


@media (min-width: 360px) {

}

@media (min-width: 400px) {
  .below_carousel h3 {
    padding-top: 80px;
    padding-bottom: 0;
    margin-bottom: 100px;
    font-size: 30px;
  }
}

@media (min-width: 500px) {
  h3 {
    font-size: 37px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }

  p {
    font-size: 25px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .carousel {
    margin-top: 100px;
  }

  .second_card {
    margin-top: unset;
  }

}


@media (min-width: 750px) {

  .top_text {
    max-width: 85%;
  }

}

@media (min-width: 1000px) {

  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    max-width: 90%;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }

  .first_card {
    margin-top: 0;
    margin-bottom: 0;
  }

  .second_card h3 {
    max-width: 250px;
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  #cg_topper_img {
    width: 450px;
  }

  .bottom_button {
    margin-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    padding-top: 80px;
    padding-bottom: 0;
    max-width: 350px;
    font-size: 35px;
    /*margin-bottom: -20px;*/
  }


}

@media (min-width: 1200px) {

  .right_side {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .right_side h3 {
    width: 390px;
    text-align: center;
    font-size: 42px;
  }

  #cg_topper_img {
    width: 500px;
  }

  .top_section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .below_carousel h3 {
    color: var(--primary-purple);
    margin-top: 100px;
    font-size: 38px;
  }

  .cards {
    margin-top: 0px;
  }

  .top_text {
    max-width: 800px;
  }


}

@media (min-width: 2000px) {

  .carousel {
    margin-top: 150px;
  }

  .top_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 1450px;

    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
  }


}

</style>