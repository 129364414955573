<template>
  <div class="core_c">

    <inside_banner
        mode="speedydelivery_agency"
        :owner_perm="admins_perm"
        @agency_info_clicked="do_nothing"
        @invite_clicked="do_nothing"
        @promote_clicked="do_nothing"
    >
    </inside_banner>

    <basic_page_infrastructure
        @local_page_update="do_nothing"
        @continue_clicked="confirm_delete_module"
    ></basic_page_infrastructure>


    <section class="flex_centered_start_column ">
      <div class="flex_centered_row margin_b_30">
        <img @click="go_back" class="padding_tb_20 sd_logo cursor_ptr"
             src="@/assets/popdocs.svg"
             alt="PopDocs Feature Logo">
      </div>
      <div class="screen_block bg_lauren_gray padding_tb_40 margin_b_30">
        <h3 class="text_align_center flex_centered_column  ">Create New In-Service</h3>
        <div class="flex_centered_column">

          <input_with_label
              ref="inservice_title"
              class="form_input"
              label="In-Service Title"
              @emit_state="catch_title"
          ></input_with_label>

          <input_with_label
              ref="inservice_desc"
              class="form_input margin_b_20"
              label="Short Description"
              @emit_state="catch_body"
          ></input_with_label>

          <basic_text_button
              class="padding_lr_6 padding_tb_6 bottom_button"
              :hover_color=colors.cyanic_blue
              :hover_text_color=colors.white
              :button_state="true"
              :button_color="colors.cyanic_blue"
              :text_color=colors.white
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              button_height="52px"
              button_width="125px"
              border_rad_pct="0"
              text="Create"
              text_size="14px"
              :bold="true"
              event_name="backend_test_clicked"
              @backend_test_clicked=create_new_inservice
          ></basic_text_button>
        </div>

      </div>

    </section>

    <section class="flex_centered_start_column ">
      <div class="flex_centered_row">
        <div class="flex_centered_column">
          <h2 v-if="mod_data.length>0" class="margin_tb_15">In-Services</h2>
        </div>
      </div>


      <div v-for="module in mod_data" :key="module">



        <module_display_unit
            v-if="module['module_active'] === 1"
            @module_clicked="go_to_in_service_assignments_page(module)"
            @delete_module_clicked="ask_to_delete_module"
            :module_data="module"
            :stats="false"
            :del_button="true"
        >
        </module_display_unit>
      </div>

    </section>


  </div>
  <outside_footer class="footer_c margin_t_50"></outside_footer>
</template>

<script>
/* eslint-disable */
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import outside_banner_std from "@/components/parts/outside_banner_std";
import inside_banner from "@/components/parts/inside_banner";
import outside_footer from "@/components/parts/outside_footer";
import spinner from "@/components/parts/spinner";
import basic_text_button from "@/components/parts/basic_text_button";
import module_display_unit from "@/components/SpeedyDelivery/module_display_unit";
import input_with_label from "@/components/parts/input_with_label";
import store from "@/store";
// import image_upload_widget_general from "@/components/parts/image_upload_widget_general";
import camera_widget from "@/components/parts/camera_widget";
import {
  get_uuid,
  round,
  colors,
  desktop_size,
  scroll_to_top,
  get_pda_home_route,
  be_main_address, searchArrayForValue,open_spinner, close_spinner,
  prime_socket, receive_over_socket, clone_object,non_contextual_route
} from "@/library";
import {Database} from "@/client_db";
import {mapGetters} from "vuex";
// import Image_upload_widgit_general from "@/components/parts/image_upload_widget_general";
import axios from "axios";

export default {
  name: "custom_in_service",
  async mounted() {
    this.db = new Database();
    this.id_ = get_uuid()
    scroll_to_top()
    await this.local_page_update()
    await this.check_for_general_admin_ui_perm()
    this.run_window_width_adjustments(this.windowWidth)
    await prime_socket(this.local_message_handler)

    try {
      await this.$nextTick(function () {

        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }

      })
    } catch (error) {
      console.log(error)
    }
  },
  async updated() {
    try {
      await this.$nextTick(function () {
        if (this.bg_color) {
          let box = document.getElementById(this.id)
          box.style.background = this.bg_color
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  props: [],
  components: {
    // Image_upload_widgit_general,
    outside_footer,
    outside_banner_std,
    spinner,
    basic_text_button,
    basic_page_infrastructure,
    inside_banner,
    camera_widget,
    // image_upload_widget_general,
    module_display_unit,
    input_with_label
  },
  data() {
    return {
      id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      admins_perm_: false,
      button_size_: "50px",
      button_img_size_: "50px",
      input: {
        title: "",
        desc: "",
      },

      mod_data: [],

    }
  },
  methods: {
    do_nothing(something) {
       console.log("Just got something...")
      console.log(something)
    },
    ask_to_delete_module(inservice_module){
      console.log("You just clicked to delete a custom inservice")
      console.log(inservice_module)
      let m = `Are you sure you want to delete ${inservice_module['module_name']}?`
      // store.commit('notification_enqueue', `You just clicked to delete a custom inservice: ${inservice_module['module_name']}`)
      store.commit('continue_enqueue', [m, inservice_module['module_id']])
      // let r = inservice_module['module_id']
      // this.deactivate_custom_inservice(inservice_module['module_id'])
    },
    confirm_delete_module(module_id){
      this.deactivate_custom_inservice(module_id)
    },
    async go_to_in_service_assignments_page(inservice_module){
      console.log("You just clicked a custom inservice")
      console.log(inservice_module)
      let r = inservice_module['module_id']

      await non_contextual_route(this.$router, r)
      // this.simple_route_prep(q)
    },
    async local_message_handler(from_server_json) {
      let update = await receive_over_socket(from_server_json)
      if (update) {
        await this.local_page_update()
      }
    },
    async local_page_update() {
      console.log("Running cUSTOM INSERVICE Interface local page update.")
      await this.load_in_services_from_local_db()
    },

    async load_in_services_from_local_db() {
      console.log("INSIDE LOAD THE CUSTOM INSERVIES FROM THE DATABASE.")

      let search = {
        key: "",
        additional_context: [['div', 'o&a'], ['div', 'in_services']],
        base_context: store.getters.find_current_preamble
      }

      // console.log(JSON.stringify(search))

      let all_docs = await this.db.db_search(search)
      console.log("Found the following In Service Documents")
      console.log(JSON.stringify(all_docs))
      console.log(`Found ${all_docs.length} docs.`)

      if (all_docs.length === 0) {
        return null
      }

      function filter_out_duplicate_module_ids(arrayOfObjects, singleObject) {
        const moduleIdToMatch = singleObject.module_id;

        // Use the Array filter method to remove objects with matching module_id
        const filteredArray = arrayOfObjects.filter(obj => obj.module_id !== moduleIdToMatch);

        // Add the singleObject to the filteredArray
        filteredArray.push(singleObject);

        return filteredArray;
      }

      let mod_data_clone = clone_object(this.mod_data)
      for (const c of all_docs) {
        console.log(typeof c)
        console.log(c)
        mod_data_clone = filter_out_duplicate_module_ids(mod_data_clone, c)

      }
       this.mod_data = mod_data_clone
    },


    async deactivate_custom_inservice(module_id){

      let body = {
        body: this.input.desc,
        title: this.input.title
      }
      let body_json = JSON.stringify(body)
      console.log(body_json)

      open_spinner()
      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        module_id: module_id
      }
      console.log("This is data1")
      console.log(data1)

      let target = be_main_address('sd') + "deactivate_custom_in_service"

      await axios.post(target, {
        method: 'post',
        url: target,
        data: data1,
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          console.log("Something went wrong with your in-service deactivation.")
          console.log(error.response)
          store.commit('error_enqueue', "Something went wrong with in-service deactivation.")
          console.log(error)
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          return null
        } else {
          return null
        }
      }).then((response) => {
            console.log("Got good response to in-service deactivation.");
            console.log(response.data);
            store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {
              //pass
            } else {
              console.log("Something went wrong with the in-service deactivation.")
              console.log(response.data)
            }
          }
      )
      close_spinner()

    },

    async create_new_inservice() {

      if (!this.input.desc || !this.input.title) {
        return null
      }

      let duplicate_in_service = searchArrayForValue(this.mod_data, 'module_name', this.input.title)
      if(duplicate_in_service){
        store.commit("error_enqueue", `There is already an in-service named ${this.input.title}.`)
        return null
      }


      let body = {
        body: this.input.desc,
        title: this.input.title
      }
      let body_json = JSON.stringify(body)
      console.log(body_json)


      let data1 = {
        caller: store.getters.my_name,
        subscriber: store.getters.org_context,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        module_name: this.input.title,
        module_desc: this.input.desc,
        icon_file: "sd_in_service.svg",
      }
      console.log("This is data1")
      console.log(data1)

      let target = be_main_address('sd') + "create_custom_in_service"

      open_spinner()

      await axios.post(target, {
        method: 'post',
        url: target,
        data: data1,
      }).catch(function (error) {
        console.log("in the catch")
        console.log(error)
        if (error.response) {
          console.log("Something went wrong with your login.")
          console.log(error.response)
          store.commit('error_enqueue', "Something went wrong with in-service creation.")
          console.log(error)
          return null
        } else if (error.request) {
          let online = window.navigator.onLine;
          let message
          if (online) {
            message = "There are problems with your network connection, or Tiny Magic Hat may be down."
          } else {
            message = "You appear to have lost your network connection."
          }
          store.commit('error_enqueue', message)
          return null
        } else {
          return null
        }
      }).then((response) => {
            console.log("Got good response to register.");
            console.log(response.data);
            store.commit('notification_enqueue', response.data['message'])
            if (response.data.error === false) {
              this.input.desc = ""
              this.input.title = ""
              this.$refs.inservice_desc.reset_state()
              this.$refs.inservice_title.reset_state()
            } else {
              console.log("Something went wrong with push test attempt.")
              console.log(response.data)
            }
          }
      )
      close_spinner()

    },

    catch_body(content) {
      this.input.desc = content.trim().slice(0, 200)
    },
    catch_title(content) {
      this.input.title = content.trim().slice(0, 50)
    },
    async go_back() {
      await this.$router.back()
    },
    async go_to_profile_home_route() {
      await this.$router.push({path: `${get_pda_home_route()}`})
    },

    run_window_width_adjustments(new_w) {
      if (new_w > 100) {
        console.log(`New size is ${new_w}`)
        // remove_class_to_body('content_no_radial')
        // remove_class_to_body('content_large_screen')
        this.button_size_ = "50px"
        this.button_img_size_ = "50px"
      }
      if (new_w > 500) {
        console.log(`New size is ${new_w}`)
        this.button_size_ = "100px"
        this.button_img_size_ = "80px"
      }
      if (new_w > 1000) {
        console.log(`New size is ${new_w}`)
        this.button_size_ = "110px"
        this.button_img_size_ = "90px"
      }

      if (new_w > 1500) {
        console.log(`New size is ${new_w}`)
        this.button_size_ = "110px"
        this.button_img_size_ = "90px"
      }

    },

    simple_route_prep(r) {
      let this_route = `${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },
    async check_for_general_admin_ui_perm() {
      // console.log("inside get profile image from database.")
      let search = {
        key: 'route',
        additional_context: [['div', 'o&a'], ['div', 'perms']],
        base_context: store.getters.find_current_preamble
      }
      let profile_data = await this.db.db_search(search)
      // console.log(profile_data)
      let target = "speedydelivery:agency"
      if (profile_data.includes(target)) {
        this.admins_perm_ = true
        console.log("You have the speedydelivery general admins perm")
        return true
      } else {
        this.admins_perm_ = false
        console.log("You don't have the speedydelivery general admins perm")
        return false
      }
    },
  },
  computed: {
    ...mapGetters([
        'socket_connection_counter'
    ]),
    id() {
      return this.id_
    },
    spinner_up() {
      return store.getters.get_spinner_state
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    mobile_mode() {
      return this.mobile_mode_x
    },
    admins_perm() {
      return this.admins_perm_
    },

  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
     async socket_connection_counter(old_val, new_val) {
      await prime_socket(this.local_message_handler)
    },
  },
}
</script>
<style scoped>
@import '../../common.css';

@media (min-width: 100px) {
  h1 {
    color: white;
    font-size: 24px;
  }

   .sd_logo{
    width: 150px;
    margin-bottom: 40px;
  }

  h2{
    font-size: 20px;
  }


  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 25px;
    text-align: center
  }


  .standard_method p {
    font-size: 20px;
  }

  .mfa_method p {
    font-size: 20px;
  }


}

@media (min-width: 280px) {
   .sd_logo{
    width: 150px;
    margin-bottom: 0;
  }

  h3{
    font-size: 24px;
    margin-bottom: 20px;
  }

   h2 {
    font-size: 24px;
     margin-top: 40px;
  }

}

@media (min-width: 500px) {
   h2{
    font-size: 30px;
  }

  .sd_logo{
    width: 200px;
    margin-bottom: 0;
  }
}

@media (min-width: 800px) {
}

@media (min-width: 1000px) {
  .ft_logo {
    width: 400px
  }
}

@media (min-width: 1000px) {
 .sd_logo{
    width: 250px;
    margin-bottom: 0;
  }
}

@media (min-width: 2000px) {
  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 32px;
  }

  .standard_method p {
  }


  .mfa_method p {
  }


}
</style>