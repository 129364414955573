<template>
  <div class="screen_block padding_tb_20">
    <div class="flex_centered_column">

      <div class="flex_centered_row padding_b_10 label_regular">
        {{ label }}
      </div>

      <div class="flex_centered_column res_width margin_lr_10">
        <input class="margin_b_10 b_rad_10" v-model="text1_" :placeholder="placeholder1">
        <input class="margin_b_10 b_rad_10" v-model="text2_" :placeholder="placeholder2">
        <input class="margin_b_10 b_rad_10" v-model="text3_" :placeholder="placeholder3">
        <input class="b_rad_10" v-model="text4_" :placeholder="placeholder4">
        <input class="margin_b_10 b_rad_10" v-model="text5_" :placeholder="placeholder5">
        <input class="margin_b_10 b_rad_10" v-model="text6_" :placeholder="placeholder6"> <!-- Added Pretty Name Input -->

        <drop_down_select
          class="margin_b_10"
          ref="drop_down_child"
          title="Select Timezone"
          :values="na_tzs"
          @value-selected="timezone_selected"
        ></drop_down_select>
      </div>

      <div class="flex_space_around_row flex_wrap margin_tb_10">
        <basic_toggle ref="toggle_child"
                      class="margin_lr_10"
                      :uuid="child_toggle_uuid"
                      :state_disabled="!(text1 && text2 && text3 && text4 && text5 && text6 && tz_selected)"
                      @toggle-state_true="toggle_is_true"
                      @toggle-state_false="toggle_is_false"
        ></basic_toggle>

        <launch_button
            ref="button_child"
            :uuid="child_button_uuid"
            :button_state_start=button_state_start
            :button_text=button_text
            @launch="launch"
        >
        </launch_button>
      </div>
    </div>
  </div>
</template>

<script>
import basic_toggle from "@/components/parts/basic_toggle";
import launch_button from "@/components/parts/launch_button";
import drop_down_select from "@/components/parts/drop_down_select";
import {north_american_timezones} from "@/dates_library";
import {v4 as uuidv4} from 'uuid';

export default {
  name: "six_texts_dropdown_and_launch", // Updated name for clarity
  emits: ['precheck', 'create_pda_assign_owner'],
  props: ['uuid', 'label', 'label_color','event_name','placeholder1', 'placeholder2', 'placeholder3', 'placeholder4',
    'placeholder5', 'placeholder6','button_text', 'button_state_start'],
  components: {basic_toggle, launch_button, drop_down_select},
  async mounted() {
    // console.log("running mounted")
    let bg = this.$el
    bg.style.color = this.label_color
  },
  data() {
    return {
      text1_: "",
      text2_: "",
      text3_: "",
      text4_: "",
      text5_: "",
      text6_: "", // Added text6 data property
      tz_selected_: '',
      na_tzs: north_american_timezones,
      child_button_uuid: uuidv4(),
      child_toggle_uuid: uuidv4()
    }
  },
  methods: {
    toggle_is_true() {
      // console.log("Child toggle reports true, emitting precheck.")
      this.$emit('precheck', this.text1, this.text2, this.text3, this.text4, this.text5, this.text6, this.uuid)
    },
    toggle_is_false() {
      console.log("Child toggle reports false")
      this.$refs.button_child.reset();
      this.$refs.toggle_child.toggle_toggle();
    },
    timezone_selected(tz){
      console.log(`Just got a selected tz of`)
      console.log(tz)
      this.tz_selected_ = tz
    },
    launch() {
      this.$emit(this.event_name, this.text1, this.text2, this.text3, this.text4, this.text5, this.text6, this.tz_selected, this.uuid)
    },
    reset() {
      this.$refs.button_child.reset();
      this.$refs.toggle_child.toggle_toggle();
      this.$refs.drop_down_child.reset();
      this.reset_text1()
      this.reset_text2()
      this.reset_text3()
      this.reset_text4()
      this.reset_text5()
      this.reset_text6() // Added reset for text6
    },
    reset_text1() {
      this.text1_ = ""
    },
    reset_text2() {
      this.text2_ = ""
    },
    reset_text3() {
      this.text3_ = ""
    },
    reset_text4() {
      this.text4_ = ""
    },
    reset_text5() {
      this.text5_ = ""
    },
    reset_text6() { // Added reset function for text6
      this.text6_ = ""
    },
    toggle_state_to_false() {
      this.$refs.toggle_child.toggle_state_to_false();
    },
    toggle_state_to_true() {
      this.$refs.toggle_child.toggle_state_to_true();
    },
    button_state_to_false() {
      this.$refs.button_child.button_state_to_false();
    },
    button_state_to_true() {
      this.$refs.button_child.button_state_to_true();
    },
  },
  computed: {
    text1() {
      return this.text1_
    },
    text2() {
      return this.text2_
    },
    text3() {
      return this.text3_
    },
    text4() {
      return this.text4_
    },
    text5() {
      return this.text5_
    },
    text6() { // Added computed property for text6
      return this.text6_
    },
    tz_selected(){
      return this.tz_selected_
    },
  },
}
</script>

<style scoped>

</style>
